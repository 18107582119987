.back_btn_and_cart {
  position: sticky;
  top: 71px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 0.4rem 0;
  width: 100%;
  z-index: 1;

  .back_btn_and_cart_inner {
    width: 1114px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .storeBack {
      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.15rem;
      }

      color: #000;
      font-family: Poppins;
      font-size: 1.15rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.8rem;
      cursor: pointer;
    }
  }
}
