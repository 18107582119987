.rewardModal{
  border-radius: 8px;
  background: #FFF;
  box-shadow: 2px 2px 8px 0px rgba(59, 59, 59, 0.25);
  // outline: 10px solid #6D48EF;

  .modal-content {
    width: 80%;
    margin: auto;
  }

  .btn-close {
    opacity: 1;
  }

  .coinText{
    color: #2D2D2D;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 600;
    line-height: normal;
  }
  .coinText1{
    color: #121212;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    letter-spacing: 0.16px;
    margin: 20px 0 35px;
  }
  .coinNum{
    color: #2D2D2D;
    font-family: "Poppins";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
  }
  .coinBtn{
    color: #FFF;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    padding: 12px 32px;
    border-radius: 4px;
    background: #B50503;
    text-decoration: none;
  }
  .coinText3{
    color: #2D2D2D;
    text-align: center;
    font-family: "Poppins";
    font-size: 11px;
    font-weight: 400;
    line-height: normal;

    span{
      color: #1D60C5;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}