.TestReportContentComponentWrapper {
  .papers_tab_wrapper {
    .outer_content_wrapper {
      .content_wrapper {
        .left_side_content_wrapper {
          text-align: center;
          margin-bottom: 25px;
          display: inline-flex;
          margin-left: 189px;

          .subject_type_collection_wrapper {
            display: flex;
            flex-wrap: nowrap;
            background-color: #f5f4f8;
            border-radius: 25px;
            overflow: hidden;

            .single_select_subject_type {
              display: flex;
              align-items: center;
              min-width: 160px;
              margin: 0 5px;

              input[type="radio"] {
                opacity: 0;
                position: absolute;
                width: 0;
              }

              label {
                background-color: #fafafd;
                padding: 0.5rem 1rem;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                width: 100%;
                text-align: center;
                transition: background-color 0.3s, color 0.3s;
              }

              input[type="radio"]:checked + label {
                background-color: #ff933a;
                color: #ffffff;
              }
            }
          }
        }

        .paper {
          // background: lightgreen;
          // margin-top: 40px;
          width: 90%;
          margin: 0 auto;

          section {
            margin-bottom: 30px;
          }

          .testcards {
            display: flex;
            gap: 2.5rem;
            width: 100%;

            .testCard {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: flex-start;
              // padding: 1rem 2.75rem 1rem 1rem;
              padding: 0.8rem 0;
              border-radius: 0.5rem;
              border: 0.953px solid #e8e8e8;
              width: 25%;

              .testCardImg {
                img {
                  width: 2rem;
                  height: 2rem;
                  margin-right: 0.8rem;
                }
              }

              .testCardRight {
                .testCardRightTop {
                  margin-bottom: 0.13rem;
                  color: #828282;
                  text-align: center;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                .testCardRightBottom {
                  // color: #00afb9;
                  font-family: Poppins;
                  font-size: 1.3rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.015rem;
                }

                .expected {
                  color: #00afb9;
                }

                .allindia {
                  color: #ec6f64;
                }
                .yourmarks {
                  color: #02519a;
                }

                .toppermarks {
                  color: #9a7902;
                }
              }
            }
          }

          .redirectPredictClg {
            border-radius: 0.5rem;
            background: linear-gradient(269deg, #eecef3 -30.7%, #f7c7ad 97.88%);
            box-shadow: 2px 2px 0px 0px rgba(255, 226, 229, 0.24);

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 27px;
            height: 102px;

            .redirectPredictClgPara {
              color: #000;
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.02rem;
              width: 80%;

              .btm-textt {
                color: #363636;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            button {
              /* Your existing styles */
              color: #ffffff;
              font-family: Poppins;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0em;
              gap: 0.7rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              background: #b50503;
              /* Add transition for smooth animation */
              transition: transform 0.2s ease, box-shadow 0.2s ease;
            }

            /* Animation on hover */
            button:hover {
              /* Add a slight scale up effect when hovering */
              transform: scale(1.05);
              /* Add a slight shadow when hovering */
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            }
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
