.excrvideoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .examcracker_view_videoSolution_btn {
    text-align: center;
    margin-bottom: 1rem;

    border-radius: 0.25rem;
    background: #263238;

    padding: 0.625rem 1.25rem;
    color: #fff;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  #iframe {
    height: 420px;
    width: 100%;
  }
}
