.bitSatTestheading {
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #121212;
  margin-bottom: 20px;
}

.bitSat_WiseWrapper {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  .bitSat_box {
    cursor: pointer;
    margin: 0 20px 20px 0;
    width: 500px;

    &:nth-child(2n) {
      margin: 0 0 20px 0;
    }
    
    .mock_test_content {
      border-radius: 8px;
      border: 0.8px solid #E9E9E9;
      background: #FFF;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px;
      
      .img_wrpr img {
        width: 55px;
        border-radius: 4px;
      }
      .content {
        margin-right: 100px;
        margin-left: 10px;
        .mock_test_heading {
          font-family: "Poppins";
          font-weight: 500;
          font-size: 16px;
          line-height: 27px;
          color: #121212;
          // margin-top: -5px;
        }
        .total_questions_time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .total_question {
            margin-right: 12px;
            display: flex;
            align-items: center;
          }
          span {
            margin-right: 3px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: normal;
            color: #515151;
            letter-spacing: 0.12px;
          }
          .total_time {
            display: flex;
            align-items: center;
          }
        }
      }

      .arrow {
        margin-left: auto;

        img {
          width: 6px;
        }
      }
    }
  }
  .bitsat_no_data_found_wrapper {
    margin: auto;
    margin-top: 50px;
    p {
      text-align: center;
    }
  }
}
@media (max-width: 575.98px) {
  .bitSat_WiseWrapper{
    margin-left: 0;
    .bitSat_box{
      width: 100%;
      justify-content: center;
      .mock_test_content {
        .content{
          margin-left: 0;
          margin-right: 0;
        }
      }
      .img_wrpr{
        display: none;
      }
    }
  }
}
