.questionClassWise {
  margin-bottom: 30px;
  .section-headingg {
    color: #373737;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0.5rem;
  }

  .table_section {
    // border: 1px solid #c2c2c2;
    // border-radius: 6px;

    table {
      width: 100%;

      .table_heading {
        text-align: center;

        tr {
          background: #fff;
          border: 1px solid #d2d2d2;

          th {
            padding: 10px 25px;

            color: #121212;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.01rem;

            .sub_subjects {
              display: flex;
              justify-content: space-around;
              align-items: center;
              span {
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
              }
            }
          }

          .chem {
            text-align: center;
            border-top-right-radius: 6px;
          }

          .line {
            border: 1px solid #dddddd;
            // width: 93%;
            letter-spacing: 10px;
            margin-top: -2px;
            margin-left: 7px;
          }

          .firstTd {
            text-align: left !important;
            border-top-left-radius: 6px;
            width: 33.5%;
          }
        }
      }

      tbody {
        tr {
          // border-bottom: 1px dashed #dec2ab;
          // background: #fffcfa;
          // background: #000;
          background: #fff;

          &:nth-child(2n + 1) {
            // background: #faf6f3;
            background: #fff;
          }

          td {
            text-align: center;
            padding: 10px 25px 5px 25px;
            border-bottom: 1px dashed #fffdf5;
          }

          .num {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .firstTd {
            text-align: left !important;
            // color: #373737;
            color: #373737;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            // background: #fff;
          }

          .chemistrySubSubjects {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
          }
        }

        :last-child {
          border-bottom: none;
          background: none;
        }
      }
    }
  }
}
