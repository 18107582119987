.slider_container {
  margin-top: 20px;
  margin-bottom: 4rem;
  .container {
    .dashboard_offerings_slider_inner_wrapper {
      .slick-list {
      }

      .slick-slide > div {
        padding: 0 8px !important;
      }

      .slick-arrow {
        box-shadow: 0px 0px 30px #0000001a;
        width: 30px;
        height: 30px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAXCAYAAADQpsWBAAAABHNCSVQICAgIfAhkiAAAAOhJREFUOE+N1L8KwjAQBnC76HuJiGAHQcXV2SkdnFz7AJY+gKvg4KM4C7pJB58hafwOYumf5HJZQsn9SMh3aTISjizLDtba8RkjkRil1Al1uatdR1EPVNhtyqI+MMakZVk+gigE6IhexAEvioEBkoAOkoIGAVzwsXc5VP9bCmWYAFyxuHMFX4AFXSsXOqE7ClZUhOA+mGZFUTxZRIuAN0wbB9+YlxxscgKk3qIeox1Z2Am3DWFfwKlvx0FHSGCojZqj+nbkGjYIY0/DCyWPsAO11vMocjm24VaECOLHcqzreoIIcjFqt9UPHiuwN42MBW0AAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-position: center;
        background-color: #fff;
        border-radius: 50%;
        z-index: 1;
        padding: 5px;
        background-size: 9px;
        top: 46%;
        position: absolute;
      }

      .slick-prev:before,
      .slick-next:before {
        content: "";
      }

      .slick-prev {
        left: -14px;
      }

      .slick-next {
        right: -12px;
        transform: rotate(180deg) translateY(50%);
        top: 50%;
      }

      .new_offerings_header_text h4 {
        color: #000000;
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 0.2rem;
      }


      .new_offerings_wrapper {
        .new_offerings_inner_wrapper {
          border-radius: 0.5rem;

          .new_offerings_image_wrapper {
            img {
              width: 100%;
              object-fit: cover;
              border-top-right-radius: 0.5rem;
              border-top-left-radius: 0.5rem;
            }
          }

          .new_offerings_content {
            font-family: Poppins;
            display: flex;
            background: #ffffff;
            padding: 1.125rem;
            justify-content: center;
            align-items: center;
            border-bottom: 0.038rem solid #cacaca;
            border-left: 0.038rem solid #cacaca;
            border-right: 0.038rem solid #cacaca;

            border-bottom-right-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;

            .new_offerings_title_descript {
              width: 60%;

              .new_offerings_title {
                color: #121212;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.023rem;
              }

              .new_offerings_description {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                color: #676767;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .new_offerings_start_btn {
              width: 40%;
              display: flex;
              justify-content: center;

              .n_o_btn {
                padding: 0.875rem 2.625rem;
                border-radius: 0.25rem;
                background: #b50303;
                color: #fff;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-left: 2.5rem;
              }
            }
          }
        }
      }

      .new_offerings_wrapper:hover {
        box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.06),
          -4px -4px 10px 2px rgba(0, 0, 0, 0.06);
      }

    }
  }
}

@media (max-width: 575.98px) {
  .slider_container {
    margin-bottom: 20px;
    .container {
      padding: 0px;
      .dashboard_offerings_slider_inner_wrapper {
        .new_offerings_header_text h4 {
        }
        .new_offerings_wrapper {
          // min-height: 250px;

          .new_offerings_inner_wrapper {
            .new_offerings_coming_soon {
            }

            .img_content {
              flex-direction: column;
              margin-bottom: 0;

              .new_offering_image {
                // text-align: center;
                img {
                  margin-top: 10px 0;
                  margin-left: auto;
                  margin-right: auto;
                }
              }
              .new_offerings_content_wrapper {
                text-align: center;
                padding: 10px;
                .new_offerings_title {
                }
                .new_offerings_desc p {
                }
              }
            }
            .new_offerings_button {
              p {
              }
            }
          }
        }

        .new_offerings_wrapper:hover {
          .new_offering_image img {
          }

          .new_offerings_content_wrapper {
            .new_offerings_title {
            }

            .new_offerings_desc p {
            }
          }
        }
      }
    }
  }
}
