.QuestionListPanelContent {
  .subsection_wrapper {
    background-color: #ffffff;
    padding: 25px 40px 15px 25px;
  }
  .text_content_wrapper {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text_content {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      color: #0f0404;
    }

    button {
      font-weight: bold;
      text-decoration: underline;
    }

    .countdown_timer_container {
      min-width: 55px;

      span {
        label {
          color: #6d48ef;
          font-size: 15px;
        }
      }
    }
  }

  .question_marking_wrapper {
    flex: 0 0 100%;
    margin-bottom: 20px;
    .question_marking_Innrwrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 5px;

      .single_marking {
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0px;
        }

        .box_pic {
          width: 14px;
          height: 14px;
          border-radius: 3px;
          margin-right: 5px;
        }

        .box_pick_background_1 {
          border: 1px solid #8ae09f;
          background: #c8f1d2;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          margin-right: 5px;
        }

        .box_pick_background_2 {
          border: 1px solid #d9d9d9;
          background: #fff;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          margin-right: 5px;
        }

        .box_pick_background_3 {
          background-color: #e2e2e2;
          border: 1px solid #e2e2e2;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          margin-right: 5px;
        }

        .box_pick_background_4 {
          background: #fed5d5;
          border: 1px solid #ffc4c4;
          width: 20px;
          height: 20px;
          border-radius: 4px;
          margin-right: 5px;
        }

        .box_pick_background_5 {
          background: #6d48ef;
          border: 1px solid #6d48ef;
        }

        .question_marking_text {
          font-size: 12px;
          font-weight: 500;
          color: #000;
          vertical-align: middle;
          margin-top: -4px;
        }
      }
    }
  }

  .questions_serial_number_collection {
    font-family: "Poppins";
    overflow-y: scroll;
    overflow-y: auto;
    max-height: 83vh;
    padding: 0.5rem;
    margin-bottom: 0;
    background: #f6f6f7;
    .single_question_number_wrapper {
      margin: 5px;
      display: inline-block;

      .arrow {
        width: 8px;
      }

      .unanswered_lbl {
        font-size: 14px;
        font-weight: 500;
        color: #000;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #e2e2e2;
        cursor: pointer;
        display: flex;
        align-items: center;
        background: #e2e2e2;
        justify-content: space-between;
        min-width: 40px;
        &.review_lbl {
          padding: 10px;
          border-radius: 8px;
          background: #ffc4c4;
          border: 1px solid #ffc4c4;
          cursor: pointer;
        }

        &.skip_lbl {
          border: 1px solid #ffc4c4;
          background-color: #fed5d5;
        }

        &.current_lbl {
          border: 1px solid #ff933a;
          cursor: pointer;
          background: #ffffff;
        }

        &.answered_lbl {
          border: 1px solid #e2e2e2;
          background-color: #48ef4c;
        }

        .questionBox {
          display: flex;
          align-items: baseline;
          overflow-x: hidden;

          .QBox {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
            width: 370px;

            .wrs_chemistry {
              display: block;
              margin: 20px auto;
              font-family: Arial, sans-serif;
              font-size: 14px;
              overflow-x: auto;

              mtable {
                width: 100%;
                border-collapse: collapse;
                border: 1px solid #ccc;

                mtr {
                  &:nth-child(even) {
                    background-color: #f5f5f5;
                  }

                  mtd {
                    padding: 5px;
                    border: 1px solid #ccc;
                    vertical-align: middle;
                    text-align: center;

                    mtext {
                      display: inline-block;
                    }

                    mtable {
                      margin-top: 5px;
                      width: 100%;

                      mtr {
                        mtd {
                          padding: 5px;
                          border: 1px solid #ccc;
                          vertical-align: middle;
                          text-align: center;
                          display: inline-block;
                        }
                      }
                    }
                  }
                }
              }
            }

            .wrs_chemistry::-webkit-scrollbar {
              width: 4px;
            }

            .wrs_chemistry::-webkit-scrollbar-track {
              background-color: #f1f1f1;
            }

            .wrs_chemistry::-webkit-scrollbar-thumb {
              background-color: #888;
              border-radius: 2px;
            }

            .wrs_chemistry math,
            .wrs_chemistry mo,
            .wrs_chemistry mi,
            .wrs_chemistry mn,
            .wrs_chemistry msub,
            .wrs_chemistry mtext,
            .wrs_chemistry mfenced,
            .wrs_chemistry msup,
            .wrs_chemistry mrow {
              display: inline;
              height: 20px;
            }

            .tableContainer {
              max-height: 200px;
              max-height: 200px; /* Adjust the max-height as per your requirements */
              overflow-y: auto;
              overflow-x: auto;

              ::-webkit-scrollbar {
                width: 4px !important;
                height: 2px !important;
              }

              ::-webkit-scrollbar-track {
                background-color: #f1f1f1;
              }

              ::-webkit-scrollbar-thumb {
                background-color: #888;
                border-radius: 4px;
              }

              ::-webkit-scrollbar-thumb:hover {
                background-color: #555;
              }
            }

            .tableContainer {
              table {
                width: 50%;
                table-layout: fixed;

                td {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            .tableContainer::-webkit-scrollbar {
              width: 4px !important;
              height: 4px !important;
            }

            img {
              width: 100%;
              max-height: 100px;
              object-fit: cover;
            }
            &.twoLines {
              p {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                margin-left: 5px;
              }
            }

            p {
              margin-left: 2px;
              margin-bottom: 0px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            p > p {
              /* Add  desired styles for nested <p> elements here */
              display: inline;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
