.userAnalyticsContentComponent_wrapper {
  padding-top: 55px;
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 20px;

  .userAnalyticsContentComponent_inner_wrapper {
    margin: 40px;
    background-color: rgba(238, 239, 240, 0.5);
    border-radius: 10px;
    padding: 30px 20px;

    .section_wrapper {
      .section_inner_wrapper {
        h2 {
          font-size: 25px;
          font-weight: 600;
          text-align: center;
          margin-bottom: 20px;
        }

        .options_wrapper {
          .custom_nav_pills {
            // padding: 5px;
            width: max-content;
            align-items: center;
            justify-content: center;
            background: #f5f4f8;
            width: max-content;
            margin: 0 auto 35px;
            border-radius: 8px;
            overflow: hidden;

            .custom_nav_item {
              // min-width: 170px;
              background-color: #ffffff;

              .custom_nav_link {
                border-radius: 0;
                background-color: #ffffff;
                color: #585858;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 10px 27.5px;
                letter-spacing: 0.2px;
                cursor: pointer;
                text-align: center;
                border-bottom: 2px solid #fff;
              }

              .custom_nav_link.active {
                border-bottom: 2px solid #b50303;
                font-weight: 500;
                color: #b50303;
              }
            }
          }
        }

        .tab_wrapper {
          .custom_tab_content {
            //////overall test tab////
            .custom_tab_pane {
              .overall_test_tab_wrapper {
                h3 {
                  font-size: 22px;
                  font-weight: 600;
                  margin-bottom: 20px;
                }

                .outer_content_wrapper {
                  .Chartcontent_wrapper {
                    .sec_1_wrapper {
                      // background-color: #ffffff;
                      // background-color: red;
                      border-radius: 10px;
                      margin: 40px 0;
                      display: flex;
                      justify-content: space-between;

                      .sec_1_inner_wrapper {
                        display: block;
                        padding: 10px;
                        background-color: #fff;
                        width: 48%;
                        border-radius: 8px;

                        .marksChart {
                          .marksIndicator {
                            display: flex;
                            justify-content: flex-end;
                            margin: 10px 0px 15px 0px;

                            .mrk_indc {
                              font-size: 12px;

                              margin-right: 15px;
                              span {
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                margin-right: 5px;
                                display: inline-block;
                              }

                              .correct {
                                background-color: #668de1;
                              }

                              .incorrect {
                                background-color: red;
                              }

                              .attempted_tests {
                                background-color: #31d680;
                              }

                              .unattempted_tests {
                                background-color: #ff933a;
                              }
                            }
                          }
                        }

                        #canvasjs-react-chart-container-1645621796406 {
                          height: 300px !important;
                        }

                        .canvasjs-chart-toolbar {
                          display: none;
                        }

                        .canvasjs-chart-credit {
                          display: none;
                        }
                      }
                    }

                    .sec_2_wrapper {
                      background-color: #ffffff;
                      border-radius: 10px;
                      margin: 10px 0;

                      .sec_2_inner_wrapper {
                        padding: 10px;

                        .progress_bar_collection {
                          .single_progress_bar_wrapper {
                            margin: 5px 0;

                            .progress_bar_heading_wrapper {
                              .progress_bar_heading_content {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 500;
                                color: #6b6876;
                              }
                            }

                            .progress_bar_content_wrapper {
                              display: flex;
                              justify-content: space-between;
                              align-items: center;

                              .progress_bar_wrapper {
                                flex: 0 0 80%;

                                progress {
                                  -webkit-appearance: none;
                                  -moz-appearance: none;
                                  appearance: none;
                                  width: 100%;
                                  height: 10px;
                                  border-radius: 10px;
                                }

                                progress::-webkit-progress-bar {
                                  background: #f2f2f3;
                                  border-radius: 25px;
                                }

                                progress::-webkit-progress-value {
                                  background: #a02b2d;
                                  border-radius: 25px;
                                }

                                progress::-moz-progress-bar {
                                  background: #8d8d99;
                                  border-radius: 25px;
                                }

                                progress::-moz-progress-value {
                                  background: #a02b2d;
                                  border-radius: 25px;
                                }
                              }

                              .percentage_content_wrapper {
                                flex: 0 0 10%;

                                .percentage_content {
                                  margin: 0;
                                  font-size: 14px;
                                  font-weight: 500;
                                  color: #09041a;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .my_progress {
              background-color: #f5f4f8;

              .subject_collection_wrapper {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: max-content;
                background: #f5f4f8;
                border-radius: 4px 0px 0px 4px;
                margin: 0 auto;
                overflow: hidden;
              }

              .heading-text {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 22px;
                line-height: 33px;
                margin: 0px 0px 20px 20px;

                color: #060606;
              }

              .progress_box_wrapper {
                margin-top: 30px;
                width: 100%;
                background-color: #f5f4f8;
                padding: 15px 15px 15px 15px;
                .progress_box {
                  width: 100%;

                  display: flex;
                  align-items: center;

                  .progress-circle {
                    width: 100%;
                    padding: 12px;
                    position: relative;

                    .Default {
                    }

                    .CircularProgressbar {
                      border-radius: 50%;
                      width: 150px;
                    }

                    .CircularProgressbar .CircularProgressbar-trail {
                      stroke: #f5f4f8;
                    }

                    .CircularProgressbar .CircularProgressbar-path {
                    }

                    .CircularProgressbar .CircularProgressbar-text {
                      fill: #000;
                      font-size: px;

                      font-weight: 600;
                    }
                  }
                }
              }
            }

            //////individual test tab////
            .custom_tab_pane {
              .individual_test_tab_wrapper {
                // background-color: #ffffff;
                // border-radius: 10px;

                .outer_content_wrapper {
                  padding: 0 10px;
                  padding: 20px;

                  .left_side_content_wrapper {
                    margin-bottom: 30px;

                    .subject_collection_wrapper {
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      width: max-content;
                      background: #f5f4f8;
                      border-radius: 20px;
                      margin: 0 auto;
                      overflow: hidden;

                      .single_select_subject {
                        input[type="radio"] {
                          opacity: 0;
                          position: fixed;
                          width: 0;
                        }

                        label {
                          padding: 6px 20px;
                          font-size: 15px;
                          font-weight: 500;
                          letter-spacing: 0.2px;
                          color: #6b6876;
                          cursor: pointer;
                          display: flex;
                          align-items: center;
                        }

                        input[type="radio"]:checked + label {
                          background: #b7b7b7;
                          border: none;
                          color: #ffffff;
                        }
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    h3 {
                      font-size: 20px;
                      font-weight: 600;
                    }

                    .tests_collection_wrapper {
                      display: flex;
                      flex-wrap: wrap;
                      min-height: 350px;
                      max-height: 450px;
                      overflow-y: auto;
                      align-content: baseline;
                      padding: 10px;

                      .single_test_wrapper {
                        width: calc(50% - 8px);
                        padding: 13px;
                        border-radius: 0.5rem;
                        border: 0.8px solid #e9e9e9;

                        background: #fff;
                        margin: 0px 16px 16px 0px;

                        &:nth-child(2n + 2) {
                          margin-right: 0px;
                        }

                        .sub_section_wrapper_1 {
                          margin: 5px 0;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;

                          label {
                            font-size: 12px;
                            color: #707070;
                          }

                          .paper_id_text {
                            color: #707070;
                          }

                          .paper_idd {
                            font-size: 12px;
                            margin-left: 0.2rem;
                            color: #0f0404;
                          }
                        }

                        .sub_section_wrapper_2 {
                          margin: 5px 0;

                          .text_content_wrapper {
                            .text_content {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #0f0404;
                            }
                          }
                        }

                        .sub_section_wrapper_3 {
                          margin: 5px 0;

                          .sub_content_wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .left_content_wrapper {
                              label {
                                font-size: 14px;
                                text-align: center;
                                color: #6b6876;
                              }
                            }

                            .right_content_wrapper {
                              // border: 1px solid #b7b7b7;
                              text-align: center;
                              // width: 25%;
                              cursor: pointer;
                              // border-radius: 6px;
                              padding: 4px 15px;

                              .lbl_1 {
                                color: #b91311;
                                cursor: pointer;
                                font-family: "Poppins";
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 0.875rem; /* 100% */
                                letter-spacing: 0.00875rem;
                              }
                            }
                          }
                        }
                      }
                    }

                    .tests_collection_wrapper::-webkit-scrollbar {
                      width: 4px;
                    }

                    .tests_collection_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px grey;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .tests_collection_wrapper::-webkit-scrollbar-thumb {
                      background: #b7b7b7;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .tests_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: #b7b7b7;
                    }

                    .navigation_btn_wrapper {
                      margin: 10px 0;

                      .navigation_btn_inner_wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .btn_wrapper {
                          button {
                            border: none;
                            outline: none;
                            width: auto;
                            height: 36px;
                            border-radius: 5px;
                            border: 2px solid #b7b7b7;
                            background-color: #b7b7b7;
                            font-size: 16px;
                            font-weight: 600;
                            text-align: center;
                            color: #ffffff;
                            padding: 0 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            //////learn analysis tab////
            .custom_tab_pane {
              .learn_analysis_tab_wrapper {
                background-color: #ffffff;
                border-radius: 10px;

                .outer_content_wrapper {
                  padding: 0 10px;
                  display: flex;
                  justify-content: space-between;
                  padding: 20px;

                  .left_side_content_wrapper {
                    flex: 0 0 25%;

                    .subject_collection_wrapper {
                      display: flex;
                      flex-wrap: wrap;
                      flex-direction: column;
                      align-items: center;

                      .single_select_subject {
                        margin: 10px 0;

                        input[type="radio"] {
                          opacity: 0;
                          position: fixed;
                          width: 0;
                        }

                        label {
                          border: solid 1px #f0eef8;
                          background-color: #fafafd;
                          padding: 10px 20px;
                          border-radius: 25px;
                          font-size: 16px;
                          font-weight: 500;
                          letter-spacing: 0.2px;
                          color: #6b6876;
                          cursor: pointer;
                          display: flex;
                          align-items: center;
                        }

                        input[type="radio"]:checked + label {
                          background-color: #b7b7b7;
                          border: none;
                          color: #ffffff;
                        }
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    flex: 0 0 65%;

                    .sub_content_1 {
                      display: flex;
                      justify-content: space-evenly;
                      align-items: center;
                      flex-wrap: wrap;

                      .graph_wrapper {
                        flex: 0 0 45%;
                        margin: 5px;
                      }
                    }

                    .sub_content_2 {
                      .progress_bar_collection {
                        .single_progress_bar_wrapper {
                          margin: 5px 0;

                          .progress_bar_heading_wrapper {
                            .progress_bar_heading_content {
                              margin: 0;
                              font-size: 14px;
                              font-weight: 500;
                              color: #6b6876;
                            }
                          }

                          .progress_bar_content_wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .progress_bar_wrapper {
                              flex: 0 0 80%;

                              progress {
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                width: 100%;
                                height: 10px;
                                border-radius: 10px;
                              }

                              progress::-webkit-progress-bar {
                                background: #f2f2f3;
                                border-radius: 25px;
                              }

                              progress::-webkit-progress-value {
                                background: #a02b2d;
                                border-radius: 25px;
                              }

                              progress::-moz-progress-bar {
                                background: #8d8d99;
                                border-radius: 25px;
                              }

                              progress::-moz-progress-value {
                                background: #a02b2d;
                                border-radius: 25px;
                              }
                            }

                            .percentage_content_wrapper {
                              flex: 0 0 10%;

                              .percentage_content {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 500;
                                color: #09041a;
                              }
                            }
                          }
                        }
                      }
                    }

                    .tests_collection_wrapper {
                      display: flex;
                      flex-direction: column;

                      .single_test_wrapper {
                        flex: 0 0 100%;
                        padding: 20px;
                        border-radius: 16px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
                        margin: 10px 0;
                        cursor: pointer;

                        .sub_section_wrapper_1 {
                          margin: 5px 0;

                          label {
                            border-radius: 15px;
                            background-color: #3aa02b;
                            padding: 5px 10px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #ffffff;
                          }
                        }

                        .sub_section_wrapper_2 {
                          margin: 5px 0;

                          .text_content_wrapper {
                            .text_content {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #0f0404;
                            }
                          }
                        }

                        .sub_section_wrapper_3 {
                          margin: 5px 0;

                          .sub_content_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .left_content_wrapper {
                              label {
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
                                color: #6b6876;
                              }
                            }

                            .right_content_wrapper {
                              .lbl_1 {
                                font-size: 14px;
                                font-weight: 500;
                                text-align: center;
                                color: #b7b7b7;
                                text-decoration: underline;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .userAnalyticsContentComponent_wrapper {
    .userAnalyticsContentComponent_inner_wrapper {
      margin: 40px 0px;
    }
  }
}
@media (max-width: 768px) {
  .userAnalyticsContentComponent_wrapper {
    .userAnalyticsContentComponent_inner_wrapper {
      .section_wrapper {
        .section_inner_wrapper {
          .tab_wrapper {
            .custom_tab_content {
              //////overall test tab////
              .custom_tab_pane {
                .overall_test_tab_wrapper {
                  .outer_content_wrapper {
                    .Chartcontent_wrapper {
                      .sec_1_wrapper {
                        flex-direction: column;
                        .sec_1_inner_wrapper {
                          width: 100%;
                          margin: 20px auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userAnalyticsContentComponent_wrapper {
    padding-top: 50px;

    .userAnalyticsContentComponent_inner_wrapper {
      margin: 20px 0px;
      padding: 20px 5px;

      .section_wrapper {
        .section_inner_wrapper {
          h2 {
            font-size: 22px;
          }

          .options_wrapper {
            .custom_nav_pills {
              margin-bottom: 0;
              .custom_nav_item {
                min-width: 120px;
              }
            }
          }

          .tab_wrapper {
            .custom_tab_content {
              .custom_tab_pane {
                .overall_test_tab_wrapper {
                  h3 {
                    font-size: 22px;
                  }
                }

                .individual_test_tab_wrapper {
                  .outer_content_wrapper {
                    .left_side_content_wrapper {
                      .subject_collection_wrapper {
                        .single_select_subject {
                          label {
                            padding: 6px 10px;
                          }
                        }
                      }
                    }
                    .right_side_content_wrapper {
                      .tests_collection_wrapper {
                        flex-wrap: nowrap;
                        flex-direction: column;
                        .single_test_wrapper {
                          width: 100%;
                          margin-right: 0;
                          text-align: center;
                          .sub_content_wrapper {
                            flex-direction: column;
                            .left_content_wrapper {
                              margin-bottom: 5px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
