.exam_cracker_test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;
          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .section_content_wrapper_backdrop {
            display: none;
          }

         
        }
      }
    }
  }
}

.onSubmitModal {
  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    margin-top: 0px !important;
    .submit_Popup {
      .exit_img {
        width: 100%;

        img {
          width: 35%;
          height: 130px;
          margin-bottom: 20px;
          margin-top: -25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #6d48ef;
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #6d48ef;
        }
        :hover {
        }
        :hover {
          background: #6d28ef;
        }

        .cancel {
          color: #6d48ef;
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}

///////////////////////////   REPORT ERROR MODAL ///////////////////////////

.errorModal {
  margin-top: 70px;
  .modal-content {
    border-radius: 16px;
  }
  text-align: center;

  .modal-header {
    margin-top: 20px;
    font-weight: 600;
    font-family: "Poppins";
    font-style: normal;
    border-bottom: 0 none;
    padding: 0;

    .btn-close {
      width: 0;
      height: 0;
      margin-right: 20px;
    }

    h3 {
      color: #000000;
      font-weight: 600;
      margin-left: 150px;
      font-size: 20px;
    }
  }

  .modal-body {
    padding: 0;

    .error_Popup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 85%;
      width: 85%;
      margin: auto;
    }

    .error_Popup .text_content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #606060;
    }

    .errorList {
      text-align: start;
      padding: 0;
      margin: 0;
    }

    .errorList .errorListItem {
      list-style: none;
      text-align: start;
      margin-bottom: 10px;
      font-size: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: #000000;
    }

    .errorList .errorListItem input {
      font-size: 25px;
    }

    .errorList .errorListItem input[type="radio"] {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 8px;
    }

    textarea::-webkit-input-placeholder {
      padding: 0;
      font-size: 14px;
      color: #b8b8b8;
    }

    button {
      padding: 10px 40px;
      font-weight: 500;
      background: #6d48ef;
      border-radius: 4px;
      color: #fff;
    }

    textarea {
      width: 100%;
      height: 85px;
      padding: 10px;
      border-radius: 4px;
      resize: none;
    }
  }
}
.classCorrect {
  border: 1px solid #5ddd9b;
  background: #e1f1ec !important;
}

.classWrong {
  border: 1px solid #f93030;
  background: #f6e0e4 !important;
}

.opacity {
  opacity: 0.5;
}

@media (max-width: 578px) {
  .exam_cracker_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            .section_content_wrapper_backdrop {
              // height: 100vh;
              width: 100vw;
              z-index: 1995;
              background-color: #000;
              opacity: 0.5;
              position: absolute;
              display: block;
              top: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
}
