
.pkg-terms {
  .modal-dialog {
    margin: auto; /* Center the modal horizontally and vertically */
  }

  .modal-content {
    width: 46.875rem; /* Set your custom width */
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    .hed {
      color: #121212;
      text-align: center;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.00563rem;
      text-align: start;
      margin-bottom: 0.75rem;
    }
  }
}
