.Ots_ppr_test_intructions_view_wrapper {
  padding-top: 50px;
  //   width: 70%;
  margin: 0 auto;
  max-width: 1320px;

  .ots_test_intructions_view_inner_wrapper {
    background-color: rgba(238, 239, 240, 0.50);
    border-radius: 16px;
    padding: 35px;
    max-width: 1230px;
    margin: 0 auto 20px;

    //istruction Screen
    .OtsInstructionScreenBox {
      .heading-div {
        .backBtn {
          cursor: pointer;
          display: inline-block;
        }
  
        .ots_instruction_title {
          color: #000;
          font-family: "Poppins";
          font-size: 22px;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          width: 100%;
          margin-bottom: 40px;
        }
      }

      .ots_instruction_detailBox {
        max-width: 820px;
        margin: 0 auto;

        .OtsExamTime {
          border-radius: 8px;
          border: 0.6px solid #979797;
          background: #FFF;
          padding: 20px 50px;
          text-align: center;
          margin-bottom: 15px;

          .OtsExamTitle {
            color: #121212;
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
          }

          .OtsExamDurations {
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 137.5% */
            letter-spacing: 0.16px;

            span {
              font-weight: 600;
            }
          }
        }

        .OtsInstructions {
          border-radius: 8px;
          border: 0.6px solid #979797;
          background: #FFF;

          h3 {
            color: #000;
            font-family: "Poppins";
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            padding: 25px 20px 0;
            margin: 0;
          }

          .instPoint {
            padding: 10px 15px;

            img {
              width: 100%;
            }

            p {
              color: #424242;
              font-family: "Poppins";
              font-size: 14px;
              font-weight: 400;
              line-height: 23px;
            }

            h6 {
            }
          }
        }

        .img {
          text-align: center;
          padding: 15px 0px;
        }
      }
    }
  }
  .OtsStartTestBtn {
    display: flex;
    justify-content: center;

    button {
      width: 328px;
      border-radius: 4px;
      border: 0.6px solid #B91311;
      background: #B50503;
      color: #FFF;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      padding: 12px 16px;
    }
  }
}

.detailsModal {
  font-family: "Poppins";
  font-style: normal;
  margin-top: 100px;
  h3 {
    text-align: start;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #303030;
  }

  .form-group {
    margin-bottom: 20px;
    label {
      font-weight: 400;
      font-size: 17px;
      line-height: 27px;
      color: #444444;
    }
    span {
      color: #940001;
    }

    input::placeholder {
      color: #a2a2a2;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    input[type="submit"] {
      cursor: pointer;
      border: 1px solid #6d48ef;
      border-radius: 6px;
      height: 40px;
      width: 100%;
      background: linear-gradient(
        90.65deg,
        #6d48ef 0.21%,
        rgba(58, 19, 196, 0.93) 97.55%
      );
      box-shadow: 0px 2px 8px rgba(109, 72, 239, 0.16);
      border-radius: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;
      margin-bottom: -20px;
    }
  }
  .submit_btn {
    cursor: pointer;
    border: 1px solid #6d48ef;
    border-radius: 6px;
    height: 40px;
    width: 100%;
    box-shadow: 0px 2px 8px rgba(109, 72, 239, 0.16);
    border-radius: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
  }
}
// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .Ots_ppr_test_intructions_view_wrapper {
    padding-top: 0;
    .ots_test_intructions_view_inner_wrapper {
      height: 100vh;
      .OtsInstructionScreenBox {
          .ots_instruction_title {
            font-size: 22px !important;
          }
      
          .OtsInstructions {
            .instPoint {
              height: 50vh;
              overflow-y: scroll;
            }
          }
        }
    }
  }
}