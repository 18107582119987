.news-inner-wrapper {
  padding: 0 5rem;

  .news-heading-wrapper {
    .news-heading {
      color: #121212;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 1.75rem;
      font-weight: 800;
      line-height: 2.375rem;
      letter-spacing: 0.035rem;
      margin-bottom: 0.87rem;

      span {
        color: #b50503;
      }
    }

    .news-subheading {
      text-align: center;
      color: #494949;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 2.56rem;
    }
  }

  .news-content-wrapper {
    margin-top: 3.25rem;

    .news-content-inner-wrapper {
      .slick-slider {
        .slick-list {
          margin: 0 -10px;
        }

        .slick-slide {
          padding: 0 10px;
          outline: none;

          .news-slide {
            height: 100%;
          }

          .news {
            list-style: none;
            border-radius: 0.5rem;
            border: 1px solid #d1d1d1;
            transition: transform 0.3s ease;
            height: 100%;
            display: flex;
            flex-direction: column;

            &:hover {
              transform: scale(1.05);
              box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
            }

            .news-img {
              height: 60%;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.5rem 0.5rem 0 0;
              }
            }

            .news-title {
              color: #484848;
              font-family: "Poppins", sans-serif;
              font-size: 1rem;
              font-weight: 600;
              line-height: 1.5rem;
              margin-bottom: 0.5rem;
              text-align: start;
              padding: 0.75rem 1rem;
              background-color: #fff;
              flex-grow: 1;
              display: flex;
              align-items: center;
              min-height: 72px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .news-inner-wrapper .news-heading-wrapper .news-heading {
    font-size: 1.375rem;
    font-weight: 800;
    line-height: 2.375rem;
    letter-spacing: 0.0275rem;
  }
  .news-inner-wrapper .news-heading-wrapper .news-subheading {
    font-size: 0.875rem;
  }

  .news-inner-wrapper
    .news-content-wrapper
    .news-content-inner-wrapper
    .slick-slider
    .slick-slide
    .news
    .news-title {
    margin-bottom: 0px;
  }

  .news-inner-wrapper .news-content-wrapper {
    margin-top: 2.25rem;
  }
  .news-inner-wrapper {
    padding: 0 3rem;
  }
  .news-inner-wrapper
    .news-content-wrapper
    .news-content-inner-wrapper
    .slick-slider
    .slick-slide
    .news
    .news-title {
    font-size: 0.64831rem;
    line-height: 1.0445rem;
    margin-bottom: 0.5rem;
    padding: 0.75rem 1rem;
    background-color: #fff;
    flex-grow: 0;
    display: flex;
    align-items: center;
    width: 13.47031rem;
  }
}

@media (max-width: 768px) {
  .news-inner-wrapper {
    padding: 0 2rem;
  }

  .news-content-wrapper {
    .slick-slide {
      .news {
        .news-title {
          font-size: 0.875rem;
          line-height: 1.25rem;
          padding: 0.5rem 0.75rem;
        }
      }
    }
  }
}

@media (max-width: 765px) {
  .news-inner-wrapper
    .news-content-wrapper
    .news-content-inner-wrapper
    .slick-slider
    .slick-slide
    .news
    .news-title {
    font-size: 0.6631rem;
    line-height: 0.945rem;
    padding: 0.55rem 0.55rem;
    width: 10.47031rem;
  }
}

@media (max-width: 560px) {
  .news-inner-wrapper .news-heading-wrapper .news-heading {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.01125rem;
  }
  .news-inner-wrapper .news-heading-wrapper .news-subheading {
    width: 15rem;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: auto;
  }
  .news-inner-wrapper
    .news-content-wrapper
    .news-content-inner-wrapper
    .slick-slider
    .slick-slide
    .news
    .news-title {
    font-family: Poppins;
    font-size: 0.89344rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.43944rem;
    width: 18.56419rem;
  }
}
