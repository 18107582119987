.our_course_wrapper {
  font-family: Poppins;

  .course_title {
    h4 {
      margin-bottom: 1rem;
      color: #000;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    p {
      letter-spacing: 0.36px;
      color: #707070;
      opacity: 0.8;
      font-size: 14px;
    }
  }

  .course_box_wrapper {
    .slick-slider {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
    }

    .slick-list {
      width: 100%;
      overflow: hidden;
    }

    .slick-track {
      display: flex;
      flex-direction: row;
    }

    .slick-slide {
      height: auto;
      > div {
        height: 100%;
      }
    }

    .slick-arrow {
      box-shadow: 0px 0px 30px #0000001a;
      width: 30px;
      height: 30px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAXCAYAAADQpsWBAAAABHNCSVQICAgIfAhkiAAAAOhJREFUOE+N1L8KwjAQBnC76HuJiGAHQcXV2SkdnFz7AJY+gKvg4KM4C7pJB58hafwOYumf5HJZQsn9SMh3aTISjizLDtba8RkjkRil1Al1uatdR1EPVNhtyqI+MMakZVk+gigE6IhexAEvioEBkoAOkoIGAVzwsXc5VP9bCmWYAFyxuHMFX4AFXSsXOqE7ClZUhOA+mGZFUTxZRIuAN0wbB9+YlxxscgKk3qIeox1Z2Am3DWFfwKlvx0FHSGCojZqj+nbkGjYIY0/DCyWPsAO11vMocjm24VaECOLHcqzreoIIcjFqt9UPHiuwN42MBW0AAAAASUVORK5CYII=);
      background-repeat: no-repeat;
      background-position: center;
      background-color: #fff;
      border-radius: 50%;
      z-index: 1;
      padding: 5px;
      background-size: 9px;
      top: 50%;
      // transform: translateY(-50%);
      position: absolute;
    }

    .slick-prev:before,
    .slick-next:before {
      content: "";
    }

    .slick-prev {
      left: -15px;
    }

    .slick-next {
      right: -16px;
      transform: rotate(180deg) translateY(50%);
      top: 46%;
    }

    .course_bx_wrap {
      padding-right: 155px;

      .course_box {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: #fff;
        border-radius: 0.5rem;
        border: 0.6px solid #c2c2c2;
        overflow: hidden;
        width: 260px;

        .course_img {
          position: relative;

          img {
            width: 100%;
            height: 13.125rem;
            object-fit: cover;
          }
        }

        .course_details_wrapper {
          padding: 0.875rem;
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .course_detail {
            flex-grow: 1;

            h4 {
              color: #121212;
              font-size: 1rem;
              font-weight: 500;
              min-height: 3rem;
              margin-bottom: 0.5rem;
            }

            h6 {
              color: #121212;
              font-size: 10px;
              font-weight: 500;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              margin-bottom: 0.5rem;
            }

            .course_price {
              color: #121212;
              font-size: 0.875rem;
              font-weight: 700;
              margin-bottom: 0.5rem;

              s {
                margin-left: 0.5rem;
                color: #868686;
                font-size: 12px;
                font-weight: 400;
                text-decoration-line: line-through;
              }
            }

            p {
              color: #313131;
              font-size: 0.625rem;
              font-weight: 400;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              margin-bottom: 0.813rem;
            }
          }

          .enroll_btn {
            .button {
              border-radius: 4px;
              color: #fff;
              padding: 0.5rem 0;
              display: block;
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              background: #b91311;
              text-align: center;
              text-decoration: none;
              border: none;
              cursor: pointer;

              &:focus {
                outline: none;
              }
            }
          }
        }
      }

      .course_box:hover {
        box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.06),
          -4px -4px 10px 2px rgba(0, 0, 0, 0.06);
      }
    }
  }

  @media (max-width: 575.98px) {
    .course_title {
      width: auto;
    }

    .freePaid_courseTab {
      .freePaidTab {
        margin-bottom: 50px;

        .single_select_subject {
          label {
            padding: 7px 9px;
            font-size: 12px;
            width: max-content;
          }
        }
      }

      .seeAll {
        top: 135%;
        right: 15px;
        font-size: 14px;
      }
    }
  }
}
