.custom-player-container {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e8e8e8;
  
    background: #fff;
  
    .custom-player {
      audio {
        background-color: inherit !important;
      }
    }
  }
  