.course-inner-wrapper {
  padding: 0 5rem !important;

  .courses-header {
    padding-bottom: 2.25rem;

    .main-heading {
      color: #000;
      font-family: "Poppins", sans-serif;
      font-size: 1.75rem !important;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.035rem;
      text-align: center;
      padding-bottom: 0.5rem;
    }
    .main-heading span {
      color: #b50503;
      font-family: "Poppins", sans-serif;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.035rem;
    }

    .sub-heading {
      width: 33.1875rem !important;
      margin: 0 auto;
      color: #818181;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 1rem !important;
      font-weight: 400;
      line-height: 1.625rem;
    }
  }

  .courses-container {
    .courses-list {
      margin-top: 2rem;

      .course {
        width: 100%;
        height: 30rem !important;
        position: relative;
        overflow: hidden;
        border-radius: 1rem;
        transition: transform 0.3s ease;
        perspective: 1000px;

        .flip-card-inner {
          width: 100%;
          height: 100%;
          transition: transform 0.6s;
          transform-style: preserve-3d;
          position: relative;

          .flip-card-front,
          .flip-card-back {
            position: absolute;
            width: 100%;
            height: 100%;
            backface-visibility: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }

          .flip-card-front {
            transform: rotateY(0deg);
            z-index: 2;

            img {
              width: 6rem;
              height: 6rem;
              margin-bottom: 1rem;
            }

            span {
              color: #575757;
              font-size: 20px;
              font-weight: 500;
              text-align: center;
            }
          }

          .flip-card-back {
            transform: rotateY(180deg);
            z-index: 1;
            padding: 1.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .flip-card-back-content {
              width: 100%;
            }

            .flip-card-back-text-heading {
              font-size: 1.5rem;
              font-weight: 600;
              margin-bottom: 1rem;
              color: #333;
              text-align: center;
            }

            .flip-card-back-text {
              span {
                font-size: 18px;
                font-weight: 500;
                color: #666;
                display: block;
              }

              .class-items {
                display: flex;
                flex-wrap: wrap;
                gap: 0.5rem;

                .class-item {
                  .class-pill {
                    display: inline-block;
                    background-color: #fff;
                    color: #333;
                    border-radius: 1.5rem;
                    padding: 0.5rem 1rem;
                    font-size: 18px;
                    font-weight: 500;
                    border: 1px solid #6d6d6d;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }

        &.hovered {
          cursor: pointer;

          .flip-card-inner {
            transform: rotateY(180deg);
          }
        }
      }
    }
  }
}

.slick-slider {
  .slick-list {
    margin: 0 -0.625rem;
  }

  .slick-slide {
    padding: 0 0.625rem;
  }
}
@media (max-width: 1920px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 24rem !important;
  }
}
@media (max-width: 1800px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 22rem !important;
  }
}
@media (max-width: 1550px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 19rem !important;
  }
}
@media (max-width: 1400px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 18rem !important;
  }
}
@media (max-width: 1394px) {
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text-heading {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.765rem;
    color: #333;
    text-align: center;
  }
}

@media (max-width: 1234px) {
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items
    .class-item
    .class-pill {
    display: inline-block;
    background-color: #fff;
    color: #333;
    border-radius: 2rem;
    padding: 0.425rem 0.775rem;
    font-size: 1rem;
    font-weight: 500;
    border: 1px solid #6d6d6d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
@media (max-width: 1200px) {
  .course-inner-wrapper {
    padding: 0 3rem;
  }
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 16rem !important;
  }
}

@media (max-width: 1069px) {
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text-heading {
    font-size: 0.775rem;
    margin-bottom: 0.565rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items {
    grid-gap: 0.324rem;
    gap: 0.324rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items
    .class-item
    .class-pill {
    border-radius: 1rem;
    padding: 0.225rem 0.475rem;
    font-size: 0.7rem;
  }
}
@media (max-width: 1060px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 13rem !important;
  }
}

@media screen and (max-width: 1050px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 13rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    img {
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1024px) {
  .course-inner-wrapper {
    padding: 0 3rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    img {
    width: 3.35163rem;
    height: 3.35163rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 0.92706rem;
    letter-spacing: 0.01856rem;
  }
  .homepage-container .courses-wrapper {
    margin-top: 5rem;
  }
  .course-inner-wrapper .courses-header .main-heading {
    font-size: 1.375rem;
    letter-spacing: 0.0275rem;
  }
  .course-inner-wrapper .courses-header .main-heading span {
    color: #b50503;
    font-size: 1.375rem;
  }
  .course-inner-wrapper .courses-header .sub-heading {
    width: 33.1875rem;
    font-size: 0.875rem;
  }
  .course-inner-wrapper .courses-container .courses-list {
    margin-top: 0px;
  }
  .course-inner-wrapper .courses-container .courses-list .course {
    width: 10.875rem;
    height: 10.875rem;
  }
}
@media (max-width: 980px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 11rem !important;
  }
}
@media screen and (max-width: 950px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 10rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    img {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 926px) {
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back {
    transform: rotateY(180deg);
    z-index: 1;
    padding: 0.655rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items
    .class-item
    .class-pill {
    border-radius: 1rem;
    padding: 0.125rem 0.275rem;
    font-size: 0.555rem;
  }
}

@media (max-width: 900px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 10rem !important;
  }
}

@media screen and (max-width: 855px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    width: 100%;
    height: 10rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 0.625rem;
    letter-spacing: 0.02rem;
  }
}
@media (max-width: 818px) {
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text-heading {
    font-size: 0.575rem;
    margin-bottom: 0.365rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items {
    grid-gap: 0.124rem;
    gap: 0.124rem;
  }
}

@media (max-width: 820px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 9rem !important;
  }
}

@media screen and (max-width: 795px) {
}

@media (max-width: 768px) {
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    img {
    width: 45px;
    height: 45px;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 12px;
  }
  .course-inner-wrapper {
    padding: 0 2rem !important;
  }
  .homepage-container .courses-wrapper {
    margin-top: 5rem;
  }
  .course-inner-wrapper .courses-header .main-heading {
    font-size: 24px !important;
  }
  .course-inner-wrapper .courses-header .sub-heading {
    width: 32.1875rem !important;
    margin: auto;
    font-size: 16px !important;
    line-height: 23px;
  }
  .course-inner-wrapper .courses-container .courses-list {
    margin-top: 0px;
  }
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 10rem;
  }
}
@media screen and (max-width: 733px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 15rem;
  }
}

@media (max-width: 700px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 8rem !important;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 590px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 7rem !important;
  }
  .flip-card-back-class {
    font-size: 0.554rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text-heading {
    font-size: 0.475rem;
    margin-bottom: 0.265rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items {
    grid-gap: 0.111rem;
    gap: 0.111rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items
    .class-item
    .class-pill {
    padding: 0.125rem 0.275rem;
    font-size: 0.455rem;
  }
}
@media (max-width: 576px) {
  .course-inner-wrapper {
    .courses-header {
      .main-heading {
        font-size: 1.5rem;
      }

      .sub-heading {
        font-size: 0.875rem;
      }
    }

    .courses-container {
      .courses-list {
        .course {
          height: 12rem;

          .flip-card-front {
            img {
              width: 4rem;
              height: 4rem;
            }

            span {
              font-size: 1.25rem;
            }
          }

          .flip-card-back {
            .flip-card-back-text-heading {
              font-size: 1.25rem;
            }

            .flip-card-back-text {
              span {
                font-size: 1rem;
              }

              .class-items {
                .class-item {
                  .class-pill {
                    font-size: 0.875rem;
                    padding: 0.375rem 0.75rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 560px) {
  .course-inner-wrapper .courses-header .main-heading {
    font-size: 1.125rem;
    letter-spacing: 0.0225rem;
  }

  .course-inner-wrapper .courses-header .sub-heading {
    color: #4c4c4c;
    text-align: center;
    font-size: 0.75rem !important;
    font-weight: 400;
    line-height: 1.1875rem;
    width: 20.25rem !important;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    img {
    width: 3.9375rem;
    height: 3.9375rem;
    flex-shrink: 0;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.02rem;
  }
  .course-inner-wrapper {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 551px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 15rem !important;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    img {
    width: 6.9375rem;
    height: 6.9375rem;
    flex-shrink: 0;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 1rem !important;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text-heading {
    font-size: 0.876rem;
    margin-bottom: 0.265rem;
  }
  .flip-card-back-class {
    font-size: 0.954rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items {
    gap: 0.911rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items
    .class-item
    .class-pill {
    padding: 0.525rem 0.875rem;
    font-size: 0.655rem;
  }
}
@media screen and (max-width: 529px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 12rem !important;
  }
}
@media (max-width: 486px) {
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text-heading {
    font-size: 0.676rem;
    margin-bottom: 0.265rem;
  }
  .flip-card-back-class {
    font-size: 0.754rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items {
    grid-gap: 0.511rem;
    gap: 0.511rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items
    .class-item
    .class-pill {
    padding: 0.425rem 0.675rem;
    font-size: 0.555rem;
  }
}
@media (max-width: 450px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 10rem !important;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    img {
    width: 3.9375rem;
    height: 3.9375rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 0.7rem !important;
  }
}
@media (max-width: 406px) {
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 9rem !important;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text-heading {
    font-size: 0.576rem;
    margin-bottom: 0.165rem;
  }
  .flip-card-back-class {
    font-size: 0.654rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-back
    .flip-card-back-text
    .class-items
    .class-item
    .class-pill {
    padding: 0.225rem 0.475rem;
    font-size: 0.455rem;
  }
}

@media screen and (min-width: 425px) {
  .course-inner-wrapper {
    padding: 0 1rem;
  }
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 11rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 0.8rem;
    letter-spacing: 0.02rem;
  }
  .course-inner-wrapper .courses-header .main-heading {
    font-size: 1.125rem;
  }
  .course-inner-wrapper .courses-header .sub-heading {
    font-size: 0.75rem;
    width: 20.25rem;
  }
}
@media screen and (max-width: 376px) {
  .course-inner-wrapper {
    padding: 0 1rem;
  }
  .course-inner-wrapper .courses-container .courses-list .course {
    height: 10rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    img {
    width: 2.5rem;
    height: 2.5rem;
  }
  .course-inner-wrapper
    .courses-container
    .courses-list
    .course
    .flip-card-inner
    .flip-card-front
    span {
    font-size: 0.8rem;
    letter-spacing: 0.02rem;
  }
}
