.questions-panel {
  flex: 0 0 100%;
  .questions-panel-header {
    padding: 25px 40px 15px 20px;
    margin: 0;
    border-bottom: 1px solid #d1d1d1;
    .header-text {
      color: #121212;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
    }
  }

  .questions-panel-markings {
    padding: 10px 16px;

    .question-marking {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      .marking-icon {
        width: 14px;
        height: 14px;
        border-radius: 4px;
        margin-right: 8px;
      }

      .marking-icon-completed {
        border-radius: 4px;
        border: 1px solid #8ae09f;
        background: #c8f1d2;
      }

      .marking-icon-pending {
        border-radius: 4px;
        background-color: #f5f4f8;
        border: 1px solid #e2e2e2;
      }

      .marking-icon-skipped {
        background: #fed5d5;
        border: 1px solid #ffc4c4;
      }

      .marking-icon-box_pick_background_4 {
        border-radius: 4px;
        background-color: #f99746;
      }

      .marking-text {
        font-size: 12px;
        font-weight: 500;
        color: #000;
        vertical-align: middle;
        margin-top: -4px;
      }
    }
  }

  .questions-list {
    overflow-y: scroll;
    // height: calc(100vh - 8px);
    height: 73vh;
    background-color: #f6f6f7;
    padding: 0 15px;
  }
}
