.single_doubt_wrappr {
  border-radius: 0.5rem;
  border: 0.05rem solid #e9e9e9;
  background: #f6f6f7;
  font-family: Poppins;
  flex-basis: 32.333333%;

  .single_doubt_innr-wrppr {
    padding: 1rem 1.5rem 1rem 1rem;

    .doubt_date {
      color: #888;
      font-size: 0.688rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.11px;
    }

    .doubt {
      color: #121212;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.16px;
      margin: 0.375rem 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .view_scanned_doubt {
      color: #b50303;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.875rem; /* 116.667% */
      letter-spacing: 0.12px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}
