.questionDetailPanel {
  width: 68%;
  overflow-y: auto;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06);

  .right_btn_wrapper {
    display: none;
  }

  .questionDetailPanel_wrapper {
    position: relative;
    padding: 1.5rem 2.5rem 0 2.5rem;
    height: 100vh;

    .questionAndSolutionWrapper {
      overflow-y: auto;
      max-height: 68%;
    }

    .qstnSwitcherAndReprtAndErr {
      position: fixed;
      bottom: 0;
      width: 100%;

      .reportAndError {
        cursor: pointer;
        img {
          margin-right: 0.25rem;
        }
        .reportAndErrText {
          color: #b50503;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.875rem; /* 116.667% */
        }
      }
    }
  }
}
@media (max-width: 578px) {
  .questionDetailPanel {
    width: 100%;
    padding: 0;
    .right_btn_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      position: sticky;
      top: 0;
      z-index: 1000;
      background: inherit;
      padding: 10px;
      .togglebtn {
        border: none;
        outline: none;
        border-radius: 8px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #fff;
        padding: 10px;
        background: #6d48ef;

        &.submit {
          background: coral;
        }
      }
    }
    .questionDetailPanel_wrapper {
      padding: 10px;
    }
  }
}
