
.common_promo_banner {
    border-radius: 8px;
    background: linear-gradient(0deg, #263238 0%, #263238 100%), linear-gradient(253deg, #DA1724 -4.09%, #FD6239 88.36%);
    max-width: 750px;
    margin: 0 auto 30px;
    padding: 20px 25px 20px 20px;

    div:first-child {
      padding-left: 16px;
      max-width: 50%;

      p {
        margin: 0;
      }

      p:first-child {
        color: #FFF;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: inline-block;
        margin-bottom: 5px;
      }

      span {
        border-radius: 4px;
        background: #F90;
        padding: 2px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        margin-left: 8px;
      }

      p:last-child {
        color: #E3E3E3;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span {
          font-weight: 600;
        }
      }
    }

    div:last-child {
      margin-left: auto;

      button {
        color: #121212;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: flex;
        padding: 10px 22px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #FFF;
      }
    }
  }
