.components {
  .studentHeader {
    padding-bottom: 20px;
  }
  .testsComponent {
    padding: 116px;
    margin: auto;
    padding-bottom: 50px;
    .testsContentInrWrapper {
      border-radius: 1rem;
      background: rgba(238, 239, 240, 0.5);
    }
  }
}
