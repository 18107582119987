////////No need to subtract scrollbar width in ipad,and mobile screens
.footer_component_wrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
  .footerTopMenuWrapper {
    background-color: #ebebeb;
    .upper_layer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .header_inner_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    margin: 0px auto;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .footer_component_wrapper {
    // width: calc(100vw - 190px);
    .header_inner_wrapper {
      padding: 10px 10px;
      .header_left_wrapper {
        flex: 0 0 50%;
      }
      .header_right_wrapper {
        flex: 0 0 50%;
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .footer_component_wrapper {
    // width: calc(100vw - 160px);
    .header_inner_wrapper {
      padding: 15px 5px;

      .header_left_wrapper {
        flex: 0 0 47%;
      }
      .header_right_wrapper {
        flex: 0 0 53%;
      }
    }
  }
}

//
.footer_top_wrapper {
  background-color: #ebebeb;
  .container {
    .hdrTpInr {
      padding: 5px 0px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .hdrTopLeft {
        ul {
          margin: 0px;
          padding: 0px;
          line-height: normal;
          li {
            list-style: none;
            display: inline-block;
            margin-right: 20px;
            .hdr_link {
              text-decoration: none;
              color: #000;
              span {
                line-height: normal;
                font-size: 12px;
              }
            }
          }
        }
      }
      .hdrTopRight {
        button {
          background-color: #000;
          color: #ffffff;
          padding: 2px 10px 4px;
          border-radius: 30px;
          font-size: 12px;
          img {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 704px) {
  .footer_top_wrapper .container .hdrTpInr .hdrTopLeft ul li .hdr_link span {
    font-size: 10px;
  }
}

@media (max-width: 618px) {
  .footer_top_wrapper .container .hdrTpInr .hdrTopLeft ul li {
    margin-right: 6px;
  }
}
