.parent-header_left_wrapper {
  .logo {
    width: 100px;
    display: inline-block;
    img {
      width: 100%;
    }
  }

  .left_menu {
    display: inline-block;
    margin-left: 60px;

    ul {
      margin-bottom: 0px;
      li {
        list-style: none;
        display: inline-block;
        line-height: normal;
        margin-right: 30px;
        &:last-child {
          margin-right: 0px;
        }

        .menu-link {
          text-decoration: none;
          color: #000;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .current {
          color: #b91311;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-decoration: underline;
          text-underline-offset: 6px;
        }
      }
    }
  }
}
