.ots_test_result_content_component_wrapper {
  padding-top: 55px;

  .ots_test_result_content_component_inner_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .otsTestSummaryPageWrapper {
      width: 78.75%;
      margin-top: 40px;
      border-radius: 1rem;
      background: rgba(238, 239, 240, 0.50);
      padding: 1.5rem;

      .ots_test_summary_header {
        margin-bottom: 2.5rem;

        .backBtn {
          display: inline-block;
          cursor: pointer;
        }

        .ots_paper_categories {
          display: flex;
          max-width: max-content;
          margin: -1.5rem auto 0;
          overflow: hidden;
          border-radius: 0.5rem;
          border: 0.6px solid #E0E0E0;
          background: #FFF;

          .ots_single_paper_select_category {
            // margin: 0 80px;

            &:last-child {
              margin-right: 0px;
            }

            input[type="radio"] {
              opacity: 0;
              position: fixed;
              width: 0;
            }

            label {
              color: #585858;
              font-family: "Poppins";
              font-size: 0.875rem;
              font-weight: 400;
              line-height: normal;
              padding: 0.625rem 1.25rem;
              cursor: pointer;
            }

            input[type="radio"]:checked + label {
              color: #B50303;
              font-weight: 500;
              border-bottom: 2px solid #B50303;
            }
          }
        }
      }

      .ots_test_name {
        color: #000;
        font-family: "Poppins";
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        margin: 0 auto 2.5rem;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .ots_test_result_content_component_wrapper {
    padding-top: 50px;
      .ots_test_result_content_component_inner_wrapper {
        .otsTestSummaryPageWrapper {
          width: 97%;
          margin-top: 10px;
          padding: 10px;
            .ots_test_summary_header {
              margin-bottom: 15px;
              .ots_paper_categories {
                .ots_single_paper_select_category {
                  margin: 0;
                }
              }
            }
          .otsTestSummaryPageInrWrapper {
            .test_summaryBox {
              .rankMarksBox{
                padding: 0;
                .rankChartBox {
                  display: block;
                  .expctd_AIR_Chart{
                    width: 100%;
                    margin-bottom: 15px;
                  }
                  .AttemptAndAccuracyPercent{
                    width: 100%;
                    padding: 15px 10px;
                    margin-bottom: 15px;
                  }
              }
              .testTypeOption{
                display: block;
                .testResultOption{
                  width: 100%;
                }
              }
              }
            }
          }
        }
    }
  }
}
