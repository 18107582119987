.confirmation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .confirmation-message {
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      padding: 30px;
      text-align: center;
      max-width: 400px;
      width: 100%; 
  
      h2 {
        font-size: 24px;
        margin-bottom: 10px;
        color: #333333;
      }
  
      p {
        font-size: 16px;
        color: #666666;
        line-height: 1.5;
      }
  
      button {
        width: 100%;
        padding: 12px 16px;
        background-color: #b50503;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
  
        &:hover {
          background-color: #a40001;
        }
      }
  
      .check-icon {
        img {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }
  