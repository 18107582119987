
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@600&display=swap');

:root {
  --black: #000;
  --white: #fff;
  --bg-gray:#eeeff080;
  --bg-dark:#263238;
  --border:#E0E0E0;
  --border-lime:#FF9900;
  --red:#B50303;
  --text-dark:#121212;
  --text-gray-300:#484848;
  --text-gray-400:#585858;
}


body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins' !important;
  overflow: auto !important;
}

html {
  scroll-behavior: smooth;
}

@media print{
  body{
    display: none;
  }
}
mjx-container[jax="CHTML"] {
  font-size: inherit !important;
}
.questionWrapper{

}
.questionWrapper::-webkit-scrollbar {
  width: 3px;
}
.questionWrapper::-webkit-scrollbar-track {
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
border-radius: 10px;
}
.questionWrapper::-webkit-scrollbar-thumb {
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

input[type=radio]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E") !important;
  background-color: var(--red) !important;
    border: var(--red) !important;
    appearance: none;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid var(--red) !important;
    border-radius: 50%;
}

button{
  border: 0px;
  background-color: #fff;
}