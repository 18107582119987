.signUp_step_two_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .signup_right_form {
    .signup_form {
      .content_inner_wrapper {
        padding-top: 20px;
        .back_btn_wrapper {
          margin-bottom: 30px;
          button {
            border: none;
            outline: none;
            background: transparent;
          }
        }

        .class_course_wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          padding-top: 46px;

          .text_content_wrapper {
            display: flex;
            align-items: center;
            img {
              width: 36px;
              height: 36px;
            }
            .text_content {
              color: #3d3d3d;
              font-family: Poppins;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-top: 10px;
            }
          }

          .radio_collection_wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .single_select {
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }
              label {
                border-radius: 0.25rem;
                border: 0.8px solid #9f9f9f;
                padding: 0.6875rem 11.8125rem 0.6875rem 0.875rem;
                width: 100%;
                color: #6b6b6b;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &:hover {
                  border-radius: 0.25rem;
                  border: 0.8px solid #6f6f6f;

                  background: #f6f6f6;
                  color: #121212;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .terms_n_condn_wrapper {
          margin: 5px 0 10px;
          .terms_n_condn_inner_wrapper {
            text-align: center;
            input[type="checkbox"] {
              margin: 0 10px;
            }
          }
        }
      }

      .btn_wrapper {
        button {
          margin: 0 8px;
          padding: 10px 20px;
          background-color: #6d48ef;
          color: #fff;
          border: none;
          cursor: pointer;
          outline: none;
          border-radius: 4px;
          width: 45%;
          &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 575.98px) {
  .signUp_step_two_wrapper {
    .signUp_step_two_inner_wrapper {
      .content_wrapper {
        width: 100%;
        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;
        }
      }
    }
  }
}
