.test_intructions_view_wrapper_PYQ_TopicWise_Test {
  padding-top: 50px;
  background-color: var(--white);
  .test_intructions_view_inner_wrapper {
    background-color: var(--bg-gray);;
    border-radius: 10px;
    padding: 15px;
    //istruction Screen
        .instructionScreenBox{
          .backBtn{
                  cursor: pointer;
                  display: inline-block;
                }
            .instruction_title{
                text-align: center;
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 20px;
            }
            .instruction_detailBox{
                max-width: 750px;
                margin: 0 auto;
                .examTime{
                    border: 0.6px solid #979797;
                    background: var(--white);
                    padding: 15px;
                    text-align: center;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    .examTitle{
                      color: #121212;
                      font-size: 1rem;
                      font-weight: 600;
                    }
                    .examDurations{}
                }
                .instructions{
                    border: 0.6px solid #979797;
                    border-radius: 10px;
                    background: var(--white);
                    margin-bottom: 15px;
                    h3{
                        font-size: 20px;
                        padding: 10px 15px;
                    }
                    .instPoint{
                        padding: 0 15px 10px;
                        img{
                          width: 100%;
                        }
                        p{
                            // font-size: 14px;
                            color: #424242;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.5625rem;
                        }
                        h6{
                          text-align: center;
                        }
                    }
                }
                .img{
                  text-align: center;
                  padding: 15px 0px;
                }
                .startTestBtn{
                  text-align: center;
                    button{
                      background: var(--red);
                      width: 100%;
                      max-width: 20rem;
                      color: #fff;
                      padding: 7px 1.5rem;
                      border-radius: 4px;
                    }
                }
            }
        }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .test_intructions_view_wrapper_PYQ_TopicWise_Test {
    .test_intructions_view_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .sub_section_wrapper_1 {
            .text_content_wrapper_1 {
              .text_content_1 {
              }
            }
            .text_content_wrapper_2 {
              .text_content_2 {
              }
            }
          }
          .sub_section_wrapper_2 {
            .text_content_wrapper {
              .text_content {
                img {
                }
              }
            }
          }
          .sub_section_wrapper_3 {
            .btn_wrapper {
              button {
                width: 220px;
              }
            }
          }
        }
      }
    }
  }
}
