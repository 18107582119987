.address_form_header_wrapper {
  padding-top: 75px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  position: sticky;
  top: 0;
  .address_form_header {
    display: flex;
    align-items: center;
    width: 1114px;
    margin: 0 auto;
    padding: 0.2rem 0 0.4rem 0;

    img {
      width: 2.25rem;
      height: 2.25rem;
      margin-right: 0.25rem;
      cursor: pointer;
    }

    .add_address_text {
      color: #121212;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.01125rem;
    }
  }
}
