.all_page_content_wrapper {
    max-width: 80%;
    margin: 40px auto;
    border-radius: 16px;
    background: rgba(238, 239, 240, 0.50);
    min-height: 80vh;
    padding-bottom: 50px;

    .all_page_button {
        background-color: #F5F4F8;
        border-radius: 16px;

        .back_btn {
            padding: 25px;

            h2 {
                display: inline-block;
                width: 95%;
                text-align: center;
                color: #000;
                font-family: "Poppins";
                font-size: 22px;
                font-weight: 500;
                line-height: normal;
                margin: 0;
            }
        }

        .container_inner {
            // max-width: 890px;
            width: 72%;
            margin: 0 auto;
            background-color: #ffffff;

            h2 {
                font-size: 40px;
                text-align: center;
                padding: 60px 0px 80px;
            }

            .all_page_tabs {
                border: none;
                justify-content: space-between;

                li {

                    button {
                        color: #585858;
                        font-family: "Poppins";
                        font-size: 14px;
                        font-weight: 400;
                        line-height: normal;
                        padding: 12px 24px;
                        border: none;

                        &:focus,
                        :hover {
                            background-color: none !important;
                            outline: none !important;
                            // border: none !important;
                        }

                        :not(:last-child) {
                            margin-right: 15px;
                        }

                    }

                    .nav-link.active {
                        color: #B50303;
                        font-family: "Poppins";
                        font-size: 14px;
                        font-weight: 500;
                        line-height: normal;
                        padding: 12px 24px;
                        border: 0;
                        border-bottom: 2px solid #B50303;
                    }
                }

                .nav-tabs .nav-link {
                    border: none !important;
                }

                .nav-tabs .nav-link:hover {
                    border: none !important;
                }
            }
        }
    }

    .tab-content {
        max-width: 72%;
        margin: 0 auto;
    }
}

@media (max-width: 575.98px) {
    .all_page_content_wrapper {
        .all_page_button {
            .container_inner {
                .all_page_tabs {
                    margin-bottom: 15px;
                }

                h2 {
                    font-size: 28px;
                    padding: 0px 0px 20px;
                }
            }
        }
    }
}