.topperComparisonWrapper {
  font-family: Poppins;
  width: 90%;
  margin: auto;

  .topperComparisonContent {
    .text {
      text-align: center;
      color: #121212;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .banner {
      display: flex;
      margin: 0 auto;
      gap: 2.5rem;
      padding: 1.2rem 2.2rem 1.6rem 2.2rem;
      border-radius: 0.5rem;
      border: 1px solid #ddd;
      background: #fff;
      width: fit-content;

      .card-left {
        .bottom {
          padding: 2.2rem 4rem;
          border-radius: 0.5rem;
          border: 0.6px solid #fff;
          background: #02929a;
          .text {
            color: #fff;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 0.88rem;
          }

          .num {
            color: #fff;
            font-family: Poppins;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: center;
          }
        }
      }

      .card-right {
        border-radius: 0.25rem;
        border: 1px solid #e2e2e2;
        background: #fff;
        padding: 2.2rem 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .compare {
          button {
            img {
              width: 3.27113rem;
              height: 3.27113rem;
              margin: auto;
            }
          }
        }

        .plus-icon {
          button {
            img {
              width: 3rem;
              height: 3rem;
              margin-bottom: 0.62rem;
              margin: auto;
            }
          }
        }

        .compare-text {
          margin-top: 0.5rem;
          color: #121212;
          font-family: Poppins;
          font-size: 0.93463rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .text {
          color: #121212;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .compareBtn {
      text-align: center;
      margin: 1.5rem 0 2.5rem 0;
      button {
        padding: 0.75rem 3rem;
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.25rem;
        opacity: 0.6;
        background: #b50303;
        box-shadow: 2px 2px 6px 0px rgba(189, 31, 31, 0.24);
      }
    }

    .activeCompareBtn {
      text-align: center;
      margin: 1.5rem 0 2.5rem 0;

      button {
        padding: 0.75rem 3rem;
        border-radius: 0.25rem;
        background: #b50303;
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    table {
      border-radius: 0.375rem;
      border: 0.6px solid #ddd;
      background: #fffdf5;
    }

    .section-heading {
      color: #373737;
      font-family: Poppins;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.5rem;
    }

    #customers {
      border-collapse: collapse;

      width: 100%;
      margin: auto;

      thead {
        tr {
          background: #fff7d7;
          th {
            padding: 1rem 1.8rem 1rem 1.8rem;
            color: #121212;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.01rem;
            // text-align: center;
          }
        }
      }

      tbody {
        tr {
          td {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: 1px dashed #fffdf5;
            background: #fffdf5;
            padding: 1rem 1.8rem 1rem 1.8rem;
          }

          .td-text {
            color: #373737;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }

    #comparison-table {
      width: 100%;

      thead {
        tr {
          background: #fff7d7;
          th {
            padding: 1rem 1.8rem 1rem 1.8rem;
            color: #121212;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.01rem;
            // text-align: center;
          }
        }
      }

      tbody {
        tr {
          td {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: 1px dashed #fffdf5;
            background: #fffdf5;
            padding: 1rem 1.8rem 1rem 1.8rem;
          }

          .td-text {
            color: #373737;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    // SCSS for modal
    .modal {
      // SCSS for modal-dialog
      .modal-dialog {
        margin-top: 100px;

        // SCSS for modal-content
        .modal-content {
          // SCSS for modal-header
          background: none !important;
          .modal-header {
            border: none;

            background: #263238;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
            display: flex;
            justify-content: space-between;
            align-items: center;

            // SCSS for modal-header-left
            .modal-header-left {
              color: #fff;
              font-family: Poppins;
              font-size: 1.25rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .modal-header-right {
              .btn-close {
                --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
                --bs-btn-close-opacity: 1; // Make it fully opaque
                opacity: 1;
              }
            }
          }

          // SCSS for headings
          .headings {
            padding: 0.6rem 2rem;
            background: #fff;

            ul {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0;
              list-style: none;
              margin: 0;
              // margin-bottom: -0.1px;

              // SCSS for li elements inside headings
              li {
                color: #121212;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
              .li-name {
                width: 50%;
              }

              // SCSS for li-marks
              .li-marks {
                text-align: center;
                width: 20%;
              }

              // SCSS for li-rank
              .li-rank {
                width: 20%;
                text-align: center;
              }
            }
          }

          // SCSS for modal-body
          .modal-body {
            background: #f9f9f9;

            .singleStudentDetails {
              cursor: pointer;

              ul {
                display: flex;
                justify-content: space-between;
                padding: 0;
                list-style: none;

                // SCSS for li elements inside singleStudentDetails
                li {
                  color: #000;
                  font-family: Poppins;
                  font-size: 1.125rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                // SCSS for li-name inside singleStudentDetails
                .li-name {
                  width: 50%;
                }

                // SCSS for li-marks inside singleStudentDetails
                .li-marks {
                  width: 20%;
                  text-align: center;
                }

                // SCSS for li-rank inside singleStudentDetails
                .li-rank {
                  width: 20%;
                  text-align: center;
                }
              }
              &:hover {
                background-color: #f0f0f0;
              }
            }
          }
        }
      }
    }

    .tables {
      padding-bottom: 30px;
    }
  }
}
