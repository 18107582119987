.no_data_found_wrapper {
  margin: 2rem 0 1rem 0;

  img {
    width: 7.5rem;
    height: 7.5rem;
  }

  p {
    color: #000;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.438rem; /* 164.286% */
  }
}
