.topicWiseWrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 750px;
    margin: auto;

    .topicBox {
        // background: #F5F4F8;
        border-radius: 8px;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(50% - 10px);
        margin: 0px 20px 20px 0px;
        cursor: pointer;

        &:nth-child(2n+2) {
            margin-right: 0px;
        }

        .topicNameImg {
            .img_wrp {
                display: inline-block;
                margin-right: 15px;
                width: 32px;

                img {
                    width: 100%;
                }
            }

            h5 {
                display: inline-block;
                margin: 0px;
                color: #000;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .arrow {
            img {
                width: 6px;
            }
        }
    }
}

@media (max-width: 991.98px) {
    .topicWiseWrapper {
        .topicBox {
            padding: 10px;
        }
    }
}

@media (max-width: 575.98px) {
    .topicWiseWrapper {
        .topicBox {
            margin: 0px 0px 10px 0px;
            width: 100%;

            .topicNameImg {
                h5 {
                    font-size: 19px;
                }
            }
        }
    }
}