.create_doubt_btn_wrapperr {
  border-radius: 1.625rem;
  background: #b50503;
  color: #fff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.75rem 1.875rem;
  display: inline-block;
  cursor: pointer;
  margin: 0 auto;

  .plus_symbol {
    margin-right: 0.375rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}
