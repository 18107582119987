.form_Wrapper {
  padding-top: 101px;
  background-color: #ffffff;

  .form_InrWrapper {
    padding-bottom: 100px;

    .form_contentWpr {
      border-radius: 16px;
      background: rgba(238, 239, 240, 0.5);
      .form_header {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        .form_heading {
          color: #121212;
          font-family: "Poppins";
          font-size: 22px;
          font-weight: 500;
          line-height: normal;
          text-align: center;
          width: 100%;
        }
      }

      .form {
        margin: auto;
        width: 43%;
        // padding: 15px;
        .qb_label {
          color: #121212;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 6px;

          span {
            color: #616161;
            font-family: "Poppins";
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            margin-right: 6px;
          }
          img {
            width: 16px;
          }
        }
        .qb_test_name input {
          width: 100%;
          height: 56px;
          background: #ffffff;
          border: none;
          border-radius: 8px;
          padding-left: 20px;

          &::placeholder {
            color: #9a9a9a;
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
          }

          &:focus {
            outline: none !important;
          }
        }
        .qb_subjects {
          margin-top: 40px;

          .qb_subjects_list {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 40px;

            .single_select_subject {
              width: calc(33% - 10px);
              border: none;

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                // border: solid 1px #f0eef8;
                background-color: #ffffff;
                padding: 16px;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.2px;
                color: #121212;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="radio"]:checked + label {
                background: #02262b;
                color: #ffffff;
                font-weight: 500;
              }
            }
          }
        }

        .qb_topics {
          margin-bottom: 10px;
          margin-top: 20px;
          span {
            color: #616161;
            font-family: "Poppins";
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
          }
          .topic_content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #ffffff;
            border-radius: 8px;
            width: 100%;
            padding: 16px 20px;
            // margin-top: -10px;
            cursor: pointer;
            img {
              width: 13px;
              // height: 6px;
            }
          }
        }

        .selected_topics {
          width: 90%;

          display: flex;
          .topics {
            display: flex;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
          }
          .selected_topic {
            color: #000;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
            padding: 6px 8px;
            border-radius: 4px;
            border: 0.6px solid #ffd8ab;
            background: #fff6eb;

            margin-right: 10px;
            margin-top: 1px;
          }

          .qb_length {
            margin-top: 5px;
            cursor: pointer;
            // background-color: red;
            float: right;

            .topic_content {
              color: #000;
              font-family: "Poppins";
              font-size: 12px;
              font-weight: 500;
              line-height: normal;
              width: 30px;
              height: 30px;
              background-color: #cecece;
              border-radius: 50%;
              border: 1px solid #121212;
            }
          }

          .modal {
            .modal-header {
              display: flex;
              .modal-header-left {
                // margin: auto;

                .modal-title p {
                  font-size: 20px;
                  line-height: 30px;
                  color: #000000;
                  background: red;
                }
              }
              .modal-header-right {
                margin-top: -15px;
              }
            }

            .modal-body {
              padding: 20px 0 !important;
              margin: 30px 42px 0 !important;
              border-radius: 8px;
              border: 0.6px solid #000;

              .qb_topic {
                color: #616161;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 0 0 10px 40px;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  left: 25px;
                  top: calc(50% - 7px);
                  background-color: #616161;
                  border-radius: 50%;
                  width: 5px;
                  height: 5px;
                }
              }
            }

            .modal-footer {
              .done_btn {
                border-radius: 4px;
                background: #b50503;
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 600;
                line-height: normal;
                margin: 28px auto 22px;
                width: 140px;
                color: #ffffff;
                padding: 12px;
              }
            }
          }
        }

        .modal {
          .modal-header {
            display: flex;
            justify-content: space-between;
            border: none;
            padding-bottom: 0px;

            .modal-header-left {
              // margin-bottom: -30px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              margin: 20px auto 0;

              .modal-title {
                font-size: 22px;
                font-weight: 500;
                line-height: normal;
                color: #000;
              }
              .modal-title-sub {
                font-size: 16px;
                line-height: 24px;
                color: #6d48ef;
              }
            }
            .modal-header-right {
              // margin-top: -25px;
            }
          }
          .single_btn_wrapper {
            color: #b50503;
            border-radius: 4px;
            border: 0.6px solid #b50503;
            width: 133px;
            padding: 12px 16px;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            // margin-top: -20px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 20px;

            input {
              margin-right: 8px;
            }
            input[type="checkbox"] {
              width: 20px;
              height: 20px;
              border: 1px solid #b50503;
              border-radius: 5px;
              accent-color: #b50503;
            }
            label {
            }
            input[type="checkbox"]:checked + label {
            }
          }
          .single_topic_wrapper {
            // border-bottom: 1px solid #757575;
            position: relative;

            input[type="checkbox"] {
              position: absolute;
              left: 0;
              top: 0;
              width: 24px;
              height: 24px;
              border-radius: 5px;
              border: 1px solid #000;
              margin: 12px 0 12px 16px;
              accent-color: #02262b;
            }
            .topic_label {
              cursor: pointer;
              color: #616161;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding: 12px 0 12px 52px;
            }

            input[type="checkbox"]:checked + label {
              color: #121212;
            }
          }
          .modal-footer {
            .done_btn {
              border-radius: 4px;
              background: #b50503;
              width: 140px;
              color: #ffffff;
              font-family: "Poppins";
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              padding: 12px;
              margin: 28px auto 13px;
            }
          }
        }

        .qb_difficulty_level {
          width: 100%;
          margin-top: 20px;
          .difficulty_level_para {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808080;
            margin-top: -5px;
          }
          .qb_difficulty_level_list {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 40px;

            .multi_select_diffculty_level {
              // margin-right: 5px;
              width: calc(33% - 10px);
              background: #ffffff;
              border-radius: 8px;
              border: none;

              input[type="checkbox"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                // background-color: #fafafd;
                padding: 16px;
                // border-radius: 8px;
                color: #121212;
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="checkbox"]:checked + label {
                font-weight: 500;
                background-color: #02262b;
                color: #ffffff;
                border-radius: 8px;
              }
            }
          }
        }

        .qb_no_of_qtsns {
          .select_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-bottom: 30px;
            margin-bottom: 40px;

            .single_select_subject {
              background-color: #ffffff;
              width: calc(33% - 10px);
              margin-bottom: 20px;
              cursor: pointer;
              border-radius: 8px;
              // padding: 0px 10px;
              text-align: center;

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                color: #121212;
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
                padding: 16px;
              }

              input[type="radio"]:checked + label {
                background-color: #02262b;
                width: 100%;
                border-radius: 8px;
                color: #ffffff;
                font-weight: 500;
              }
            }
          }
        }

        .modal-content {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 110%;
          pointer-events: auto;
          border-radius: 8px;
          background: #fff;
          box-shadow: 2px 2px 8px 0px rgba(59, 59, 59, 0.25);
          outline: 0;
        }

        .modal-footer {
          text-align: center;
          border: none;
        }
      }
    }
    .qb_create_new_paper_button {
      text-align: center;
      margin: auto;

      button {
        border-radius: 4px;
        border: 0.6px solid #b91311;
        background: #b50303;
        padding: 15px 30px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        width: 380px;
        margin: auto;
      }
    }
  }
}

.qb_onSubmitModal {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  .modal-content {
    width: 500px;
    border-radius: 8px;

    .modal-header {
      padding: 20px 25px 0 0;
      border: 0px;

      button {
        opacity: 1;
      }
    }

    .modal-body {
      .qb_submit_Popup {
        margin-top: -25px;
        padding: 40px;

        .exit_img {
          width: 100%;

          img {
            width: 35%;
            height: 130px;
            margin-bottom: 20px;
            margin-top: -25px;
          }
        }

        h5 {
          color: #000;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.16px;
          margin-bottom: 10px;
        }

        p {
          margin: 0 auto;
          color: #5d5d5d;
          text-align: center;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
        }

        .submitCancelBtn {
          margin-top: 40px;

          button {
            border-radius: 4px;
            height: 45px;
            width: 150px;
            color: #fff;
            background: #b50503;
            font-family: "Poppins";
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
          }

          .cancel {
            color: #121212;
            background: none;
            margin-right: 20px;
            border: 1px solid #000;
          }
        }
      }
    }
  }
}
