.examcrackerInstructionScreenWrppr {
  background: #fff;

  .examcrackerInstructionScreenInnrWrppr {
    width: fit-content;
    margin: 0 auto;
    padding: 0 9.75rem 2.25rem 9.75rem;
    border-radius: 1rem;
    background: rgba(238, 239, 240, 0.5);
    margin-top: 2.5rem;
    height: 70vh;
    margin-bottom: 2rem;
    position: relative;

    .bckBtnAndNameWrppr {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.6rem 0 2.44rem 0;

      .backBtn {
        position: absolute;
        left: 2rem;

        img {
          width: 2.25rem;
          height: 2.25rem;
        }
      }

      h1 {
        color: #000;
        font-family: Poppins;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        margin: 0;
      }
    }

    .contentWrppr {
      width: 48.6875rem;

      height: 55%;

      .header-section {
        display: flex;
        width: 100%;
        padding: 1.25rem 4rem;
        justify-content: space-between;
        border-radius: 0.5rem;
        border: 0.6px solid #979797;
        background: #fff;
        margin-bottom: 1rem;

        .name {
          color: #121212;
          font-family: "Source Sans Pro";
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .content-section {
        border-radius: 0.5rem;
        border: 0.6px solid #979797;

        height: 100%;
        width: 100%;
        position: relative;

        .content-inner-section {
          padding: 1.5rem 0 0 1.25rem;

          h3 {
            color: #000;
            font-family: Poppins;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          ul li {
            color: #424242;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.875rem; /* 214.286% */
          }

          .bestOfLuckText {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5625rem;
            bottom: 1rem;
            text-align: center;
            left: 45%;
            position: absolute;

            margin: 0 auto;
          }
        }
      }
    }
  }
}

.startTestBtn {
  text-align: center;
  color: #fff;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 0.25rem;
  border: 0.6px solid #b91311;
  background: #b50503;
  padding: 0.75rem 8rem;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}
