.cart_collection {
  padding-top: 100px;
  padding-bottom: 50px;
  .container {
    background: #ffffff;
    margin-left: 125px;
    width: 83.5%;
    padding-bottom: 100px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px;
      width: 100%;
      //   box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
      border-bottom: 2px solid rgba(0, 0, 0, 0.08);
      .right {
        width: 18%;
        .wishLst_addCart {
          display: flex;
          justify-content: space-around;
          align-items: center;
          color: #121212;
          img {
            margin-right: 5px;
          }
          .wishList {
            cursor: pointer;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .badge {
              background: #263238;

              color: #f5f4f8;
              font-weight: bold;
              border-radius: 5px;
              position: relative;
              top: -11px;
              right: 12px;
            }
          }
          .addCart {
            cursor: pointer;
            display: flex;
            align-items: center;
            .badge {
              background: #263238;

              color: #f5f4f8;
              font-weight: bold;
              border-radius: 5px;
              position: relative;
              top: -11px;
              right: 12px;
            }
            .cart {
              margin-left: -5px;
            }
          }
        }
      }
    }
    // .cart_details_wrapper {
    //   margin-top: 20px;
    //   text-align: center;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   margin-bottom: 30px;
    //   .progrs_bar {
    //     display: flex;
    //     justify-content: space-between;
    //     position: relative;
    //     width: 60%;
    //     margin: 0 auto;
    //     padding: 10px;
    //     .first_box {
    //       display: inline-block;
    //       text-align: -webkit-center;
    //       vertical-align: top;
    //       .circle {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         width: 35px;
    //         height: 35px;
    //         border: 1px solid #f3f3f3;
    //         background: #c7c7c7;
    //         // color: #acacac;
    //         text-align: center;
    //         border-radius: 50%;
    //         position: relative;
    //       }
    //       span {
    //         font-size: 14px;
    //       }
    //     }
    //     .line_div {
    //       top: 15px;
    //       position: absolute;
    //       width: 95%;
    //       line-height: 0px;
    //       //   background-color: #f3f3f3;
    //       z-index: -1;
    //       left: 2%;
    //       height: 3px;
    //       .line {
    //         width: 150px;
    //         height: 3px;
    //         display: inline-block;
    //         position: relative;
    //         line-height: 0px;
    //         top: -2px;
    //       }
    //     }
    //     .bg_color {
    //       background-color: #ff8826;
    //       color: #fff;
    //     }
    //   }
    // }

    .cart_details_wrapper {
      .content {
        align-items: center;
        margin-top: 20px;
        .progressbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 10;
          // width: 60%;
          width: 45%;
          margin: 0 auto;
        }

        .progreesbarlables {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          // width: 64%;
          width: 49%;
          margin: 0 auto;
          margin-top: 10px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #121212;
        }
        .progressbar::before {
          content: "";
          background: #e0e0e0;
          position: absolute;
          top: 50%;
          left: 0%;
          transform: translateY(-50%);
          border-radius: 5px;
          height: 2px;
          width: 100%;
          z-index: -1;
          transition: 0.4s ease;
          border: 2px dashed #fff;
        }

        .progress {
          background: #b50503;
          opacity: 0.5;
          position: absolute;
          top: 50%;
          left: 0%;
          transform: translateY(-50%);
          border-radius: 5px;
          height: 2px;
          border: 2px dashed #fff;
          width: 50%;
          z-index: -1;
          // transition: 0.6s ease;
          // transition: 1s ease;
        }

        .circle {
          background: #e0e0e0;
          color: #999;
          border-radius: 50%;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 3px solid #e0e0e0;
          // transition: 0.4s ease;
        }

        .circle.active {
          border: 1px solid #b50503;
          background: #b50503;
          background-image: url("../../../../../../utilities/images/user_activity/user_activity_41.png");
          background-repeat: no-repeat, repeat;
          background-position: center;
        }

        .btns {
          display: flex;
          justify-content: space-around;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .backBtn_content {
      display: flex;
      gap: 1rem;
      padding: 20px 25px;
      .backBtn img {
        color: #6d48ef;
      }
      .backBtn_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #6d48ef;
      }
    }
    .cart_wrapper {
      display: flex;
      width: 100%;
    }

    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 60vh;

      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
        color: #9d9d9d;
        margin-bottom: 10px;
      }

      button {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        background: #b50503;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        color: #ffffff;
        padding: 7px 25px;
      }
    }
  }
}

@media (max-width: 578px) {
  .cart_collection {
    padding-top: 55px;
    padding-bottom: 0;
    .container {
      margin-left: auto;
      margin-right: auto;
      width: 97%;
      .header {
        padding: 20px 0;
        .right {
          width: auto;
          .wishLst_addCart {
            gap: 0.5rem;
          }
        }
      }
      .cart_details_wrapper {
        .content .progressbar {
          width: 90%;
        }
        .content {
          .progreesbarlables {
            width: 100%;
            text-align: center;
            font-size: 12px;
            line-height: 1;
          }
        }
      }
      .backBtn_content {
        padding: 20px 0;
      }
      .added_to_cart_container {
        padding: 0;
        flex-direction: column;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
        }
      }
    }
  }
}
