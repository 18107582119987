navbar {
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.37rem 5rem;

    .motion-logo {
      img {
        width: 7.375rem;
        height: 2.26925rem;
        cursor: pointer;
      }
    }

    .details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;

      .features {
        gap: 2rem;
        display: flex;

        .feature {
          color: #121212;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }

      .get-in-touch-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        border: 0.8px solid #b50503;
        padding: 0.5rem 0.75rem;
        gap: 0.5rem;
        cursor: pointer;
        img {
          width: 1.5rem;
          height: 1.5rem;
        }

        .get-in-touch-text {
          color: #232323;
          font-family: Poppins;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        .get-in-touch-mobile-no {
          color: #121212;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.0075rem;
          text-decoration: none;
        }
      }

      .login-button {
        cursor: pointer;
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0.875rem 2rem;
        border-radius: 0.5rem;
        background: #b50503;
        box-shadow: -4px -4px 12px 0px rgba(181, 5, 3, 0.12),
          4px 4px 12px 0px rgba(181, 5, 3, 0.12);
      }
    }
  }
}
navbar .mobile-navbar-container {
  display: none !important;
}

@media screen and (max-width: 768px) {
  navbar .navbar-container {
    padding: 12px 24px 13px 24px;
  }
  navbar .navbar-container .details {
    gap: 15px;
  }
  navbar .navbar-container .details .features {
    gap: 1rem;
  }
  navbar .navbar-container .details .features .feature {
    font-size: 14px;
  }
  navbar .navbar-container .details .get-in-touch-wrapper {
    padding: 3px 8px 3px 8px;
    gap: 13px;
  }
  navbar .navbar-container .details .login-button {
    padding: 11px 15px 11px 15px;
  }
}
@media screen and (max-width: 600px) {
  navbar {
    .navbar-container {
      display: none !important;
    }
    .mobile-nav-menu {
      background-color: white;
      min-height: calc(100vh - 100px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .mobile-nav-icon {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
    .right-arrow img {
      width: 1.5rem;
    }
    .mobile-navbar-container {
      display: block !important;

      .mobile-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;

        .menu-icon {
          font-size: 24px;
          cursor: pointer;
        }

        .motion-logo img {
          width: 100px;
          height: auto;
        }
      }
      .login-button {
        display: inline-flex;
        padding: 0.5rem 1.25rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.5rem;
        background: #b50503;
      }
      .mobile-nav-row {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .mobile-menu {
        background-color: white;
        padding: 20px;
        height: 38rem;

        .feature {
          width: 22.5rem;
          padding: 0.3rem 0;

          a {
            text-decoration: none;
            color: #333;
            font-size: 18px;
            font-weight: 500;
          }
        }

        .get-in-touch-wrapper {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-top: 20px;

          img {
            width: 20px;
            height: 20px;
          }

          .get-in-touch-text {
            font-size: 12px;
            color: #666;
          }

          .get-in-touch-mobile-no {
            font-size: 16px;
            font-weight: 600;
            color: #333;
            text-decoration: none;
          }
        }
      }
    }
  }
}
