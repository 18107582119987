.examCrackerquestionSwitcherWrrpr {
  font-family: Poppins;
  width: 62.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.88rem 0;
  border-top: 0.8px solid #f2f2f2;
  background: #fff;
  margin-top: 2.19rem;

  .exc_left_side_wrapper {
    .btn_wrapper {
      button {
        color: #b50503;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 0.62rem 1rem;
        border-radius: 0.25rem;
        border: 1px solid #b50503;
      }
    }
  }

  .exc_right_side_wrapper {
    .solution {
      cursor: pointer;
      color: #121212;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 0.25rem;
      border: 1px solid #121212;
      padding: 0.625rem 1rem;
    }

    .btn_wrapper {
      .excr_next_btn {
        color: #fff;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.25rem;
        border: 0.6px solid #b91311;
        background: #b91311;
        padding: 0.625rem 4.25rem;
        margin-left: 1.25rem;
        cursor: pointer;
      }
    }
  }
}
