.live-streaming-container {
  width: 100%;
  // padding: 5rem 8rem 0 8rem;
}
iframe body {
  overflow: hidden !important;
}

.video-player-container {
  // overflow-y: scroll;
  position: relative;
  padding: 10px;
}

// .video-player {
//   width: 100%;
//   height: 100%;
//   border: none;
// }
