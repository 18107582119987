#cm {
    .modal-content {
        .modal-body {

            p {
                text-align: center;

                &:first-child {
                    color: #121212;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0.16px;
                }

                &:nth-child(2) {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.16px;
                }

                &:last-child {
                    color: #505050;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0.14px;
                }
            }

            button {
                color: #FFF;
                font-size: 14px;
                font-weight: 500;
                padding: 12px 14px;
                gap: 8px;
                border-radius: 4px;
                background: #B50503;
                width: 200px;
                text-align: center;
                margin: auto;
            }

            div {
                &:last-child {
                    position: absolute;
                    top: 20px;
                    right: 5px;
                    width: 32px;
                }
            }
        }
    }
}



.pyq_subjectSubTopicWrapper {
    padding-top: 50px;
    padding-bottom: 20px;

    .pyq_subjectTopicInner {
        padding: 20px;
        margin: 40px 40px;
        background-color: rgba(238, 239, 240, 0.50);
        border-radius: 16px;
        min-height: 70vh;

        .backBtn {
            display: inline-block;
            cursor: pointer;
            padding: 10px 0 0 10px;
            img {}
        }

        .pyqSbjTpcListBox {
            .subject_name {
                text-align: center;
                font-size: 22px;
                font-weight: 500;
                margin-bottom: 80px;
                color: #000;
                padding-top: 10px;
            }

            .subject_topic_list {
                max-width: 950px;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;

                .subject_topics {
                    background: #ffffff;
                    border-radius: 8px;
                    padding: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: calc(50% - 10px);
                    margin: 0px 20px 20px 0px;
                    cursor: pointer;

                    &:nth-child(2n+2) {
                        margin-right: 0px;
                    }

                    .sbjct_tpc_name {}

                    .arrow {
                        padding-left: 15px;
                        img {
                            width: 8px;
                        }
                    }
                }
            }
        }

        //istruction Screen
        .instructionScreenBox {
            .instruction_title {
                text-align: center;
                font-size: 25px;
                font-weight: 600;
                margin-bottom: 20px;
            }

            .instruction_detailBox {
                max-width: 750px;
                margin: 0 auto;

                .examTime {
                    border: 1px solid #FFDEC2;
                    background: #FF933A0D;
                    padding: 15px;
                    text-align: center;
                    border-radius: 10px;
                    margin-bottom: 20px;

                    .examTitle {
                        color: #F1A769;
                        margin-bottom: 8px;
                    }

                    .examDurations {}
                }

                .instructions {
                    border: 1px solid #FFDEC2;
                    border-radius: 10px;
                    background: #FF933A0D;
                    margin-bottom: 15px;

                    h3 {
                        font-size: 20px;
                        border-bottom: 1px solid #FFDEC2;
                        padding: 10px 15px;
                    }

                    .instPoint {
                        padding: 10px 15px;

                        p {
                            font-size: 14px;
                            color: #000;
                        }

                        h6 {}
                    }
                }

                .startTestBtn {
                    button {
                        background: #6D48EF;
                        width: 100%;
                        color: #fff;
                        padding: 7px 10px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}

@media (max-width:575.98px) {
    .pyq_subjectSubTopicWrapper {
        padding-top: 50px;

        .pyq_subjectTopicInner {
            margin: 20px 0px;

            .pyqSbjTpcListBox {
                .subject_name {
                    font-size: 22px;
                    margin-bottom: 20px;
                }

                .subject_topic_list {
                    .subject_topics {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                    }
                }
            }
        }
    }
}