.banner-inner-wrapper {
  padding: 0 5rem;
  background: linear-gradient(135deg, #c90707 0%, #680008 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45.125rem;

  .banner-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .text1 {
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 3rem !important;
      font-style: normal;
      font-weight: 900;
      line-height: 4.1875rem;
      letter-spacing: 0.06rem;
      width: 48rem !important;
      margin-bottom: 0.75rem;
    }

    .text2 {
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 1.25rem !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 45.375rem !important;
      margin-bottom: 1.62rem;
    }

    .enroll-btn {
      width: fit-content;
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 0.5rem;
      padding: 0.875rem 2rem;
      border: 1px solid #fff;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: -4px -4px 12px 0px rgba(181, 5, 3, 0.12),
        4px 4px 12px 0px rgba(181, 5, 3, 0.12);
      cursor: pointer;
    }
  }

  .banner-img {
    img {
      width: 22.875rem;
      height: 45.125rem;
      object-fit: contain;
    }
  }
}
.carousel-indicators {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
  }

  li.active {
    background-color: rgba(255, 255, 255, 1);
  }
}
.mobile-banner-inner-wrapper {
  display: none !important;
}
@media screen and (max-width: 2561px) {
  .banner-inner-wrapper .banner-img img {
    width: 28.875rem;
  }
}
@media screen and (max-width: 1441px) {
  .banner-inner-wrapper .banner-img img {
    width: 22.975rem;
  }
}

@media screen and (max-width: 1440px) {
  .banner-inner-wrapper {
    height: 36.125rem;
  }
}

@media screen and (max-width: 1231px) {
  .banner-inner-wrapper .banner-img img {
    width: 19.875rem;
    height: 31.125rem;
    object-fit: contain;
  }
  .banner-inner-wrapper {
    height: 31.125rem;
  }
}

@media screen and (max-width: 1190px) {
  .banner-inner-wrapper .banner-img img {
    width: 16.875rem;
    height: 27.125rem;
  }
  .banner-inner-wrapper {
    height: 26.4rem;
  }

  .banner-inner-wrapper .banner-content .text2 {
    font-size: 1.25rem !important;
    width: 33.375rem !important;
  }
  .banner-inner-wrapper .banner-content .text1 {
    font-size: 2rem !important;
    line-height: 3.1875rem;
    width: 37rem !important;
  }
}

@media screen and (max-width: 1024px) {
  .banner-inner-wrapper {
    height: 24.623rem;
  }
  .banner-inner-wrapper .banner-img img {
    width: 15.75rem;
    height: 31rem;
    flex-shrink: 0;
  }

  .banner-inner-wrapper .banner-content .text2 {
    font-size: 0.875rem !important;
    line-height: 1.4375rem;
    width: 32.3125rem !important;
  }

  .banner-inner-wrapper .banner-content .text1 {
    font-size: 2rem !important;
    line-height: 2.75rem;
    letter-spacing: 0.03rem;
    width: 32.3125rem !important;
  }
}

@media screen and (max-width: 961px) {
  .banner-inner-wrapper {
    height: 24.625rem;
  }
  .banner-inner-wrapper .banner-content .text2 {
    font-size: 1.25rem !important;
    width: 26.375rem !important;
  }
  .banner-inner-wrapper .banner-content .text1 {
    font-size: 2rem !important;
    line-height: 2.1875rem;
    width: 26rem !important;
  }
}

@media screen and (max-width: 768px) {
  .banner-inner-wrapper {
    padding: 37px;
    height: 24.725rem;
  }
  .banner-inner-wrapper .banner-content .text1 {
    font-size: 21px !important;
    line-height: 22px;
    width: 23rem !important;
  }
  .banner-inner-wrapper .banner-content .text2 {
    font-size: 12px !important;
    width: 23.375rem !important;
  }
  .banner-inner-wrapper .banner-content .enroll-btn {
    font-size: 14px;
    padding: 10px 12px 10px 12px;
  }
}

@media screen and (max-width: 680px) {
  .banner-inner-wrapper {
    width: 100%;
    height: 14.425rem;
    padding: 25px;
  }
  .banner-inner-wrapper .banner-content .text1 {
    font-size: 1.125rem !important;
    line-height: 1.5625rem;
    letter-spacing: 0.0225rem;
    width: 20.1875rem !important;
  }
  .banner-inner-wrapper .banner-content .text2 {
    font-size: 0.625rem !important;
    line-height: 1rem;
    width: 18.0625rem !important;
  }
  .banner-inner-wrapper .banner-content .enroll-btn {
    font-size: 0.875rem;
    display: inline-flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .banner-inner-wrapper .banner-img img {
    width: 9.75rem;
    height: 14.4375rem;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 550px) {
  .banner-inner-wrapper {
    display: none !important;
  }
  .mobile-banner-inner-wrapper {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(136deg, #c60606 0%, #6c0007 101.16%);
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
  }

  .banner-content .text1 {
    color: #fff;
    font-family: "Poppins";
    font-size: 1.125rem !important;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5625rem;
    letter-spacing: 0.0225rem;
    width: 19.6875rem !important;
    margin-bottom: 1rem;
  }

  .banner-content .text2 {
    color: #fff;
    font-family: Poppins;
    font-size: 0.625rem !important;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    width: 18.625rem !important;
    margin-bottom: 1rem;
  }

  .banner-content .enroll-btn {
    border-radius: 0.25rem;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: -4px -4px 12px 0px rgba(181, 5, 3, 0.12),
      4px 4px 12px 0px rgba(181, 5, 3, 0.12);
    display: inline-flex;
    padding: 0.5rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
  }

  .banner-img {
    display: flex;
    justify-content: center;
  }

  .banner-img img {
    width: 12.5rem;
    height: 18.5625rem;
    object-fit: contain;
  }
}
