.address_and_payment {
}

.handbook_coupon_coin_wrapper {
  width: 365px;
  // height: 470px;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 25px;

  position: sticky;
  top: 160px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem !important;

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      span {
        color: #121212;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .view-coupons {
      color: #121212;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.12px;
      text-decoration: none;
      margin-left: 65px;
    }
  }

  .coupon-input {
    display: flex;
    margin-bottom: 20px;

    input {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px 0 0 8px;
      border: 1px solid #b1b1b1;
      color: #797979;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      padding-left: 0.6rem;
    }

    button {
      display: flex;
      padding: 11px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      background: #263238;
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      border-radius: 0 8px 8px 0;
    }

    .disableapplybtn {
      opacity: 0.7 !important;
      background: #263238 !important;
    }
  }

  .coins-reedem-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.4rem;

    .coins-reedem-inner-wrapper {
      display: flex;
      align-items: center;

      img {
        width: 1.625rem;
        height: 1.625rem;
        margin-right: 0.5rem;
      }

      .coins-reedem-content {
        .reedem-heading {
          color: #000;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.00875rem;
          text-align: start;
        }

        .coins-count {
          color: #505050;
          text-align: center;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.0075rem;
          text-align: start;

          span {
            color: #121212;
            font-family: Poppins;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0075rem;
          }
        }
      }
    }

    .coins-input {
      input {
        stroke-width: 1px;
        stroke: #8b8b8b;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .price-details {
    h3 {
      color: #121212;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .price-row {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 8px;
      font-size: 14px;

      &.total {
        font-weight: bold;
        margin-top: 15px;
        border-top: 0.6px solid #ececec;
        margin-bottom: 0.5rem;
        padding-top: 0.2rem;
      }

      .price-value {
        color: green;
      }
    }
  }

  .terms {
    color: #256bbd;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    margin: 1.8rem 0 1.25rem;
    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }

  .proceed-button {
    display: flex;
    width: 318px;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #b50503;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin-top: 12px;
  }

  .validity {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 12px;
    span {
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
