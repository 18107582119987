.questionInfoText {
  h2 {
    font-family: Poppins;
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
  }
  .text_content_wrapper {
    .topicWrapper {
      color: #121212;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 15px;

      span {
        color: #5E5E5E;
        font-weight: 400;
      }
    }

    .subTopicWrapper {
      color: #121212;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 15px;

      span {
        color: #5E5E5E;
        font-weight: 400;
      }
    }

    .typeLanguageWrapper {
      display: block!important;
      color: #121212;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 30px;

      span {
        color: #5E5E5E;
        font-weight: 400;
      }
    }

    // .questionBox {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   font-family: Poppins;
    //   font-size: 16px;
    //   font-weight: 500;
    //   line-height: 24px;
    //   letter-spacing: 0em;
    //   color: #000000;

    //   p {
    //     flex: 1;
    //   }
    // }

    .questionBox {
      display: block;
      justify-content: space-between;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;

      letter-spacing: 0em;
      color: #000000;
      margin-top: 20px;
      span {
        margin-right: 10px;
      }
      p {
        flex: 1;
        text-align: start;
      }
    }
  }
}
