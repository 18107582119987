.single_question_number_wrapper {
  margin: 10px 5px;
  display: block;

  .arrow {
    width: 8px;
  }

  .unanswered_lbl {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .questionBox {
      display: flex !important;
      align-items: center;
      overflow-x: hidden;
      justify-content: space-between;

      p {
        display: block !important;
        margin-bottom: 0px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        math {
          display: inline-block;
        }

        img {
          display: none;
        }

        table {
          width: 100% !important;
        }
      }
    }
  }

  .answered_lbl {
    border: 1px solid #e2e2e2;
    background-color: #fff;
  }

  .review_lbl {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    padding: 10px;
    border-radius: 8px;
    background: #fed5d5;
    border: 1px solid #ffc4c4;
    cursor: pointer;
  }

  .skip_lbl {
    border: 1px solid #ffc4c4;
    background-color: #fed5d5;
  }

  .current_lbl {
    border: 1px solid #ff933a;
    cursor: pointer;
    background: #ffffff;
  }
}

.single_question_number_wrapper_box {
  display: inline-block !important;
  padding: 10px 5px 0px 7px;
}
.unanswered_lbl {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding: 8px;
  border-radius: 0.25rem;
  border: 1px solid #d9d9d9;
  background: #fff;
  cursor: pointer;
  min-width: 40px;
  .questionBox {
    display: flex !important;
    p {
      overflow: visible;
      display: block !important;
    }
    .QBox {
      display: inline-block;
    }
  }
}
.current_lbl {
  border: 1px solid red;
  cursor: pointer;
  background: #fff;
}
