.onlineTransactionWrapper {
  // margin: 75px 0;

  .online_transactions_history_collection_wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    // padding: 0 5px;

    .no-transaction {
      text-align: center;
      margin-top: 100px;

      img {
        width: 120px;
      }

      p {
        color: #000;
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }

    .single_transaction_wrapper {
      border-radius: 8px;
      border: 1px solid #C4C4C4;
      background: #FFF;
      padding: 15px;
      margin-bottom: 20px;

      .section_wrapper_1 {
        border-radius: 4px;
        background: #F6F6F7;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left_side_content_wrapper {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              color: #263238;
              font-family: "Poppins";
              font-size: 18px;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .right_side_content_wrappers {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              color: #263238;
              font-family: "Poppins";
              font-size: 18px;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }

      .section_wrapper_2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;
        padding: 10px 10px 0;

        .text_content_wrapper {
          .text_content_1 {
            color: #2D2D2D;
            font-family: "Poppins";
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 5px !important;
          }

          .text_content_2 {
            margin: 0;
            color: #121212;
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
    }
  }
}
