.OtsAnalytics_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1020px;

  .OtsAnalytics_test_item {
    background: #ffffff;
    padding: 20px;
    width: calc(50% - 10px);
    margin: 0 20px 20px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }

    .OtsAnalytics_test_heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      .OtsAnalytics_test_name {
        color: #121212;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .OtsAnalytics_test_icons {
        display: flex;
        justify-content: space-around;
        width: 90px;

        img {
          cursor: pointer;
        }
      }
    }

    .OtsAnalytics_test_course {
      font-size: 12px;
      font-weight: 400;
      font-family: "Poppins";
      font-style: "Regular";
      line-height: 18px;
      color: #4d4d4d;
      margin-bottom: 12px;
    }

    .OtsAnalytics_test_que_mins_marks ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        color: #484848;
        font-family: "Poppins";
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 7px;
      }

      :nth-child(2) {
        margin-left: 5px;
      }
      :nth-child(3) {
        margin-left: 5px;
      }
    }

    .OtsAnalytics_test_attemptedDate {
      color: #484848;
      font-family: "Poppins";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.12px;
      margin-bottom: 20px;
    }

    .OtsAnalytics_test_buttons {
      .OtsAnalytics_test_ViewAnalytics_button {
        cursor: pointer;
        border-radius: 59px;
        background: #263238;
        padding: 14px 32px;
        color: #fff;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
      }

      .OtsAnalytics_test_ViewAnalytics_button_fade {
        box-shadow: 0px 4px 12px rgba(66, 38, 15, 0.15);
        border-radius: 20px;
        padding: 6px 10px 6px 10px;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
      }
    }
  }
  .ots_no_data_found_wrapper {
    margin: auto;
    img {
      width: 150px;
      height: 150px;
    }
  }
}

@media (max-width: 575.98px) {
  .OtsAnalytics_container {
    .OtsAnalytics_test_item {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
