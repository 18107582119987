.single_question_number_wrapper1 {
  .unanswered_lbl1 {
    overflow: hidden;
    display: flex !important;
    gap: 0.5rem;
    align-items: center !important;
    justify-content: space-between !important;
    .questionBox1 {
      display: flex !important;
      p {
        overflow: visible;
        display: block !important;
      }
      .QBox {
        display: inline-block;
      }
    }
  }
}
.single_question_number_wrapper {
  margin: 5px;
  display: inline-block;

  .lblBox {
    background-color: #ffffff;
  }

  .arrow {
    width: 8px;
  }

  .unanswered_lbl {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 40px;

    .questionBox {
      display: flex;
      align-items: baseline;
      overflow-x: hidden;

      p {
        margin-left: 2px;
        margin-bottom: 0px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        math {
          display: inline-block;
        }

        img {
          display: none;
        }

        table {
          width: 100% !important;
        }
      }
    }
  }

  .answered_lbl {
    border: 0.6px solid #8ae09f;
    cursor: pointer;
    background: #c8f1d2 !important;

    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 40px;

    .questionBox {
      display: flex;
      align-items: baseline;
      margin-right: 5px;
      overflow-x: hidden;

      p {
        margin-left: 2px;
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        math {
          display: inline-block;
        }

        img {
          display: none;
        }

        table {
          width: 100% !important;
        }
      }
    }
  }

  .review_lbl {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 10px;
    border-radius: 8px;
    background: #ffc4c4;
    border: 1px solid #ffc4c4;
    cursor: pointer;
    min-width: 40px;

    .questionBox {
      display: flex;
      align-items: baseline;
      margin-right: 5px;
      overflow-x: hidden;

      p {
        margin-left: 2px;
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        math {
          display: inline-block;
        }

        img {
          display: none;
        }

        table {
          width: 100% !important;
        }
      }
    }
  }

  .skip_lbl {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ffa68c;
    cursor: pointer;
    background: #ffc5b4;
    min-width: 40px;

    .questionBox {
      display: flex;
      align-items: baseline;
      margin-right: 5px;
      overflow-x: hidden;

      p {
        margin-left: 2px;
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        math {
          display: inline-block;
        }

        img {
          display: none;
        }

        table {
          width: 100% !important;
        }
      }
    }
  }

  .current_lbl {
    // border: 1px solid #ff933a;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ffa68c;
    cursor: pointer;
    background: #fff;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 40px;

    .questionBox {
      display: flex;
      align-items: baseline;

      p {
        margin-bottom: 0px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;
        width: 100% math {
          display: inline-block;
        }

        img {
          display: none;
        }

        table {
          width: 100% !important;
        }
      }
    }
  }

  .isGussedAnswer {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #b184ff;
    cursor: pointer;
    background: #dbc9fb !important;
    min-width: 40px;

    .questionBox {
      display: flex;
      align-items: baseline;
      margin-right: 5px;
      overflow-x: hidden;

      p {
        margin-left: 2px;
        margin-bottom: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        -webkit-box-orient: vertical;

        math {
          display: inline-block;
        }

        img {
          display: none;
        }

        table {
          width: 100% !important;
        }
      }
    }
  }
}
