.behaviourWrapper {
    background-color: #ffffff;
    min-height: 100vh;


    .summary_outer {
        padding-top: 101px;

        .summary_wrapper {
            border-radius: 1rem;
            background: rgba(238, 239, 240, 0.50);
            width: 78.75%;
            padding: 1.75rem 5.95rem 4.25rem;
            margin: 0 auto 3.7rem;

            .heading_div {
                h3 {
                    margin: 0 auto 2.25rem;
                    color: #000;
                    font-family: "Poppins";
                    font-size: 1.375rem;
                    font-weight: 500;
                    line-height: normal;
                }
            }
    
            .cards_wrapper {
                display: flex;
                flex-wrap: wrap;
    
                .summary_card {
                    padding: 1.8rem;
                    border-radius: 0.5rem;
                    border: 0.8px solid #E9E9E9;
                    background: #FFF;
                    text-align: center;
                    width: calc( 33.33% - 1rem );
                    margin-right: 1.25rem;
                    margin: 0 1.25rem 1.25rem 0;

                    &:nth-child(3n) {
                        margin-right: 0;
                    }
    
                    p {
                        &:first-child {
                            color: #505050;
                            font-family: "Poppins";
                            font-size: 1.125rem;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 0.5rem;
                        }
    
                        &:last-child {
                            color: #121212;
                            font-family: "Poppins";
                            font-size: 1.5rem;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: 0.03rem;
                            margin: 0;
                        }
                    }
                }
            }
        }
    
        .button_div {
            display: flex;
            justify-content: center;
            width: 100%;
    
            button {
                width: 14rem;
                padding: 1rem 3rem;
                border-radius: 0.5rem;
    
                &:first-child {
                    color: #B50303;
                    font-family: "Poppins";
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: normal;
                    border: 1px solid #B50303;
                    margin-right: 1.5rem;
                }
    
                &:last-child {
                    color: #FFF;
                    font-family: "Poppins";
                    font-size: 1rem;
                    font-weight: 500;
                    line-height: normal;
                    background: #B50303;
                }
            }
        }
    }
    

    // .behaviourInrWrapper {
    //     padding-top: 101px;
    //     padding-bottom: 20px;

    //     .sbjtWiseMarksInr {
    //         margin: 20px 50px 0px;
    //         background-color: #fff;
    //         border-radius: 10px;
    //         padding: 20px;

    //         .backBtn {
    //             cursor: pointer;

    //             img {}
    //         }

    //         .difficultyLevel {
    //             h3 {
    //                 text-align: center;
    //                 font-size: 25px;
    //                 font-weight: 600;
    //                 margin-bottom: 25px;
    //             }

    //             .difficultyLevelTab {
    //                 .difficultyLevelcontent {
    //                     display: flex;
    //                     flex-wrap: wrap;

    //                     .difficultyData {
    //                         background: #F5F4F8;
    //                         padding: 15px 20px;
    //                         width: calc(50% - 15px);
    //                         border-radius: 8px;
    //                         margin-bottom: 30px;

    //                         ul {
    //                             padding: 0px;
    //                             margin: 0px;

    //                             li {
    //                                 list-style: none;
    //                                 margin-bottom: 20px;

    //                                 span {
    //                                     width: 25%;
    //                                     display: inline-block;
    //                                     font-weight: 600;
    //                                     font-size: 15px;
    //                                 }
    //                             }

    //                             .titleData {
    //                                 span {
    //                                     font-weight: 500;
    //                                     font-size: 14px;
    //                                 }
    //                             }
    //                         }
    //                     }

    //                     .scatterChart {
    //                         width: 100%;
    //                         margin-bottom: 40px;
    //                         position: relative;

    //                         .canvasjs-chart-credit {
    //                             display: none;
    //                         }
    //                     }

    //                     .scorePercentageBox {
    //                         width: calc(50% - 15px);
    //                         display: flex;
    //                         align-items: center;
    //                         border: 1px solid #eeeeee;
    //                         border-radius: 8px;
    //                         background: #f5f4f8;
    //                         margin-bottom: 30px;
    //                         margin-left: 30px;

    //                         .canvasDoughnutChart_wrapper {
    //                             position: relative;

    //                             ::after {
    //                                 content: "";
    //                                 width: 60px;
    //                                 height: 10px;
    //                                 background-color: #F5F4F8;
    //                                 position: absolute;
    //                                 bottom: 2px;
    //                                 left: 1px;
    //                             }

    //                             .canvasjs-chart-credit {
    //                                 display: none;
    //                             }
    //                         }

    //                         .scoreChart {
    //                             width: 150px;
    //                             margin: 40px 20px;

    //                             .CircularProgressbar .CircularProgressbar-path {
    //                                 stroke: #FCB21D;
    //                             }
    //                         }

    //                         .mediumResult {
    //                             .CircularProgressbar .CircularProgressbar-path {
    //                                 stroke: #6D48EF;
    //                             }
    //                         }

    //                         .hardResult {
    //                             .CircularProgressbar .CircularProgressbar-path {
    //                                 stroke: #4DD7D8;
    //                             }
    //                         }

    //                         .scoreDetail {
    //                             p {
    //                                 font-size: 14px;
    //                                 margin-bottom: 10px;
    //                                 font-weight: 600;
    //                                 padding-right: 15px;
    //                             }

    //                             .percentageDiv {
    //                                 font-size: 12px;
    //                                 margin-bottom: 5px;

    //                                 span {
    //                                     background-color: #E2E2E2;
    //                                     width: 8px;
    //                                     height: 8px;
    //                                     border-radius: 50%;
    //                                     display: inline-block;
    //                                     margin-right: 8px;
    //                                 }

    //                                 p {
    //                                     font-size: 12px;
    //                                     margin-bottom: 5px;
    //                                     font-weight: normal;
    //                                     display: inline-block;
    //                                     padding: 0px;
    //                                     max-width: 24px;
    //                                     overflow: hidden;
    //                                     vertical-align: -webkit-baseline-middle;
    //                                 }
    //                             }

    //                             .correct {
    //                                 span {
    //                                     background-color: #31D680;
    //                                 }
    //                             }

    //                             .wrong {
    //                                 span {
    //                                     background-color: #F93030;
    //                                 }
    //                             }

    //                             .unattempt {
    //                                 span {
    //                                     background-color: #668DE1;
    //                                 }
    //                             }
    //                         }
    //                     }

    //                     .margin0 {
    //                         margin-left: 0px;
    //                     }
    //                 }

    //                 .marksSummary {
    //                     border: 1px solid #EEEEEE;
    //                     background: #F5F4F8;
    //                     border-radius: 8px;
    //                     width: calc( 50% - 15px);
    //                     padding: 40px;


    //                     ul {
    //                         padding: 0px;
    //                         margin: 0px;

    //                         li {
    //                             list-style: none;
    //                             display: flex;
    //                             justify-content: space-between;
    //                             margin: 12px 0px;

    //                             span {
    //                                 font-size: 15px;

    //                                 &:last-child {
    //                                     font-size: 16px;
    //                                     font-weight: 500;
    //                                 }
    //                             }

    //                         }
    //                     }
    //                 }

    //                 .viewSolution {
    //                     padding: 15px 0px 10px;
    //                     text-align: center;

    //                     button {
    //                         background-color: #6D48EF;
    //                         padding: 7px 15px;
    //                         border-radius: 10px;
    //                         color: #fff;

    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}