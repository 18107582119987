.doubtsView_wrapper {
  font-family: Poppins;
  .doubtsView_inner_wrapper {
    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .content_wrapper {
          padding: 20px 0;

          .left_side_content_wrapper {
            .doubts_subject_collection_wrapper {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              gap: 20px;

              .doubts_single_select_subject {
                border-radius: 0.5rem;
                padding: 0.875rem 0.438rem;
                border-radius: 0.5rem;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.2px;
                cursor: pointer;
                min-width: 150px;
                text-align: center;

                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }

                label {
                  border: solid 1px #f0eef8;
                  padding: 0.875rem 3.438rem;
                  border-radius: 0.5rem;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: 0.2px;
                  cursor: pointer;
                  min-width: 150px;
                  text-align: center;
                }
              }
            }
          }

          .right_side_content_wrapper {
            position: relative;

            .doubtsCollectionView_wrapper {
              margin-top: 1.875rem;
              .doubtsCollectionView_inner_wrapper {
                .section_wrapper {
                  .section_inner_wrapper {
                    .options_wrapper {
                      display: flex;
                      justify-content: space-between;

                      .custom_nav_pills {
                        max-width: fit-content;
                        margin-left: 28.125rem;
                        // background: red;

                        .custom_nav_item {
                          border: none;
                          outline: none;
                          overflow: hidden;

                          &:first-child .custom_nav_link {
                            border-top-left-radius: 30px;
                            border-bottom-left-radius: 30px;
                          }

                          &:last-child .custom_nav_link {
                            border-top-right-radius: 30px;
                            border-bottom-right-radius: 30px;
                          }

                          .custom_nav_link {
                            //  opacity: 10;

                            // border-radius: 30px;
                            color: #000;
                            font-size: 0.875rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            background-color: #e0e0e0;
                            padding: 0.625rem 1.25rem;
                            margin: 0;
                          }

                          .custom_nav_link.active {
                            color: #ffffff;
                            background: #263238;
                          }
                        }
                      }

                      .overall_create_new_doubt_btn {
                        float: right;
                      }
                    }

                    .tab_wrapper {
                      margin-top: 3rem;

                      .custom_tab_content {
                        .custom_tab_pane {
                          .doubts_tab_wrapper {
                            .outer_content_wrapper {
                              .doubts_collection_wrapper {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 1rem;
                                margin: 0 auto;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .sec_1_wrapper {
                position: absolute;
                top: 0px;
                right: 0px;
              }
            }
          }
        }
      }
    }
  }

  .no_data_found_wrapper {
    text-align: center;
    width: 100%;

    img {
      width: 150px;
      height: 150px;
    }

    p {
      font-size: 14px;
      width: 40%;
      margin: 0 auto;
    }
  }

  .create_douts_modal {
    position: relative;

    .modal-header {
      padding: 10px 15px;
      border: none;
    }

    .modal-body {
      padding: 0px 1rem !important;
    }
  }

  @media (max-width: 991.98px) {
    .doubtsView_wrapper {
      .doubtsView_inner_wrapper {
        .sec_2_wrapper {
          .sec_2_inner_wrapper {
            .content_wrapper {
              .right_side_content_wrapper {
                .doubtsCollectionView_wrapper {
                  .doubtsCollectionView_inner_wrapper {
                    .section_wrapper {
                      .section_inner_wrapper {
                        .options_wrapper {
                          .custom_nav_pills {
                            max-width: 300px;
                          }
                        }
                      }
                    }
                  }
                }

                .sec_1_wrapper {
                  .content_inner_wrapper {
                    .text_content_wrapper {
                      .text_content {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    .doubtsView_wrapper {
      .doubtsView_inner_wrapper {
        .sec_2_wrapper {
          margin: 0px;

          .sec_2_inner_wrapper {
            .content_wrapper {
              .left_side_content_wrapper {
                .doubts_subject_collection_wrapper {
                  margin-bottom: 20px;

                  .doubts_single_select_subject {
                    margin: 0px 10px 10px 0px;

                    label {
                      padding: 6px 10px;
                      min-width: max-content;
                    }
                  }
                }
              }

              .right_side_content_wrapper {
                .doubtsCollectionView_wrapper {
                  .doubtsCollectionView_inner_wrapper {
                    .section_wrapper {
                      .section_inner_wrapper {
                        .options_wrapper {
                          margin-bottom: 75px;

                          .custom_nav_pills {
                            max-width: 300px;
                          }
                        }
                      }
                    }
                  }
                }

                .sec_1_wrapper {
                  top: 50px;
                  right: 50%;
                  transform: translateX(50%);

                  // .content_inner_wrapper {
                  //   .text_content_wrapper {
                  //     .text_content {
                  //       font-size: 12px;
                  //     }
                  //   }
                  // }
                }
              }
            }
          }
        }
      }
    }

    .no_data_found_wrapper p {
      width: 100%;
    }
  }
}

// .create_doubt_btn_wrapper {
//   border-radius: 1.625rem;
//   background: #b50503;
//   color: #fff;
//   font-size: 0.875rem;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
//   padding: 0.75rem 1.875rem;
//   display: inline-block;
//   cursor: pointer;

//   .plus_symbol {
//     margin-right: 0.375rem;
//     width: 1.5rem;
//     height: 1.5rem;
//   }
// }
