.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-- {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  max-width: 800px;
  width: 100%;
  // overflow: hidden;
  padding: 8px;
  position: relative;
  max-height: 90vh; /* Ensure the modal fits within the viewport */
  overflow-y: auto; /* Enable vertical scrolling within the modal */
}

.modal-left {
  flex: 1;
  position: relative;
}

.modal-right {
  flex: 1.2;
  padding-left: 30px;
  padding-right: 30px;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-btn {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.modal-right-content {
  padding-left: 30px;
  padding-right: 30px;
}

.title {
  text-align: center;
  color: #121212;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.18px;
  margin-bottom: 22px;
}

.role-buttons {
  display: flex;
  justify-content: center;
  border-radius: 24px;
  padding: 2px;
  background-color: #f6f6f6;
  width: 70%;
  margin: 0 auto 28px auto;
}

.role-button {
  padding: 10px 35px;
  cursor: pointer;
  color: #585858;
}

.role-button.active {
  background-color: #b50503;
  color: #fff;
  border-radius: 24px;
}

.section-title {
  color: #3d3d3d;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 8px;
  margin-top: 38px;
}

.form-group {
  margin-bottom: 28px;
}

.input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.send-otp-button {
  display: flex;
  justify-content: center;
  background-color: #b50503;
  border-radius: 4px;
  cursor: pointer;
}

.send-otp-button .send-otp-button-text {
  padding: 10px 20px;
  color: #fff;
}

/* Media Queries for responsiveness */
// @media (max-width: 1199.98px) {
//   .modal-- {
//     flex-direction: column;
//     max-width: 600px;
//   }
//   .modal-right {
//     padding: 20px;
//   }
// }

// @media (max-width: 991.98px) {
//   .modal-- {
//     flex-direction: column;
//     max-width: 500px;
//   }
//   .modal-right {
//     padding: 20px;
//   }
// }

@media (max-width: 575.98px) {
  .modal-- {
    flex-direction: column;
    max-width: 100%;
    padding: 10px;
  }
  .modal-left,
  .modal-right {
    flex: 1;
  }
  .modal-close-btn {
    top: 5px;
    right: 5px;
  }
}
