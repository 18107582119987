.new_handbook_wrapper {
  padding-top: 75px;
  width: 100%;

  .new_handbook_inner_wrapper {
    display: flex;
    justify-content: center;
    gap: 50px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;

    .new_handbook_container {
      width: 688px;
      display: flex;
      flex-direction: column;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      justify-content: center;
      gap: 50px;
      position: relative;

      .store-banner-content-and-btn-wrapper {
        img {
          height: 30.1875rem;
          width: 100%;
          object-fit: cover;
        }
        .store-package {
          .store-package-img {
            width: 77px;
            height: 66px;
            margin-bottom: 3px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .product_name {
          color: #000;
          font-family: Poppins;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.01125rem;
          margin-top: 1rem;
          margin-bottom: 0.38rem;
        }
        .product_type {
          color: #b80f0d;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: 1rem; /* 100% */
          letter-spacing: 0.01rem;
          margin: 0.75rem;
        }

        .price_wrapper {
          display: flex;
          width: fit-content;
          justify-content: center;
          align-items: center;
          gap: 0.46rem;
          .discount_price {
            color: #121212;
            font-family: Poppins;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.03rem;

            .price {
              color: #5e5e5e;
              font-family: Poppins;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: line-through;
              margin-left: 0.45rem;
            }
          }

          .discount_percent {
            img {
              width: 1.06975rem;
              height: 1.06975rem;
            }
            color: #148e00;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            border-radius: 0.125rem;
            border: 0.214px solid #e6ffe2;
            background: #edffea;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.3125rem;
            width: fit-content;
          }
        }

        .text {
          position: absolute;
          text-align: center;
          color: #fff;
          top: 40%;
          left: 35%;
          font-family: Poppins;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.4px;
        }

        .new-label {
          position: absolute;
          top: 24px;
          right: 10px;
          padding: 2px 8px;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .tabs-container {
        display: flex;
        background-color: white;
        align-items: center;
        padding: 0 15px;

        .tabs {
          display: flex;
          flex: 1;
        }

        .tab {
          padding: 10px 15px;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          color: #797979;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-weight: 400;

          &.active {
            color: #b50503;
            border-bottom-color: #b50503;
          }

          .icon {
            margin-right: 5px;
            vertical-align: middle;
          }
        }

        .contact-icons {
          display: flex;
          gap: 10px;

          .icon-button {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  .subject-tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    gap: 1rem;

    .single-sub {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // width: calc(48% - 10px);
      width: 48.5%;
      // gap: 10rem;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
        -4px -4px 8px 0px rgba(0, 0, 0, 0.06);

      .sub-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .sub-image {
          width: 2.25rem;
          height: 2.25rem;
          margin-right: 0.75rem;
        }

        .subname {
          color: #121212;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 0.2rem;
        }

        .subtopics {
          color: #696969;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .arrw-image {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
  }

  .description-inner-wrapper {
    display: flex;
    width: 749px;
    padding: 16px 17px 18px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
      -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
    margin-top: 30px;

    .description-content-and-btn-wrapper {
      h3 {
        color: #121212;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.08px;
      }

      .description-content-wrapper {
        .description-content {
          .description-heading {
            color: #505050;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px; /* 164.286% */
            letter-spacing: 0.07px;
            width: 699px;
            button {
              color: #b50503;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 23px;
              letter-spacing: 0.07px;
            }
          }
        }
      }
    }
  }

  .video-inner-wrapper {
    display: flex;
    width: 749px;
    padding: 16px 17px 18px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
      -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
    margin-top: 30px;
    .video-content-and-btn-wrapper {
      h3 {
        color: #121212;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.08px;
      }
    }
  }

  .course-benefits-inner-wrapper {
    display: flex;
    width: 677px;
    padding: 16px 17px 18px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
      -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
    margin-top: 30px;
    margin-left: 5px;
    h3 {
      color: #121212;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.08px;
      margin-bottom: 8px;
    }
    .course-benefits-list {
      .course-benefit-item {
        img {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        span {
          color: #505050;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }

  .performance-inner-wrapper {
    display: inline-flex;
    padding: 11px 30px 11px 30px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    background: #fff3e9;
    width: 749px;
    flex-shrink: 0;
    margin-top: 30px;
    border-radius: 8px;
    margin-left: 5px;
    .performance-benefits-list {
      .performance-benefit-item {
        .performance-benefit-name {
          img {
            width: 42px;
            height: 42px;
          }
          color: #464646;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.16px;
          margin-left: 30px;
        }
      }
    }
  }

  .other-details-inner-wrapper {
    display: flex;
    width: 677px;
    padding: 16px 17px 18px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
      -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
    margin-top: 30px;
    margin-left: 5px;
    h3 {
      color: #121212;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.08px;
    }
    ul {
      color: #505050;
    }
    li {
      color: #505050;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .faq-inner-wrapper {
    display: flex;
    width: 677px;
    padding: 16px 17px 18px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
      -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
    margin-top: 30px;
    margin-bottom: 70px;
    margin-left: 5px;

    h3 {
      color: #121212;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.08px;
      margin-bottom: 16px;
    }

    .faq-accordion {
      width: 100%;

      .accordion-item {
        border: none;
        border-radius: 8px;
        margin-bottom: 8px;

        .accordion-header {
          .accordion-button {
            background: #f6f8f8;
            color: #000;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-weight: 400;
            padding: 12px 16px;

            &:not(.collapsed) {
              color: #000;
              background-color: transparent;
              box-shadow: none;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }

        .accordion-body {
          padding: 0 16px 16px;
          color: #666;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .cart_actions_wrapper {
    width: 365px;
    // height: 470px;
    height: fit-content;
    position: sticky;
    top: 160px;

    flex-shrink: 0;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06),
      -4px -4px 12px 0px rgba(0, 0, 0, 0.06);
    padding: 25px;

    .cart_actions {
      .handbook_add_cart {
        color: #121212;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 0.25rem;
        border: 0.8px solid #121212;
        background: #fff;
        padding: 0.75rem 0;
        text-align: center;
        margin-bottom: 1.25rem;
        cursor: pointer;
      }

      .handbook_buynow {
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0.75rem 0;
        border-radius: 0.25rem;
        background: #b50503;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .faculty-details-inner-wrapper {
    display: flex;
    width: 749px;
    padding: 16px 17px 18px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
      -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
    margin-top: 30px;
    margin-left: 5px;

    h3 {
      color: #121212;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.08px;
      margin-bottom: 8px;
    }

    .slick-slider {
      width: 100%;
    }

    .faculty-slide {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0 10px;

      .faculty-image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 8px;
        margin: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .faculty-name {
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #333;
        margin-bottom: 4px;
      }

      .view-more {
        button {
          font-family: Poppins, sans-serif;
          font-size: 12px;
          color: #b80f0d;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1;

      &:before {
        content: "";
        width: 10px;
        height: 10px;
        border-top: 2px solid #bc1b19;
        border-right: 2px solid #bc1b19;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &.slick-prev {
        left: -20px;
        &:before {
          transform: translate(-25%, -50%) rotate(-135deg);
        }
      }

      &.slick-next {
        right: -20px;
        &:before {
          transform: translate(-75%, -50%) rotate(45deg);
        }
      }

      &:hover {
        background-color: #fff0f0;
      }
    }
  }
}
