.otsSummaryComponentWrapper {
  padding-top: 100px;
  min-height: 100vh;
  background: #ffffff;

  .summary_outer {
    padding-top: 101px;

    .summary_wrapper {
        border-radius: 1rem;
        background: rgba(238, 239, 240, 0.50);
        width: 78.75%;
        padding: 1.75rem 5.95rem 4.25rem;
        margin: 0 auto 3.7rem;

        .heading_div {
            h3 {
                margin: 0 auto 2.25rem;
                color: #000;
                font-family: "Poppins";
                font-size: 1.375rem;
                font-weight: 500;
                line-height: normal;
            }
        }

        .cards_wrapper {
            display: flex;
            flex-wrap: wrap;

            .summary_card {
                padding: 1.8rem;
                border-radius: 0.5rem;
                border: 0.8px solid #E9E9E9;
                background: #FFF;
                text-align: center;
                width: calc( 33.33% - 1rem );
                margin-right: 1.25rem;
                margin: 0 1.25rem 1.25rem 0;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                p {
                    &:first-child {
                        color: #505050;
                        font-family: "Poppins";
                        font-size: 1.125rem;
                        font-weight: 400;
                        line-height: normal;
                        margin-bottom: 0.5rem;
                    }

                    &:last-child {
                        color: #121212;
                        font-family: "Poppins";
                        font-size: 1.5rem;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.03rem;
                        margin: 0;
                    }
                }
            }
        }
    }

    .button_div {
        display: flex;
        justify-content: center;
        width: 100%;

        button {
          width: 14rem;
          padding: 1rem 3rem;
          border-radius: 0.5rem;
          color: #B50303;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 500;
          line-height: normal;
          border: 1px solid #B50303;
          margin-right: 1.5rem;
        }
    }
  }

  // .otsSummaryComponentInnrWrapper {
  //   .container {
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     padding-top: 60px;
  //     .otsSummaryContainer {
  //       // width: 750px;
  //       // height: 400px;
  //       width: 60%;
  //       flex-shrink: 0;
  //       border-radius: 12px;
  //       background: #fff;

  //       .otsSummaryContent {
  //         .otsSummaryBackBtn {
  //           text-align: left;
  //           padding: 22px;
  //         }

  //         .section_heading {
  //           color: #353535;
  //           text-align: center;
  //           font: 400 22px "Poppins", sans-serif;
  //           margin-top: -47px;
  //         }

  //         .cards {
  //           display: flex;
  //           flex-wrap: wrap;
  //           justify-content: center;
  //           padding: 30px 110px;
  //           gap: 2rem;
  //           margin-top: 20px;
  //           .card {
  //             //   width: 144px;
  //             width: 154px;
  //             height: 100px;
  //             display: flex;
  //             justify-content: center;
  //             padding: 10px;
  //             .text {
  //               color: #585858;
  //               text-align: center;
  //               font: 400 14px "Poppins", sans-serif;
  //             }

  //             .num {
  //               color: #000000;
  //               text-align: center;
  //               font: 500 24px "Poppins", sans-serif;
  //             }
  //           }
  //         }

  //         .ots_no_analysis_found_wrapper {
  //           img {
  //             width: 150px;
  //             height: 150px;
  //             margin: 20px 0px 0px 300px;
  //           }

  //           p {
  //             text-align: center;
  //             margin-top: 20px;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .back-to-tests {
  //     text-align: center;
  //     font: 500 16px "Poppins", sans-serif;
  //     margin-top: 35px;

  //     button {
  //       border: 1px solid #6d48ef;
  //       padding: 10px 113px;
  //       color: #6d48ef;
  //       border-radius: 6px;
  //     }
  //   }
  // }
}
