.ots_wrapper {
  .otsTabBox {
    display: flex;

    ul {
      border: none;
      margin: 0 auto;
      width: max-content;
      border-radius: 8px;
      border: 0.6px solid #E0E0E0;
      background: #FFF;
      overflow: hidden;

      li {
        // border: none;

        button {
          // border: none;
          color: #585858;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 0px;
          min-width: 105px;
          padding: 10px 20px;
        }

        .nav-link.active {
          color: #B50303;
          font-weight: 500;
          border-bottom: 4px solid #B50303!important;
        }
      }
    }
  }

  .otsTabContent {
    min-height: 65vh;

    .ots_test_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1020px;
      margin-top: 50px;

      .ots_test_list_item {
        background: #ffffff;
        border-radius: 8px;
        border: 0.8px solid #E9E9E9;
        margin: 0 20px 20px 0;
        width: calc(50% - 10px);

        &:nth-child(2n) {
          margin-right: 0;
        }

        .ots_test_list_content {
          display: flex;
          padding: 15px 20px;

          .ots_test_list_img {
            margin-right: 10px;

            img {
              width: 40px;
            }
          }

          .ots_test_list_text {

            .ots_test_list_left {
              pointer-events: none;

              .ots_test_list_title {
                color: #B50303;
              font-family: "Poppins";
              font-size: 16px;
              font-weight: 500;
              line-height: normal;
              }

              .ots_test_list_exam {
                color: #484848;
                font-family: "Poppins";
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
              }

              .ots_test_list_paragraph {
                padding-right: 10px;
                margin-top: 15px;

                p {
                  font-family: "Open Sans";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;

                  color: rgba(33, 33, 33, 0.6);

                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: -webkit-box !important;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  white-space: normal;
                }
              }
            }
            .ots_test_active {
              pointer-events: auto !important;
              cursor: pointer;
              max-width: 55%;
            }

            .ots_test_list_right {
              // background: red;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              
              .ots_test_list_planner_icon {
                cursor: pointer;
                // margin-right: 10px;
              }

              .ots_test_list_planner_price {
                // background: gray;
                .ots_test_list_price {
                  // margin-top: 15px;
                  // // margin-left: 10px;
                  // margin-bottom: 5px;

                  // font-family: "Open Sans";
                  // font-style: normal;
                  // font-weight: 600;
                  // font-size: 16px;
                  // line-height: 22px;

                  // // color: #880000;
                  // width: 100%;
                  color: #000;
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  margin: -10px 0 5px 5px;

                  .ots_price_strike {
                    text-decoration: line-through;
                    color: #808080;
                    font-size: 12px;
                    font-weight: 400;
                  }

                  .ots_discount_price {
                    // color: #00bc5b;
                    // color: #095a30;
                    margin-left: 5px;
                  }

                  .ots_paid_price {
                    // color: #095a30;
                    color: #880000;
                  }
                }

                .ots_test_list_paynow {
                  // margin-left: 5px;
                  float: right;
                  span {
                    color: #FFF;
                    font-family: "Poppins";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border-radius: 4px;
                    background: #B50303;
                    padding: 8px 16px;
                  }
                }

                .free {
                  margin-top: 35px;

                  span {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;

                    letter-spacing: 0.01em;

                    color: #ffffff;
                    padding: 4px 10px;

                    //  color:#00bc5b;
                    background: linear-gradient(
                      90deg,
                      #00bc5b 2.81%,
                      #007e3d 78.21%
                    );
                    border-radius: 2px;
                  }
                }
                .paid {
                  // margin-top: 35px;

                  span {
                    color: #FFF;
                    font-family: "Poppins";
                    font-size: 14px;
                    font-weight: 500;
                    line-height: normal;
                    border-radius: 4px;
                    background: #36A800;
                    padding: 6px 16px;
                  }
                }
              }
            }
          }
        }
      }

      .ots_no_data_found_wrapper {
        margin: auto;
        img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}

.ots_view_planner_wrapper {
  margin-bottom: 20px;
  .ots_view_planner {
    background: #ffffff;
    border: 0.6px solid #a8a8a8;
    border-radius: 4px;
    text-align: center;
    margin-bottom: 15px;

    .ots_view_planner_header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      // background: red;

      h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        padding: 10px;
        margin-bottom: -20px;
      }

      .test_free_paid {
        margin-top: 15px;
        .test_free {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;

          color: #ffffff;

          background: #00bc5b;
          box-shadow: 2px 2px 6px rgba(0, 188, 91, 0.1);
          border-radius: 2px;
          padding: 3px 7px;
        }
        .test_paid {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #ffffff;
          background: #b92100;
          box-shadow: 2px 2px 6px rgba(0, 188, 91, 0.1);
          border-radius: 2px;
          padding: 3px 7px;
        }
      }
    }

    ul {
      margin: 0px;
      padding: 0px;
      margin-top: 10px;

      li {
        list-style: none;
        margin-bottom: 10px;
        text-align: start !important;
        margin-left: 50px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #646464;
        width: 100%;
      }
      span {
        color: #000000;
        font-weight: 600;
      }
    }
  }

  table {
    width: 70%;
    text-align: center;
    border-collapse: collapse;
    background: #ffffff;
    border: 0.6px solid #a8a8a8;
    border-radius: 4px;
    margin-bottom: 55px;

    th {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      border: 0.6px solid #a8a8a8;
      color: #ffffff;
      background: linear-gradient(90.62deg, #6d48ef 0%, #5025ee 99.99%);
      padding: 7px;
    }
    td {
      width: 135px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      border: 0.6px solid #a8a8a8;
      color: #5c5c5c;
      padding: 10px;
    }
  }
}

.ots_no_data_text {
  h4 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    padding: 10px;
    margin-bottom: -20px;
    text-align: center;
  }
}


@media (max-width: 575.98px) {
  .ots_wrapper {
    .otsTabBox {
      display: flex;
      ul {
        li {
          button {
            min-width: 10px;
            width: auto;
          }
          .nav-link.active {
          }
        }
      }
    }
    .otsTabContent {
      .ots_test_list {
        .ots_test_list_item{
          width: 100%;
        }
      }
    }
  }
}