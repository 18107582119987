.QuestionBankCustomPaperWrppr {
  margin-top: 27px;

  .QuestionBankCustomPaperInrWrppr {
    .qBankTab {
      margin-bottom: 22px;
      justify-content: space-between;

      ul {
        margin: auto;
        width: max-content;
        border-radius: 8px;
        border: 0.6px solid #e0e0e0;
        background: #fff;
        overflow: hidden;

        li {
          button {
            border: none;
            color: #000;
            font-size: 14px;
            font-weight: 400;
            border-radius: 0px;
            min-width: 138px;
            padding: 10px 20px;
          }

          .nav-link.active {
            color: #b50303;
            font-weight: 500;
            border-bottom: 4px solid #b50303 !important;
          }
        }
      }
    }

    .heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #3c3c3c;
      margin-bottom: 10px;
      margin-left: 450px;
    }
    .QuestionBankCustomPaperContent {
      display: flex;
      flex-wrap: wrap;
      border-radius: 16px;
      background: rgba(238, 239, 240, 0.5);
      margin: 0 33px 40px;
      padding: 40px 56px 20px;
      min-height: 500px;

      .questionBankCustomPaperBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 8px;
        border: 0.8px solid #e9e9e9;
        background: #ffffff;
        padding: 20px 20px 25px;
        margin: 0px 20px 20px 0px;
        width: calc(50% - 10px);
        max-height: 200px;

        &:nth-child(2n + 2) {
          margin-right: 0px;
        }
        .questionBankCustomPaperName {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 4px;

          .paperName {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: normal;
            color: #121212;
          }

          .created_date {
            color: #484848;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            strong {
              color: #121212;
              font-weight: 500;
            }
          }
        }

        .questions_data_count {
          font-family: "Poppins";
          font-style: normal;
          ul {
            margin-bottom: 8px;

            list-style: none;
            display: flex;
            margin-left: 0px;
            li {
              margin-right: 7px;
            }

            .qb_total_q {
              border-radius: 4px;
              background: #edf0f0;
              padding: 6px 12px;
              color: #353535;
              font-family: "Poppins";
              font-size: 12px;
              font-weight: 500;
              line-height: normal;

              span {
                font-weight: 600;
              }
            }

            .qb_unattempt {
              font-weight: 500;
              font-size: 12px;
              line-height: normal;

              color: #f5a200;
              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.01em;

                color: #f5a200;
              }
            }
            .qb_wrong {
              color: #ff1212;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;

              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.01em;
                color: #ff1212;
              }
            }
            .qb_correct {
              color: #19af00;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;

              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.01em;
                color: #19af00;
              }
            }
          }
        }
        .qb_topics {
          margin: -12px 0 8px;
          display: flex;
          justify-content: space-between;
          .qb_topics_list {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #656565;
            white-space: wrap;
            word-wrap: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 40ch;
            max-height: 40px;

            .qb_topic {
              margin-right: 5px;
            }
          }

          .topic_content {
            color: #3980d3;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            text-decoration-line: underline;
          }
        }
        .modal {
          .modal-content {
            width: 110%;

            .modal-header {
              display: flex;
              border: none;

              .modal-header-left {
                margin: 20px auto 0;

                .modal-title {
                  color: #000;
                  font-family: "Poppins";
                  font-size: 22px;
                  font-weight: 500;
                  line-height: normal;
                }
              }
              .modal-header-right {
                // margin-top: -25px;
                margin-top: -15px;
                margin-right: 5px;

                button {
                  opacity: 1;
                }
              }
            }

            .modal-body {
              padding: 20px 0 !important;
              margin: 0 42px;
              border-radius: 8px;
              border: 0.6px solid #000;

              .qb_topic {
                padding: 0 0 10px 45px;
                color: #616161;
                font-family: "Poppins";
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                border: none;
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  left: 20px;
                  top: calc(50% - 7px);
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background-color: #616161;
                }
              }
            }

            .modal-footer {
              margin: 28px 0 22px;
              border: none;

              .done_btn {
                border-radius: 4px;
                background: #b50503;
                color: #fff;
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 600;
                line-height: normal;

                margin: auto;
                width: 140px;
                padding: 12px;
              }
            }
          }
        }

        .num_of_questions {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 0;

          color: #4e4e4e;
          .question_tex {
            color: #353535;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            padding: 6px 12px;
            border-radius: 4px;
            background: #edf0f0;
            margin-right: 8px;

            strong {
              font-weight: 600;
            }
          }

          .subject_tex {
            color: #eaa800;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            padding: 6px 12px;
            border-radius: 4px;
            background: #fcf5e6;
          }
        }

        .no_of_quests_startBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height */

          color: #4e4e4e;

          .no_of_questions {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */

            color: #4e4e4e;
            .question_text {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              /* identical to box height */

              color: #4e4e4e;
            }
          }

          .startBtn {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            cursor: pointer;
            color: #6d48ef;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .view_sol_btn {
              text-decoration-line: underline;
            }
            .start_pract_btn {
              text-decoration-line: underline;
            }
            span {
              font-size: 25px;
              margin-top: 2px;
              cursor: pointer;
              margin-right: 13px;
            }
          }
        }

        .date_startBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height */

          color: #4e4e4e;

          .startBtn {
            width: unset;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            cursor: pointer;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .start_pract_btn {
              text-decoration-line: none;
              display: flex;
              color: #b50303;
              font-size: 14px;
              font-weight: 500;

              img {
                width: 24px;
              }
            }
            span {
              font-size: 25px;
              margin-top: 2px;
              cursor: pointer;
              margin-right: 13px;
            }
          }
        }
      }

      .no_custom_tests_img_div {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .no_custom_tests_img {
          border-radius: 50%;
          background-color: #2bccfe1a;
          width: 86px;
          height: 86px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;

          img {
            width: 40px;
          }
        }

        p {
          color: #424242;
          text-align: center;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
