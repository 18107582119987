.test_intructions_view_wrapper_cps {
  padding-top: 40px;
  background-color: #ffffff;

  .test_intructions_view_inner_wrapper {
    border-radius: 16px;
    background: rgba(238, 239, 240, 0.50);
    padding: 35px;
    margin: 0 33px 20px;
    //istruction Screen
        .instructionScreenBox{
          .backBtn{
                  cursor: pointer;
                  display: inline-block;
                }
            .instruction_title{
              color: #000;
              font-family: "Poppins";
              font-size: 22px;
              font-weight: 500;
              line-height: normal;
              margin: 0 auto 40px;
            }
            .instruction_detailBox{
              max-width: 820px;
                margin: 0 auto;
                .examTime{
                  border-radius: 8px;
                  border: 0.6px solid #979797;
                  background: #FFF;
                  padding: 20px 50px;
                  text-align: center;
                  margin-bottom: 15px;
                    .examTitle{
                      color: #121212;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: normal;
                    }
                    .examDurations{
                      color: #000;
                      font-family: "Poppins";
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 22px; /* 137.5% */
                      letter-spacing: 0.16px;

                      span {
                        font-weight: 600;
                      }
                    }
                }
                .instructions{
                  border-radius: 8px;
                  border: 0.6px solid #979797;
                  background: #FFF;
                  
                  h3 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 18px;
                    font-weight: 500;
                    line-height: normal;
                    padding: 25px 20px 0;
                    margin: 0;
                  }
                    .instPoint{
                        padding: 10px 15px;
                        img{
                          width: 100%;
                        }
                        ul {
                          padding-left: 20px;
                          li {
                            color: #424242;
                            font-family: "Poppins";
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 23px;
                            text-align: left;
                            padding-bottom: 10px;
                          }
                        }
                        
                        h6{}
                    }
                }
                .img{
                  text-align: center;
                  padding: 15px 0px;
                }
            }
        }
  }
  .startTestBtn {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;

    button {
      width: 328px;
      border-radius: 4px;
      border: 0.6px solid #B91311;
      background: #B50503;
      color: #FFF;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      padding: 12px 16px;
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .test_intructions_view_wrapper {
    .test_intructions_view_inner_wrapper {
      .sec_1_wrapper {
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .sub_section_wrapper_1 {
            .text_content_wrapper_1 {
              .text_content_1 {
              }
            }
            .text_content_wrapper_2 {
              .text_content_2 {
              }
            }
          }
          .sub_section_wrapper_2 {
            .text_content_wrapper {
              .text_content {
                img {
                }
              }
            }
          }
          .sub_section_wrapper_3 {
            .btn_wrapper {
              button {
                width: 220px;
              }
            }
          }
        }
      }
    }
  }
}
