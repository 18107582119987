.studentProfile {
  padding-top: 7.3rem;
  // margin: auto;
  .Card {
    border-radius: 1rem;
    background: #f6f6f7;
    width: 100%;
    .card-body {
      // padding: 3%;
      padding: 2% 2%;
      .left {
        flex: 8;
        .profile-img {
          img {
            height: 205px;
            width: 205px;
            border-radius: 50%;
          }
        }

        .profile-content {
          margin-left: 25px;
          width: 100%;
          h2 {
            color: #b50303;
            font-family: Poppins;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.03rem;
          }

          ul {
            width: 100%;

            padding: 0px;
            margin: 0px;
            margin-left: 29px;
            li {
              list-style: none;
              margin-left: -28px;
              display: flex;

              .lft-li {
                color: #717171;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 70px;
              }
              .rght-li {
                color: #333;
                font-family: Poppins;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }
      .right {
        flex: 6.5;
        gap: 1.2rem;
        justify-content: center;
        align-items: center;

        .reportCard {
          width: 35%;
          // border-radius: 6px;
          height: 169.5px;
          border-radius: 0.5rem;
          border: 1px solid #d9d9d9;
          background: #fff;
          .top {
            // background: rgba(255, 255, 255, 0.8);
            text-align: center;
            padding: 17px 10px;
            border-top-right-radius: 6px;
            border-top-left-radius: 6px;
            color: #fff;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .btm {
            margin-bottom: -17px;
            border-bottom-left-radius: 5.5px;
            border-bottom-right-radius: 5.5px;
            text-align: center;
            // padding: 22px 10px;
            padding: 13px 10px;

            .num {
              color: #121212;
              font-family: Poppins;
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.015rem;
              margin-bottom: 4px;

              color: #000000;
            }
            .text {
              color: #3e3e3e;
              font-family: Poppins;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .tt {
            background: #02519a;
          }

          .app {
            background: #00afb9;
          }

          .att {
            background: #9a7902;
          }
        }

        .totalTests {
          // background: #02519a;
          // border: 1px solid #63bfcc;
          // box-shadow: 2px 2px 8px 2px rgba(157, 202, 208, 0.24);
        }

        .appeared {
          // background: #00afb9;
          // border: 1px solid #73bb37;
          // box-shadow: 2px 2px 8px 2px rgba(175, 206, 149, 0.24);
        }

        .attendance {
          // background: #9a7902;
          // border: 1px solid #e1d198;
          // box-shadow: 2px 2px 8px 2px rgba(225, 209, 152, 0.24);
          .btm {
            // text-align: start !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            // width: 80%;
            .classes {
              display: flex;
              align-items: center;
              .text {
                padding-right: 20px;
              }
              .num {
                width: 50px;
                margin-top: 5px;
              }
            }

            .atndce {
              display: flex;
              align-items: center;
              .text {
                padding-right: 7px;
              }
              .num {
                margin-left: 5.5px;
                width: 50px;
              }
            }
          }
        }
      }
    }
  }
}
