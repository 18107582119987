.modal-content {
  font-family: "Poppins", sans-serif !important;
  border-radius: 16px;

  .modal-header {
    position: relative;
    // justify-content: center;
    border: 0px;
    padding-top: 25px;
    padding: 1rem 3rem;

    .btn-close {
      position: absolute;
      right: 20px;
      top: 15px;
    }

    .modal-title {
      color: #121212;
      font-size: 22px;
      font-weight: 500;
    }
  }

  .modal-body {
    padding: 2rem !important;
    overflow: auto;

    .modal_body_wrapper {
      .sec_1_wrapper {
        padding-bottom: 15px;

        .text_content_wrapper {
          margin: 10px 0;

          .text_content {
            font-size: 18px;
            text-align: left;
            color: #121212;
            margin-bottom: 10px;
          }
        }

        .target_collection_wrapper {
          .single_target_wrapper {
            position: relative;
            margin: 18px;

            input[type="radio"] {
              opacity: 0;
              position: absolute;
              width: 0;
            }

            label {
              padding: 16px 48px 16px 24px;
              border-radius: 2rem;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0.2px;
              color: #121212;
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 0.8px solid #9f9f9f;
              position: relative;
              width: 100%;
              box-sizing: border-box;
              .target_short_desc {
                color: #565656;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-left: 4px;
              }

              .info_icon {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                width: 25px;
                height: 25px;
                cursor: pointer;
                z-index: 1;
              }
            }

            input[type="radio"]:checked + label {
              border: none;
              color: #ffffff;
              background: #263238;
            }

            input[type="radio"]:checked + label .target_short_desc {
              color: #ffffff; /* Make the span text white */
            }
          }
        }
      }

      .sec_2_wrapper {
        padding-bottom: 15px;

        .text_content_wrapper {
          margin: 10px 0;

          .text_content {
            font-size: 18px;
            text-align: left;
            color: #121212;
            margin-bottom: 10px;
          }
        }

        .class_collection_wrapper {
          display: flex;
          height: auto;
          flex-wrap: wrap; /* Allow items to wrap to the next row */
        }

        .single_class_wrapper {
          margin: 5px;
          width: calc(
            33.33% - 10px
          ); /* Make each item take 1/3 of the width, minus the margin */
          box-sizing: border-box; /* Ensure padding and margin don't affect the width calculation */
        }

        .single_class_wrapper input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }

        .single_class_wrapper label {
          padding: 15px 0px;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.2px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 40px;
          border: 0.8px solid #9f9f9f;
          color: #121212;
          position: relative;
          width: 100%; /* Make the label take up full width of its container */
          box-sizing: border-box;
        }

        .single_class_wrapper input[type="radio"]:checked + label {
          border: none;
          color: #ffffff;
          background: #263238;
        }

        .single_class_wrapper label img.info_icon {
          position: absolute;
          right: 16px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          cursor: pointer;
          z-index: 1;
        }
      }

      .sec_3_wrapper {
        padding-bottom: 15px;

        .text_content_wrapper {
          margin: 10px 0;

          .text_content {
            font-size: 18px;
            text-align: left;
            color: #121212;
            margin-bottom: 10px;
          }
        }

        .course_collection_wrapper {
          .single_course_wrapper {
            margin: 18px;

            input[type="radio"] {
              opacity: 0;
              position: fixed;
              width: 0;
            }

            label {
              padding: 16px 0px 16px 0px;
              border-radius: 42px;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0.2px;
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 0.8px solid #9f9f9f;
              color: #121212;
              position: relative;
              width: 100%;
              box-sizing: border-box;
              text-align: center;
              justify-content: center;

              img.info_icon {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                cursor: pointer;
                z-index: 1;
              }
            }

            input[type="radio"]:checked + label {
              border: none;
              color: #ffffff;
              background: #263238;
            }
          }
        }
      }

      .sec_4_wrapper {
        .text_content_wrapper {
          margin: 10px 0;

          .text_content {
            font-size: 18px;
            font-weight: 500;
            text-align: left;
            color: #121212;
          }
        }

        .batch_collection_wrapper {
          overflow-y: auto;
          height: 60vh;
          .single_batch_wrapper {
            margin: 18px;

            input[type="radio"] {
              opacity: 0;
              position: fixed;
              width: 0;
            }

            label {
              padding: 16px 0px 16px 0px;
              border-radius: 42px;
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 0.2px;
              cursor: pointer;
              display: flex;
              align-items: center;
              border: 0.8px solid #9f9f9f;
              color: #121212;
              position: relative;
              width: 100%;
              box-sizing: border-box;
              text-align: center;
              justify-content: center;

              img.info_icon {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                cursor: pointer;
                z-index: 1;
              }
            }

            input[type="radio"]:checked + label {
              border: none;
              color: #ffffff;
              background: #263238;
            }
          }
        }
      }

      .sec_5_wrapper {
        margin: 10px 0;

        .text_content_wrapper {
          .text_content {
            color: #121212;
            text-align: center;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }

  .modal-footer {
    border: none !important;

    .modal_footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        border: none;
        outline: none;
        width: 171px;
        height: 37px;
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        border-radius: 0.25rem;
        background: #b50503;
      }
    }
  }
}

.modal.show {
  display: block !important;
}

.update_course_modal {
  width: 70%;
}
.modal-content {
  border-radius: 14px !important;
}
.description_target {
  color: #2d2d2d;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.target_collection_wrapper {
  overflow-y: auto;
  height: 60vh;
}
.class_collection_wrapper {
  overflow-y: auto;
  height: 60vh;
}
.course_collection_wrapper {
  overflow-y: auto;
  height: 60vh;
}
// Add this CSS to your stylesheet:
.target-content {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.target-content input[type="radio"] {
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.info-icon-wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.info-icon-wrapper img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
