
.test_intructions_view_wrapper {
    padding-top: 50px;
    background-color: #fff !important;
    .test_intructions_view_inner_wrapper {
      background-color: var(--bg-gray) !important;
      border-radius: 10px;
      padding: 15px;
      //istruction Screen
      .instructionScreenBox {
        .backBtn {
          cursor: pointer;
          display: inline-block;
        }
        .instruction_title {
          text-align: center;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 45px;
          color: #000000;
          margin-bottom: 50px;
          margin-top: -25px;
        }
        .instruction_topicNam {
          text-align: center;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: normal;
          color: #000000;
          margin: 30px 0 20px;
          color: #000;
        }
        .instruction_detailBox {
          max-width: 750px;
          margin: 0 auto;
          .examTime {
            border: 0.6px solid #979797;
            background: #ff933a0d;
            padding: 15px;
            text-align: center;
            border-radius: 10px;
            margin-bottom: 20px;
            .examTitle {
              color: #000;
            margin-bottom: 0px;
            }
            .examDurations {
            }
          }
          .instructions {
            border: none !important;
            border-radius: 0 !important;
            background: transparent !important;
            margin-bottom: 15px;
            h3 {
              font-size: 20px;
             border: 0.6px solid #979797 !important;
             border-radius: 8px;
             background-color: #fff;
              padding: 10px 15px;
              color: #000000;
              margin-bottom: 0.5rem;
              text-align:center;
              font-family: "Source Sans 3", sans-serif !important;
              font-weight: 600 !important;
              font-size: 1.2rem !important;
            }
            .instPoint {
              padding: 10px 15px;
              border: 0.6px solid #979797 !important;
             border-radius: 8px;
             background-color: #fff;
              padding: 10px 15px;
              h5{
                font-size: 1.2rem;
                margin-bottom: 0.7rem;
                font-weight: 500;
              }
              img {
                width: 100%;
              }
              li {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 10px;
                color: #424242;
              }
              h6 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
              }
            }
          }
          .img {
            text-align: center;
            padding: 15px 0px;
          }
          .startTestBtn {
            // margin-bottom: 90px;
            text-align: center;
            button {
              background: var(--red) !important;
              width: 100%;
              max-width: 20rem;
              color: #fff;
              padding: 7px 10px;
              border-radius: 4px !important;
            }:hover{
              // background: #6d18ef;
  
            }
          }
        }
      }
    }
  }
  