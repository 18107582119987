.pyq_subjectTopicWrapper {
  padding-top: 101px;
  background: var(--white);
  .pyq_subjectTopicInner {
    padding: 20px;
    margin: 40px 0px;
    background-color: var(--bg-gray);
    border-radius: 10px;
    min-height: 70vh;
    .backBtn {
      display: inline-block;
      cursor: pointer;
      img {
      }
    }
    .pyqSbjTpcListBox {
      .subject_name {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      .subject_topic_list {
        max-width: 950px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        .subject_topics {
          background: #fff;
          border: 0.8px solid var(--border);
          border-radius: 8px;
          padding: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: calc(50% - 8px);
          margin: 0px 16px 16px 0px;
          cursor: pointer;
          &:nth-child(2n + 2) {
            margin-right: 0px;
          }
          .sbjct_tpc_name {
          }
          .arrow {
            img {
              width: 8px;
            }
          }
          .icon {
            img {
              width: 32px;
              margin-right: 15px;
            }
          }
        }
      }
    }
    //istruction Screen
    .instructionScreenBox {
      .instruction_title {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 20px;
      }
      .instruction_detailBox {
        max-width: 750px;
        margin: 0 auto;
        .examTime {
          border: 1px solid #ffdec2;
          background: #ff933a0d;
          padding: 15px;
          text-align: center;
          border-radius: 10px;
          margin-bottom: 20px;
          .examTitle {
            color: #f1a769;
            margin-bottom: 8px;
          }
          .examDurations {
          }
        }
        .instructions {
          border: 1px solid #ffdec2;
          border-radius: 10px;
          background: #ff933a0d;
          margin-bottom: 15px;
          h3 {
            font-size: 20px;
            border-bottom: 1px solid #ffdec2;
            padding: 10px 15px;
          }
          .instPoint {
            padding: 10px 15px;
            p {
              font-size: 14px;
              color: #000;
            }
            h6 {
            }
          }
        }
        .startTestBtn {
          button {
            background: #6d48ef;
            width: 100%;
            color: #fff;
            padding: 7px 10px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
