.topicWiseMarksBox {
  .subjectsListWrapper {
    .subjectsList {
      display: flex;
      max-width: max-content;
      margin: 0 auto 2.5rem;
      border-radius: 1.5rem;
      border: 0.6px solid #E0E0E0;
      background: #FFF;
      overflow: hidden;

      .single_select_subject {
        &:last-child {
          margin-right: 0px;
        }

        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;
        }

        label {
          color: #585858;
          font-family: "Poppins";
          font-size: 0.875rem;
          font-weight: 400;
          line-height: normal;
          padding: 0.62rem 1.25rem;
          cursor: pointer;
          min-width: 110px;
          text-align: center;
        }

        input[type="radio"]:checked + label {
          background: #263238;
          font-weight: 500;
          border: none;
          color: #ffffff;
        }
      }
    }
  }

  .topicAnalysisData {
    display: flex;
    flex-wrap: wrap;

    .testProgressIndicators {
      border-radius: 0.5rem;
      border: 0.8px solid #E9E9E9;
      background: #FFF;
      padding: 2rem 1.5rem;
      margin-right: 1.25rem;
      width: calc(50% - 0.625rem);

      &:nth-child(2n + 2) {
        margin-right: 0px;
      }

      .topicDetail {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        h4 {
          color: #121212;
          font-family: "Poppins";
          font-size: 0.875rem;
          font-weight: 400;
          line-height: normal;
        }
      }

      .ProgressBar_Line {
        position: relative;
      }

      .ProgressBar_Line span {
        position: absolute;
        top: -22px;
        font-size: 14px;
      }

      .progress {
        height: 1rem;
        border-radius: 1rem;
        margin-bottom: 5px;

        .progress-bar {
          background: #3271D1;
          border-radius: 1rem;
        }
      }

      .questionPercentage {
        display: flex;
        justify-content: space-between;
        padding: 0px 3px;

        span {
          color: #121212;
          font-family: "Poppins";
          font-size: 0.875rem;
          font-weight: 500;
          line-height: normal;
        }
      }
    }
  }
}
