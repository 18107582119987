.cart_products_container {
  width: 749px;

  .cart_product_list_container {
    .products_list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      //   background: red;
      .product_item_wrapper {
        list-style: none;
        border-radius: 0.5rem;
        border: 0.6px solid #dfdfdf;
        background: rgba(255, 255, 255, 0.5);

        .product_item_inner_wrapper {
          display: flex;
          justify-content: center;
          //   align-items: center;

          padding: 1.5rem;
          .product_img {
            flex: 1;

            img {
              width: 8.37794rem;
              height: 8.37794rem;
              margin-right: 1rem;
            }
          }

          .product_content {
            flex: 3;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .p_title {
              color: #000;
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.01rem;
              margin-bottom: 0.38rem;
            }

            .p_type {
              color: #b80f0d;
              font-family: Poppins;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 600;
              line-height: 1rem; /* 133.333% */
              letter-spacing: 0.0075rem;
            }

            .price_and_actions {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .p_price {
                color: #121212;
                font-family: Poppins;
                font-size: 1.375rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.0275rem;
              }

              .p_actions {
                display: flex;
                gap: 1.5rem;

                .p_increase_decrease {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 1rem;

                  .p_decrease {
                    img {
                      width: 2rem;
                      height: 2rem;
                      cursor: pointer;
                    }
                  }

                  .p_quantity {
                    color: #000;
                    font-family: Poppins;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }

                  .p_increase {
                    img {
                      width: 2rem;
                      height: 2rem;
                      cursor: pointer;
                    }
                  }
                }

                .delete {
                  border-radius: 0.25rem;
                  background: #edf0f0;
                  padding: 0.625rem;
                  cursor: pointer;

                  img {
                    width: 1.25rem;
                    height: 1.25rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
