.scheduleClass_wrapper {
  .scheduleClass_inner_wrapper {
    display: flex;
    justify-content: space-between;

    .scheduleClass_left {
      width: calc(50% - 8px);

      border-radius: 0.5rem;
      border: 1px solid rgba(44, 52, 55, 0.11);
      background: #eeeff0;
      padding: 0 1.375rem 1.563rem 1.375rem;

      h4 {
        color: #000;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 1.469rem 0 1.594rem 0;
      }

      .sec_2_wrapper {
        .date_time_collection {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow-x: auto;
          padding-bottom: 1rem;

          .single_select_date {
            margin: 0 0.65rem;

            border-radius: 0.25rem;
            background: #ffffff;
            cursor: pointer;

            &:first-child {
              margin: 0;
            }

            &:last-child {
              margin: 0;
            }

            input[type="radio"] {
              opacity: 1;
              position: fixed;
              width: 0;
            }

            label {
              padding: 0.3rem 0.6rem;

              .text_content_wrapper_1 {
                .text_content_1 {
                  color: #000;
                  font-size: 0.55rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-align: center;
                }
              }

              .text_content_wrapper_2 {
                .text_content_2 {
                  color: #000;
                  font-size: 0.8rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  margin-bottom: 0.09rem;
                  text-align: center;
                }
              }
            }

            input[type="radio"]:checked + label {
              border: none;
              background: #b91311;
              border-radius: 0.25rem;

              .text_content_wrapper_1 {
                .text_content_1 {
                  color: #fff;

                  font-size: 0.55rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-align: center;
                }
              }

              .text_content_wrapper_2 {
                .text_content_2 {
                  color: #ffffff;
                  min-width: 15px;
                  font-size: 0.8rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                  margin-bottom: 0.09rem;
                  text-align: center;
                }
              }
            }
          }
        }

        .date_time_collection::-webkit-scrollbar {
          height: 7px;
          scrollbar-width: thins;
        }
      }

      .sec_3_wrapper {
        max-height: 295px;
        overflow-y: auto;
        padding: 10px;
        margin-top: 0 !important;

        .classes_collection {
          gap: 0.71rem;
          padding-right: 1rem;

          .single_class_wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-radius: 8px;
            margin-bottom: 0.71rem;

            cursor: pointer;

            background: #ffffff;

            padding: 0.9rem 0;

            &:last-child {
              margin-bottom: 0;
            }
            &:first-child {
              margin-top: 1.4rem;
            }

            .left_side_container_wrapper {
              width: auto;

              .image_wrapper {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                overflow: hidden;
                margin-left: 10px;
                margin-right: 5px;

                img {
                  width: 100%;
                  min-width: 60px;
                  min-height: 60px;
                }
              }
            }

            .right_side_container_wrapper {
              width: auto;
              margin: 0 10px;
              width: 100%;
              position: relative;

              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #000;
                }
              }

              .text_content_wrapper_2 {
                .text_content_2 {
                  margin: 0;

                  color: #000;
                  font-family: Poppins;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }

                .batchNm {
                  color: #000;
                  font-family: Poppins;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }

              .text_content_wrapper_3 {
                .text_content_3 {
                  color: #707070;
                  font-family: Poppins;
                  font-size: 0.75rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  margin-bottom: 0.37rem;

                  p {
                    margin: 0;
                    padding: 0;
                  }
                }
              }

              .sub_content_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .left_side_container_wrapper {
                  flex: 0 0 87%;

                  .text_content_wrapper {
                    display: flex;
                    align-items: center;

                    .text_content {
                      margin: 0;

                      color: #003642;
                      font-family: Poppins;
                      font-size: 0.75rem;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;

                      .join_class {
                        color: #707070;
                        font-family: Poppins;
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                      }

                      img {
                        margin-right: 5px;
                        vertical-align: sub;
                      }

                      span {
                        margin-right: 5px;
                        color: #000;
                      }
                    }

                    .Ended {
                      background-color: #ff5d00;
                      background-image: linear-gradient(
                        180deg,
                        #ff9600,
                        #ff5d00
                      );
                      padding: 3px 12px;
                      font-size: 13px;
                      color: #fff;
                      border-radius: 5px;
                      margin-left: 12px;
                    }

                    .Upcoming {
                      background-color: #ffa700;
                      background-image: linear-gradient(
                        180deg,
                        #ffa700,
                        #ffd300
                      );
                      padding: 3px 12px;
                      font-size: 13px;
                      color: #fff;
                      border-radius: 5px;
                      margin-left: 12px;
                    }

                    .Live {
                      background-color: #006eff;
                      background-image: linear-gradient(
                        180deg,
                        #006eff,
                        #00a7ff
                      );
                      padding: 3px 12px;
                      font-size: 13px;
                      color: #fff;
                      border-radius: 5px;
                      margin-left: 12px;
                    }
                  }
                }

                // .right_side_container_wrapper {
                //   flex: 0 0 35%;
                //   text-align: right;
                //   .subject_name {
                //     padding: 5px;
                //     border-radius: 5px;
                //     background-image: linear-gradient(
                //       120deg,
                //       #ff9600 37%,
                //       #ff5d00 72%
                //     );
                //     font-size: 12px;
                //     font-weight: 600;
                //     color: #ffffff;
                //   }
                // }
              }

              .arrow_dv {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);

                img {
                  width: 0.434rem;
                  height: 0.868rem;
                }
              }
            }
          }

          .no_data_found_wrapper {
            //margin: 5px 0;
            .text_content_wrapper {
              p {
                margin: 0;
                font-size: 18px;
                font-weight: 600;
              }
            }

            img {
              width: 200px;
            }
          }
        }
        .schedule_img {
          width: 100px;
          flex-shrink: 0;
          margin: 0 auto;
          img {
            width: 100%;
            height: auto;
          }
        }
        .schedule_text {
          color: #121212;
          text-align: center;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px; /* 158.333% */
          letter-spacing: 0.06px;
        }
      }
    }

    .scheduleClass_right {
      width: calc(50% - 8px);
      border-radius: 0.5rem;

      .home_page_my_progress {
        margin-bottom: 10px;
        border-radius: 8px;
        background: rgba(234, 235, 236, 0.8);
        // padding: 0 1.375rem 3.563rem 1.375rem;
        padding: 0 1.375rem 1.563rem 1.375rem;

        h4 {
          color: #000;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          // padding: 1.469rem 0 1.594rem 0;
          padding: 1.469rem 0 1.594rem 0;
        }

        .home_page_progress_box_wrapper {
          background: #ffffff;
          font-family: Poppins;
          border-radius: 0.25rem;
          .home_page_progress_box {
            display: flex;
            align-items: center;
            justify-content: center;
            // height: 9.375rem;
            height: 10.375rem;

            .home_page_progress-circle {
              width: 20%;
              position: relative;

              .CircularProgressbarr {
              }

              .CircularProgressbar {
                background: #fff;
                border-radius: 50%;
              }

              .CircularProgressbar .CircularProgressbar-trail {
                stroke: #f5f4f8;
              }

              .CircularProgressbar .CircularProgressbar-path {
                stroke: #31d680;
              }

              .CircularProgressbar .CircularProgressbar-text {
                fill: #000000;
                color: #000;
                font-size: 1.313rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .score {
                color: #707070;
                font-size: 0.688rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, 50%);
                width: 100%;
                text-align: center;
                margin-top: 0.063rem;
              }
            }

            .prgs_tst_detail {
              position: relative;
              width: 70%;
              margin-left: 1.406rem;
              font-family: Poppins;

              .tst_dtl_count {
                .tst_count {
                  color: #000000;
                  font-size: 0.9rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  min-width: 30px;
                  display: inline-block;
                  text-align: center;
                  margin-right: 0.25rem;
                }

                .tst_name {
                  margin-left: 0.5rem;
                  color: #707070;
                  font-family: Poppins;
                  // font-size: 0.75rem;
                  font-size: 0.8rem;

                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .arrow_dv {
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                img {
                  width: 0.434rem;
                  height: 0.86794rem;
                }
              }
            }
          }
        }
      }

      .home_page_test_list_wrapper {
        h4 {
          color: #000;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .test_list {
          .test_1 {
            height: calc(50% - 8px);
            background: linear-gradient(
              109deg,
              #2f76f4 0%,
              rgba(83, 141, 244, 0.9) 102.52%
            );
            border-radius: 0.5rem;
            margin-bottom: 1rem;
          }

          .test_2 {
            height: calc(50% - 8px);
            background: linear-gradient(109deg, #786df5 0%, #9f97ff 102.52%);
            border-radius: 0.5rem;
          }

          .test_name {
            padding: 1.875rem 1.154rem 1.875rem 1.154rem;
            color: #fff;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration: none;

            span {
              float: right;
              color: #ffffff;
              img {
                width: 0.465rem;
                height: 1.072rem;
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      padding: 10px;
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      .scheduleClass_left {
        .sec_3_wrapper {
          .classes_collection {
            .single_class_wrapper {
              .right_side_container_wrapper {
                font-size: 14px;

                .text_content_wrapper_1 {
                  .text_content_1 {
                    line-height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .scheduleClass_wrapper {
    .scheduleClass_inner_wrapper {
      padding: 0px;
      flex-direction: column;

      .scheduleClass_left {
        width: 100%;
        padding: 5px;
        margin-bottom: 15px;

        h4 {
          font-size: 18px;
        }

        .sec_2_wrapper {
          .date_time_collection {
            .single_select_date {
              label {
                border-radius: 5px;
                padding: 3px;

                .text_content_wrapper_1 {
                  .text_content_1 {
                    font-size: 12px;
                  }
                }

                .text_content_wrapper_2 {
                  .text_content_2 {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }

      .scheduleClass_right {
        width: 100%;
        font-family: Poppins;

        .my_progress {
          // padding: 10px;

          h4 {
            color: #000;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .progress_box_wrapper {
            background: #ffffff;
            .progress_box {
              flex-direction: column;
              align-items: center;
              .progress-circle {
                width: 50%;
                // padding: 0px;
                .CircularProgressbar {
                  width: auto;
                }

                .score {
                  font-size: 9px;
                }
              }

              .prgs_tst_detail {
                width: 100%;
                margin-left: 0;
                .tst_dtl_count {
                  .tst_count {
                  }

                  .tst_name {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }

        .pyq_cyps_wrapper {
          // padding: 10px;
        }
      }
    }
  }
}
