.acps_banner {
  // width: 100%;
  padding: 15px 75px 15px 40px;
  margin: 20px auto 25px;
  max-width: 1230px;
  border-radius: 16px;
  background: linear-gradient(269deg, #EECEF3 -30.7%, #F7C7AD 97.88%);
  box-shadow: 2px 2px 0px 0px rgba(255, 226, 229, 0.24);

  div:first-child {
    margin-right: 25px;

    img {
      width: 108px;
    }
    
  }

  div:nth-child(2) {
    width: 400px;
    p {
      margin: 0;

      &:first-child {
        color: #000;
        // font-family: Poppins;
        font-size: 24px;
        // font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.48px;
      }

      &:last-child {
        color: #121212;
        // font-family: "Source Sans Pro";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */

        strong {
          font-weight: 600;
        }

        span {
          color: #17A400;
          font-weight: 600;
        }
      }
    }
  }

  div:last-child {
    margin-left: auto;

    button {
      color: #FFF;
      // font-family: "Source Sans Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-radius: 4px;
      border: 0.6px solid #B91311!important;
      background: #B50303;
      padding: 15px 28px;

      img {
        width: 24px;
        margin-right: 6px;
      }
    }

    
  }
}

// $primaryColor: #815efb;
// $secondaryColor: #6d48ef;
// $imgWidth: 110px;
// $imgHeight: 100px;

// .adaptiveCpsCreatePaperWrppr {
//   font-family: "Poppins", sans-serif;
//   padding: 30px;
//   text-align: center;
//   background: #fff8f2;
//   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
//   width: 100%;
//   border-top-left-radius: 10px;
//   border-top-right-radius: 10px;

//   .adaptiveCpsCreatePaperContent {
//     text-align: center;
//     width: 40%;
//     margin: 0 auto;
//     .heading {
//       font-weight: 500;
//       font-size: 22px;
//       line-height: 33px;
//       color: #121212;
//     }

//     .imgWrppr {
//       text-align: center;

//       img {
//         width: $imgWidth;
//         height: $imgHeight;
//       }
//     }

//     .adaptiveCpsCreatePaperContentPara {
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 27px;
//       color: #555555;
//       margin: 0px;

//       margin-bottom: 20px !important;
//       .span-1 {
//         color: #252525;
//         font-weight: bold;
//       }

//       .span-2 {
//         color: #169b00;
//         font-weight: bold;
//       }
//     }

//     .create_new_button {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       margin: 0px 56px;
//       background: linear-gradient(
//         91.51deg,
//         $primaryColor 0%,
//         $secondaryColor 97.4%
//       );
//       box-shadow: 0px 2px 4px rgba(110, 73, 240, 0.16);
//       border-radius: 4px;
//       cursor: pointer;

//       p {
//         padding: 10px;
//         font-weight: 500;
//         font-size: 16px;
//         line-height: 24px;
//         color: #ffffff;
//         margin-bottom: 0px;

//         .add_btn {
//           margin-right: 10px;
//         }
//       }
//     }
//   }
// }
