.testProgressIndicator {
  display: inline-flex;
  padding: 0 1.25rem;
  height: 3.5rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background: #263238;
  width: 100%;
  margin-bottom: 1.5rem;

  .qstnCount {
    color: #fff;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01rem;
  }

  .languageSwitcherIcon {
    button {
      color: #fff !important;
      font-family: Mukta;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.testProgressIndicators {
  // display: inline-flex;
  padding: 0 1.25rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background: #263238;
  width: 100%;
  margin-bottom: 1.5rem;

  .qstnCount {
    color: #fff;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.01rem;
  }

  .languageSwitcherIcon {
    button {
      color: #fff !important;
      font-family: Mukta;
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
