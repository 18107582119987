.userEditProfileContentComponent_wrapper {
  padding-top: 101px;
  padding-bottom: 20px;

  .userEditProfileContentComponent_inner_wrapper {
    padding: 25px;
    margin: 20px auto 70px;
    border-radius: 16px;
    background: #f2f2f3;
    width: 94%;

    .content_wrapper {
      border-radius: 10px;
      padding: 0;
      margin: 0px auto;

      .image_content_wrapper {
        display: flex;
        justify-content: center;

        .image_wrapper {
          width: 120px;
          height: 120px;
          border-radius: 50%;

          .image_inner_wrapper {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: #eceff4;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .dummy_image {
              width: 100px;
              height: 100px;
              border-radius: 50%;
            }

            span {
              padding: 4px 8px;
              border-radius: 24px;
              background: #263238;
              color: #fff;
              font-family: "Poppins";
              font-size: 10px;
              font-weight: 400;
              line-height: normal;
              margin-right: -20px;
            }

            .user_image {
              width: 70px;
              height: 70px;
              border-radius: 50%;
            }

            label {
              position: absolute;
              bottom: 0;
              right: 0;
              cursor: pointer;

              input[type="file"] {
                display: none;
              }

              img {
                width: 100%;
              }
            }
          }
        }
      }

      .tab_button {
        border: 0px;
        justify-content: center;
        margin: 8px auto;
        background-color: #ffffff;
        display: flex;
        width: max-content;
        font-size: 14px;
        font-weight: 400;
        border-radius: 24px;

        li {
          button {
            min-width: 160px;
            border: 0px;
            border-radius: 0;
            border-top-left-radius: 24px;
            border-bottom-left-radius: 24px;
            color: #707070;
            font-size: 14px;
          }

          &:nth-child(2) {
            button {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 24px;
              border-bottom-right-radius: 24px;
            }
          }

          .active {
            color: #fff;
            font-weight: 500;
            background: #263238;
          }
        }
      }

      .user_detail_box {
        width: auto;
        margin: auto;

        label {
          margin: 0 0 10px;
          color: #3d3d3d;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
        }

        .title_edit {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;

          h3 {
            color: #000;
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 400;
            line-height: normal;
            margin: 0;
          }

          button {
            i {
              color: #121212;
            }
          }
        }

        .input_wrp_box {
          display: flex;
          justify-content: space-between;

          .input_wrapper {
            margin-bottom: 20px;
            width: calc(50% - 20px);

            .error_text {
              display: block !important;
              color: red;
              margin: 0;
            }

            input[type="text"],
            input[type="date"],
            input[type="email"],
            input[type="number"] {
              width: 100%;
              height: 45px;
              border-radius: 6px;
              border: none;
              background: #ffffff;
              padding: 0 10px;

              ::placeholder {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.5;
              }

              &:focus {
                border: 0.8px solid #000;
                outline: none !important;
                box-shadow: none !important;
              }
            }
          }
        }

        .gender_wrapper {
          .text_content_wrapper {
            margin: 10px 0;

            .text_content {
              margin: 0;
              font-size: 15px;
              font-weight: 600;
              line-height: 1.33;
              text-align: left;
              color: #0f0404;
            }
          }

          .radio_collection_wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .single_select {
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                border: solid 1px #f0eef8;
                background-color: #fafafd;
                padding: 10px 20px;
                border-radius: 25px;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                display: flex;
                align-items: center;

                img {
                  margin-right: 5px;
                }
              }

              input[type="radio"]:checked + label {
                background-color: #6d48ef;
                border: none;
                color: #ffffff;
              }
            }
          }
        }

        .select_wrapper {
          margin-bottom: 20px;
          width: calc(50% - 20px);

          select {
            width: 100%;
            height: 45px;
            border-radius: 6px;
            border: none;
            background: #ffffff;
            padding: 0 10px;

            &:focus {
              border: 0px !important;
              outline: none !important;
              box-shadow: none !important;
            }
          }
        }

        .btn_wrapper {
          margin: 40px 0 70px;
          text-align: center;

          button {
            border-radius: 4px;
            border: 0.6px solid #b91311;
            background: #b50503;
            padding: 12px 16px;
            width: 328px;
            color: #fff;
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
          }
        }
      }
      .tab-content {
        .your_package_list {
          .current_package {
            margin-bottom: 15px;
            margin-top: 26px;

            h3 {
              color: #000;
              font-family: "Poppins";
              font-size: 20px;
              font-weight: 500;
              line-height: normal;
              margin-bottom: 10px;
            }

            .crnt_pckgeBx_wrpr {
              display: flex;
              flex-wrap: wrap;

              .crnt_pckge_box {
                // display: flex;
                // flex-direction: column;
                // al
                width: calc(100% / 3 - 15px);
                border-radius: 8px;
                border: 0.8px solid #d8d8d8;
                background: #fff;
                // overflow: hidden;
                margin-right: 20px;
                padding: 15px;

                div {
                  &:first-child {
                    // margin-bottom: 10px;

                    .course-heading {
                      width: 60%;
                      text-overflow: ellipsis;

                      -webkit-line-clamp: 2;
                      color: #121212;
                      font-family: "Poppins";
                      font-size: 16px;
                      font-weight: 500;
                      line-height: normal;
                      margin: 0;
                    }

                    .expiry-date {
                      p {
                        margin: 0;
                        font-family: "Poppins";
                        font-size: 12px;
                        line-height: normal;

                        &:first-child {
                          font-weight: 400;
                        }

                        &:last-child {
                          font-weight: 600;
                        }
                      }
                    }
                  }

                  &:nth-child(2) {
                    p {
                      margin: 0;
                      color: #484848;
                      font-family: "Poppins";
                      font-size: 12px;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }

                  &:last-child {
                    p {
                      color: #121212;
                      font-family: "Poppins";
                      font-size: 20px;
                      font-weight: 600;
                      line-height: 22px; /* 110% */
                      letter-spacing: 0.2px;
                      margin: 0;
                    }

                    div {
                      margin: 0;

                      .refund-btn {
                        color: #b50503;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.12px;
                        margin-right: 20px;
                      }

                      .details-btn {
                        color: #121212;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.12px;
                      }
                    }
                  }
                }

                &:nth-child(3n) {
                  margin-right: 0px;
                }

                .left_title {
                  background-color: #e230a0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 10px;
                  flex: 0 0 120px;

                  span {
                    color: #fff;
                    font-weight: 500;
                    font-size: 14px;
                  }
                }

                .right_detail {
                  padding: 10px;
                  width: calc(100% - 120px);
                  position: relative;

                  .refund-status {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-bottom: 15px;
                    border-radius: 0px 4px 0px 4px;
                    padding: 4px 10px 4px 10px;
                    // color: #ffffff;
                    font-size: 10px;
                    font-weight: 500;
                    line-height: 11px;
                    letter-spacing: 0em;
                  }

                  .refund_submitted {
                    border: 1px solid #e82b2b;
                    background: #e82b2b;
                    color: #ffffff;
                  }

                  .refundinitiated {
                    border: 1px solid #f68a26;
                    background: #f68a26;
                    color: #ffffff;
                  }

                  .refundcompleted {
                    border: 1px solid #26b923;
                    background-color: #26b923;
                    color: #ffffff;
                  }

                  h4 {
                    font-size: 15px;
                    font-weight: 600;
                    margin-top: 20px;
                  }

                  .expiry_type_1 {
                    span {
                      color: #707070;
                      font-size: 11px;
                    }

                    .package_type_1 {
                      padding-top: 15px;
                      display: flex;
                      justify-content: flex-end;
                      align-items: flex-end;

                      .ask-refund {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 18px;
                        letter-spacing: 0em;
                        text-decoration: underline;
                        color: #6d48ef;
                        cursor: pointer;
                      }

                      .package-status {
                        background: #6d48ef;
                        border-radius: 5px;
                        font-size: 12px;
                        color: #fff;
                        padding: 2px 7px;
                      }
                    }

                    .refund {
                      justify-content: space-between !important;
                    }
                  }

                  .expiry_type {
                    display: flex;
                    justify-content: space-between;
                    padding-top: 15px;
                    align-items: flex-end;

                    span {
                      color: #707070;
                      font-size: 11px;
                    }

                    .package_type {
                      background: #6d48ef;
                      border-radius: 5px;
                      font-size: 12px;
                      color: #fff;
                      padding: 2px 7px;
                    }
                  }
                }

                .card-type {
                  color: #fff;
                  font-family: "Source Sans Pro";
                  font-size: 0.625rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 1rem; /* 160% */
                  border-radius: 0.25rem;
                  background: #e58845;
                  padding: 0.375rem 0.5rem;
                  width: fit-content;
                }

                .course-heading {
                  width: 80%;
                  text-overflow: ellipsis;

                  -webkit-line-clamp: 2;
                  color: #121212;
                  font-family: "Poppins";
                  font-size: 16px;
                  font-weight: 500;
                  line-height: normal;
                  margin: 0;
                }

                .order-status-handbook {
                  color: #5e5e5e;
                  font-family: Poppins;
                  font-size: 0.625rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  span {
                    color: #4bae4f;
                    font-weight: 500;
                  }

                  .order_failed {
                    color: #b50503;
                    font-weight: 500;
                  }
                }
              }
            }
          }

          .past_package {
            .crnt_pckgeBx_wrpr {
              // opacity: 0.5;

              .crnt_pckge_box {
                &:nth-child(even) {
                  .left_title {
                    background-color: #a231d6;
                  }
                }

                .left_title {
                  background-color: #668de1;
                }
              }
            }
          }
        }

        .no_dataFind {
          text-align: center;
          max-width: 500px;
          margin: 0 auto;

          .img_box {
            width: 160px;
            margin: 0 auto 20px;

            img {
              max-width: 100%;
            }
          }

          h5 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 20px;
          }

          p {
            color: #707070;
            font-size: 14px;
            margin-bottom: 20px;
          }

          button {
            background: #6d48ef;
            font-size: 16px;
            color: #fff;
            border-radius: 6px;
            width: 180px;
            padding: 5px 15px;
          }
        }
      }
    }
  }
}

.package_coursename {
  margin: 10px 0 15px;
}
.package_target {
  margin: 10px 0 15px;
}

@media (max-width: 1198px) {
  .package_coursename {
    margin: 0px 0 7px;
  }
  .package_target {
    margin: 0px 0 7px;
    font-size: 12px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box {
    padding: 10px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    div:last-child
    div
    .refund-btn {
    font-size: 10px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    .course-heading {
    font-size: 12px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    .card-type {
    font-size: 0.525rem;
    line-height: 0.5rem;
    padding: 0.175rem 0.2rem;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    div:first-child
    .course-heading {
    font-size: 14px;
  }
  .package_coursename {
    font-size: 12px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    div:last-child
    p {
    font-size: 14px;
    line-height: 14px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    div:last-child
    div
    .details-btn {
    font-size: 10px;
    line-height: 14px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    div:first-child
    .expiry-date
    p {
    font-size: 10px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    div:first-child
    .course-heading {
    font-size: 12px;
  }
  .package_coursename {
    font-size: 12px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    div:last-child
    p {
    font-size: 12px;
  }
}

@media (max-width: 1024px) {
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper {
    width: calc(50% - 20px); /* Adjust width for tablets */
  }

  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .title_edit
    h3 {
    font-size: 18px;
  }
}

@media (max-width: 890px) {
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box {
    padding: 7px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .image_content_wrapper
    .image_wrapper
    .image_inner_wrapper {
    width: 75px;
    height: 75px;
  }

  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab_button
    li
    button {
    min-width: 94px;
    font-size: 10px;
  }

  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    label {
    margin: 0 0 2px;
    font-size: 12px;
  }

  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper
    input[type="text"],
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper
    input[type="date"],
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper
    input[type="email"],
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper
    input[type="number"] {
    width: 91%;
    height: 33px;
    padding: 0 5px;
    font-size: 10px;
  }

  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .btn_wrapper
    button {
    padding: 8px 2px;
    width: 231px;
    font-size: 12px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .select_wrapper
    select {
    width: 91%;
    height: 34px;
    border-radius: 6px;
    border: none;
    background: #ffffff;
    padding: 0 10px;
    font-size: 10px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper {
    margin-bottom: 4px;
    width: calc(50% - 20px);
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .select_wrapper {
    margin-bottom: 4px;
    width: calc(50% - 20px);
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper {
    margin-bottom: 4px;
    width: calc(50% - 0px);
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .select_wrapper {
    margin-bottom: 4px;
    width: calc(50% - 0px);
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box {
    width: calc(48%);
    margin-right: 15px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box:nth-child(3n) {
    margin-right: 15px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    .card-type {
    font-size: 0.625rem;
    line-height: 8px;
    padding: 0.275rem 0.4rem;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box
    .course-heading {
    font-size: 12px;
  }
  .package_target {
    font-size: 12px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box {
    width: 44%;
    margin-right: 15px;
  }
}

@media (max-width: 780px) {
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .image_content_wrapper
    .image_wrapper
    .image_inner_wrapper
    .dummy_image {
    width: 77px;
    height: 77px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .image_content_wrapper
    .image_wrapper
    .image_inner_wrapper
    span {
    font-size: 7px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab_button
    li
    button {
    min-width: 90px;
    height: 30px;
  }
}

@media (max-width: 690px) {
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr {
    margin-left: -21px;
    margin-right: -22px;
  }
}

@media (max-width: 551px) {
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box {
    width: 92%;
    margin-right: 0px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab-content
    .your_package_list
    .current_package
    .crnt_pckgeBx_wrpr
    .crnt_pckge_box:nth-child(3n) {
    margin-right: 2px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box {
    display: block;
    justify-content: space-between;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper {
    margin-bottom: 4px;
    width: calc(100% - 25px);
    margin: 0px auto;
    margin-left: 42px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .select_wrapper {
    margin-bottom: 4px;
    width: calc(100% - 51px);
    margin: 0px auto;
    margin-left: 40px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .image_content_wrapper
    .image_wrapper
    .image_inner_wrapper {
    width: 60px;
    height: 60px;
  }

  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .tab_button
    li
    button {
    min-width: 80px;
    font-size: 9px;
  }

  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper
    input[type="text"],
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper
    input[type="date"],
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper
    input[type="email"],
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper
    input[type="number"] {
    width: 85%;
    height: 30px;
    padding: 0 5px;
    font-size: 9px;
  }

  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .btn_wrapper
    button {
    padding: 7px 2px;
    width: 200px;
    font-size: 10px;
  }
}

@media (max-width: 490px) {
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .input_wrp_box
    .input_wrapper {
    margin-bottom: 4px;
    width: calc(100% - 25px);
    margin: 0px auto;
    margin-left: 30px;
  }
  .userEditProfileContentComponent_wrapper
    .userEditProfileContentComponent_inner_wrapper
    .content_wrapper
    .user_detail_box
    .select_wrapper {
    margin-bottom: 4px;
    width: calc(100% - 51px);
    margin: 0px auto;
    margin-left: 28px;
  }
}
