.question_bank_Wrapper {
  padding-top: 75px;
  background-color: #ffffff;

  .question_bank_InrWrapper {
    .question_bank_contentWpr {
      background-color: #fff;
      border-radius: 10px;
    }
  }
}
