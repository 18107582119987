////////No need to subtract scrollbar width in ipad,and mobile screens
.userDashboardContentComponent_wrapper {
  padding-top: 101px;

  .userDashboardContentComponent_inner_wrapper {
    width: 100%;

    .slider_container {
      .slick-dots {
        bottom: 10px;
      }

      .single_image_inner_wrapper {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .slick-dots li button:before {
        font-size: 8px;
      }

      .slick-dots li.slick-active button:before {
        color: #fff;
      }
    }

    .graphStatistics_container {
      margin-top: 20px;
    }

    .subject_collection_container {
      margin: 3rem 0 0 0;
    }

    .our_course_target {
      text-align: center;
      margin: 3rem 0 4rem 0;

      .container {
        .btn_wrapper {
          border-radius: 37px;
          padding: 0.73rem 1.5rem;
          background: #b91311;
          width: fit-content;
          margin: 0 auto;

          a {
            text-decoration: none;

            .explore-text {
              color: #fff;
              font-family: Poppins;
              font-size: 0.85rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-right: 1rem;
            }

            .icon {
              img {
                width: 1.875rem;
                height: 1.875rem;
              }
            }
          }
        }
      }
    }

    .institute_wrapper {
      margin: 4rem 0 8rem 0;
      font-family: Poppins;

      .container {
        padding: 0 2rem; // Added container padding

        .institute_title {
          margin-bottom: 2rem; // Increased margin

          h4 {
            letter-spacing: 0.6px;
            color: #000;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 1rem;
          }
        }

        .institute_inner_wrapper {
          display: flex;
          gap: 2rem; // Added gap between boxes
          width: 100%;
          font-family: Poppins;
          flex-wrap: wrap; // Added flex-wrap for responsiveness
        }

        .institute_box_wrapper {
          border-radius: 0.75rem; // Increased border radius
          width: 58%; // Adjusted width accounting for gap
          position: relative;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }

        .institute_box {
          padding: 2rem; // Increased padding
          border: 1px solid rgba(255, 255, 255, 0.1); // Made border subtle
          height: 100%;
        }

        .pgrm_name {
          color: #fff;
          font-size: 1.75rem; // Increased font size
          font-weight: 600;
          line-height: 1.3;
          letter-spacing: 0.033rem;
          margin-bottom: 1rem;
          width: 80%;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .pgrm-description {
          color: #e2e2e2;
          font-size: 1.125rem;
          font-weight: 400;
          line-height: 1.6;
          margin-bottom: 1.5rem;
          width: 70%; // Increased width
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .date_content {
          margin: 1.5rem 0;
        }

        .starting_from_text {
          color: #fff;
          font-size: 0.875rem; // Increased font size
          font-weight: 400;
          margin-bottom: 0.5rem;
        }

        .pgrm_date {
          color: #121212;
          font-size: 1rem;
          font-weight: 600;
          padding: 0.5rem 1rem; // Increased padding
          border-radius: 0.375rem;
          background: #ffa767;
          width: fit-content;
        }

        .price_content {
          margin: 1.5rem 0; // Adjusted margin
        }

        .price_text {
          color: #fff;
          font-size: 0.875rem;
          font-weight: 400;
          margin-bottom: 0.5rem;
        }

        .pgrm_price {
          color: #fff;
          font-size: 1.75rem; // Increased size for better visibility
          font-weight: 700;
          line-height: 1.375rem;
          letter-spacing: 0.03rem;
        }

        .know_more_text {
          color: #000;
          font-size: 0.875rem;
          font-weight: 600;
          border-radius: 0.375rem;
          background: #d9cccc;
          padding: 0.75rem 3rem; // Adjusted padding
          margin: 1.5rem 0; // Added margin
          width: fit-content;
          transition: background-color 0.3s ease; // Added hover effect

          &:hover {
            background: #c4b5b5;
          }
        }

        .pgrm_terms {
          color: #e8e8e8;
          font-size: 0.75rem; // Slightly increased
          font-weight: 400;
          line-height: 1.4;
          width: 70%; // Increased width
          margin-top: auto; // Push to bottom
        }
      }
    }

    .scheduleClassBx_wrapper {
      background: #ffffff;
      margin-bottom: 4rem;
    }
  }

  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    .userDashboardContentComponent_wrapper {
      .userDashboardContentComponent_inner_wrapper {
        padding: 20px 10px;

        .slider_container {
        }

        .graphStatistics_container {
          margin-top: 20px;
        }

        .subject_collection_container {
          margin: 30px 0;
        }

        .right_side_content_wrapper {
          width: 340px;

          .right_side_content_inner_wrapper {
          }
        }
      }
    }
  }
}

.btn-hide {
  display: none !important;
}

.complete-profile-modal {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Poppins;

  .modal-content {
    margin: 0 auto;
    width: 24.75rem !important;

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .complete-profile-image {
        width: 80px;
        height: 80px;
        margin-top: 1.8rem;
        margin: 0 auto;
      }

      .modal-heading {
        margin-top: 1.438rem;
        color: #000;
        text-align: center;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
      }

      .profile-modal-data {
        color: #000;
        text-align: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.75rem;
      }

      .profile-more-info {
        color: #5c5c5c;
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 1rem;
        margin-bottom: 1.375rem;
      }

      .profile-buttons {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;

        .ask-ltr-btn {
          padding: 0.625rem 2.25rem;

          color: #121212;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.16px;
          border-radius: 0.25rem;
          border: 1px solid #434343;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
        }

        .get-sms-btn {
          padding: 0.625rem 2.25rem;

          color: #fff;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.16px;
          border-radius: 0.25rem;
          background: #6d48ef;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

.fee-pending-modal {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Poppins;

  .modal-content {
    margin: 0 auto;
    width: 24.75rem !important;

    .modal-body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .fee-pending-image {
        width: 80px;
        height: 80px;
        margin-top: 1.8rem;
      }

      .fee-pending-heading {
        margin-top: 1.438rem;
        color: #000;
        text-align: center;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
      }

      .fee-data {
        color: #000;
        text-align: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0.75rem;
      }

      .fee-more-info {
        color: #5c5c5c;
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 1.25rem;
        margin-top: 0.75rem;
      }

      .fee-pending-button {
        margin-bottom: 1rem;

        .fee-pending-btn {
          padding: 0.625rem 9.25rem;
          color: #fff;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.16px;
          border-radius: 4px;
          background: #6d48ef;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .userDashboardContentComponent_wrapper {
    .userDashboardContentComponent_inner_wrapper {
      .slider_container {
      }

      .graphStatistics_container {
        margin-top: 20px;
      }

      .subject_collection_container {
        margin: 30px 0;
      }

      .right_side_content_wrapper {
        width: 250px;

        .right_side_content_inner_wrapper {
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .userDashboardContentComponent_wrapper {
    padding-top: 46px;

    .userDashboardContentComponent_inner_wrapper {
      flex-wrap: wrap;
      .institute_wrapper {
        padding-top: 0;
      }

      .subject_collection_container {
        margin: 10px 0;
      }

      .our_course_target {
        padding: 10px 0px 20px;
      }

      .left_side_content_inner_wrapper {
        .slider_container {
        }

        .graphStatistics_container {
        }

        .subject_collection_container {
        }
      }

      .right_side_content_wrapper {
        order: 1;
        width: calc(100vw - 20px);
        margin: 10px 0;

        .right_side_content_inner_wrapper {
        }
      }

      .institute_wrapper {
        .container {
          .institute_title {
            width: auto;
            padding-bottom: 15px;
          }

          .institute_box_wrapper {
            .institute_box {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.emojiContainer {
  position: relative;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.emojiDiv {
  height: auto;
  text-align: center;
}
.emojiLine {
  width: 90%;
  height: 4px;
  // background-color: #FBFBFB;
  background-color: #fbfbfb;
  position: absolute;
  // transform: translate(50% 50%);
  top: 8px;
  left: 5%;
  z-index: 1;
}
.emojiContainer input {
  position: relative;
  z-index: 55;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  padding: 0 0 0 0px;
  /* background: url(https://pbs.twimg.com/profile_images/550291079719698432/LbybHjIh.jpeg) no-repeat; */
  height: 20px;
  width: 20px;
  background-size: 100%;
  // background-color: black;
  border-radius: 50%;
  transition: all 100ms linear;
  cursor: pointer;
}
// .emojiContainer input:hover{
//   transform: scale(1.2);
// }
.emojiContainer .emoji_Bad {
  background-image: url("https://cdn.motion.ac.in/website/Sad_Face_Emoji_3.png");
}
.emojiContainer .emoji_Ave {
  background-image: url("https://cdn.motion.ac.in/website/Confused_Face_Emoji_2.png");
}
.emojiContainer .emoji_Good {
  background-image: url("https://cdn.motion.ac.in/website/Slightly_Smiling_Face_Emoji_3.png");
}
.emojiContainer .emoji_Great {
  background-image: url("https://cdn.motion.ac.in/website/Smiling_Face_Emoji_2.png");
}
.emojitext {
  color: #9c9c9c;
  transition: all 100ms linear;
}
.emojiContainer input:checked {
  background-color: yellow;
  transform: scale(1.4);
}
.emojiContainer input:checked + .emojitext {
  transform: scale(1.2);
}
.emojiContainer input:checked + .emojitext.emojiBad {
  color: #ce0700;
}
.emojiContainer input:checked + .emojitext.emojiAve {
  color: #9e4c00;
}
.emojiContainer input:checked + .emojitext.emojiGood {
  color: #00c795;
}
.emojiContainer input:checked + .emojitext.emojiGreat {
  color: #009220;
}

.feedbackSubmit {
  width: 100%;
  padding: 0.7rem 1rem;
  background: #6d48ef;
  box-shadow: 0px 4px 12px rgba(109, 72, 239, 0.12);
  border-radius: 4px;
  color: #fff;
  transition: all 200ms linear;
}
.feedbackSubmit:disabled {
  background: #d2d2d2;
}
.feedbackSuccess {
  // width: 100%;
  padding: 0.7rem 1rem;
  background: #6d48ef;
  box-shadow: 0px 4px 12px rgba(109, 72, 239, 0.12);
  border-radius: 4px;
  color: #fff;
  transition: all 200ms linear;
}
