.heading-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 22px;
  line-height: 33px;
  margin: 0px 0px 20px 20px;
  color: #121212 !important;
}

.toughness_collection_wrapper {
 display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // width: max-content;
  // background: #f5f4f8;
  // border-radius: 4px 0px 0px 4px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0.25rem;
border: 0.6px solid #E0E0E0;

background: #FFF;

width: max-content;
  // margin-top: 30px;
  margin-bottom: 20px;

  .single_select_toughness {
    border: 1px solid #737373;
    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label {
      padding: 6px 20px;
      letter-spacing: 0.2px;
      color: #585858;
      cursor: pointer;
      display: flex;
      align-items: center;

font-family: 'Poppins';
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
    }

    input[type="radio"]:checked + label {
      background: #263238;
  font-weight: 500;
      color: #FFFFFF;
      outline: 0.6px solid #263238;
      // border: 1px solid #ff933a;
    }
  }
}

.progress_box_wrapper {
  width: 100%;
  background-color: #f5f4f8;
  padding: 15px 15px 15px 15px;
  margin-top: 0.5rem;
  .progress_box {
    width: 100%;
    display: flex;
    align-items: center;
    .prgs_tst_detail {
      position: relative;
      width: 70%;
      margin-left: 15px;

      .tst_dtl_count {
        padding: 3px 0px;

        .tst_count {
          color: #000;
          font-weight: 600;
          min-width: 30px;
          display: inline-block;
          margin-right: 5px;
        }

        .tst_name {
          color: #707070;
          font-size: 14px;
        }
      }

      .arrow_dv {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }

    .progress-circle {
      width: 100%;
      padding: 12px;

      .progress-circle-top {
        display: flex;
        justify-content: space-around;

        .progress-circle-1 {
           position: relative;
          span {
            position: absolute;
           
            top:80px;
            left: 50px;


            margin-top: 8px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            color: #000000;
          }
        }

        .progress-circle-2 {
          position: relative;

        
          span {
            position: absolute;
            top: 80px;
            right:30px;
            margin-top: 8px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            color: #000000;
          }
        }
      }

      .progress-circle-bottom {
        display: flex;
        align-items: center;
        justify-content: center;

        .progress-circle-3 {
          position: relative;
          span {
            position: absolute;
            left: 48px;
            top: 80px;
            margin-top: 8px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            color: #000000;
          }
        }
      }
    }

    .Default {
      background-color: rgb(111, 0, 255);
    }

    .CircularProgressbar {
      background: #fff;
      border-radius: 50%;
      width: 150px;
    }

    .CircularProgressbar .CircularProgressbar-trail {
      stroke: #ffffff;
    }

    .CircularProgressbar .CircularProgressbar-path {
      stroke: #31d680;
    }

    .CircularProgressbar .CircularProgressbar-text {
      fill: #000;
      font-weight: 600;
      dominant-baseline: auto;
    }

    .score {
      color: #707070;
      font-size: 11px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 100%;
      text-align: center;
    }
  }
}

@media (max-width: 1199.98px) {
  .toughness_collection_wrapper{
    
    .single_select_toughness{
      margin-bottom: 0.3rem;
    }
  }
}
