.orderStatusContainer {
  padding-top: 70px;
  padding-bottom: 20px;
}

.backBtnContainer {
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  z-index: 1;
  margin-bottom: 1.5rem;
  padding: 0.81rem 0rem;

  .backBtnInrContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 84.3%;
    margin: 0px auto;

    .backBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .backBtnImg {
        width: 2rem;
        height: 2rem;
        margin-right: 0.25rem;
        cursor: pointer;
      }

      .backBtnText {
        color: #121212;
        font-family: "Source Sans Pro";
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.01125rem;
      }
    }

    .contactUsBtn {
      color: #fff;
      font-family: "Poppins";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.313rem;
      padding: 0.5rem 0.75rem;
      border-radius: 0.25rem;
      background: #b50503;
    }
  }
}

.orderStatusinnerWpr {
  margin: 0px auto;
  width: 84%;
  display: flex;
  gap: 100px;

  .leftSideContainer {
    flex: 2;

    .productsTotalId {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      .productsTotal {
        font-family: "Poppins";
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #000000;
      }

      .productsId {
        font-family: "Poppins";
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #6e6e6e;
      }

      .productIdNum {
        font-weight: 600;
        color: #000000;
      }
    }

    .handbookCard {
      display: flex;
      gap: 20px;
      border: 1px solid #dfdfdf;
      padding: 20px 24px;
      margin-bottom: 20px;
      border-radius: 8px;

      .handbookCardImg {
        width: 108px;
        height: 108px;
      }

      .cardDetails {
        .productName {
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.01em;
          color: #000000;
          max-width: 300px;
          overflow-wrap: break-word;
          white-space: normal;
          margin-bottom: 6px;
        }
        .productCourse {
          font-family: "Poppins";
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.01em;
          color: #b80f0d;
          margin-bottom: 11px;
        }

        .productPrice_Qty {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .productPrice {
            font-family: "Poppins";
            font-size: 22px;
            font-weight: 600;
            line-height: 33px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #121212;
          }

          .productQnty {
            background-color: #edf0f0;
            font-family: Poppins;
            font-size: 11px;
            font-weight: 400;
            line-height: 16.5px;
            padding: 6px 8px;
            border-radius: 4px;
            color: #4f4f4f;
          }
        }
      }
    }
  }

  .rightSideContainer {
    flex: 1;
    margin-bottom: 20px;

    .statusCard {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05),
        0px -4px 8px rgba(0, 0, 0, 0.05), 4px 0px 8px rgba(0, 0, 0, 0.05),
        -4px 0px 8px rgba(0, 0, 0, 0.05);

      background-color: #ffffff;
      padding: 14px 8px 2px 8px;
      padding-left: 8px;
      border-radius: 8px;
      margin-bottom: 14px;

      .statusItem {
        display: flex;
        align-items: flex-start;
        position: relative;
        padding-left: 30px;

        .statusCircle {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #ddd;
          margin-right: 10px;
          border: 2px solid #ddd;
          position: absolute;
          left: 0;
          top: 5px;
          transition: background-color 0.3s ease;
          z-index: 1;
        }

        &.active .statusCircle {
          background-color: #b50503;
          border-color: #b50503;
        }

        &::after {
          content: "";
          position: absolute;
          width: 2px;
          height: 50px;
          background-color: #ddd;
          left: 7px;
          top: 20px;
          z-index: 0;
        }

        &:last-child::after {
          display: none;
        }

        &.active::after {
          background-color: #b50503;
        }

        .statusTextContainer {
          display: flex;
          flex-direction: column;
          margin-bottom: 12px;

          .statusTitle {
            color: #121212;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
          }

          .statusTitle.active {
            color: #b50503;
          }

          .statusDate {
            font-family: "Poppins";
            font-size: 9px;
            font-weight: 400;
            line-height: 13.5px;
            color: #676767;
            margin-top: 4px;
          }

          .statusDescription {
            font-family: "Poppins";
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            color: #000000;
            margin-top: 2px;
          }
        }
      }
    }

    .trackingCard {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05),
        0px -4px 8px rgba(0, 0, 0, 0.05), 4px 0px 8px rgba(0, 0, 0, 0.05),
        -4px 0px 8px rgba(0, 0, 0, 0.05);

      background-color: #ffffff;
      border-radius: 8px;
      padding: 12px 15px;
      margin-bottom: 14px;

      .trackingId {
        font-family: "Poppins";
        font-size: 12px;
        font-weight: 600;
        line-height: 13.75px;
        color: #121212;
        margin-bottom: 4px;
      }

      .courierText {
        font-family: "Poppins";
        font-size: 11px;
        font-weight: 400;
        line-height: 13.5px;
        color: #5c5c5c;
      }
    }

    .priceDetailsCard {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05),
        0px -4px 8px rgba(0, 0, 0, 0.05), 4px 0px 8px rgba(0, 0, 0, 0.05),
        -4px 0px 8px rgba(0, 0, 0, 0.05);

      background-color: #ffffff;
      border-radius: 8px;
      padding-top: 21px;

      .priceDetailsText {
        font-family: "Poppins";
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #121212;
        padding: 0px 12px;
      }

      .priceRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 12px;
        margin-bottom: 3px;

        .priceRowText {
          font-family: "Poppins";
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #6a6a6a;
        }
        .priceRowNum {
          font-family: "Poppins";
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: #414141;
        }

        .priceRowNum2 {
          color: #2a7f42;
        }
      }

      .totalPriceContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #ececec;
        padding: 6px 12px;

        .priceRowText {
          font-family: "Poppins";
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #121212;
        }
        .priceRowNum {
          font-family: "Poppins";
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          color: #121212;
        }
      }
    }
  }
}
