.benifits-inner-wrapper {
  padding: 0 5rem;

  .benifits-heading-wrapper {
    text-align: center;
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 1.75rem;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.035rem;
    margin-bottom: 0.75rem;

    span {
      color: #b50503;
    }
  }

  .benifits-subheading {
    color: #494949;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
    width: 36.5rem;
    margin: 0 auto;
    margin-bottom: 0.4rem;
  }

  .benifits-container {
    .tabs-container {
      margin: 0 auto;
      margin-bottom: 2.75rem;
    }

    .tabs-list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2.5rem;
      background: #f6f6f6;
      width: fit-content;
      margin: 0 auto;
      padding: 0; /* Reset padding */

      .tab {
        color: #494949;
        font-family: "Poppins", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        padding: 0.62rem 1.25rem;
        list-style: none;
        cursor: pointer;
        position: relative;

        &:hover {
          color: #b50503;
        }

        &.active {
          color: #b50503;
          font-weight: 500;

          &:after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #b50503;
          }
        }
      }
    }

    .tabs-content-container {
      display: flex;
      justify-content: space-between;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;

      &.animating {
        opacity: 0;
      }

      .left-content {
        width: 60%;
        padding-right: 1rem;

        .left-heading-wrapper {
          .main-heading {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 1.75rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0175rem;
            margin-bottom: 0.25rem;

            span {
              background: linear-gradient(
                135deg,
                #c7cce0 0%,
                #f48d1f 32%,
                #d72734 65.5%,
                #2f3067 92.94%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          .sub-heading {
            color: #3f3f3f;
            font-family: "Poppins", sans-serif;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.01125rem;
            margin-bottom: 3rem;
          }
        }

        .left-text-wrapper {
          .left-text {
            display: flex;
            align-items: center;
            margin-bottom: 1.38rem;

            img {
              width: 1.5rem;
              height: 1.5rem;
              margin-right: 0.5rem;
            }

            .left-first {
              color: #000;
              font-family: "Poppins", sans-serif;
              font-size: 1.125rem;
              font-weight: 500;
              line-height: normal;
              letter-spacing: 0.01125rem;
              margin-bottom: 0.13rem;
            }

            .left-second {
              color: #494949;
              font-family: "Poppins", sans-serif;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.00875rem;
            }
          }
        }

        .enrollbtn {
          padding: 0.75rem 1.625rem;
          border-radius: 0.5rem;
          border: 1px solid #b50503;
          background: #b50503;
          color: #fff;
          font-family: "Poppins", sans-serif;
          font-size: 1rem;
          font-weight: 600;
          line-height: normal;
          width: fit-content;
          cursor: pointer;
          text-align: center;
        }
      }

      .right-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .benifits-inner-wrapper {
    padding: 0 3rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .main-heading {
    font-size: 1.375rem;
    font-weight: 600;
    letter-spacing: 0.01375rem;
  }

  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .sub-heading {
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.00875rem;
    width: 21.5rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    img {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
  }

  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-first {
    font-size: 0.875rem;
    font-weight: 500;
    letter-spacing: 0.00875rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-second {
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.0075rem;
    width: 24.3125rem;
  }
  .benifits-inner-wrapper .benifits-heading-wrapper {
    font-size: 1.375rem;
    letter-spacing: 0.0275rem;
  }
  .benifits-inner-wrapper .benifits-subheading {
    font-size: 0.875rem;
    line-height: 1.4375rem; /* 164.286% */
    width: 30.5rem;
  }

  .benifits-inner-wrapper .benifits-container .tabs-list {
    padding: 0rem 1rem;
    gap: 0.5rem;
  }
  .benifits-inner-wrapper .benifits-container .tabs-list .tab {
    font-size: 0.75rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content {
    width: 50%;
    height: 22.875rem;
    flex-shrink: 0;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .enrollbtn {
    display: inline-flex;
    padding: 0.625rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .right-content {
    width: 50%;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .right-content
    img {
    display: inline-flex;
    align-items: center;
  }
}

@media screen and (max-width: 957px) {
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-second {
    width: 19.3125rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .sub-heading {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 795px) {
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .main-heading {
    font-size: 1rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .sub-heading {
    font-size: 0.775rem;
    width: 17.5rem;
  }
}

@media (max-width: 768px) {
  .homepage-container .benifits-wrapper {
    margin-top: 5rem !important;
  }
  .benifits-inner-wrapper .benifits-heading-wrapper {
    font-size: 24px;
  }
  .benifits-inner-wrapper .benifits-subheading {
    font-size: 16px;
    width: 31.5rem;
  }
  .benifits-inner-wrapper .benifits-container .tabs-container {
    margin-bottom: 24px;
  }
  .benifits-inner-wrapper .benifits-container .tabs-list {
    gap: 17px;
  }
  .benifits-inner-wrapper .benifits-container .tabs-list .tab {
    font-size: 13px;
    padding: 10px 10px 10px 10px;
  }
  .benifits-inner-wrapper {
    padding: 11px 40px 10px 40px;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .main-heading {
    font-size: 20px;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .sub-heading {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text {
    margin-bottom: 10px;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    img {
    width: 13px;
    height: 13px;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-first {
    font-size: 0.775rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-second {
    font-size: 13px;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .enrollbtn {
    padding: 10px 14px 10px 14px;
  }
}

@media screen and (max-width: 741px) {
  .benifits-inner-wrapper {
    padding: 11px 23px 10px 23px;
  }
}

@media screen and (max-width: 700px) {
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .main-heading {
    font-size: 1rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .sub-heading {
    font-size: 0.775rem;
    width: 17.5rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-first {
    font-size: 0.675rem;
    width: 15rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-second {
    font-size: 10px;
    width: 14rem;
  }
}
@media screen and (max-width: 690px) {
  .benifits-inner-wrapper .benifits-container .tabs-list .tab {
    font-size: 13px;
    padding: 8px 6px 8px 6px;
  }
}

@media (max-width: 560px) {
  .benifits-inner-wrapper .benifits-heading-wrapper {
    font-size: 1.125rem;
    font-weight: 800;
    letter-spacing: 0.0225rem;
  }
  .benifits-inner-wrapper .benifits-subheading {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.1875rem; /* 158.333% */
    width: 20.25rem;
  }
  .benifits-inner-wrapper .benifits-container .tabs-container {
    margin-bottom: 24px;
  }
  .benifits-inner-wrapper .benifits-container .tabs-list .tab {
    padding: 10px 0px 10px 0px;
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.0075rem;
  }
  .benifits-inner-wrapper .benifits-container .tabs-content-container {
    display: grid;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .enrollbtn {
    display: none !important;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content {
    height: 100%;
  }
  .benifits-inner-wrapper {
    padding: 0 1.5rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .right-content {
    width: 100%;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .main-heading {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.01rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-heading-wrapper
    .sub-heading {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1875rem; /* 158.333% */
    width: 19.9375rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    img {
    width: 1.25rem;
    height: 1.25rem;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-first {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .benifits-inner-wrapper
    .benifits-container
    .tabs-content-container
    .left-content
    .left-text-wrapper
    .left-text
    .left-second {
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 495px) {
  .benifits-inner-wrapper .benifits-container .tabs-list .tab {
    font-size: 0.555rem;
  }
}
@media screen and (max-width: 426px) {
  .benifits-inner-wrapper .benifits-container .tabs-list .tab {
    padding: 5px 0px 5px 0px;
    font-size: 0.5rem;
    font-weight: 600;
    letter-spacing: 0.0075rem;
  }
  .benifits-inner-wrapper {
    padding: 0 1rem;
  }
}
