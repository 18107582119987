#footer {
  background-color: #000000;
  padding: 60px 60px 30px;
  position: relative;

  .sec7_wrapper {
    display: flex;
    justify-content: space-between;

    .logo {
      width: 20%;

      img {
        max-width: 100%;
        width: 100px;
      }
    }

    .about_contact {
      width: 30%;

      h5 {
        font-size: 20px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-bottom: 18px;
      }

      .a_link {
        display: block;
        text-decoration: none;
        color: #fff;
        margin-bottom: 12px;
        cursor: pointer;

        i {
          margin-right: 8px;
        }

        .phn {
          transform: rotate(45deg);
          font-size: 19px;
        }
      }
    }

    .width20 {
      width: 20%;
    }

    .contact_form {
      width: 30%;
      text-align: right;

      h5 {
        text-align: left;
      }

      form {
        padding-left: 20%;

        .input_wrapper {
          input {
            border-radius: 4px;
            border: none;
            padding: 0px 10px;
            height: 36px;
            margin-bottom: 10px;
            width: 100%;
          }
        }

        .btn_wrapper {
          button {
            background: #b50503;
            border-radius: 4px;
            width: 100%;
            height: 36px;
            color: #fff;
          }
        }
      }
    }
  }

  .copyright {
    text-align: center;
    padding-top: 30px;
    color: #ffffff;
    opacity: 0.7;
    font-size: 13px;
  }

  .upArrow {
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0px 0px 40px #0000001a;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -20px;
    right: 10%;
    cursor: pointer;

    img {
      width: 25px;
    }
  }
}

#mobile-footer {
  display: none !important;
}

@media screen and (max-width: 768px) {
  #footer {
    background-color: #000000;
    padding: 36px 16px 19px;
    position: relative;
  }
  #footer .sec7_wrapper .logo {
    width: 11%;
  }
  #footer .sec7_wrapper .about_contact .a_link {
    margin-bottom: 10px;
    font-size: 12px;
  }
  #footer .sec7_wrapper .about_contact h5 {
    font-size: 14px;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 660px) {
  #footer {
    display: none !important;
  }
  .get-in-touch,
  .contact-form {
    margin-top: 20px;
  }

  #mobile-footer {
    background-color: #000000;
    padding: 2rem 1rem;
    color: #ffffff;
    display: block !important;

    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
    }

    h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .get-in-touch h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .company-info {
      a {
        display: block;
        color: #c0c0c0;
        font-family: Poppins;
        text-decoration: none;
        margin-bottom: 5px;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .get-in-touch {
      div {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;

        i {
          margin-right: 10px;
          color: #c0c0c0;
          font-size: 0.875rem;
        }
        span {
          color: #c0c0c0;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .contact-form {
      form {
        display: flex;
        flex-direction: column;

        input {
          background-color: #333333;
          border: none;
          border-radius: 4px;
          padding: 10px;
          margin-bottom: 15px;
          color: #ffffff;
        }

        button {
          background-color: #b50503;
          color: #ffffff;
          border: none;
          border-radius: 4px;
          padding: 10px;
          cursor: pointer;
        }
      }
    }

    .contact-form h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .logo {
      text-align: center;

      img {
        max-width: 100px;
        margin-top: 53px;
      }
    }
  }
}
