.Pyq_ppr_test_intructions_view_wrapper {
  padding-top: 50px;

  .test_intructions_view_inner_wrapper {
    background-color: var(--bg-gray);
    border-radius: 10px;
    padding: 15px;

    //istruction Screen
    .instructionScreenBox {
      .backBtn {
        cursor: pointer;
        display: inline-block;
      }

      .instruction_title {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .instruction_detailBox {
        max-width: 750px;
        margin: 0 auto;

        .examTime {
          border: 0.6px solid #979797;
          background: #fff;
          padding: 15px;
          text-align: center;
          border-radius: 10px;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1rem;

          .examTitle {
            color: #000;
            margin-bottom: 0px;
          }

          .examDurations {}
        }

        .instructions {
          border: 0.6px solid #979797;
          border-radius: 10px;
          background: var(--white);
          margin-bottom: 15px;

          h3 {
            font-size: 20px;
            // border-bottom: 1px solid #FFDEC2;
            padding: 10px 15px;
          }

          .instPoint {
            padding: 0 15px 10px;

            img {
              width: 100%;
            }

            p {
              font-size: 14px;
              color: #000;
            }

            h6 {}
          }
        }

        .img {
          text-align: center;
          padding: 0 0 15px;
        }

        .startTestBtn {
          text-align: center;
          button {
            background: var(--red);
            width: 100%;
            max-width: 20rem;
            color: #fff;
            padding: 7px 1.5rem;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .Pyq_ppr_test_intructions_view_wrapper {
    .test_intructions_view_inner_wrapper {
      .instructionScreenBox {
        .instruction_detailBox{
          .examTime{
            flex-direction: column;
          }
        }
        .instruction_title {
          font-size: 22px;
        }
      }
    }
  }
}