.subjectWiseMarksBox {
  .subjectMarksSmry {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .marksChart {
      width: calc(50% - 0.625rem);
      border-radius: 0.5rem;
      border: 0.8px solid #E9E9E9;
      background: #FFF;
      padding: 1.63rem;
      margin-right: 1.25rem;

      .canvasjs-chart-credit,
      .canvasjs-chart-toolbar {
        display: none;
      }
    }

    .marksSummary {
      border-radius: 0.5rem;
      border: 0.8px solid #E9E9E9;
      background: #FFF;
      width: calc(50% - 0.625rem);
      padding: 1.6rem 1.5rem 1.2rem;

      ul {
        padding: 0px;
        margin-top: 0;

        li {
          list-style: none;
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.25rem;

          span {
            color: #505050;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;

            &:last-child {
              color: #121212;
              font-size: 1rem;
              font-weight: 600;
              letter-spacing: 0.01rem;
            }
          }
        }
      }
    }

    .rank {
      //  margin:auto;
      margin-top: 30px;
      margin-left: 200px;

      width: 60%;
      height: 80%;
      background: #f5f4f8;
      border-radius: 8px;
      padding: 10px;
      margin-right: 20px;
    }
  }
}
