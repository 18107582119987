.contact-us-inner-wrapper {
  display: flex;
  border-radius: 1rem;
  background: linear-gradient(
      0deg,
      rgba(181, 5, 5, 0.81) 0%,
      rgba(181, 5, 5, 0.81) 100%
    ),
    lightgray 50% / cover no-repeat;

  .content-and-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    img {
      width: 17.5rem;
      height: 13.6875rem;
      object-fit: contain;
    }

    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 6.8rem 0 3rem;

      .content {
        .heading {
          color: #fff;
          font-family: Poppins;
          font-size: 1.375rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.0275rem;
          margin-bottom: 0.25rem;
        }

        .sub-heading {
          color: #fff;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.01rem;
          width: 34.125rem;
        }
      }

      .contact-us-btn-wrapper {
        border-radius: 0.25rem;
        background: #fff;
        padding: 0.75rem 1.25rem;
        cursor: pointer;

        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-right: 0.5rem;
        }

        color: #121212;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.01rem;
      }
    }
  }
}

.get-in-touch-mobile-no{
  color: #121212;
  font-family: Poppins;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.0075rem;
  text-decoration: none;
}

.mobile-contact-us-wrapper {
  display: none !important;
}

@media (max-width: 1024px) {
  .contact-us-inner-wrapper .content-and-btn-wrapper img {
    width: 9.1875rem;
    height: 12.9375rem;
    flex-shrink: 0;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .heading {
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.0225rem;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .sub-heading {
    font-size: 0.875rem;
    letter-spacing: 0.00875rem;
    width: 20.125rem;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .contact-us-btn-wrapper
    img {
    width: 18px;
    height: 18px;
    margin-right: 1px;
  }
  .contact-us-inner-wrapper .content-and-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    height: 115px;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .contact-us-btn-wrapper {
    padding: 0.625rem 1rem;
    gap: 0.5rem;
  }
}
@media (max-width: 768px) {
  .contact-us-inner-wrapper .content-and-btn-wrapper img {
    width: 7.1875rem;
    flex-shrink: 0;
  }
  .contact-us-inner-wrapper .content-and-btn-wrapper {
    height: 90px;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .heading {
    font-size: 16px;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .sub-heading {
    font-size: 10px;
    letter-spacing: 0.00875rem;
    width: 14.125rem;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .contact-us-btn-wrapper {
    padding: 10px 6px;
  }
}

@media (max-width: 560px) {
  .content-wrapper {
    padding: 0 1.5rem;
  }
  .contact-us-inner-wrapper {
    display: none !important;
  }

  .mobile-contact-us-wrapper {
    display: block !important;
  }
  .mobile-contact-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-contact-us-wrapper {
    .text-content h2 {
      color: #fff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.01rem;
    }
    .text-content p {
      color: #fff;
      font-family: Poppins;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem; /* 160% */
      width: 16.6875rem;
    }
    .contact-btn {
      display: inline-flex;
      padding: 0.625rem 1rem;
      align-items: center;
      gap: 0.5rem;
      border-radius: 0.25rem;
      background: #fff;
      margin-bottom: 25px;
    }
    .contact-btn img {
      width: 1rem;
      height: 1rem;
      transform: rotate(90deg);
    }
    color: black;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .image-wrapper {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
@media screen and (max-width: 426px) {
  .content-wrapper {
    padding: 0 1rem;
  }
  .mobile-contact-us-wrapper .contact-btn {
    margin-bottom: 20px;
  }
  .text-content {
    margin-top: 10px;
  }
}
@media screen and (max-width: 376px) {
  .mobile-contact-us-wrapper .contact-btn {
    margin-bottom: 10px;
  }
  .mobile-contact-us-wrapper .text-content p {
    width: 13.6875rem;
  }
}
@media screen and (max-width: 1318px) {
  .contact-us-inner-wrapper .content-and-btn-wrapper .content-wrapper {
    padding: 0 2.8rem 0 2rem;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .sub-heading {
    width: 23.125rem;
  }
}

@media screen and (max-width: 1231px) {
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .sub-heading {
    width: 21.125rem;
  }
}
@media screen and (max-width: 1032px) {
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .heading {
    font-size: 1.175rem;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .sub-heading {
    font-size: 0.875rem;
    width: 17.125rem;
  }
}
@media screen and (max-width: 812px) {
  .contact-us-inner-wrapper .content-and-btn-wrapper .content-wrapper {
    padding: 0 1.8rem 0 2rem;
  }
}

@media screen and (max-width: 892px) {
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .sub-heading {
    font-size: 0.675rem;
    letter-spacing: 0.00875rem;
    width: 16.125rem;
  }
}
@media screen and (max-width: 664px) {
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .heading {
    font-size: 14px;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .content
    .sub-heading {
    font-size: 0.575rem;
    letter-spacing: 0.00875rem;
    width: 13.125rem;
  }
}

@media screen and (max-width: 616px) {
  .contact-us-inner-wrapper .content-and-btn-wrapper .content-wrapper {
    padding: 0 0.8rem 0 0.8rem;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .contact-us-btn-wrapper
    img {
    width: 13px;
    height: 13px;
    margin-right: 3px;
  }
  .contact-us-inner-wrapper
    .content-and-btn-wrapper
    .content-wrapper
    .contact-us-btn-wrapper {
    font-size: 0.789rem;
  }
}
