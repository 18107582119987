.login_step_one_wrapper {
  border-radius: 10px;

  .login_step_one_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .content_wrapper {
      .content_inner_wrapper {
        width: 400px;
        border-radius: 0.5rem;
        background: #fff;
        padding: 50px 25px 25px;
        .login-heading-wrapper {
          .login-heading {
            text-align: center;
            margin-bottom: 0.5rem;
            color: #121212;
            font-family: Poppins;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.01125rem;
          }

          .login-subheading {
            text-align: center;
            color: #505050;
            font-family: Poppins;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 1.5rem;
          }
        }

        .logintypeslist {
          display: flex;
          margin: 0 auto;
          justify-content: center;
          align-items: center;
          width: 70%;
          background: #f6f6f6;
          border-radius: 1.5rem;
          margin: 2.25rem;
        }

        .logintype {
          color: #585858;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0.625rem 1.25rem;
          border-radius: 1.5rem;
          border: 0.6px solid #e0e0e0;
          background: #f6f6f6;
          width: 100%;
          height: 100%;
          border: none;
          cursor: pointer;
          text-align: center;
        }

        .active {
          color: #fff;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 5.5rem;
          background: #263238;
          padding: 0.625rem 1.25rem;
        }

        .image_container {
          text-align: center;
          width: 80px;
          margin: 0 auto 20px;

          img {
            width: 100%;
          }
        }

        h3 {
          font-size: 24px;
          color: #000;
          font-weight: 600;
          margin: 10px 0px 10px;
        }

        p {
          color: #3d3d3d;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .input_wrapper {
          margin: 10px 0;

          ::placeholder {
            color: #a1a1a1;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          input[type="number"] {
            width: 100%;
            height: 42px;
            border-radius: 0.375rem;
            border: 0.8px solid #cecece;
            background: #f6f6f6;
            padding: 0 10px;
            font-size: 18px;
            font-weight: 600;

            /* Remove up and down arrows */
            -moz-appearance: textfield; /* Firefox */
            appearance: textfield; /* Safari, Chrome, and Edge */
          }

          /* Hide the arrows for Webkit browsers */
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .btn_wrapper {
          margin: 20px 0;
          text-align: center;

          button {
            padding: 0.75rem 0;
            width: 100%;
            color: #fff;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-radius: 0.25rem;
            background: #b50503;
            box-shadow: -2px -2px 10px 1px rgba(181, 5, 3, 0.16),
              2px 2px 10px 1px rgba(181, 5, 3, 0.16);
          }
        }

        .not_member {
          span {
            letter-spacing: 0.26px;
            color: #000000;
            font-size: 13px;
          }

          .singup {
            letter-spacing: 0.26px;
            color: #ff933a;
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .login_step_one_wrapper {
    .login_step_one_inner_wrapper {
      .content_wrapper {
        .content_inner_wrapper {
          width: auto;
          padding: 20px 10px 20px;

          .btn_wrapper {
            button {
              height: 38px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .login_step_one_wrapper {
    .login_step_one_inner_wrapper {
      height: auto !important;

      .content_wrapper {
        width: 100%;

        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;

          .image_container {
            img {
            }
          }

          .text_content_wrapper {
            .text_content {
            }
          }

          .input_wrapper {
            input[type="number"] {
              ::placeholder {
              }
            }
          }

          .btn_wrapper {
            button {
            }
          }
        }
      }
    }
  }
}
