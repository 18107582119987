.couponlist_modal {
    .modal-content {
      width: 25.5625rem;
    }
  
    .modal-header {
      border-bottom: 0;
    }
  
    .modal-body {
      padding: 0px 30px 0px 30px !important;
      height: 26.875rem;
      overflow-y: auto;
  
      .coupon-wrapper {
        height: 70%;
        h3 {
          color: #000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.005rem;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
  
          .title {
            display: flex;
            align-items: center;
  
            img {
              width: 24px;
              height: 24px;
              margin-right: 8px;
            }
  
            span {
              color: #121212;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
  
          .view-coupons {
            color: #121212;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.12px;
            text-decoration: none;
            margin-left: 65px;
          }
        }
  
        .coupon-input {
          display: flex;
          margin-bottom: 20px;
  
          input {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px 0 0 8px;
            border: 1px solid #b1b1b1;
            color: #797979;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            padding-left: 0.6rem;
          }
  
          button {
            display: flex;
            padding: 11px 24px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            background: #263238;
            color: #fff;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            cursor: pointer;
            border-radius: 0 8px 8px 0;
          }
        }
  
        .coupon-list {
          .single-coupon-wrapper {
            //   background: lightcoral;
            display: flex;
            justify-content: space-between;
            padding: 0.94rem 0 1.39rem 0;
            border-bottom: 0.6px solid #efefef;
            background: #fff;
  
            .coupon-inner-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
  
              img {
                width: 1.25rem;
                height: 1.25rem;
                margin-right: 0.5rem;
              }
  
              .coupon-details {
                .coupon {
                  color: #121212;
                  font-family: Poppins;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.0175rem;
                }
  
                .coupon-desc {
                  color: #646464;
                  text-align: center;
                  font-family: Poppins;
                  font-size: 0.625rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
  
            .apply-btn {
              border-radius: 0.25rem;
              border: 0.8px solid #070e4c;
              background: #fff;
              button {
                color: #070e4c;
                font-family: Poppins;
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 0.25rem 0.75rem;
              }
            }
          }
        }
  
        .done-btn {
          border-radius: 0.25rem;
          background: #b50503;
          padding: 0.75rem 0;
          bottom: 1rem;
          position: sticky;
          text-align: center;
          cursor: pointer;
  
          button {
            color: #fff;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
  