.address_form_wrapper {
  width: 1114px;
  margin: 0 auto;
  border-radius: 1rem;
  background: #fff;
  margin-top: 2.5rem;

  .form_inner_wrapper {
    width: 70%;
    margin: 0 auto;

    padding-top: 3rem;
    padding-bottom: 2rem;

    .input_container input {
      margin-bottom: 1.5rem;
      padding-left: 1rem;
    }

    .form-state-city {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      .form-state {
        width: 100%;
      }

      .form-city {
        width: 100%;
      }
    }
    .form-mobile-num-alternate-num {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;

      .form-num {
        width: 100%;
      }

      .form-alt-num {
        width: 100%;
      }
    }

    .form_label {
      color: #3d3d3d;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    input,
    select {
      border-radius: 0.375rem;
      border: 1px solid #e7e7e7;
      background: #f6f6f7;
      width: 100%;
      padding: 0.75rem 0;
      padding-left: 1rem;
    }

    .form_submit_wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.25rem;
        border: 0.6px solid #b91311;
        background: #b50503;
        padding: 0.75rem 0;
        text-align: center;
        width: 50%;
      }
    }
  }
}
