.handbook_container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  .single_handbook_wrapper {
    width: calc(33.33% - 20px);
    cursor: pointer;
    border: 0.6px solid #d3d3d3;
    margin: 0 1.25rem 1.25rem 0;
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: -3px -3px 6px 0px rgba(0, 0, 0, 0.08),
      3px 3px 6px 0px rgba(0, 0, 0, 0.08);

    .handbook_banner {
      height: 170px;
      width: 198px;
      margin: 0 auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .handbook_inner_wrapper {
      padding: 1rem 1.25rem 1.25rem 1.25rem;

      .handbook_product-name {
        color: #000;
        font-family: Poppins;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.375rem; /* 146.667% */
        letter-spacing: 0.00938rem;
      }

      .handbook_type {
        color: #b80f0d;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.0075rem;
      }

      .handbook_price_and_discount {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .handbook_price {
          color: #121212;
          font-family: Poppins;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.025rem;
        }

        .handbook_discount_div {
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          background: #edffea;
          height: fit-content;
          margin-left: auto;
          margin-right: 0.75rem;

          img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.25rem;
          }
          p {
            color: #148e00;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            margin: 0;

            span {
              font-weight: 600;
            }
          }
        }
      }

      .handbook_discount_price {
        color: #5e5e5e;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration: line-through;
      }
    }
  }
  .package_button_div {
    padding: 10px;
    .details {
      color: #121212;
      text-align: center;
      font-family: "Poppins";
      font-size: 1rem;
      font-weight: 400;
      line-height: normal;
      padding: 0.75rem 0.5rem;
      border-radius: 0.25rem;
      border: 0.6px solid #121212;
      width: calc(50% - 0.5rem);
      margin-right: 1rem;
    }

    .buy {
      color: #fff;
      text-align: center;
      font-family: "Poppins";
      font-size: 1rem;
      font-weight: 600;
      line-height: normal;
      padding: 0.75rem 0.5rem;
      border-radius: 0.25rem;
      background: #b80f0d;
      width: calc(50% - 0.5rem);
    }
  }
}

@media (max-width: 768px) {
  .handbook_container .single_handbook_wrapper {
    width: calc(43.33%);
  }
  .handbook_container {
    justify-content: center;
  }
}
@media (max-width: 551px) {
  .handbook_container .single_handbook_wrapper {
    width: 84.33%;
  }
}
