.questionBankCustomPaperBox {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  background: #ffffff;
  padding: 20px 25px 15px 20px;
  margin: 0 20px 20px 0;
  width: calc(50% - 10px);
  &:nth-child(2n + 2) {
    margin-right: 0px;
  }
  .questionBankCustomPaperName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .paperName {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      // letter-spacing: 0.01em;
      color: #121212;
      margin-bottom: 7px;
    }
  }

  .questions_data_count {
    font-family: "Poppins";
    font-style: normal;
    ul {
      padding: 0px;

      list-style: none;
      display: flex;
      margin-left: 0px;
      li {
        margin-right: 7px;
        font-size: 12px;
        font-weight: 500;
        padding: 6px 12px;
        border-radius: 4px;
      }
      .qb_total {
        color: #353535;
        background-color: #EDF0F0;

        span {
          font-weight: 600;
        }
      }

      .qb_unattempt {
        color: #EAA800;
        background-color: #FCF5E6;

        span {
          font-weight: 600;
        }
      }
      .qb_wrong {
        color: #FF2C1F;
        background-color: #FEEBEA;

        span {
          font-weight: 600;
        }
      }
      .qb_correct {
        color: #00AC3B;
        background-color: #F2FAF0;

        span {
          font-weight: 600;
        }
      }
    }
  }
  .qb_topics {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .qb_topics_list {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      color: #636363;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 40ch;

      .qb_topic {
        margin-right: 15px;
      }
    }
    .qb_length {
      cursor: pointer;
      width: 28%;

      .topic_content {
        font-weight: 500;
      }
    }
  }
  .modal {
    .modal-header {
      display: flex;
      .modal-header-left {
        margin: auto;

        .modal-title p {
          font-size: 20px;
          line-height: 30px;
          color: #000000;
          background: red;
        }
      }
      .modal-header-right {
        // margin-top: -25px;
      }
    }

    .modal-body {
      .qb_topic {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 27px;
        padding: 10px;

        color: #757575;
        border-bottom: 1px solid #757575;
      }
    }

    .modal-footer {
      .done_btn {
        background: #6d48ef;
        box-shadow: 0px 4px 8px rgba(109, 72, 239, 0.16);
        border-radius: 10px;

        margin: auto;
        width: 40%;
        color: #ffffff;
        padding: 10px 10px;
      }
    }
  }

  .num_of_questions {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;

    color: #4e4e4e;
    .question_tex {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      color: #4e4e4e;
    }
  }

  .no_of_quests_startBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .created_date {
      color: #484848;
      font-size: 12px;
      font-weight: 400;

      strong {
        color: #121212;
        font-weight: 500;
      }
    }

    .no_of_questions {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      color: #4e4e4e;
      .question_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        color: #4e4e4e;
      }
    }

    .startBtn {
      // width: 28%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      // letter-spacing: 0.01em;
      cursor: pointer;
      color: #B50303;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .view_sol_btn {
        // text-decoration-line: underline;
        width: fit-content;
        // cursor: pointer;
      }
      .start_pract_btn {
        text-decoration-line: underline;
      }
      span {
        font-size: 25px;
        margin-top: 2px;
        cursor: pointer;
        // margin-right: 13px;
        margin-left: 4px;
      }
    }
  }

  .date_startBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #4e4e4e;

    .startBtn {
      width: 28%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      cursor: pointer;
      color: #6d48ef;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .view_sol_btn {
        text-decoration-line: underline;
        // cursor: pointer;
      }
      .start_pract_btn {
        text-decoration-line: underline;
        // cursor: pointer;
      }
      span {
        font-size: 25px;
        margin-top: 2px;
        cursor: pointer;
        margin-right: 13px;
      }
    }
  }
}
