.header_left_wrapper_desktop {
  width: fit-content;

  .logo_desktop {
    width: 100px;
    display: inline-block;
    margin-right: 2rem;

    img {
      width: 5.75rem;
      height: 1.76925rem;
      object-fit: cover;
    }
  }

  .left_menu_desktop {
    display: inline-block;
    vertical-align: middle; /* Added vertical alignment */

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      .dropdown_desktop {
        &:hover {
          .dropdown-menu {
            display: block;
            margin-top: 0;
          }
        }
        .menu-link-dropdown_desktop {
          text-decoration: none;

          color: #000;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;

          &:active {
            background-color: transparent;
          }
          &.current-dropdown {
          }
        }
      }
      li {
        display: inline-block;
        margin-right: 1.5rem;

        &:last-child {
          margin-right: 0;
        }

        .menu-link_desktop {
          text-decoration: none;

          vertical-align: middle;
          color: #000;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &.current {
            position: relative;
            color: #b91311;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 1.5px;
              background-color: #b91311;
              bottom: -4px;
            }
          }
        }
      }
    }
  }
}

// mobile responsive

.header_left_wrapper {
  transition: margin-left 0.3s ease-in-out;

  .logo {
    img {
      width: 5.75rem;
      height: 1.76925rem;
      object-fit: cover;
    }
  }

  // Sidebar menu
  .left_menu {
    display: block;
    position: absolute;
    top: -23px;
    left: -250px; /* Initially hidden */
    width: 250px;
    background-color: #fff;
    height: 100%;
    z-index: 10; /* Ensure the sidebar is on top */
    transition: left 0.3s ease-in-out;
    margin-left: -17px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: block;
        padding: 10px 10px 10px 36px;

        .menu-link {
          color: #000;
          text-decoration: none;
        }

        .current {
          color: #b91311;
        }
      }
    }
  }

  // Hamburger icon visibility in mobile
  .header_left_hamburg {
    display: none;
    .hamburg_icon {
      cursor: pointer;
      img {
        width: 20px;
      }
    }
  }

  @media (max-width: 893px) {
    .header_left_wrapper_desktop {
      display: none;
    }
    .header_left_wrapper .header_left_hamburg {
      display: flex;
    }

    .header_left_wrapper {
      display: block;
      transition: margin-left 0.3s ease-in-out;
      position: relative;
      .hamburg_icon {
        margin-right: 10px;
      }
    }

    .left_menu {
      display: block !important;
      left: -250px; /* Initially hidden */
    }

    .header_left_wrapper.sidebar-open .left_menu {
      left: 0; /* Sidebar visible when open */
      background-color: #fff;
      height: 100vh;
    }

    .header_left_wrapper .logo img {
      width: 94px;
      height: 26px;
      object-fit: cover;
    }

    .header_component_wrapper .header_inner_wrapper .header_left_wrapper {
      flex: 0 0 0%;
    }
    .header_left_wrapper .left_menu ul li {
      img {
        height: 14px;
        margin-right: 12px;
      }
    }
    .header_left_wrapper .header_left_wrapper .logo {
      padding: 23px !important;
    }
  }
}
