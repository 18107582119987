.card_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 980px;
    margin: 0 auto;
    
    .pyq_card {
        border-radius: 8px;
        border: 0.8px solid var(--border);
        background: var(--white);
        width: calc(50% - 8px);
        padding: 20px 25px;
        margin: 0 16px 16px 0;

        .sub_name {
            color: #000;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
            margin-bottom: 5px;
        }

        .test_details {
            color: #4D4D4D;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 15px;
        }

        .btn_container {
            display: flex;

            .syllabus_btn {
                color: #404040;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 20px;
                // border: 1px solid #BEBEBE;
                border: 1px solid var(--border);
                background: #F6F6F7;
                padding: 8px 16px;
                justify-content: center;
                align-items: center;
                width: 50%;
                margin-right: 16px;
            }

            .start_btn {
                color: #FFF;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.14px;
                border-radius: 20px;
                background: var(--red);
                padding: 8px 16px;
                justify-content: center;
                align-items: center;
                width: 50%;
            }
        }
    }
    .pyq_card:nth-child(even) {
        margin-right: 0px;
    }
}

.syllabus_modal {
    position: absolute;
    inset: 0;
    display: none;
    z-index: 10000;
    background-color: rgba(96, 96, 96, 0.78);
    overflow: auto;
    
    .modal_body {
        background: #fff;
        position: relative;
        padding: 25px 0px 50px;
        max-width: 700px;
        margin: auto;
        border-radius: 12px;
        top: calc(35% - 220px);

        .close_btn {
            position: absolute;
            top: 25px;
            right: 25px;
            opacity: 0.7;
        }

        .close_btn:hover {
            opacity: 1;
        }

        .done_btn {
            padding: 8px 65px;
            justify-content: center;
            align-items: center;
            border-radius: 36px;
            border: 1px solid #3839CE;
            box-shadow: 0px 4px 12px 0px rgba(109, 72, 239, 0.12);
            color: #3839CE;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .done_btn:hover {
            background: #3839ce;
            color: #fff;
        }

        .modal_heading {
            color: #303030;
            font-family: Poppins;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.2px;
        }

        hr {
            height: 0.5px;
            background: #E3E3E3;
            margin: 0;
        }

        .modal_inner {
            border-radius: 4px;
            border: 0.6px solid #A8A8A8;
            // margin: 40px 80px 50px;
            width: 95%;
            padding: 15px;
            margin-bottom: 40px;

            ul {
                // columns: 2;
                margin: 25px 40px 40px;

                li {
                    text-align: left;
                    color: #000;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 7px;
                }
            }

            .modal_subject {
                color: #000;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 6px 0;
            }

        }
    }
}

.syllabus_modal.open {
    display: block;
}

@media (max-width: 575.98px) {
    .card_container {
        .pyq_card{
            width: 100%;
            margin: 8px 0;
            padding: 1rem;
            .btn_container {
                gap: 0.5rem;
                justify-content: space-between;
                .syllabus_btn{
                    margin-right: 0;
                }
                button{
                    padding: 0.5rem !important;
                }
            }
        }
    }
}