.otsTestSummaryPageInrWrapper {
  .test_summaryBox {
    .rankMarksBox {
      // padding: 0px 20px;

      .rankMarksDiv {
        .rnkMrkBox {
          text-align: center;
          display: flex;
          justify-content: center;
          margin-bottom: 40px;

          .rankIcon {
            background: #f5f4f8;
            text-align: center;
            width: 220px;
            height: 220px;
            margin: 0px 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            img {
              margin-bottom: 10px;
              width: 55px;
            }

            h5 {
              margin-bottom: 10px;
              letter-spacing: 0.68px;
              font-size: 28px;
              font-weight: 600;
            }

            span {
              line-height: 18px;
              color: #707070;
              opacity: 0.8;
              font-size: 18px;
            }
          }
        }
      }

      .rankChartBox {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .AttemptAndAccuracyPercent {
          width: calc(50% - 0.625rem);
          border-radius: 0.5rem;
          border: 0.8px solid #E9E9E9;
          background: #FFF;
          padding: 1rem 1.5rem 1rem 1.5rem;

          .progress-container {
            // margin-top: 20px;
            // padding: 8px 0px;

            .accurateBar {
              // padding: 20px;
              span {
                color: #121212;
                font-family: "Poppins";
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }

              .text {
                color: #121212;
                font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: normal;
                margin: 0;
                padding: 1rem 0 0.5rem;
              }

              .accuracy {
                border-radius: 1rem;

                .progress-bar {
                  background: #3271D1;
                  border-radius: 1rem;
                }
              }

              .attempt {
                border-radius: 1rem;
                
                .progress-bar {
                  background: #32D14B;
                  border-radius: 1rem;
                }
              }
            }
          }
        }

        .expctd_AIR_Chart {
          width: calc(50% - 0.625rem);
          border-radius: 0.5rem;
          border: 0.8px solid #E9E9E9;
          background: #FFF;
          padding: 1.5rem;

          .rankCard {
            display: flex !important;
            padding: 0.75rem 1.25rem;
            border-radius: 0.25rem;
            background: #F6F6F7;
            margin-bottom: 1rem;
            width: 100%;

            .expectedRankImg {
              display: flex;
              align-items: center;

              img {
                width: 2.75rem;
              }
            }
            

            .content {
              margin-left: 1rem;

              .text {
                color: #5F5F5F;
                font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: normal;
                margin: 0;
              }
              .num {
                color: #000;
                font-family: "Poppins";
                font-size: 1.125rem;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.01125rem;
                margin: 0;
              }
            }
          }

          .rankCardFlex {
            display: flex !important;
            padding: 0.75rem 1.25rem;
            border-radius: 0.25rem;
            background: #F6F6F7;
            width: calc(50% - 0.5rem);

            .RankImg, .AIRImg {
              display: flex;
              align-items: center;

              img {
                width: 2.75rem;
              }
            }
            

            .content {
              margin-left: 1rem;

              .text {
                color: #5F5F5F;
                font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: normal;
                margin: 0;
              }
              .num {
                color: #000;
                font-family: "Poppins";
                font-size: 1.125rem;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.01125rem;
                margin: 0;
              }
            }
          }
        }
      }

      .scorePercentageMarksWrpr {
        display: flex;
        margin-bottom: 30px;

        .scorePercentageBox {
          width: calc(50% - 0.625rem);
          margin-right: 16px;
          display: flex;
          align-items: center;
          border: 1px solid #eeeeee;
          border-radius: 8px;
          background: #f5f4f8;
          margin-right: 16px;

          .scoreChart {
            width: 150px;
            margin: 40px 20px;

            .CircularProgressbar .CircularProgressbar-path {
              stroke: #fcb21d;
            }
          }

          .scoreDetail {
            p {
              font-size: 14px;
              margin-bottom: 10px;
            }

            .percentageDiv {
              font-size: 12px;
              margin-bottom: 5px;

              span {
                background-color: #e2e2e2;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                display: inline-block;
                margin-right: 8px;
              }
            }

            .gotPrcntg {
              span {
                background-color: #fcb21d;
              }
            }
          }
        }
      }

      .testTypeOption {
        display: flex;
        flex-wrap: wrap;

        .testResultOption {
          border-radius: 0.5rem;
          background: #FFF;
          padding: 1.25rem 1.5rem;
          display: flex;
          width: calc(50% - 0.625rem);
          margin: 0 1.25rem 1.25rem 0;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &:hover {
            border: 0.8px solid #121212;
          }

          &:nth-child(2n + 2) {
            margin-right: 0px;
          }

          span {
            color: #000;
            font-family: "Poppins";
            font-size: 1rem;
            font-weight: 400;
            line-height: normal;
          }

          .arrow {
            img {
              width: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .testSummaryPageWrapper {
    .testSummaryPageInrWrapper {
      margin: 0px 0px;
    }
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 575.98px) {
  .testSummaryPageWrapper {
    .testSummaryPageInrWrapper {
      padding: 10px;

      .test_summaryBox {
        .rankMarksBox {
          padding: 0px 0px;

          .testTypeOption {
            .testResultOption {
              width: 100%;
              margin: 0px 0px 15px 0px;
            }
          }

          .rankMarksDiv {
            .rnkMrkBox {
              .rankIcon {
                height: auto;
                padding: 15px 0px;
                border-radius: 10px;
              }
            }
          }

          // .marksChart {
          //   width: 50%;
          // }

          .scorePercentageMarksWrpr {
            flex-direction: column;

            .scorePercentageBox {
              width: 100%;
              margin: 0px 0px 15px 0px;
            }
          }
        }
      }
    }
  }
}
