.improvementsSuggestions {
  width: 100%;
  margin-top: 20px;
  .section-heading {
    color: #373737;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0.5rem;
  }

  .content {
    border-radius: 0.375rem;
    border: 0.8px solid #ddd;
    background: #fff;
    padding: 1.5rem 1.5rem 1.75rem 1.5rem;
    .cards {
      display: flex;
      gap: 0.58rem;
      margin-bottom: 14px;
      .better {
        border-radius: 0.25rem !important;
        border: 1px solid #5fdc91 !important;
        background: #f5fff7 !important;
      }
      .needsImprove {
        border-radius: 0.25rem !important;
        border: 1px solid #ef8f8f !important;
        background: #fff4f4 !important;
      }
      .card {
        width: 16.5%;
        padding: 20px;
        .text {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          text-align: center;

          color: #6d6d6d;
        }
        .num {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
        }
      }
    }

    .circles {
      width: 26%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .circlesContent {
        display: flex;
        align-items: center;
      }
      .circle {
        border-radius: 50%;
        padding: 6px;
        margin-right: 5px;
      }

      .cr1 {
        background: #d1ffde;
        border: 0.6px solid #16d54b;
      }
      .cr2 {
        background: #ffe1e1;
        border: 0.6px solid #ff9b9b;
      }
      .text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #000000;
      }
    }

    .topics-to-focus-on {
      margin-bottom: -15px;
      .section-heading {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        color: #000000;
        margin-bottom: 7px;
      }

      .container {
        .row {
          .column {
            .subject {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #626262;
              margin-left: -10px;
            }

            ul {
              padding: 5px 9px 0px 9px;

              li {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                color: #414141;
              }
            }
          }
        }
      }
    }
  }
}
