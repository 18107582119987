.exam_test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 0.25rem;
              border: 0.6px solid #b91311;
              background: #b91311;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;

          .left_side_content_wrapper {
            width: 25%;
            background-color: #ffffff;
            background-color: #ffffff;
            // padding: 0.5rem;
            position: sticky;
            top: 0;
            height: 100vh;
            overflow: hidden;

            .sub_section_1_wrapper {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .closebtn {
                display: none;
              }
              .testProgressIndicator {
                background: #fff;
                padding: 0.2rem 0.5rem;
                border-radius: 8px;
                margin-bottom: 0.5rem;
                border: 0.8px solid #c9c9c9;
              }
              .submitBtn {
                background: #6d48ef;
                border-radius: 4px;
                padding: 0.3rem 0.6rem;
                text-decoration: none;
                font-weight: 500;
                font-size: 1rem;
                width: 100%;
                color: #ffffff;
              }

              .text_content_wrapper {
                margin-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem 0.5rem;
                border-bottom: 1px solid #d1d1d1;

                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #0f0404;
                }

                button {
                  border-radius: 4px;
                  padding: 0.3rem 0.6rem;
                  text-decoration: none;
                  font-weight: 500;
                  font-size: 0.8rem;
                  border-radius: 0.25rem;
                  border: 1px solid #646464;
                  background: #fff;
                  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
                }

                .countdown_timer_container {
                  min-width: 55px;
                  background: #f5f4f8;
                  text-align: end;
                  border: 0.8px solid #e9e3ff;
                  border-radius: 4px;
                  padding: 3px 6px;

                  span {
                    label {
                      color: #6d48ef;
                      font-size: 15px;
                    }
                  }
                }
              }

              .questions_serial_number_collection {
                font-family: "Poppins";
                overflow-y: scroll;
                scrollbar-width: thin;
                overflow-y: auto;
                max-height: 70vh;
                padding: 0.5rem;
                margin-bottom: 0.5rem;
                background: #f6f6f7;
                .single_question_number_wrapper1 {
                  display: block !important;
                  margin: 0.875rem 5px !important;
                  .unanswered_lbl1 {
                    height: 80px;
                    overflow: hidden;
                    display: flex !important;
                    gap: 0.5rem;
                    align-items: center !important;
                    justify-content: space-between !important;
                    .questionBox1 {
                      display: flex !important;
                      p {
                        overflow: visible;
                        display: block !important;
                      }
                      .QBox {
                        display: inline-block;
                      }
                    }
                  }
                }
                .single_question_number_wrapper {
                  margin: 5px;
                  display: inline-block;

                  .arrow {
                    width: 8px;
                  }

                  .unanswered_lbl {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid #d9d9d9;
                    background: #fff;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 40px;

                    .questionBox {
                      display: block;
                      align-items: baseline;
                      overflow-x: hidden;

                      .QBox {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* number of lines to show */
                        -webkit-box-orient: vertical;

                        p {
                          margin-left: 2px;
                          margin-bottom: 0px;

                          math {
                            display: inline-block;
                          }

                          img {
                            display: none;
                          }

                          table {
                            width: 100% !important;
                          }
                        }
                      }
                    }
                  }

                  .answered_lbl {
                    border: 0.6px solid #8ae09f;
                    background: #c8f1d2 !important;
                  }

                  .review_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    padding: 10px;
                    border-radius: 8px;
                    background: #ffc4c4;
                    border: 1px solid #ffc4c4;
                    cursor: pointer;
                  }

                  .skip_lbl {
                    border: 1px solid #ffa68c;

                    background: #ffc5b4;
                  }

                  .current_lbl {
                    // border: 1px solid #ff933a;
                    border: 1px solid #ffa68c;
                    cursor: pointer;
                    background: #fff;
                  }

                  .isGussedAnswer {
                    border: 1px solid #b184ff;

                    background: #dbc9fb !important;
                  }
                }
              }
            }

            .sub_section_2_wrapper {
              margin-bottom: 0.5rem;

              padding: 0.5rem;

              .question_marking_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding: 5px;
                .single_marking {
                  display: flex;
                  align-items: center;
                  flex: 1 1 50%;
                  margin-bottom: 5px;

                  &:last-child {
                    margin-right: 0px;
                  }

                  .box_pic {
                    width: 14px;
                    height: 14px;
                    border-radius: 3px;
                    margin-right: 5px;
                  }

                  .box_pick_background_1 {
                    border: 1px solid #8ae09f;
                    background: #c8f1d2;
                  }

                  .box_pick_background_2 {
                    background-color: #f99746;
                  }

                  .box_pick_background_3 {
                    border: 1px solid #d9d9d9;
                    background: #fff;
                  }

                  .box_pick_background_4 {
                    border: 1px solid #ffa68c;
                    background: #ffc5b4;
                  }

                  .box_pick_background_5 {
                    border: 1px solid #b184ff;
                    background: #dbc9fb;
                  }

                  .question_marking_text {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    color: #000000;
                  }
                }
              }
            }
          }

          .right_side_content_wrapper {
            background: blue;
            width: 75%;
            border-radius: 0.5rem;
            padding: 1rem;
            background-color: #ffffff;
            box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06);
            z-index: 10;
            .right_btn_wrapper {
              display: none;
            }

            .sub_section_wrapper {
              padding: 0rem 1rem 0rem 1rem;
              margin: 0 auto;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              min-width: 100%;

              .sub_sub_section_wrapper_1 {
                .sub_sub_section_wrapper_1_card {
                  background-color: #263238;
                  padding: 0.5rem 1rem;
                  border-radius: 8px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  color: var(--white);
                  margin-bottom: 0.5rem;
                  .countdown_timer_container {
                    color: #f7b201;
                    font-weight: 600;
                  }
                  p {
                    margin-bottom: 0;
                    letter-spacing: 0.01rem;
                  }
                  .markReview {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // gap: 0.5rem;
                    .m2_language {
                      color: var(--white);
                      font-weight: 700;
                      width: 17px;
                      height: 40px;
                      margin-left: 40px;
                      margin-top: 2px;
                    }
                    .BookmarkBtn {
                      cursor: pointer;
                      width: 14px;
                      height: 18.2px;
                    }
                  }
                }
                h2 {
                  text-align: center;
                  font-size: 1.5rem;
                  font-weight: bold;
                  margin-bottom: 0.6rem;
                }

                .right_side_content_wrapper {
                  flex: 0 0 100%;
                  display: flex;
                  justify-content: flex-end;

                  .review_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }

                  .bookmark_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }
                }
              }

              .testProgressIndicator {
                background: #fff;
                padding: 5px 10px;
                border-radius: 8px;
                margin-bottom: 20px;

                h4 {
                  font-size: 14px;
                  color: #000;
                  margin-bottom: 16px;
                }

                .ProgressBar_Line {
                  position: relative;
                }

                .ProgressBar_Line span {
                  position: absolute;
                  top: -16px;
                  font-size: 14px;
                }

                .progress {
                  height: 8px;
                  margin-bottom: 5px;

                  .progress-bar {
                    background: #ff933a;
                    border-radius: 9px;
                  }
                }

                .questionPercentage {
                  display: flex;
                  justify-content: space-between;

                  span {
                    color: #707070;
                    font-size: 14px;
                  }
                }
              }

              .sectionWiseTestWrapper {
                .subjectWiseTest_heading {
                  color: #121212;
                  font-size: 0.875rem;
                  font-weight: 500;
                  margin: 0 !important;
                  span {
                    font-weight: 400;
                    color: #5e5e5e;
                  }
                }
                .subjectWiseTest {
                  border-radius: 0.5rem;
                  border: 0.6px solid #cecece;
                  background: #f6f6f7;
                  max-width: -moz-fit-content;
                  max-width: fit-content;
                  display: flex;
                  padding: 4px;
                  justify-content: space-between;
                  align-items: center;
                  // margin: 0px auto;

                  .single_select_subject {
                    input[type="radio"] {
                      opacity: 0;
                      position: fixed;
                      width: 0;

                      &:checked + label {
                        background-color: #263238;
                        border: none;
                        color: #ffffff;
                        border-radius: 0.25rem;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 23px;
                      }
                    }

                    label {
                      padding: 3px;
                      font-size: 15px;
                      letter-spacing: 0.2px;
                      color: #6b6876;
                      cursor: pointer;
                      min-width: 150px;
                      text-align: center;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                    }
                  }
                }

                .sectionWiseTest {
                  display: flex;
                  width: max-content;
                  // margin: 0 auto;
                  margin-bottom: 0.4rem;
                  margin-top: 0.4rem;
                }
              }
              .questionWrapper {
                // background: #f5f4f8;
                // border: 1px solid #e6deff;
                // border-radius: 4px;
                // padding: 10px;
                margin: 1rem 0;
                width: 100%;
                height: 50vh;
                overflow-y: auto;
                overflow-x: hidden;
                scrollbar-width: thin;

                .sub_sub_section_wrapper_2 {
                  h2 {
                    text-align: center;
                    font-size: 25px;
                    font-weight: bold;
                    margin-bottom: 20px;
                  }

                  .text_content_wrapper {
                    .typeLanguageWrapper {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 5px;

                      .stopwatch_timer {
                        font-family: "Poppins" !important;
                        color: #6d48ef;
                        font-size: 15px;
                        justify-content: center;
                      }

                      span {
                        color: #707070;
                        font-size: 12px;
                      }

                      .markReview {
                        display: flex;

                        .bookmark_image_wrapper {
                          margin: 0px 10px;

                          span {
                            margin-right: 5px;
                          }

                          img {
                            cursor: pointer;
                          }
                        }

                        button {
                        }
                      }
                    }

                    .questionBox {
                      display: block;
                      align-items: baseline;
                      margin-bottom: 20px;

                      span {
                        margin-right: 5px !important;
                      }

                      .text_content {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                        color: #000;

                        p {
                          margin-bottom: 0px;
                        }
                      }

                      .hide {
                        display: none;
                      }

                      .show {
                        display: block;
                      }
                    }
                  }
                }

                .sub_sub_section_wrapper_3 {
                  //single-select
                  .single_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-bottom: 30px;

                    .single_select {
                      width: calc(50% - 8px);
                      margin: 10px 16px 10px 0px;

                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }

                      .classCorrect {
                        border: 1px solid #5ddd9b;
                        background: #e1f1ec !important;
                      }

                      .classWrong {
                        border: 1px solid #f93030;
                        background: #f6e0e4 !important;
                      }

                      input[type="checkbox"] {
                        opacity: 1;
                        position: fixed;
                        width: 0;
                      }

                      .single_option_wrapper {
                        min-height: 50px;
                        background-color: #fff;
                        border-radius: 0.25rem;
                        border: 0.6px solid #939393;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;

                        .option_initial {
                          margin-right: 10px;

                          .text_content_2 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #121212;
                            text-transform: lowercase;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #000;

                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      input[type="checkbox"]:checked + .single_option_wrapper {
                        border-radius: 0.25rem;
                        border: 1px solid #fbab35;
                        background: #fff9ef;
                        .option_initial {
                          .text_content_2 {
                            font-weight: 600;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            font-weight: 600;
                          }
                        }
                      }
                    }
                  }

                  .hide {
                    display: none !important;
                  }

                  .show {
                    display: flex !important;
                  }

                  .show2 {
                    display: block !important;
                  }

                  //multi-select
                  .multi_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    .single_select {
                      width: calc(50% - 8px);
                      margin: 10px 16px 10px 0px;

                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }

                      input[type="checkbox"] {
                        opacity: 1;
                        position: fixed;
                        width: 0;
                      }

                      .single_option_wrapper {
                        min-height: 50px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
                        border-radius: 15px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;

                        .option_initial {
                          margin-right: 10px;

                          .text_content_2 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }
                      }

                      input[type="checkbox"]:checked + .single_option_wrapper {
                        border: 2px solid #6d48ef;

                        .option_initial {
                          .text_content_2 {
                            font-weight: 600;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            font-weight: 600;
                          }
                        }
                      }
                    }
                  }

                  //integer
                  .input_wrapper {
                    .input_inner_wrapper {
                      margin-bottom: 30px;

                      input {
                        border: none;
                        outline: none;
                        width: 100%;
                        border: solid 1px #f0eef8;
                        background-color: #fff;
                        height: 50px;
                        border-radius: 8px;
                        padding: 0 5px;
                        font-size: 16px;
                        font-weight: 500;
                      }

                      input[type="number"]:focus {
                        border: 2px solid #6d48ef;
                      }

                      input[type="number"]::-webkit-outer-spin-button,
                      input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_4 {
                margin-bottom: 15px;

                .checkbox_wrapper {
                  input[type="checkbox"] {
                    margin-right: 10px;
                  }

                  label {
                    font-size: 13px;
                    vertical-align: text-top;
                    color: #09041a;
                  }
                }
              }

              .sub_sub_section_wrapper_5 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0.5rem auto 0;
                width: 100%;
                padding-top: 0.5rem;
                border-top: 1px solid #ddd;

                .left_side_wrapper {
                  // width: calc(50% - 8px);
                  width: auto;
                  margin-right: 16px;

                  .btn_wrapper {
                    text-align: left;

                    button {
                      border: none;
                      outline: none;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: var(--red);
                      padding: 10px;
                      width: auto;
                      border-radius: 0.25rem;
                      border: 1px solid var(--red);
                      // text-decoration: underline;
                    }
                  }
                }

                .right_side_wrapper {
                  // width: calc(50% - 8px);
                  width: auto;

                  .btn_wrapper {
                    text-align: right;
                    .markBtn {
                      background-color: #e9e9e9 !important;
                    }
                    button {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      padding: 10px;
                      width: auto;

                      &:first-child {
                        border-radius: 0.25rem;
                        border: 1px solid #e9e9e9;
                        margin-right: 0.5rem;
                        color: #333333;
                        background-color: #fff;
                      }
                      &:last-child {
                        border-radius: 0.25rem;
                        color: #ffffff;
                        border: 0.6px solid #b91311 !important;
                        background: #b91311 !important;
                      }
                      // text-decoration: underline;
                    }
                  }
                }
              }

              .QuestionSolution {
                padding-top: 20px;

                h5 {
                }

                div {
                  p {
                    margin-bottom: 2px;
                  }
                }
              }

              .sub_sub_section_wrapper_6 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.onSubmitModal {
  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    margin-top: 0px !important;
    .submit_Popup {
      .exit_img {
        width: 100%;

        img {
          width: 35%;
          height: auto;
          margin-bottom: 20px;
          margin-top: -25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #b50503 !important;
          border-radius: 0.25rem;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #b50503 !important;
        }

        .cancel {
          border: 1px solid #000 !important;
          color: #000 !important;
          background: #fff !important;
          margin-right: 10px !important;
        }
      }
    }
  }
}

///////////////////////////   FOR ERROR MODAL ///////////////////////////

.errorModal {
  margin-top: 70px;

  .modal-content {
    border-radius: 16px;
  }
  text-align: center;

  .modal-header {
    margin-top: 20px;
    font-weight: 600;
    font-family: "Poppins";
    font-style: normal;

    border-bottom: 0 none;
    padding: 0;

    .btn-close {
      width: 0;
      height: 0;
      margin-right: 20px;
    }

    h3 {
      color: #000000;
      font-weight: 600;
      margin-left: 150px;
      font-size: 20px;
    }
  }

  .modal-body {
    padding: 0;

    .error_Popup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      height: 85%;
      width: 85%;
      margin: auto;
    }

    .error_Popup .text_content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;

      letter-spacing: 0.02em;
      color: #606060;
    }

    .errorList {
      text-align: start;
      padding: 0;
      margin: 0;
    }

    .errorList .errorListItem {
      list-style: none;
      text-align: start;
      margin-bottom: 10px;

      font-size: 16px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: #000000;
    }

    .errorList .errorListItem input {
      font-size: 25px;
    }

    .errorList .errorListItem input[type="radio"] {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 8px;
    }

    textarea::-webkit-input-placeholder {
      padding: 0;
      font-size: 14px;

      color: #b8b8b8;
    }

    button {
      padding: 10px 40px;
      font-weight: 500;
      background: #6d48ef;
      border-radius: 4px;
      color: #fff;
    }

    textarea {
      width: 100%;
      height: 85px;
      padding: 10px;

      border-radius: 4px;
      resize: none;
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
  .Pyq_ppr_test_questions_view_wrapper_PYQ_Test {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            flex-direction: column;

            .left_side_content_wrapper {
              .sub_section_1_wrapper {
                .questions_serial_number_collection {
                  // height: 17vh;
                }
              }
            }

            .right_side_content_wrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-height: 640px) {
  .Pyq_ppr_test_questions_view_wrapper_PYQ_Test
    .test_questions_view_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .section_content_wrapper
    .right_side_content_wrapper
    .sub_section_wrapper
    .questionWrapper {
    height: 45vh;
  }
}
@media (max-width: 575.98px) {
  .Pyq_ppr_test_questions_view_wrapper_PYQ_Test {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            .section_content_wrapper_backdrop {
              height: 100vh;
              width: 100vw;
              z-index: 1995;
              background-color: #000;
              opacity: 0.5;
              position: absolute;
              display: block;
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }

            .left_side_content_wrapperToggle {
              display: block !important;
              animation: fadeIn 200ms ease-in;
            }
            .left_side_content_wrapper {
              position: absolute !important;
              display: none;
              z-index: 1999;
              padding: 10px;
              width: 80% !important;
              height: 100vh !important;
              .sub_section_1_wrapper {
                position: relative;
                .testProgressIndicator {
                  margin-top: 2rem;
                }

                .text_content_wrapper {
                  button {
                    display: none;
                  }

                  .countdown_timer_container {
                    display: none;
                  }
                }

                .closebtn {
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  right: 0;
                }

                .questions_serial_number_collection {
                  height: 45vh !important;
                }
              }
            }

            .right_side_content_wrapper {
              width: 100%;
              padding: 0 !important;
              .right_btn_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: sticky;
                top: 0;
                z-index: 1000;
                background: inherit;
                padding: 7px 10px;
                .togglebtn {
                  border: none;
                  outline: none;
                  background-color: #ffffff;
                  font-size: 14px;
                  font-weight: 600;
                  text-align: center;
                  color: #fff;
                  padding: 0.3rem 0.6rem;
                  text-decoration: none;
                  font-weight: 500;
                  font-size: 0.8rem;
                  border-radius: 0.25rem;
                  background: #b91311;

                  &.submit {
                    border: 1px solid #646464;
                    background: #fff;
                    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
                    color: #000;
                  }
                }
                .countdown_timer_container {
                  min-width: 55px;

                  span {
                    label {
                      color: #6d48ef;
                      font-size: 15px;
                    }
                  }
                }
              }

              .sub_section_wrapper {
                padding: 0 10px 10px;
                justify-content: flex-start;
                .sub_sub_section_wrapper_1 {
                  h2 {
                    font-size: 1rem;
                    margin-bottom: 0.4rem;
                  }
                }
                .sectionWiseTestWrapper {
                  .subjectWiseTest {
                    padding: 4px 8px;
                    margin-bottom: 0.5rem;
                    justify-content: space-between;
                    // border-radius: 36px;
                    overflow: visible;

                    .single_select_subject {
                      &:nth-child(4),
                      &:nth-child(5) {
                        flex: 0 0 50%;

                        label {
                          width: 100%;
                        }
                      }

                      input[type="radio"] {
                        &:checked + label {
                          // border-radius: 36px;
                          line-height: 1;
                        }
                      }

                      label {
                        padding: 5px 8px;
                        font-size: 14px;
                        min-width: auto;
                        border-radius: 0;
                      }
                    }
                  }
                }

                .questionWrapper {
                  height: 48vh;
                  .sub_sub_section_wrapper_2 {
                    h2 {
                      font-size: 22px;
                      margin-bottom: 15px;
                      display: none;
                    }

                    .text_content_wrapper {
                      .typeLanguageWrapper {
                        flex-direction: column;

                        span {
                          margin-bottom: 10px;
                        }

                        .markReview {
                          width: 100%;
                          justify-content: space-between;
                          margin-bottom: 10px;
                        }
                      }

                      .questionBox {
                        .qst {
                          p {
                            line-height: 21px;
                          }
                        }
                      }
                    }
                  }

                  .sub_sub_section_wrapper_3 {
                    .single_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }

                    .multi_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }
                  }
                }

                .sub_sub_section_wrapper_5 {
                  .left_side_wrapper {
                    .btn_wrapper {
                      button {
                        font-size: 14px;
                        padding: 5px;
                      }
                    }
                  }
                  .right_side_wrapper {
                    .btn_wrapper {
                      button {
                        font-size: 14px;
                        padding: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .onSubmitModal {
    .modal-body {
      .submit_Popup {
        .submitCancelBtn {
          button {
          }

          .cancel {
            margin-right: 0 !important;
            margin-bottom: 10px !important;
          }
        }
      }
    }
  }
}

// .onSubmitModal {
//   .modal-header {
//     padding: 10px;
//     border: 0px;
//   }

//   .modal-body {
//     margin-top: 0px !important;

//     .submit_Popup {
//       .exit_img {
//         width: 100%;

//         img {
//           width: 35%;
//           height: 130px;
//           margin-bottom: 20px;
//           margin-top: -25px;
//         }
//       }

//       h5 {
//         font-size: 18px;
//         font-weight: 600;
//       }

//       p {
//         margin: 0 auto;
//         max-width: 310px;
//         margin-bottom: 20px;
//       }

//       .submitCancelBtn {
//         button {
//           border: 1px solid #6d48ef;
//           border-radius: 6px;
//           height: 40px;
//           width: 150px;
//           color: #fff;
//           background: #6d48ef;
//         }

//         .cancel {
//           color: #6d48ef;
//           background: none;
//           margin-right: 10px;
//         }
//       }
//     }
//   }
// }

.onSubmitModal {
  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    margin-top: 0px !important;
    .submit_Popup {
      .exit_img {
        width: 100%;

        img {
          width: 35%;
          height: auto;
          margin-bottom: 20px;
          margin-top: -25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #b50503 !important;
          border-radius: 0.25rem;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #b50503 !important;
        }

        .cancel {
          border: 1px solid #000 !important;
          color: #000 !important;
          background: #fff !important;
          margin-right: 10px !important;
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

@media (max-width: 991.98px) {
  .exam_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            // flex-direction: column;

            .left_side_content_wrapper {
              width: 70%;

              .sub_section_1_wrapper {
                .questions_serial_number_collection {
                  // height: 17vh;
                }
              }
            }

            .right_side_content_wrapper {
              width: 100%;

              .sub_section_wrapper {
                .sectionWiseTestWrapper {
                  .sectionWiseTest {
                    width: auto;
                    flex-wrap: wrap;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .exam_test_questions_view_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            flex-direction: row;

            .section_content_wrapper_backdrop {
              height: 100vh;
              width: 100vw;
              z-index: 1995;
              background-color: #000;
              opacity: 0.5;
              position: absolute;
              display: block;
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }
            .left_side_content_wrapper {
              position: absolute;
              display: none;
              z-index: 1999;
              padding: 10px;
              width: 80%;

              .sub_section_1_wrapper {
                position: relative;
                .testProgressIndicator {
                  margin-top: 2rem;
                }

                .text_content_wrapper {
                  button {
                    display: none;
                  }

                  .countdown_timer_container {
                    display: none;
                  }
                }

                .closebtn {
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  right: 0;
                }

                .questions_serial_number_collection {
                  height: 45vh;
                }
              }
            }
            .left_side_content_wrapperToggle {
              display: block !important;
              animation: fadeIn 200ms ease-in;
            }

            .right_side_content_wrapper {
              padding: 0 !important;

              .right_btn_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: sticky;
                top: 0;
                z-index: 1000;
                background: inherit;
                padding: 7px 10px;

                .togglebtn {
                  border: none;
                  outline: none;
                  border-radius: 8px;
                  background-color: #ffffff;
                  font-size: 14px;
                  font-weight: 600;
                  text-align: center;
                  color: #fff;
                  padding: 10px;
                  background: #6d48ef;

                  &.submit {
                    background: coral;
                  }
                }

                .countdown_timer_container {
                  min-width: 55px;

                  span {
                    label {
                      color: #6d48ef;
                      font-size: 15px;
                    }
                  }
                }
              }

              .sub_section_wrapper {
                padding: 0 10px 10px !important;
                justify-content: flex-start;
                .sub_sub_section_wrapper_1 {
                  h2 {
                    font-size: 1rem;
                    margin-bottom: 0.4rem;
                  }
                }

                .sectionWiseTestWrapper {
                  .subjectWiseTest {
                    padding: 0;
                    margin-bottom: 0.5rem;
                    justify-content: space-between;
                    border-radius: 36px;
                    overflow: hidden;

                    .single_select_subject {
                      &:nth-child(4),
                      &:nth-child(5) {
                        flex: 0 0 50%;

                        label {
                          width: 100%;
                        }
                      }

                      input[type="radio"] {
                        &:checked + label {
                          border-radius: 36px;
                          line-height: 1;
                        }
                      }

                      label {
                        padding: 6px 18px;
                        font-size: 14px;
                        min-width: auto;
                        border-radius: 0;
                      }
                    }
                  }
                }

                .questionWrapper {
                  height: 55vh;
                  .sub_sub_section_wrapper_2 {
                    h2 {
                      font-size: 22px;
                      margin-bottom: 15px;
                      display: none;
                    }

                    .text_content_wrapper {
                      .typeLanguageWrapper {
                        flex-direction: column;

                        span {
                          margin-bottom: 10px;
                        }

                        .markReview {
                          width: 100%;
                          justify-content: space-between;
                          margin-bottom: 10px;
                        }
                      }

                      .questionBox {
                        .qst {
                          p {
                            line-height: 21px;
                          }
                        }
                      }
                    }
                  }

                  .sub_sub_section_wrapper_3 {
                    .single_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }

                    .multi_select_option_collection_wrapper {
                      .single_select {
                        width: 100%;
                        margin: 0px 0px 10px 0px;
                      }
                    }
                  }
                }

                .sub_sub_section_wrapper_5 {
                  .left_side_wrapper {
                    .btn_wrapper {
                      button {
                        font-size: 15px;
                        padding: 8px;
                      }
                    }
                  }
                  .right_side_wrapper {
                    .btn_wrapper {
                      button {
                        font-size: 15px;
                        padding: 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}