.contactUsContentComponent_wrapper {
  padding-top: 40px;

  .contactUsContentComponent_inner_wrapper {
    max-width: 41%;
    margin: 0 auto;

    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .heading_wrapper {
          .heading_text_content {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 30px;
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        form {
          .input_wrapper {
            margin-bottom: 20px;
            input[type="text"],
            input[type="email"] {
              width: 100%;
              height: 45px;
              border-radius: 6px;
              border: none;
              background: #ffffff;
              padding: 0px 10px;
              font-family: "Poppins", sans-serif;
              font-size: 14px;
              color: #333;
              border: 1px solid #ccc;
              transition: border-color 0.2s ease-in-out;

              &::placeholder {
                color: #7e7e7e;
                font-weight: 400;
              }

              &:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
              }
            }
          }

          .description_wrapper {
            margin-bottom: 30px;
            textarea {
              width: 100%;
              border-radius: 10px;
              border: none;
              background: #ffffff;
              padding: 10px;
              font-family: "Poppins", sans-serif;
              font-size: 14px;
              color: #333;
              border: 1px solid #ccc;
              transition: border-color 0.2s ease-in-out;

              &::placeholder {
                color: #7e7e7e;
                font-weight: 400;
              }

              &:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
              }
            }
          }

          .btn_wrapper {
            margin-top: 20px;
            text-align: center;
            button {
              width: 100%;
              padding: 12px 16px;
              background-color: #b50503;
              color: #fff;
              border: 0.6px solid #b91311;
              border-radius: 4px;
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: normal;
              cursor: pointer;
              transition: background-color 0.2s ease-in-out;

              &:hover {
                background-color: #a20000;
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}
