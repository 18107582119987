////////No need to subtract scrollbar width in ipad,and mobile screens
.parent-header_component_wrapper {
  width: 100%;
  position: fixed;
  z-index: 999;
  background-color: #fff;
  border-bottom: 0.75px solid #EBEBEB;;
  // padding: 10px 0px;
  padding: 7px 0px;

  .header_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
    .header_left_wrapper {
      flex: 0 0 58%;
    }
    .header_right_wrapper {
      flex: 0 0 42%;
    }
  }
}
