.modal-full-screen {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.modal-full-screen .modal-content {
  height: 100%;
  width: 98.7vw !important;

  img {
    width: 100%;
    height: 37.5rem;
    overflow: hidden;
  }
}
