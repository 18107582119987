.package_offering_component_wrapper {
  min-height: 100vh;
  margin: 10px 0;

  .store_banner {
    margin-top: 1.5rem;

    img {
      width: 100%;
    }
  }

  .package_filter_div {
    border-bottom: 0.6px solid #e7e7e7;
    margin: 2.5rem 0 1.5rem;

    button {
      color: #646666;
      font-family: "Poppins";
      font-size: 0.875rem;
      font-weight: 400;
      line-height: normal;
      padding: 0.75rem 1.125rem;
      margin-right: 1.25rem;
    }

    .active {
      border-bottom: 2px solid #263238;
      color: #263238;
      font-weight: 600;
    }
  }

  .package_offering_component_inner_wrapper {
    display: flex;
    flex-wrap: wrap;

    .package_outer {
      border-radius: 1rem;
      border: 0.6px solid #d3d3d3;
      background: #fff;
      width: calc(33.33% - 0.85rem);
      margin: 0 1.25rem 1.25rem 0;
      padding: 0.75rem 0.75rem 1.19rem 0.75rem;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:hover {
        border: 0.8px solid #707070;
        box-shadow: -2px -2px 6px 0px rgba(0, 0, 0, 0.12),
          2px 2px 6px 0px rgba(0, 0, 0, 0.12);
      }

      .package_name_banner {
        position: relative;
        margin-bottom: 1rem;

        img {
          width: 100%;
        }

        p {
          font-size: 0.83894rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.00838rem;
          border-radius: 0.13981rem;
          padding: 0.27963rem 0.55931rem;
          display: inline-block;
          position: absolute;
          top: 1.3rem;
          left: 8.5rem;
        }

        h1 {
          color: #fff;
          font-size: 1.375rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.01375rem;
          position: absolute;
          top: 3.3rem;
          left: 8.5rem;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* Limiting to two lines */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .package_description {
        color: #505050;
        font-family: "Poppins";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Limiting to two lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 1rem;
        height: 2.75rem;
      }

      .package_flex {
        margin-bottom: 1.56rem;
        min-height: 3.21rem;

        .duration_div {
          img {
            width: 1.25rem;
            margin-right: 0.5rem;
          }

          p {
            color: #121212;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            margin: 0;
          }
        }

        .discount_div {
          padding: 0.25rem 0.5rem;
          border-radius: 0.25rem;
          background: #edffea;
          height: fit-content;
          margin-left: auto;
          margin-right: 0.75rem;

          img {
            width: 1rem;
            height: 1rem;
            margin-right: 0.25rem;
          }
          p {
            color: #148e00;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            margin: 0;

            span {
              font-weight: 600;
            }
          }
        }

        .price_div {
          p {
            margin: 0;
          }

          .initial_amount {
            color: #4b4b4b;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
          }

          .final_amount {
            color: #121212;
            font-family: "Poppins";
            font-size: 1.25rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0125rem;
          }
        }
      }

      .package_button_div {
        .details {
          color: #121212;
          text-align: center;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 400;
          line-height: normal;
          padding: 0.75rem 0.5rem;
          border-radius: 0.25rem;
          border: 0.6px solid #121212;
          width: calc(50% - 0.5rem);
          margin-right: 1rem;
        }

        .buy {
          color: #fff;
          text-align: center;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 600;
          line-height: normal;
          padding: 0.75rem 0.5rem;
          border-radius: 0.25rem;
          background: #b80f0d;
          width: calc(50% - 0.5rem);
        }
      }
    }

    .sec_2_wrapper {
      margin: 0px 0px 30px 0px;
      .text_content_wrapper {
        padding: 10px 0;
        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }
      .package_collection_wrapper {
        .package_collection {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .single_package_wrapper {
            // flex: 0 0 32.5%;
            width: 32%;

            margin-right: 13px;
            margin-bottom: 13px;
            cursor: pointer;

            &:nth-child(3n + 3) {
              margin-right: 13px;
            }
            .single_package_inner_wrapper {
              border: 1px solid #ebebeb;
              border-radius: 10px;
              background-color: #f5f4f8;
              padding: 10px;
              display: flex;
              justify-content: space-between;
              .sec_1_wrapper {
                width: calc(100% - 148px);
                margin: 0px;
                .image_wrapper {
                  text-align: right;
                  img {
                    width: 20px;
                  }
                }
                .text_content_wrapper_1 {
                  .text_content_1 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 500;
                    color: #000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    // white-space: nowrap;
                    height: 50px;
                    margin-bottom: 5px;
                  }
                }

                .text_content_wrapper_3 {
                  margin-bottom: 18px;

                  .text_content_3 {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 500;
                    color: #707070;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 250px;
                  }
                }

                .btn_wrapper_1 {
                  span {
                    display: inline-block;
                    font-size: 20px;
                    font-weight: 600;
                  }
                }
              }

              .sec_2_wrapper {
                margin: 0;
                flex-direction: column;
                justify-content: space-between;
                display: flex;

                .content_wrapper_1 {
                  .text_content_wrapper {
                    .text_content {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      color: #000000;
                      text-align: center;
                    }
                  }
                }

                .content_wrapper_2 {
                  .btn_wrapper_1 {
                    text-align: center;

                    button {
                      border: none;
                      outline: none;
                      background-color: #6d48ef;
                      font-size: 18px;
                      font-weight: 600;
                      color: #ffffff;
                      border-radius: 25px;
                      padding: 10px 15px;
                      width: 80%;
                    }
                  }

                  .text_content_wrapper_1 {
                    margin: 10px 0;

                    .text_content_1 {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #000000;
                    }
                  }

                  .text_content_wrapper_2 {
                    margin: 10px 0;
                    .text_content_2 {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #a02b2d;
                    }
                  }

                  .btn_wrapper_2 {
                    text-align: right;

                    button {
                      border: none;
                      outline: none;
                      background-color: transparent;
                      font-size: 15px;
                      font-weight: 500;
                      text-decoration: underline;
                      color: #000;
                    }

                    .course_type {
                      background: #ff933a;
                      padding: 2px 10px;
                      border-radius: 4px;
                      color: #fff;
                      font-size: 14px;
                    }
                  }
                }

                .purchase_btn {
                  width: 100%;
                  button {
                    background-color: #31d680;
                    padding: 5px 10px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 14px;
                    border-radius: 4px;
                  }

                  .expiry_date {
                    color: #707070;
                    font-size: 10px;
                    padding: 7px 0px;
                    display: inline-block;
                    width: 110%;
                    width: 145px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .free_course_package {
      .text_content_wrapper {
        margin: 10px 0px;

        .text_content {
          margin: 0;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }

      .package_collection {
        display: flex;
        flex-wrap: wrap;

        .single_package_wrapper {
          flex: 0 0 32.5%;
          margin-right: 13px;
          margin-bottom: 13px;
          width: 32.5%;

          &:nth-child(3n + 3) {
            margin-right: 0px;
          }

          .single_package_inner_wrapper {
            border: 1px solid #ebebeb;
            border-radius: 10px;
            background-color: #f5f4f8;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            min-height: 120px;

            .sec_1_wrapper {
              width: calc(100% - 108px);
              margin: 0px;

              .image_wrapper {
                text-align: right;

                img {
                  width: 20px;
                }
              }

              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 500;
                  color: #000;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  height: 50px;
                  margin-bottom: 5px;
                }
              }
              .text_content_wrapper_3 {
                margin-bottom: 18px;
                .text_content_3 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 500;
                  color: #707070;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .btn_wrapper_1 {
                span {
                  display: inline-block;
                  font-size: 20px;
                  font-weight: 600;
                }
              }
            }

            .sec_2_wrapper {
              margin: 0;
              flex-direction: column;
              justify-content: space-between;
              display: flex;
              text-align: right;
              .content_wrapper_1 {
                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: #000000;
                    text-align: center;
                  }
                }
              }

              .content_wrapper_2 {
                .btn_wrapper_1 {
                  text-align: center;

                  button {
                    border: none;
                    outline: none;
                    background-color: #6d48ef;
                    font-size: 18px;
                    font-weight: 600;
                    color: #ffffff;
                    border-radius: 25px;
                    padding: 10px 15px;
                    width: 80%;
                  }
                }

                .text_content_wrapper_1 {
                  margin: 10px 0;

                  .text_content_1 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    color: #000000;
                  }
                }

                .text_content_wrapper_2 {
                  margin: 10px 0;

                  .text_content_2 {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                    color: #a02b2d;
                  }
                }

                .btn_wrapper_2 {
                  text-align: center;

                  button {
                    border: none;
                    outline: none;
                    background-color: transparent;
                    font-size: 15px;
                    font-weight: 500;
                    text-decoration: underline;
                    color: #000;
                  }
                }
              }

              .purchase_btn {
                a {
                  background-color: #31d680;
                  padding: 5px 10px;
                  font-size: 12px;
                  color: #fff;
                  line-height: 14px;
                  border-radius: 4px;
                  text-decoration: none;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 880px) {
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .discount_div {
    padding: 0.2rem 1px;
    border-radius: 0.25rem;
    background: #edffea;
    height: -moz-fit-content;
    height: fit-content;
    margin-left: auto;
    margin-right: 0.25rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .discount_div
    p {
    font-size: 0.675rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .discount_div
    img {
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 0.25rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .duration_div
    p {
    font-size: 0.615rem;
    margin-top: 3px;
  }
}

@media (max-width: 690px) {
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer {
    width: calc(43.33%);
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer:nth-child(3n) {
    margin-right: 23px;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_flex {
    margin-bottom: 0.56rem;
    min-height: 0.21rem;
  }
}
@media (max-width: 768px) {
  .package_offering_component_wrapper .package_filter_div button {
    font-size: 0.775rem;
    padding: 0.75rem 0.125rem;
    margin-right: 0.5rem;
  }
  .userStoreContentComponent_wrapper
    .userStoreContentComponent_inner_wrapper
    .sec_2_wrapper
    .new_wallet_wrapper {
    margin: 16px 0 10px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .package_offering_component_wrapper {
    min-height: auto;
    margin: 5px 0;
    .package_offering_component_inner_wrapper {
      padding: 10px 0;
      .sec_1_wrapper {
        margin: 5px 0;
      }
      .sec_2_wrapper {
        margin: 5px 0;
        .package_collection_wrapper {
          .package_collection {
            display: block;
            .single_package_wrapper {
              flex: 0 0 95%;
              margin-right: 0;
              &:nth-child(3n + 3) {
                margin-right: 0;
              }
              .single_package_inner_wrapper {
                .sec_2_wrapper {
                  .purchase_btn {
                    .expiry_date {
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .free_course_package {
        .package_collection {
          display: block;
          .single_package_wrapper {
            width: 100%;
            margin-right: 0;
            min-height: 70px;

            .single_package_inner_wrapper {
              .sec_1_wrapper {
                .text_content_wrapper_1 {
                  .text_content_1 {
                    white-space: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1030px) {
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    font-size: 0.594rem;
    left: 7.5rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    h1 {
    font-size: 0.575rem;
    left: 7.5rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_button_div
    .details {
    font-size: 0.645rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_button_div
    .buy {
    font-size: 0.645rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_description {
    font-size: 0.675rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .duration_div
    img {
    width: 1rem;
    height: 1rem;
    margin-top: 2px;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .duration_div
    p {
    font-size: 0.715rem;
    margin-top: 3px;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .price_div
    .final_amount {
    font-size: 1rem;
  }
}

@media (max-width: 770px) {
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    font-size: 0.394rem;
    left: 5.5rem;
    padding: 0.163rem 0.2931rem;
    top: 1rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    h1 {
    font-size: 0.475rem;
    left: 5.5rem;
    top: 2.3rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_description {
    font-size: 0.575rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_button_div
    .details {
    font-size: 0.545rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_button_div
    .buy {
    font-size: 0.545rem;
  }
}

@media (max-width: 500px) {
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer {
    width: 93.33%;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    h1 {
    font-size: 0.675rem;
    left: 11.5rem;
    top: 2.3rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    font-size: 0.594rem;
    left: 18.5rem;
    padding: 0.163rem 0.2931rem;
    top: 1rem;
  }
}
@media (max-width: 440px) {
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    h1 {
    font-size: 0.675rem;
    left: 8.5rem;
    top: 2.3rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    font-size: 0.594rem;
    left: 15.5rem;
    padding: 0.163rem 0.2931rem;
    top: 1rem;
  }
}
@media (max-width: 385px) {
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    h1 {
    font-size: 0.575rem;
    left: 7.5rem;
    top: 2.3rem;
  }
  .package_offering_component_wrapper
    .package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    font-size: 0.494rem;
    left: 13.5rem;
    padding: 0.163rem 0.2931rem;
    top: 1rem;
  }
}
