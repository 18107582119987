/* Utility classes for flex properties */

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-hori-center {
  display: flex;
  justify-content: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
}

.package-details-container {
  padding-top: 7rem;
  background: #f5f4f8;

  .bck-btn-course-overview {
    margin-left: 8rem;
    margin-bottom: 1rem;

    h3 {
      font-size: 1.313rem;
      font-weight: 600;
      line-height: 2rem;
      letter-spacing: 0.01em;
      color: #000000;
      margin-left: 0.5rem;
      margin-top: 0.7rem;
    }
  }

  .package-details-wrapper {
    font-family: Poppins;
    background: #f5f4f8;
    justify-content: center;
    margin: 0 auto;

    .package-details-inner-wrapper {
      position: relative;
      width: 85%;
      justify-content: space-between;
      padding-bottom: 5rem;

      .package-left-wrapper {
        width: 70%;

        .package-heading {
          padding: 1.5rem 0 1.75rem 2rem;
          background: #fff;
          border-radius: 0.5rem;
          margin-bottom: 1rem;

          .package-title {
            display: flex;
            align-items: center;
            margin-bottom: 0.75rem;

            img {
              height: 44px;
              width: 44px;
              margin-right: 12px;
            }

            .package-title-name {
              margin: 0;
              font-size: 1.25rem;
              font-weight: 600;
              line-height: 1.875rem;
              letter-spacing: 0.01em;
            }
          }

          .package-descrp {
            width: 90%;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1.5rem;
            letter-spacing: 0em;
            text-align: left;
          }

          .package-tags {
            .package-tags-list {
              display: flex;
              margin: 0;
              padding: 0;
              li {
                padding: 0.25rem 0.688rem;
                list-style: none;
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.188rem;
                letter-spacing: 0em;
                border: 0.4px solid #d4d4d4;
                color: #000000;
                margin-right: 0.5rem;
                border-radius: 1.563rem;
                border: 0.4 solid #d4d4d4;
                background-color: #f5f5f5;
              }
            }
          }
        }

        .course-features-wrapper {
          background: #fff;
          border-radius: 0.5rem;
          margin-bottom: 1rem;

          .course-features-inner-wrapper {
            padding: 1.5rem 4.563rem 2.313rem 2.188rem;

            h2 {
              font-size: 1.25rem;
              font-weight: 600;
              line-height: 1.875rem;
              letter-spacing: 0.01em;
              margin-bottom: 2.5rem;
            }
            .course-features-list {
              flex-wrap: wrap;
              justify-content: space-between;

              .course-feature-list-item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                list-style: none;
                width: calc(28.33% - 10px);
                margin-bottom: 2.875rem;
                align-items: center;

                img {
                  height: 40px;
                  width: 40px;
                  margin-bottom: 0.2rem;
                }

                p {
                  margin-bottom: 0;
                  text-align: center;
                  font-size: 0.875rem;
                  font-weight: 400;
                  line-height: 1.313rem;
                  letter-spacing: 0em;
                }
              }
            }
          }
        }

        .amrit {
          background: none !important;

          .amrit-image {
            width: 100%;
            height: 262px;
          }
        }
      }

      .package-right-wrapper {
        position: absolute;
        right: 0;
        width: 30%;
      }
    }
  }
}

@media (max-width: 578px) {
  .package-details-container {
    padding-top: 3.5rem;
    width: 100%;
    .bck-btn-course-overview {
      margin-left: 1rem;
    }
    .package-details-wrapper {
      margin-left: 10px;
      margin-right: 10px;
      .package-details-inner-wrapper {
        position: static;
        width: 100%;
        flex-direction: column;
        .package-left-wrapper {
          width: 100%;
          margin-bottom: 1rem;
          .package-heading {
            padding: 1rem;
            .package-descrp {
              width: 100%;
            }
            .package-tags {
              .package-tags-list {
                justify-content: space-evenly;
                flex-wrap: wrap;
                gap: 0.5rem;
                li {
                  margin-right: 0;
                }
              }
            }
          }
          .course-features-wrapper {
            .course-features-inner-wrapper {
              padding: 1rem;
              h2 {
                margin-bottom: 1rem;
              }
              .course-features-list {
                padding-left: 0;
                gap: 0.5rem;
                justify-content: center;
                margin-bottom: 0;
                .course-feature-list-item {
                  width: calc(45% - 0.5rem);
                  margin-bottom: 1rem;
                }
              }
            }
          }
          .amrit {
            .amrit-image {
              height: auto;
            }
          }
        }
        .package-right-wrapper {
          position: static;
          width: 100%;
          .package-price-details {
            position: static;
          }
        }
      }
    }
  }
}
