.testWiseAnalysis {
  padding: 1.75rem 2rem;

  .testWiseAnalysisHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;

    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .section-heading {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #121212;
        // margin-left: 17px;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // width: 22%;
      width: fit-content;
      .text {
        color: #575757;
        font-family: "Poppins";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        margin-right: 10px;
      }

      select {
        width: 14rem;
        border-radius: 0.375rem;
        border: 1px solid #b7b7b7;
        background: #fff;
        padding: 0.625rem 1.25rem 0.625rem 0.5rem;
        color: #898989;
        font-family: "Poppins";
        font-size: 1rem;
        font-weight: 400;
        line-height: normal;

        option {
          padding: 10px 20px;
        }
      }
    }
  }

  .no-data {
    padding: 50px;
    margin: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 7.5rem;
        height: 7.5rem;
      }
    }

    .no-data-text {
      margin-top: 10px;
      text-align: center;
      color: #000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .parentTopicWiseInnerWrapperr {
    margin: auto;

    .Card {
      border-radius: 0.75rem;
      background: #fff;
      margin-bottom: 1.2rem;
      border-radius: 0.75rem;

      .card-body {
        padding: 1.5rem;
        .card-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: "Poppins";
          line-height: normal;
          margin-bottom: 0.9rem;
          .text {
            color: #121212;
            font-family: Poppins;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .date {
            color: #696969;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .card-contain {
          display: flex;

          .lft {
            width: 77.5%;
            .lft-top {
              width: 100%;
              ul {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                padding: 0px;
                gap: 10px;

                li {
                  border-radius: 0.375rem;
                  border: 0.953px solid #e8e8e8;

                  box-shadow: 2.383px 2.383px 9.532px 0px rgba(0, 0, 0, 0.08);

                  list-style: none;

                  display: inline-block;
                  padding: 10px 10px;
                  gap: 0.75rem;

                  .TestReportCard {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1rem;
                    height: 100%;

                    .text {
                      .text-top {
                        color: #535353;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                      }
                      .text-btm {
                        color: #213;
                        font-family: Poppins;
                        font-size: 0.625rem;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                      }
                    }

                    .marks {
                      color: #aa964d !important;
                    }
                    .rank {
                      color: #134f87 !important;
                    }

                    .num {
                      font-family: "Poppins";
                      font-size: 1.375rem;
                      font-weight: 600;
                      font-size: 24px;
                      line-height: 36px;

                      letter-spacing: 0.02em;

                      color: #121212;
                    }
                  }
                }
              }
            }

            .lft-btm {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0.75rem;

              .percentageBox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-radius: 0.25rem;
                padding: 0.75rem 1rem;
                width: 39.5%;
                margin-right: 0.75rem;

                .text {
                  color: #121212;
                  text-align: center;
                  font-family: "Poppins";
                  font-size: 0.875rem;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 21px;

                  text-align: center;

                  color: #121212;
                }

                .num {
                  color: #121212;
                  font-family: "Poppins";
                  font-size: 1.25rem;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  letter-spacing: 0.02em;
                  color: #121212;
                }
                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .subjs {
                padding: 0px;
                width: 61.5%;

                ul {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0px;
                  border-radius: 6px;
                  background: #ffffff;
                  box-shadow: -2px -2px 6px rgba(148, 148, 148, 0.06),
                    2px 2px 8px rgba(148, 148, 148, 0.12);
                  border: 0.8px solid #e1e1e1;
                  border-radius: 6px;
                  margin: 0;
                  li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    list-style: none;
                    width: 100%;
                    padding: 21.5px 15px;

                    .text {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      color: #565656;
                    }
                    .num {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 30px;
                      color: #000000;
                    }
                  }
                  .li-1 {
                    border-right: 0.6px solid #c8c8c8;
                  }
                  .li-2 {
                    border-right: 0.6px solid #c8c8c8;
                  }
                  .li-3 {
                    border-right: 0.6px solid #c8c8c8;
                  }
                }
              }
            }
          }

          .rght {
            width: 22.5%;
            text-align: right;
            display: flex;
            flex-direction: column;
            padding-left: 1.8rem;
            justify-content: flex-end;
            margin-bottom: 0.2rem;

            .type {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #696969;
              margin-bottom: 20px;
            }

            .viewReport {
              margin-bottom: 1.25rem;
              text-align: -webkit-right;
              button {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #b50503;
                border-radius: 0.375rem;
                padding: 0.5rem 0;
                width: 12.25rem;
                color: #fff;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                .text {
                  margin-right: 7px;
                }
                img {
                  width: 1.2rem;
                }
              }
            }

            .downloadReport {
              text-align: -webkit-right;

              button {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                padding: 7px 10px;
                border: 1px solid #b50503;
                width: 12.25rem;
                .text {
                  margin-right: 7px;
                  color: #b50503;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
                img {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
