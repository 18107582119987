.cc_avenue_handbook_container {
  .cc_avenue_handbook_success_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 12.5rem;
      height: 12.5rem;
      margin: 0 auto;
    }

    .order_status {
      color: #121212;
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 1.5rem 0 0.15rem 0;
      text-align: center;
    }

    .order_id {
      color: #121212;
      text-align: center;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        font-weight: 600;
      }
    }

    .order_text {
      color: #636363;
      text-align: center;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1875rem; /* 158.333% */
      margin: 0.5rem 0 2.8rem 0;
      text-align: center;
      width: 60%;
    }

    .go_to_store {
      color: #4b4b4b;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      cursor: pointer;
    }

    .check_my_pkgs_btn {
      border-radius: 0.25rem;
      background: #b80f0d;
      padding: 0.8rem 4rem;
      margin: 1.62rem 0 0.75rem 0;
      text-align: center;

      button {
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .check_my_pkgs_text {
      color: #3e3e3e;
      text-align: center;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .cc_avenue_handbook_failed_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 12.5rem;
      height: 12.5rem;
      margin: 0 auto;
    }

    .on_no_text {
      color: #121212;
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 1.5rem 0 0.15rem 0;
    }

    .order_failed_status {
      color: #121212;
      text-align: center;
      font-family: "Source Sans Pro";
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin: 0.12rem 0 0.75rem 0;
    }

    .order_failed_text {
      color: #636363;
      text-align: center;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1875rem; /* 158.333% */
      margin-bottom: 3.5rem;
      text-align: center;
      width: 60%;
    }

    .go_to_store {
      border-radius: 0.25rem;
      background: #b80f0d;
      padding: 0.8rem 6rem;
      margin: 1.62rem 0 0.75rem 0;
      text-align: center;
      cursor: pointer;

      button {
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
