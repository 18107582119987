

.user_doubts_wrappe {
  
  .user_doubts_header {
    margin-bottom: 1.8rem;
    width: 100%;
    height: 4.75rem;
  }

  .user_doubts_content_wrapper {
    .user_doubts_content_inner_wrapper {
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .user_doubts_wrapper {
    .user_doubts_inner_wrapper {
      .user_doubts_left_wrapper {
        flex: 0 0 190px;
      }
      .user_doubts_right_wrapper {
        flex: 0 0 calc(100% - 190px);
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .user_doubts_wrapper {
    .user_doubts_inner_wrapper {
      .user_doubts_left_wrapper {
        flex: 0 0 160px;
      }
      .user_doubts_right_wrapper {
        flex: 0 0 calc(100% - 160px);
      }
    }
  }
}
