.AdaptiveCpsCustomPaperWrppr {
  // margin-top: 40px;

  .AdaptiveCpsCustomPaperInrWrppr {
    .heading {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #3c3c3c;
      margin-bottom: 10px;
      margin-left: 450px;
    }

    .content_wrapper {
      width: 77%;
      margin: 0px auto;

      .right_side_content_wrappers {
        .AdaptiveCpsCustomheaderAndIcon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          .AdaptiveCpsDate {
            margin-right: 7px;
            cursor: pointer;
            img {
              width: 23.39999771118164px;
              height: 26px;
            }
          }
        }

        .AdaptiveCpsCustomPaperContent {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          // gap: 1rem;

          .no_custom_tests_img {
            width: 100%;
            text-align: center;
            padding: 50px;
            img {
              width: 35%;
            }
          }
        }
      }
    }

    // .AdaptiveCpsSubjectsList {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin-bottom: 20px;
    //   .AdaptiveCpsSubject {
    //     padding: 10px;
    //     background: lightgreen;
    //     margin-right: 15px;
    //   }
    // }
  }
}
