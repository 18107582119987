.app-details-inner-wrapper {
  .mobile-app-details-inner-wrapper {
    display: none !important;
  }
  display: flex;
  border-radius: 1rem;
  background: linear-gradient(
    0deg,
    rgba(181, 5, 3, 0.8) 0%,
    rgba(181, 5, 3, 0.8) 100%
  );

  .content-and-btn-wrapper {
    padding: 2.37rem 3.94rem 4.62rem 3.62rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      .heading {
        color: #fff;
        font-family: Poppins;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.0175rem;
        margin: 0.375rem;
      }

      .sub-heading {
        color: #eee;
        font-family: Poppins;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .app-details-btn-wrapper {
      cursor: pointer;
    }

    .app-details-btn-wrapper {
      border-radius: 0.25rem;
      background: #fff;
      padding: 0.75rem 1.25rem;

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }

      color: #121212;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.01rem;
    }
  }
}
.mobile-app-details-inner-wrapper {
  display: none !important;
}
@media screen and (max-width: 1056px) {
  .app-details-inner-wrapper .content-and-btn-wrapper {
    padding: 1.37rem 1.94rem 2.62rem 1.62rem;
  }
}
@media screen and (max-width: 1024px) {
  .app-details-inner-wrapper .content-and-btn-wrapper {
    padding: 2.37rem 3.94rem 2.62rem 3.62rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .content .heading {
    font-size: 1.375rem;
    letter-spacing: 0.01375rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .content .sub-heading {
    font-size: 0.875rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .app-details-btn-wrapper {
    padding: 0.75rem 1.25rem;
    gap: 0.5rem;
  }
}
@media screen and (max-width: 825px) {
  .app-details-inner-wrapper .content-and-btn-wrapper .content .sub-heading {
    font-size: 0.655rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper {
    padding: 2.37rem 1.94rem 2.62rem 1.62rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .content .heading {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .app-details-inner-wrapper .content-and-btn-wrapper {
    padding: 2.37rem 1.94rem 2.62rem 1.62rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .content .heading {
    font-size: 1rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .content .sub-heading {
    font-size: 0.675rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .app-details-btn-wrapper {
    padding: 0.75rem 1.25rem;
  }
}
@media screen and (max-width: 655px) {
  .app-details-inner-wrapper .content-and-btn-wrapper .content .heading {
    font-size: 1rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .content .sub-heading {
    font-size: 0.555rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .app-details-btn-wrapper {
    padding: 0.75rem 0.875rem;
  }
  .app-details-inner-wrapper .content-and-btn-wrapper .app-details-btn-wrapper {
    padding: 0.75rem 1rem;
    font-size: 0.75rem;
  }
  .app-details-inner-wrapper
    .content-and-btn-wrapper
    .app-details-btn-wrapper
    img {
    width: 1rem;
    height: 1rem;
  }
}
@media screen and (max-width: 560px) {
  .app-details-inner-wrapper {
    display: none !important;
  }
  .mobile-app-details-inner-wrapper {
    display: block !important;
  }

  .heading {
    color: #fff;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
    letter-spacing: 0.01rem;
  }
  .sub-heading {
    color: #eee;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 15.4375rem;
  }
  .app-details-btn-wrapper {
    display: inline-flex;
    padding: 0.5rem 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.25rem;
    background: #fff;
    img {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
    }

    color: #121212;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .content {
    padding-bottom: 12px;
  }
}
