.why-motion-inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  gap: 4rem;

  .why-motion-banner {
    img {
      object-fit: contain;
      width: 36.25rem;
    }
  }
}
.mobile-why-motion-inner-wrapper {
  display: none !important;
}

.slider-wrapper {
  .slider-heading {
    margin: auto;
    width: 29.0625rem;
    color: #000;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 800;
    line-height: 2.375rem;
    letter-spacing: 0.035rem;

    span {
      color: #b50503;
      font-family: "Poppins", sans-serif;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 800;
      line-height: 2.375rem;
      letter-spacing: 0.035rem;
    }
  }
}

.slider {
  position: relative;

  .student-feedback-container {
    min-height: 24.625rem;
    border-radius: 1rem;
    background: #fff;
    box-shadow: -2px -2px 12px 2px rgba(0, 0, 0, 0.06),
      2px 2px 12px 2px rgba(0, 0, 0, 0.06);
    padding: 2rem 1.5rem 1rem 1.5rem;

    .studentReviewIcon {
      color: rgba(181, 5, 3, 0.55);
      text-align: center;
      font-family: Nunito;
      font-size: 7rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: start;
    }

    .studentReview {
      color: #2d2d2d;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
    }

    .studentDetailsContainer {
      display: flex;
      .studentImage {
        width: 4.375rem;
        height: 4.81425rem;
        border-radius: 50%;
      }

      .studentDetails {
        .studentName {
          color: #000;
          text-align: center;
          font-family: Poppins;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.01125rem;
          text-transform: uppercase;
        }

        .studentCourse {
          color: #5a5a5a;
          text-align: center;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.slider-image {
  width: 100%;
  height: auto;
  display: block;
  width: 39.25rem;
  height: 24.625rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem auto;
  padding: 0 1rem;
}

.button {
  img {
    width: 2.625rem;
    height: 2.625rem;
  }
}

.button.active {
  cursor: pointer;
}

.button.inactive {
  cursor: not-allowed;
}

.button:disabled {
  cursor: not-allowed;
}
@media screen and (max-width: 1024px) {
  .why-motion-inner-wrapper {
    padding: 0 3rem;
    gap: 4rem;
  }
  .why-motion-inner-wrapper .why-motion-banner img {
    width: 21.6875rem;
    padding: 1.30875rem 0.74781rem 0rem 0.74788rem;
    gap: 1.2045rem;
  }

  .slider-wrapper .slider-heading {
    font-size: 1.375rem;
    line-height: 1.875rem; /* 136.364% */
    letter-spacing: 0.0275rem;
    width: 20.4375rem;
  }
  .slider .student-feedback-container {
    width: 25.1875rem;
    min-height: 14.625rem;
  }

  .slider .student-feedback-container .studentReviewIcon {
    font-size: 3.25rem;
  }
  .slider .student-feedback-container .studentReview {
    font-size: 0.75rem;
    line-height: 1.1875rem; /* 158.333% */
    width: 22rem;
  }
  .slider .student-feedback-container .studentDetailsContainer .studentImage {
    width: 2.70363rem;
    height: 2.97506rem;
    flex-shrink: 0;
  }
  .slider
    .student-feedback-container
    .studentDetailsContainer
    .studentDetails
    .studentName {
    font-size: 0.69519rem;
    letter-spacing: 0.00694rem;
  }
  .slider
    .student-feedback-container
    .studentDetailsContainer
    .studentDetails
    .studentCourse {
    font-size: 0.61794rem;
  }
}
@media screen and (max-width: 768px) {
  .why-motion-inner-wrapper {
    padding: 0 2rem;
    gap: 2rem;
  }
  .why-motion-inner-wrapper .why-motion-banner img {
    width: 18.6875rem;
    padding: 1.30875rem 0.74781rem 0rem 0.74788rem;
    gap: 1.2045rem;
  }

  .slider-wrapper .slider-heading {
    font-size: 1.375rem;
    line-height: 1.875rem; /* 136.364% */
    letter-spacing: 0.0275rem;
    width: 20.4375rem;
    margin: 0;
  }

  .slider .student-feedback-container {
    width: 24.1875rem;
    height: 14.0625rem;
  }
  .slider .student-feedback-container .studentReview {
    font-size: 0.625rem;
    line-height: 1rem; /* 160% */
    width: 20.6875rem;
  }
  .slider .student-feedback-container .studentDetailsContainer .studentImage {
    width: 2.70363rem;
    height: 2.97506rem;
  }
  .slider
    .student-feedback-container
    .studentDetailsContainer
    .studentDetails
    .studentName {
    font-size: 0.69519rem;
    letter-spacing: 0.00694rem;
  }
  .slider
    .student-feedback-container
    .studentDetailsContainer
    .studentDetails
    .studentCourse {
    font-size: 0.61794rem;
  }

  .button img {
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 700px) {
  .why-motion-inner-wrapper {
    display: none !important;
  }
  .mobile-why-motion-inner-wrapper {
    display: block !important;
  }

  .slider-wrapper .slider-heading {
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.01125rem;
    width: 17.625rem;
    margin: auto;
  }

  .slider .student-feedback-container {
    width: 24.1875rem; /* Adjust width as needed */
    height: 14.0625rem; /* Adjust height as needed */
    margin: 0 auto; /* This centers the container horizontally */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    text-align: center; /* Center text content */
    padding: 1rem; /* Adjust padding as needed */
  }

  .slider-wrapper .slider-heading span {
    color: #b50503;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.01125rem;
  }
  .slider .student-feedback-container .studentReviewIcon {
    width: 2.1875rem;
  }
  .slider .student-feedback-container .studentReview {
    color: #2d2d2d;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1875rem;
    width: 22.25956rem;
  }
  .slider .student-feedback-container .studentDetailsContainer {
    margin-top: 20px;
  }
  .buttons {
    padding: 0px 23px 0px 64px;
  }
  .why-motion-banner {
    margin-top: 90px;
  }
  .why-motion-banner img {
    display: flex;
    width: 100%;
    padding: 1.23706rem 1.70688rem 0rem 1.70694rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 1.1155rem;
    gap: 1.1155rem;
    border-radius: 0.5rem;
  }
}

@media screen and (max-width: 426px) {
  .slider-wrapper {
    margin-left: 0px;
  }
  .why-motion-banner img {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 410px) {
  .slider .student-feedback-container .studentReview {
    color: #2d2d2d;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.1875rem;
    width: 18.25956rem;
  }
  .slider .student-feedback-container {
    width: 20.1875rem;
    height: 16.0625rem;
  }
  .buttons {
    padding: 0px 40px 0px 39px;
  }
}

@media screen and (max-width: 1210px) {
  .why-motion-inner-wrapper .why-motion-banner img {
    width: 24.25rem;
  }
}

@media screen and (max-width: 930px) {
  .why-motion-inner-wrapper .why-motion-banner img {
    width: 21.25rem;
  }
}
@media screen and (max-width: 878px) {
  .why-motion-inner-wrapper .why-motion-banner img {
    width: 17.25rem;
  }
}
@media screen and (max-width: 815px) {
  .why-motion-inner-wrapper .why-motion-banner img {
    width: 14.25rem;
  }
}
