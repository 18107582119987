html {
  scroll-behavior: smooth !important;
}

.h2-heading-ttl {
  font-size: 24px;
  letter-spacing: 0.48px;
  color: #000000;
  font-weight: bold;
  line-height: 40px;
}

.p-dec {
  letter-spacing: 0.32px;
  color: #000000;
  opacity: 0.7;
}

.homepage_wrapper {
  .homepage_inner_wrapper {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0px;

      .toggle,
      .toggle2 {
        display: none;
      }

      .logo_dv {
        width: 120px;

        img {
          width: 100%;
        }
      }

      .target_link {
        a {
          text-decoration: none;
          color: #fff;
          margin-left: 20px;
        }
      }
    }
    .sec_1_wrapper {
      background-image: url('../../utilities/images/new_login_landing_page/landing-sec-bg.png');
      min-height: 100vh;
      background-size: 100%;

      .sec1_inr_wrap {
       

        .login_register_wrapr {
          display: flex;
          align-items: center;
          padding: 60px 0px;

          .register_part {
            width: 50%;

            h1 {
              color: #fff;
              font-size: 35px;
              line-height: 50px;
            }

            p {
              letter-spacing: 0.44px;
              color: #FFF;
              opacity: 0.7;
            }
            .title-bar{
              text-align: center;
              h1{
                color: #ffd600;
                -webkit-text-stroke-width: 2px;
                -webkit-text-stroke-color: #ffd600;
                // text-shadow: 0px 6.027px 6.027px rgba(192, 115, 0, 1);
                -webkit-text-stroke-width: 1.506813406944275;
                -webkit-text-stroke-color: #ffd600;
                // font-family: Mukta;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1;
                letter-spacing: 0.958px;
                padding: 0;
                margin: 0;
                margin-top: 0.5rem;
                text-align: center;
                margin-bottom: 0.5rem;
              }
                            h2 {
                              // font-family: Mukta;
                              font-size: 2rem;
                              font-weight: 600;
                              line-height: 50px;
                              letter-spacing: 0.01em;
                              text-align: center;
                              color: #ffffffe8;
                            }
            }
            .spin-wheel{
              max-width: 60%;
              margin:auto;

            }

            .reg_plstr_btn {
              button {
                border-radius: 6px;
                border: none;
                font-weight: 600;

                &:focus {
                  outline: none;
                }
              }

              .register_btn {
                background: #6D48EF;
                color: #fff;
                height: 42px;
                padding: 0px 25px;
                margin-right: 20px;
              }

              .playStore_btn {
                background-color: #fff;
                color: #000;
                height: 42px;
                padding: 0px 20px;

                img {
                  width: 20px;
                  vertical-align: text-bottom;
                }
              }
            }
          }

          .login_part {
            width: 50%;
            text-align: center;
            .reg_plstr_btn {
              margin-top: 1rem;
              button {
                border-radius: 6px;
                border: none;
                font-weight: 600;

                &:focus {
                  outline: none;
                }
              }

              .register_btn {
                background: #6D48EF;
                color: #fff;
                height: 42px;
                padding: 0px 25px;
                margin-right: 20px;
              }

              .playStore_btn {
                background-color: #fff;
                color: #000;
                height: 42px;
                padding: 0px 20px;

                img {
                  width: 20px;
                  vertical-align: text-bottom;
                }
              }
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      padding: 60px 0px 80px;

      .sec_2_inr_wrap {
        .section_ttl {
          text-align: center;
          max-width: 650px;
          margin: 0px auto 40px;
        }
      }

      .our_course_list {
        background-color: #F5F4F8;
        border-radius: 12px;
        padding: 50px 0px;

        .slick-arrow {
          box-shadow: 0px 0px 30px #0000001a;
          width: 30px;
          height: 30px;
          background-image: url('../../utilities/images/homepage/m2_leftArr.png');
          background-repeat: no-repeat;
          background-position: center;
          background-color: #fff;
          border-radius: 50%;
          z-index: 1;
          padding: 5px;
          background-size: 9px;

          &::before {
            content: "";
          }
        }

        .slick-prev {
          left: -10px;
        }

        .slick-next {
          right: -10px;
          transform: rotatey(180deg);
        }

        .course_box {
          padding-left: 60px;
          text-align: center;

          .crs_img {
            text-align: -webkit-center;
            width: 60px;
            margin: 0 auto;
            margin-bottom: 15px;

            img {
              width: 100%;
            }
          }

          h4 {
            margin-bottom: 15px;
          }

          p {}
        }
      }
    }

    .sec_3_wrapper {
      background: #F5F4F8;
      padding: 40px 0px 80px;

      .sec_3_inr_wrap {
        .section_ttl {
          text-align: center;
          max-width: 650px;
          margin: 0px auto 40px;
        }

        .work_type_list {
          .work_box_wrp {
            padding: 0px 10px;

            .work_type_box {
              background-color: #fff;
              padding: 30px 25px;
              text-align: center;

              .img_wrp {
                display: inline-block;
                width: 100px;
                margin-bottom: 20px;

                img {
                  width: 100%;
                }
              }

              h4 {
                margin-bottom: 20px;
              }

              p {
                span {
                  display: block;
                }
              }
            }
          }

          .slick-arrow {
            box-shadow: 0px 0px 30px #0000001a;
            width: 30px;
            height: 30px;
            background-image: url('../../utilities/images/homepage/m2_leftArr.png');
            background-repeat: no-repeat;
            background-position: center;
            background-color: #fff;
            border-radius: 50%;
            z-index: 1;
            padding: 5px;
            background-size: 9px;

            &::before {
              content: "";
            }
          }

          .slick-prev {
            left: -10px;
          }

          .slick-next {
            right: -9px;
            transform: rotatey(180deg);
            top: 47%;
          }
        }
      }
    }

    .sec_4_wrapper {
      background-color: #A90C13;
      background-size: cover;
      padding: 100px 0px;
      background-image: url('../../utilities/images/homepage/m2_dwldImg.png');

      .sec_4_inr_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sec4_left {
          width: 50%;
        }

        .sec4_right {
          width: 50%;

          h2 {
            letter-spacing: 0.8px;
            color: #FFFFFF;
            margin-bottom: 20px;
          }

          p {
            letter-spacing: 0.36px;
            color: #FFFFFF;
            margin-bottom: 10px;
          }

          .play_store_btn {
            padding-top: 25px;

            a {
              display: inline-block;
              width: 130px;

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .sec_5_wrapper {
      background-color: #fff;

      .expert_prf_bx {
        background-color: #F5F4F8 !important;
        border-radius: 8px;
      }
    }

    .sec_6_wrapper {
      .sec_6_inner_wrapper {
        .content_wrapper {
          padding-bottom: 50px;

          .text_content_wrapper_1 {
            text-align: center;
            margin-bottom: 20px;
          }

          .text_content_wrapper_2 {
            margin: 10px 0;

            .text_content_2 {
              margin: 0;
              font-size: 18px;
              line-height: 1.5;
              text-align: center;
              color: #6b6876;
            }
          }

          .sub_content_wrapper_1 {
            display: flex;
            align-items: self-start;
            justify-content: space-between;
            flex-wrap: wrap;

            .single_card_wrapper {
              flex: 0 0 30%;
              max-width: 30%;
              border-radius: 25px;
              padding: 25px;
              text-align: center;

              .single_card_inner_wrapper {
                .image_container {
                  text-align: center;
                  box-shadow: 0px 0px 40px #00000014;
                  display: inline-block;
                  background-color: #fff;
                  border-radius: 50%;
                  margin-bottom: 15px;
                  padding: 15px;

                  img {
                    width: 40px;
                  }
                }

                .text_content_wrapper_1 {
                  .text_content_1 {
                    margin: 0;
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center;
                    color: #09041a;
                    text-align: center;
                  }
                }

                .text_content_wrapper_2 {
                  .text_content_2 {
                    margin: 0;
                    font-size: 16px;
                    line-height: 1.5;
                    text-align: center;
                    color: #6b6876;
                    letter-spacing: 0.32px;
                    color: #000000;
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }
      }
    }

    #footer {
      background-color: #000000;
      padding: 60px 0px 30px;
      position: relative;

      .sec7_wrapper {
        display: flex;
        justify-content: space-between;

        .logo {
          width: 20%;

          img {
            max-width: 100%;
            width: 100px;
          }
        }

        .about_contact {
          width: 30%;

          h5 {
            font-size: 20px;
            letter-spacing: 0px;
            color: #FFFFFF;
            margin-bottom: 18px;
          }

          .a_link {
            display: block;
            text-decoration: none;
            color: #fff;
            margin-bottom: 12px;

            span {}

            i {
              margin-right: 8px;
            }

            .phn {
              transform: rotate(-45deg);
              font-size: 19px;
            }
          }
        }

        .width20 {
          width: 20%;
        }

        .contact_form {
          width: 30%;
          text-align: right;

          h5 {
            text-align: left;
          }

          form {
            padding-left: 20%;

            .input_wrapper {
              input {
                border-radius: 4px;
                border: none;
                padding: 0px 10px;
                height: 36px;
                margin-bottom: 10px;
                width: 100%;
              }
            }

            .btn_wrapper {
              button {
                background: #FF933A;
                border-radius: 4px;
                width: 100%;
                height: 36px;
                color: #fff;
              }
            }
          }
        }
      }

      .copyright {
        text-align: center;
        padding-top: 30px;
        color: #FFFFFF;
        opacity: 0.7;
        font-size: 13px;
      }

      .upArrow {
        width: 45px;
        height: 45px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        box-shadow: 0px 0px 40px #0000001A;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -20px;
        right: 10%;
        cursor: pointer;

        img {
          width: 25px;
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .homepage_wrapper {
    .homepage_inner_wrapper {
      .sec_1_wrapper {
        min-height: auto;
      }

      .sec_2_wrapper {
        .our_course_list {
          .course_box {
            padding: 0px 20px;
          }
        }
      }

      .sec_4_wrapper {
        background-size: 100% 100%;
        padding: 30px 0px;

        .sec_4_inr_wrap {
          .sec4_right {
            h2 {
              font-size: 27px;

            }
          }
        }
      }

      .sec_6_wrapper {
        .sec_6_inner_wrapper {
          .content_wrapper {
            .sub_content_wrapper_1 {
              .single_card_wrapper {
                flex: 0 0 33%;
                max-width: 33%;
              }
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .homepage_wrapper {
    .homepage_inner_wrapper {
      .sec_1_wrapper {
        .sec1_inr_wrap {
          .login_register_wrapr {
            justify-content: space-between;

            .register_part {
              width: 48%;

              h1 {
                font-size: 24px;
                line-height: 32px;
              }
            }

            .login_part {
              width: 48%;
            }
          }
        }
      }

      .sec_4_wrapper {
        background-image: none;

        .sec_4_inr_wrap {
          .sec4_left {
            display: none;
          }

          .sec4_right {
            width: 100%;
          }
        }
      }

      .sec_6_wrapper {
        .sec_6_inner_wrapper {
          .content_wrapper {
            .sub_content_wrapper_1 {
              .single_card_wrapper {
                flex: 0 0 50%;
                max-width: 50%;
              }
            }

            .text_content_wrapper_2 {
              .text_content_2 {}
            }

            .sub_content_wrapper_1 {
              .single_card_wrapper {
                .single_card_inner_wrapper {
                  .image_container {
                    img {}
                  }

                  .text_content_wrapper_1 {
                    .text_content_1 {}
                  }

                  .text_content_wrapper_2 {
                    .text_content_2 {}
                  }
                }

                &:hover {}
              }
            }
          }
        }
      }

      .sec_7_wrapper {
        .sec_7_inner_wrapper {
          .content_wrapper {
            .text_content_wrapper_1 {
              .text_content_1 {}
            }

            .sub_content_wrapper_1 {
              .single_card_wrapper {
                .single_card_inner_wrapper {
                  .image_container {
                    img {}
                  }

                  .text_content_wrapper_1 {
                    .text_content_1 {}
                  }
                }
              }
            }
          }
        }
      }

      .sec_8_wrapper {
        .sec_8_inner_wrapper {
          .content_wrapper {
            .left_side_content_wrapper {
              .text_content_wrapper {
                .text_content {}
              }
            }

            .right_side_content_wrapper {
              .text_content_wrapper {
                .text_content {}
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .homepage_wrapper {
    .homepage_inner_wrapper {
      .sec_1_wrapper {
        .sec1_inr_wrap {
          .login_register_wrapr {
            flex-direction: column-reverse;
            padding-top: 20px;

            .register_part {
              width: 100%;

              h1 {
                font-size: 18px;
                line-height: 25px;
              }

              p {
                font-size: 14px;
              }

              .reg_plstr_btn {
                .playStore_btn {
                  font-size: 15px;
                }

                .register_btn {
                  font-size: 15px;
                }
              }
            }

            .login_part {
              width: 100%;
              margin-bottom: 20px;
            }
          }

          .header {
            position: relative;

            .logo_dv {
              width: 100px;
            }

            .target_link {
              position: absolute;
              width: 100%;
              padding: 10px;
              background-color: #fff;
              top: 20px;
              box-shadow: #64646f33 0px 7px 29px 0px;
              display: none;
              padding-top: 30px;

              a {
                color: #000;
                display: block;
                text-align: center;
                margin: 15px 0px;
              }
            }

            .show {
              display: block;
            }

            .toggle {
              height: max-content;
              color: #fff;
              font-size: 42px;
              line-height: 24px;
              display: block;
            }

            .toggle2 {
              top: 10px;
              position: absolute;
              right: 10px;
              font-size: 17px;
              border: 1px solid #000;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              font-weight: bold;
              line-height: 13px;
              display: block;
            }
          }
        }
      }

      .h2-heading-ttl {
        font-size: 22px;
      }

      .p-desc {
        font-size: 15px;
      }

      .sec_2_wrapper {
        padding: 20px 0px 30px;
      }

      .sec_3_wrapper {
        padding: 20px 0px 30px;
      }

      .sec_4_wrapper {
        background-image: none;

        .sec_4_inr_wrap {
          .sec4_left {
            display: none;
          }

          .sec4_right {
            width: 100%;

            h2 {
              font-size: 22px;
            }

            p {
              font-size: 15px;
            }
          }
        }
      }

      .h2-heading {
        font-size: 22px;
      }

      .sec_6_wrapper {
        .sec_6_inner_wrapper {
          .content_wrapper {
            .sub_content_wrapper_1 {
              .single_card_wrapper {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 10px;

                .single_card_inner_wrapper {
                  .text_content_wrapper_1 {
                    .text_content_1 {
                      font-size: 18px;
                    }
                  }

                  .text_content_wrapper_2 {
                    .text_content_2 {
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      #footer {
        .upArrow {
          width: 40px;
          height: 40px;
        }

        .sec7_wrapper {
          flex-wrap: wrap;

          .logo {
            width: 100%;
            margin-bottom: 20px;
          }

          .about_contact {
            width: 100%;
            margin-bottom: 20px;

            h5 {
              font-size: 20px;
            }

            .a_link {
              font-size: 14px;
              margin-right: 15px;
            }
          }

          .contact_form {
            form {
              padding: 0px;
            }
          }
        }
      }
    }
  }
}
