.pyqCpsExam_wrapper {
  padding-top: 70px;
  background-color: var(--white);

  .pyqCpsExam_InrWrapper {
    padding: 8px 0px;
    .container {
      width: 95%;
      padding: 0px;
      .pyqCPsExam_contentWpr {
        background-color: var(--bg-gray);
        border-radius: 10px;
        margin: 0 45px;

        .pyqCpsExamTabBox {
          position: relative;
          button {
            outline: none;
            border: none;
          }
          .heading {
            text-align: center;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: normal;
            color: #121212;
            // padding-top: 20px;
            padding: 1.25rem 0;
            // text-decoration: underline;
          }
          .pyqCpsExamTab {
            margin-bottom: 25px;

            .pyqCpsExamUl {
              display: flex;
              padding: 15px 15px;
              border-bottom: 1px solid #ebebeb;
              flex-wrap: wrap;
              gap: 0.7rem;

              li {
                border: none;
                //   margin-right: 15px;
                // margin-bottom: 15px;

                button {
                  border: none;
                  background-color: #f5f4f8;
                  border-radius: 10px;
                  color: #707070;
                  font-size: 16px;
                }

                .nav-link.active {
                  color: #fff;
                  background-color: #6d48ef;
                  border: none;
                }
              }
            }
          }
        }

        .pyqCpsExamTabContent {
          min-height: 65vh;
          padding: 0 0px;
          max-width: 1030px;
          margin: 0 auto;

          .tab-content {
            .tab-pane {
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .left_side_content_wrapper
    .tests_type_collection_wrapper
    .single_select_test_type
    label {
    padding: 7px;
    font-size: 10px;
  }
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .all_tests_wrapper
    .all_tests_inner_wrapper
    .all_tests_collection_wrapper
    .single_test_wrapper:nth-child(2n + 2) {
    margin-right: 12px;
  }
  .pyqCpsExam_wrapper {
    padding-top: 50px;

    .pyqCpsExam_InrWrapper {
      padding: 10px 0px;

      .pyqCPsExam_contentWpr {
        .pyqCpsExamTabBox {
          .heading {
            // margin-bottom: 1rem !important;
          }
          .pyqCpsExamTab {
            margin-bottom: 10px;

            .pyqCpsExamUl {
              padding: 10px 10px;

              li {
                margin: 0px 10px 10px 0px;

                button {
                  font-size: 13px;
                }
              }
            }
          }
        }

        .pyqCpsExamTabContent {
          padding: 10px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .left_side_content_wrapper
    .tests_type_collection_wrapper
    .single_select_test_type {
    min-width: 45px;
  }
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .left_side_content_wrapper
    .tests_type_collection_wrapper
    .single_select_test_type
    label {
    padding: 0.5rem 0.5rem;
    font-size: 12px;
  }
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .all_tests_wrapper
    .all_tests_inner_wrapper
    .all_tests_collection_wrapper
    .single_test_wrapper
    .sub_section_wrapper_1
    label {
    padding: 3px 5px;
    font-size: 10px;
  }
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .all_tests_wrapper
    .all_tests_inner_wrapper
    .all_tests_collection_wrapper
    .single_test_wrapper
    .sub_section_wrapper_2
    .text_content_wrapper
    .text_content {
    font-size: 12px;
  }
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .all_tests_wrapper
    .all_tests_inner_wrapper
    .all_tests_collection_wrapper
    .single_test_wrapper
    .sub_section_wrapper_3
    .sub_content_wrapper
    .left_content_wrapper
    label {
    font-size: 10px;
  }
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .all_tests_wrapper
    .all_tests_inner_wrapper
    .all_tests_collection_wrapper
    .single_test_wrapper
    .sub_section_wrapper_3
    .sub_content_wrapper
    .right_content_wrapper
    .lbl_2 {
    font-size: 10px;
  }
  .userExerciseTestContentComponent_wrapper
    .userExerciseTestContentComponent_inner_wrapper
    .tests_tab_wrapper
    .outer_content_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .all_tests_wrapper
    .all_tests_inner_wrapper
    .all_tests_collection_wrapper
    .single_test_wrapper {
    padding: 12px;
    margin: 0px 12px 12px 0px;
  }
}
