.ots_premium_test {
  .sps_test_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;

    .sps_test_list_item {
      background: #ffffff;
      padding: 20px;
      width: calc(50% - 10px);
      margin: 0 20px 20px 0;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .sps_test_heading {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        .sps_test_name {
          color: #121212;
          font-family: "Poppins";
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
        }

        .sps_test_icons {
          display: flex;
          justify-content: space-around;
          width: 90px;
          img {
            cursor: pointer;
          }
        }
      }

      .sps_test_class_course {
        color: #121212;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;

      }
      .sps_test_description {
        // width: 80%;
        color: #484848;
        font-family: "Poppins";
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 10px;
      }

      .sps_test_que_mins_marks {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        ul {
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            color: #F90;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.12px;
            border-radius: 4px;
            border: 0.6px solid #F90;
            background: #FFF;
            padding: 4px 10px;
            margin-right: 8px;

            span {
              margin-right: 3px;
            }
          }
        }

        .sps_test_date_time {
          display: flex;
          align-items: center;
          color: #484848;
          font-family: "Poppins";
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.12px;
          border-radius: 4px;
          border: 0.6px solid #BBB;
          background: #FFF;

          .date_time {
          }
        }
      }

      .sps_Premtest_buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .sps_test_practice_button,
        .sps_test_start_button {
          // font-size: 15px;
          // font-weight: 500;
          // cursor: pointer;
          // background: #ffffff;
          // border-radius: 20px;
          // text-align: center;
          // padding: 8px 16px;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // width: 47%;
          // border: 1px solid #00bc5b;

          border-radius: 59px;
          border: 1px solid #E0E0E0;
          padding: 14px 32px;
          background: #F6F6F7;
          width: calc(50% - 10px);
          text-align: center;
          line-height: 14px; /* 100% */

          button {
            color: #263238;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;

            img {
              height: 17px;
              margin-right: 6px;
              margin-top: -3px;
            }

            span {
              margin-left: 6px;
              color: #fff;
            }
          }
        }

        .sps_test_start_button {
          background: #B50303;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.01em;
          color: #FFF;
          font-family: "Poppins";
          font-size: 14px;
          line-height: 14px; /* 100% */
          padding: 14px 32px;
        }
      }
    }
    .ots_no_data_found_wrapper {
      margin: 60px auto 0;
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .ots_premium_test {
    .sps_test_list {
      .sps_test_list_item {
        width: 100%;
        .sps_test_que_mins_marks{
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
          ul{
            li{
              text-align: center;
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
        .sps_Premtest_buttons{
          flex-direction: column;
          gap: 1rem;
          .sps_test_practice_button,.sps_test_start_button{
            width: 100%;
          }
        }
        .sps_test_description{
          text-align: center;
        }
      }
    }
  }
}
