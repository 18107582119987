.faqsContentComponent_wrapper {
  padding-top: 25px;

  .faqsContentComponent_inner_wrapper {
    .heading_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading_text_content {
        margin: 0;
        color: #000;
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.18px;
        display: inline-block;
      }

      .contact_btn {
        span {
          color: #585858;
          font-family: "Poppins";
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
        }

        .cnt {
          color: #000;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          border-radius: 4px;
          border: 0.6px solid #000;
          padding: 8px;
          margin-left: 15px;
          text-decoration: none;
        }
      }
    }

    .tab_accordion_section {
      margin: 15px 0;

      .tab_accordion_wrapper {
        .customAccordion {
          .custom_accordion_item {
            border: 0;
            margin-bottom: 15px;

            h2 {
              button {
                border-radius: 4px;
                background: #FFF;
                padding: 10px 12px;
                color: #333;
                font-family: "Poppins";
                font-size: 14px;
                font-weight: 400;
                line-height: normal;

                &:focus {
                  box-shadow: none;
                }
              }

              .accordion-button:not(.collapsed) {
                color: #000;
              }

              .accordion-button:not(.collapsed)::after {
                background-image: url(../../../../../utilities/images/homepage/right_Ar.png);
                width: 8px;
                background-size: contain;
                transform: rotate(-90deg);
              }

              .accordion-button::after {
                background-image: url(../../../../../utilities/images/homepage/right_Ar.png);
                width: 8px;
                background-size: contain;
                transform: rotate(90deg);
                margin-right: 10px;
              }
            }

            .custom_accordion_header {}

            .custom_accordion_collapse {
              .custom_accordion_body {
                font-family: "Poppins";
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .faqsContentComponent_wrapper {
    padding-top: 70px;

    .faqsContentComponent_inner_wrapper {
      .heading_wrapper {
        flex-direction: column;

        .heading_text_content {
          margin-bottom: 20px;
        }
      }
    }
  }
}