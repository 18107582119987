@media screen and (max-width: 550px) {
  .contact-banner {
    background: #ffff00;
    color: #000;
    font-family: Poppins;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 1.5625rem;
    padding: 0.3125rem 4.875rem 0.3125rem 4.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
  }

  .contact-banner span.phone-number {
    font-weight: bold;
  }
}
@media screen and (max-width: 375px) {
  .contact-banner {
    padding: 0.3125rem 1.875rem 0.3125rem 1.8125rem;
  }
}
