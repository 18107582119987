.sub_sub_section_wrapper_5 {
  padding: 14px 40px;
  border-top: 0.8px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left_side_wrapper {
    .btn_wrapper {
      display: flex;
      justify-content: flex-start;
    }
  }

  .right_side_wrapper {
    .btn_wrapper {
      display: flex;
      justify-content: flex-end;

      .solution {
        margin-right: 20px;
        background: #ffffff;
        color: #b91311;
        width: auto;
      }

      .solution.opacity {
        color: #a1a1a1;
        border: 1px solid #a1a1a1;
      }

      .que-switcher-button {
        background: #b91311;
        color: #fff;
        font-weight: 500;
        width: 135px;
      }

      .backBtn {
        text-decoration: none;
      }
    }
  }
}
