.videoFeatureContentComponent_wrapper {
  padding: 80px 0 50px;
  .videoFeatureContentComponent_inner_wrapper {
    .video_wrapper {
      .video_inner_wrapper {
        padding: 0 50px;
        .sec_1_wrapper {
          margin: 20px 0;
          .sec_1_inner_wrapper {
            display: flex;
            justify-content: flex-start;
            .btn_wrapper {
              button {
                outline: none;
                border: none;
                background: transparent;
              }
            }
            .text_content_wrapper {
              margin: 0 10px;
              .text_content {
                margin: 0;
                font-size: 22px;
                font-weight: 500;
                color: #000;
              }
            }
          }
        }
        ////////Frame////////////
        .video_frame_wrapper {
          text-align: center;
          .video-container {
            border-radius: 8px;
          }
          iframe {
            width: 100%;
            height: 600px;
          }
        }
        /////////Video Details////////////
        .video_details_wrapper {
          margin: 20px 0;
          .video_details_content_wrapper {
            .tab-list {
              display: flex;
              list-style-type: none;
              justify-content: space-between;
              border-radius: 0.5rem;
              background: #edeeee;
              padding: 0 1.5rem;
              margin-bottom: 2.5rem;

              li {
                padding: 0.75rem 1rem;
                color: #646666;
                font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;

                &.active {
                  border-bottom: 2px solid #263238;
                  color: #121212;
                  font-size: 0.875rem;
                  font-weight: 500;
                }
              }
            }

            .tab-content {
              display: none;

              .notes_wrapper {
                .notes_empty {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-top: 2rem;

                  img {
                    width: 3rem;
                    margin-bottom: 1rem;
                  }

                  p {
                    color: #7e7e7e;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .add_button {
                  margin-bottom: 1rem;

                  .active {
                    opacity: 1;
                  }

                  button {
                    display: flex;
                    align-items: center;
                    border-radius: 0.25rem;
                    opacity: 0.6;
                    background: #b50303;
                    padding: 0.1rem 0.75rem;
                    color: #fff;
                    font-family: "Poppins";
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: normal;

                    span {
                      font-size: 2rem;
                      margin-right: 0.5rem;
                      font-weight: 250;
                    }
                  }
                }

                input[type="text"] {
                  border: none;
                  border-bottom: 0.8px solid #c5c5c5;
                  width: 100%;
                  padding: 0.75rem;
                  outline: none !important;
                  margin-bottom: 1rem;

                  &:focus {
                    outline: none;
                  }

                  ::placeholder {
                    color: #9a9a9a;
                    font-family: "Poppins";
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .note_buttons {
                  p {
                    color: #121212;
                    font-family: "Poppins";
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: normal;
                  }

                  button {
                    font-family: "Poppins";
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: normal;
                    padding: 0.375rem 0.75rem;
                    border-radius: 0.25rem;
                    border: 1px solid #000;
                    color: #212121;
                    margin-left: 1.5rem;

                    &:last-child {
                      color: #fff;
                      background: #b50303;
                      border: none;
                    }
                  }
                }

                .notes_inner {
                  .notes_list {
                    margin-top: 2rem;

                    .note {
                      padding: 0.75rem;
                      margin-bottom: 0.75rem;
                      position: relative;
                      z-index: 10;

                      p {
                        color: #3f3f3f;
                        font-family: "Poppins";
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.4375rem;
                        margin: 0;
                        width: 87%;
                      }

                      div {
                        p {
                          color: #4d4d4d;
                          font-family: "Poppins";
                          font-size: 0.75rem;
                          font-weight: 400;
                          line-height: normal;
                          margin: 0 0.5rem 0 0;
                        }

                        button {
                          background: #fff;
                          width: 3rem;
                          height: 2rem;
                          border-radius: 2rem;

                          img {
                            width: 1rem;
                          }
                        }

                        button.active {
                          background: #edeeee;
                        }
                      }

                      ul {
                        position: absolute;
                        right: 0;
                        top: -150%;
                        list-style-type: none;
                        margin: 0;
                        padding: 0;
                        z-index: 11;
                        background-color: #fff;
                        border-radius: 0.5rem;
                        box-shadow: -2px -2px 6px 0px rgba(0, 0, 0, 0.12),
                          4px 4px 12px 0px rgba(0, 0, 0, 0.12);

                        li {
                          cursor: pointer;
                          color: #000;
                          padding: 0.75rem 1.25rem;

                          p {
                            font-family: "Poppins";
                            font-size: 0.75rem;
                            font-weight: 400;
                            line-height: normal;
                            display: inline;
                            margin: 0;
                          }

                          img {
                            width: 1rem;
                            margin-right: 0.5rem;
                          }

                          &:hover {
                            background: #eee;
                          }
                        }
                      }
                    }
                  }
                }
              }

              .study_material {
                flex-wrap: wrap;

                .sm_empty {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin: 2rem auto 0;

                  img {
                    width: 3rem;
                    margin-bottom: 1rem;
                  }

                  p {
                    color: #7e7e7e;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: normal;
                  }
                }

                .topic_card {
                  border-radius: 0.5rem;
                  background: #f6f6f7;
                  width: calc(50% - 1.25rem);
                  margin: 0 1.25rem 1.25rem 0;
                  padding: 1.2rem 1rem;
                  align-items: center;

                  div {
                    display: flex;
                    align-items: center;

                    img {
                      width: 2.75rem;
                    }

                    p {
                      margin: 0 0 0 1rem;
                      color: #000;
                      font-family: "Poppins";
                      font-size: 1rem;
                      font-weight: 400;
                      line-height: normal;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                  }

                  a {
                    margin-left: auto;
                    background-color: #b50503;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 1rem;
                    }
                  }
                }
              }

              .rating {
                h3 {
                  color: #121212;
                  font-family: "Poppins";
                  font-size: 1rem;
                  font-weight: 500;
                  line-height: normal;
                  letter-spacing: 0.005rem;
                  margin-bottom: 1rem;
                }

                .rating_star_div {
                  width: fit-content;

                  .stars {
                    label {
                      margin-right: 1rem;

                      &:last-child {
                        margin-right: 0;
                      }

                      input[type="radio"] {
                        display: none;
                      }

                      span {
                        img {
                          width: 3rem;
                        }
                      }
                    }
                  }

                  .rating_guide {
                    margin-top: 0.75rem;

                    span {
                      color: #5d5d5d;
                      font-family: "Poppins";
                      font-size: 0.6875rem;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }
                }

                .comments_div {
                  margin-top: 1.5rem;

                  .comment_input {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 2rem;

                    input {
                      border: none;
                      outline: none;
                      border-bottom: 0.8px solid #c5c5c5;
                      padding: 0.75rem;
                      width: 93%;

                      ::placeholder {
                        color: #9a9a9a;
                        font-family: "Poppins";
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: normal;
                      }
                    }

                    button {
                      width: 2.5rem;
                      height: 2.5rem;
                      border-radius: 2.5rem;
                      background-color: #b50503;

                      img {
                        width: 1.5rem;
                      }
                    }
                  }

                  .comment_details {
                    margin-bottom: 1.65rem;

                    .user_name {
                      color: #000;
                      font-family: "Poppins";
                      font-size: 1rem;
                      font-weight: 400;
                      line-height: normal;
                      letter-spacing: 0.005rem;
                      margin-bottom: 0.5rem;
                    }

                    .stars_container {
                      img {
                        width: 1rem;
                        margin-right: 0.2rem;
                      }
                    }

                    .comment {
                      color: #3f3f3f;
                      font-family: "Poppins";
                      font-size: 0.875rem;
                      font-weight: 400;
                      line-height: 1.4375rem;
                      margin: 0.5rem 0;
                    }

                    .like_report {
                      span {
                        margin-right: 1rem;

                        img {
                          width: 1.25rem;
                        }

                        p {
                          display: inline-block;
                          color: #4d4d4d;
                          font-family: "Poppins";
                          font-size: 0.875rem;
                          font-weight: 400;
                          line-height: normal;
                          margin: 0 0 0 0.25rem;
                        }
                      }
                    }
                  }
                }
              }

              .timeline_div {
                .timeline {
                  margin-left: 0.5rem;

                  button {
                    color: #316ddf;
                    font-family: "Poppins";
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration-line: underline;
                  }

                  p {
                    color: #585858;
                    font-family: "Poppins";
                    font-size: 0.875rem;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 0.5rem;
                    padding-left: 0.2rem;
                    display: inline-block;
                  }
                }
              }

              &.active {
                display: block;
              }
            }

            .text_content_wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;

              img {
                width: 40px;
              }

              .text_content {
                margin: 0;
                font-size: 20px;
                font-weight: 600;
                color: #000000;
              }
            }
            .content_wrapper_1 {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              .left_side_content_wrapper {
                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 400;
                    color: #585858;
                  }
                }
              }
              .right_side_content_wrappers {
                display: flex;
                align-items: center;
                .label_content_wrapper {
                  margin: 0 5px;
                  label {
                    span {
                      font-weight: 500;
                      color: #000;

                      img {
                        width: 15px;
                        height: 15px;
                        margin-right: 5px;
                        vertical-align: unset;
                      }
                    }
                  }
                }
              }
            }
            .content_wrapper_2 {
              margin: 10px 0;
              .text_content_wrapper {
                .text_content {
                  margin: 0;
                  font-size: 18px;
                  font-weight: 600;
                  color: #000000;
                }
              }
              .topic_collection {
                .sub_content_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  cursor: pointer;
                  margin-bottom: 10px;
                  .left_side_content_wrapper {
                    .text_content_wrapper {
                      .text_content {
                        margin: 0;
                        .key_tag {
                          margin-right: 5px;
                          font-size: 16px;
                          font-weight: 500;
                          color: #000000;
                        }
                        .value_tag {
                          font-size: 16px;
                          font-weight: 400;
                        }
                      }
                    }
                  }
                  .right_side_content_wrappers {
                    padding: 0;
                    .text_content_wrapper {
                      .text_content {
                        margin: 0;
                        label {
                          padding: 8px 16px;
                          border-radius: 4px;
                          background-color: #f6f6f7;
                          font-size: 14px;
                          font-weight: 500;
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        /////////Other related content//////////
        .other_related_content_wrapper {
          margin: 20px 0;
          .other_related_content_container {
            .options_collection_wrapper {
              .options_collection {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                .single_select_option {
                  flex: 0 0 45%;
                  input[type="radio"] {
                    opacity: 0;
                    position: fixed;
                    width: 0;
                  }
                  label {
                    width: 100%;
                    border: solid 1px #3f3e41;
                    background-color: #c7c7d4;
                    padding: 10px 20px;
                    border-radius: 25px;
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.2px;
                    color: #6b6876;
                    cursor: pointer;
                    text-align: center;
                  }
                  input[type="radio"]:checked + label {
                    background-color: #ff933a;
                    border: none;
                    color: #ffffff;
                  }
                }
              }
            }
            .options_data_view_wrapper {
              margin: 15px 0;
              //////notes////////////
              .notesCollectionView_wrapper {
                .notesCollectionView_inner_wrapper {
                  .notes_collection_wrapper {
                    display: flex;
                    flex-direction: column;
                    .single_notes_wrapper {
                      width: 100%;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      border-radius: 15px;
                      border: solid 1px #f0eef8;
                      background-color: #c8c8ce;
                      padding: 10px;
                      margin: 10px 0;
                      cursor: pointer;
                      .serial_number_wrapper {
                        border-radius: 15px;
                        background-image: linear-gradient(
                          149deg,
                          #fff5e6 32%,
                          #ffefe6 76%
                        );
                        padding: 15px;
                      }
                      .notes_description_wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 0 10px;
                        .notes_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #0f0404;
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                              margin: 0;
                              font-size: 14px;
                              font-weight: 500;
                              color: #6b6876;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ///////Dpp/////////
              .dppCollectionView_wrapper {
                p {
                  color: #4e4e4e;
                  font-size: 20px;
                  font-weight: 600;
                  letter-spacing: 0.4px;
                  margin: 0;
                }
                .dppCollectionView_inner_wrapper {
                  .dpp_collection_wrapper {
                    display: flex;
                    flex-direction: column;
                    .single_dpp_wrapper {
                      width: 100%;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      border-radius: 8px;
                      border: solid 1px #f0eef8;
                      background-color: #f6f6f7;
                      padding: 10px;
                      margin: 10px 0;
                      cursor: pointer;
                      .serial_number_wrapper {
                        padding: 5px;
                      }
                      .dpp_description_wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 0 10px;
                        .dpp_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                              margin: 0;
                              font-size: 16px;
                              font-weight: 500;
                              color: #000;
                              letter-spacing: 0.16px;
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                              margin: 0;
                              font-size: 14px;
                              font-weight: 400;
                              color: #3b3b3b;
                            }
                          }
                        }
                        .caret_wraper {
                          .image_wrapper {
                            img {
                              width: 7px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        ////////Related videos/////////////
        .related_videos_wrapper {
          margin: 10px 0;
          .related_video_content_wrapper {
            .text_content_wrapper {
              .text_content {
                margin: 0;
                font-size: 24px;
                font-weight: 600;
                color: #000000;
              }
            }
            .video_collection_wrapper {
              .video_collection {
                display: flex;
                flex-direction: column;
                .single_video_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #d1cfd8;
                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;
                  .left_side_content_wrapper {
                    width: auto;
                    flex: auto;
                    .image_wrapper {
                      text-align: center;
                      img {
                        width: 100px;
                      }
                    }
                  }
                  .right_side_content_wrappers {
                    flex: auto;
                    margin: 0 10px;
                    width: 100%;
                    .text_content_wrapper_1 {
                      .text_content_1 {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                        color: #0f0404;
                      }
                    }
                    .text_content_wrapper_2 {
                      .text_content_2 {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: #6f6868;
                      }
                    }
                    .sub_content_wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      .left_side_content_wrapper {
                        .label_content_wrapper {
                          label {
                            padding: 5px 10px;
                            border-radius: 15px;
                            background-color: #fef4ec;
                            span {
                              img {
                                width: 15px;
                                height: 15px;
                              }
                            }
                          }
                        }
                      }
                      .right_side_content_wrappers {
                        text-align: right;
                        margin: 0;
                        padding: 0;
                        width: auto;
                        .subject_name {
                          padding: 5px;
                          border-radius: 5px;
                          background-image: linear-gradient(
                            120deg,
                            #ff9600 37%,
                            #ff5d00 72%
                          );
                          font-size: 12px;
                          font-weight: 600;
                          color: #ffffff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal_outer_package {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.delete_modal {
  .modal_content_delete {
    background-color: #ffffff; /* white background for modal content */
    margin: 20vh auto 0;
    padding: 25px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 16px;
    width: 25%;

    h4 {
      color: #000;
      text-align: center;
      font-family: "Poppins";
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem; /* 150% */
      letter-spacing: 0.01rem;
      margin-bottom: 1rem;
    }

    p {
      color: #535353;
      text-align: center;
      font-family: "Poppins";
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.3125rem; /* 150% */
    }

    div {
      button {
        border-radius: 0.25rem;
        border: 0.6px solid #fff;
        background: rgba(181, 5, 3, 0.08);
        padding: 0.75rem 1rem;
        color: #b50503;
        font-family: "Poppins";
        font-size: 1rem;
        font-weight: 400;
        line-height: normal;
        width: calc(50% - 0.75rem);
        margin-right: 0.75rem;

        &:last-child {
          margin-right: 0;
          background: #fff;
        }
      }
    }
  }
}

.report_modal {
  .modal_content_report {
    background-color: #ffffff; /* white background for modal content */
    margin: 20vh auto 0;
    padding: 25px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 16px;
    width: 30%;
    position: relative;

    .close {
      position: absolute;
      right: 15px;
      top: 20px;
    }

    h4 {
      color: #121212;
      font-family: "Poppins";
      font-size: 1rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.01rem;
      margin-bottom: 0.5rem;
    }

    p {
      color: #505050;
      font-family: "Poppins";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4375rem; /* 164.286% */
    }

    .errorList {
      text-align: start;
      padding: 0;
      margin: 0;
    }

    .errorList .errorListItem {
      list-style: none;
      text-align: start;
      margin-bottom: 10px;

      font-size: 16px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: #000000;
    }

    .errorList {
      text-align: start;
      padding: 0;
      margin: 0 0 1.5rem;
    }

    .errorList .errorListItem {
      list-style: none;
      text-align: start;
      margin-bottom: 10px;

      font-size: 16px;

      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: #000000;
    }

    .errorList .errorListItem input {
      font-size: 25px;
    }

    .errorList .errorListItem input[type="radio"] {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 8px;
    }

    textarea {
      border-radius: 0.25rem;
      background: #f6f6f6;
      padding: 0.625rem;
      width: 100%;
      margin-bottom: 1.5rem;

      &:focus {
        outline: none !important;
      }

      &::placeholder {
        color: #797979;
        font-family: "Poppins";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.3125rem; /* 150% */
      }
    }

    div {
      button {
        border-radius: 0.25rem;
        background: #b50503;
        color: #fff;
        font-family: "Poppins";
        font-size: 0.875rem;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        padding: 0.75rem 0.875rem;
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .videoFeatureContentComponent_wrapper {
    padding-top: 60px;
    .videoFeatureContentComponent_inner_wrapper {
      padding: 20px 5px;
      .video_wrapper {
        .video_inner_wrapper {
          .video_frame_wrapper {
            iframe {
              width: 100%;
              height: calc(100vh - 60px - 40px);
            }
          }
        }
      }
    }
  }
}
.sec_2_inner_wrapper {
  .videos_collection_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .single_video_wrapper {
      width: calc(50% - 10px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;
      background-color: #ffffff;
      padding: 15px;
      margin: 0px 20px 20px 0px;
      cursor: pointer;
      &:nth-child(2n + 2) {
        margin-right: 0px;
      }
      .left_side_content_wrapper {
        width: auto;
        flex: auto;
        .image_wrapper {
          text-align: center;
          width: 105px;
          img {
            border-radius: 4px;
            width: 105px;
          }
        }
      }
      .right_side_content_wrappers {
        flex: auto;
        width: 100%;
        .right_side_content_wrappers_top {
          display: flex;
          justify-content: space-between;
          margin-right: 3px;
          .text_content_wrapper_1 {
            .text_content_1 {
              color: #121212;
              font-family: "Poppins";
              font-weight: 600;
              line-height: normal;
              letter-spacing: 0.16px;
              margin: 0;
              font-size: 16px;
              margin-bottom: 5px;
            }
          }

          .image_wrapper {
            width: 28px;

            img {
              width: 28px;
            }
          }
        }
        .text_content_wrapper_2 {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .text_content_2 {
            margin: 0;
            color: #484848;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 400;
            line-height: 14px; /* 116.667% */
            letter-spacing: 0.12px;
          }
          label {
            padding: 5px 5px 0 0;
          }
        }
        .sub_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .left_side_content_wrapper {
            .label_content_wrapper {
              label {
                padding: 5px 10px;
                border-radius: 15px;
                background-color: #fef4ec;
                span {
                  img {
                    width: 15px;
                    height: 15px;
                  }
                }
              }
            }
          }
          .right_side_content_wrappers {
            text-align: right;
            margin: 0;
            padding: 5px 0px 0px;
            width: auto;
            .subject_name {
              padding: 5px;
              border-radius: 5px;
              background-image: linear-gradient(
                120deg,
                #ff9600 37%,
                #ff5d00 72%
              );
              font-size: 12px;
              font-weight: 600;
              color: #ffffff;
            }
            button {
              background: #b50303;
              border-radius: 8px;
              color: #f5f4f8;
              font-size: 12px;
              padding: 12px 20px;
            }
          }
        }
      }
    }
  }
  .videos_collection_wrapper::-webkit-scrollbar {
    width: 3px;
  }
  .videos_collection_wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f5f4f8;
    border-radius: 10px;
  }

  /* Handle */
  .videos_collection_wrapper::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
  }

  /* Handle on hover */
  .videos_collection_wrapper::-webkit-scrollbar-thumb:hover {
    background: #f5f4f8;
  }
}
@media (max-width: 769px) {
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper {
    padding: 0 0px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-list
    li {
    padding: 0.55rem 0.575rem;
    font-size: 0.685rem;
  }

  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-list
    li.active {
    font-size: 0.675rem;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-content
    .notes_wrapper
    .add_button
    button {
    font-size: 0.675rem;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-content
    .notes_wrapper
    .add_button
    button
    span {
    font-size: 1.2345rem;
    font-weight: 250;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .sec_1_wrapper
    .sec_1_inner_wrapper
    .text_content_wrapper
    .text_content {
    font-size: 18px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .text_content_wrapper
    .text_content {
    font-size: 16px;
  }
  .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .content_wrapper_1
    .left_side_content_wrapper
    .text_content_wrapper
    .text_content {
    font-size: 13px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .other_related_content_wrapper
    .other_related_content_container
    .options_data_view_wrapper
    .dppCollectionView_wrapper
    p {
    font-size: 16px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .other_related_content_wrapper
    .other_related_content_container
    .options_data_view_wrapper
    .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .serial_number_wrapper {
    padding: 0px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .other_related_content_wrapper
    .other_related_content_container
    .options_data_view_wrapper
    .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper
    .dpp_details_wrapper
    .text_content_wrapper_1
    .text_content_1 {
    font-size: 12px;
    letter-spacing: 0.11px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .other_related_content_wrapper
    .other_related_content_container
    .options_data_view_wrapper
    .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper
    .dpp_details_wrapper
    .text_content_wrapper_2
    .text_content_2 {
    font-size: 11px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .other_related_content_wrapper
    .other_related_content_container
    .options_data_view_wrapper
    .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper {
    padding: 0 0px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .other_related_content_wrapper
    .other_related_content_container
    .options_data_view_wrapper
    .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper {
    padding: 5px;
    margin: 5px 0;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-content
    .notes_wrapper
    .notes_empty
    p {
    font-size: 0.675rem;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-content
    .notes_wrapper
    .notes_empty
    img {
    width: 2rem;
    margin-bottom: 0.1rem;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-content
    .rating
    .rating_star_div
    .stars
    label
    span
    img {
    width: 2rem;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .other_related_content_wrapper
    .other_related_content_container
    .options_data_view_wrapper
    .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper
    .dpp_details_wrapper
    .text_content_wrapper_1
    .text_content_1 {
    margin-left: 17px;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .other_related_content_wrapper
    .other_related_content_container
    .options_data_view_wrapper
    .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper
    .dpp_details_wrapper
    .text_content_wrapper_2
    .text_content_2 {
    margin-left: 17px;
  }
  .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .left_side_content_wrapper
    .image_wrapper
    img {
    width: 50px;
  }
  .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .right_side_content_wrappers
    .right_side_content_wrappers_top
    .text_content_wrapper_1
    .text_content_1 {
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 11px;
    letter-spacing: 0.1px;
  }
  .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .right_side_content_wrappers
    .text_content_wrapper_2
    .text_content_2 {
    font-size: 10px;
    line-height: 0px;
    letter-spacing: 0.1px;
    margin-top: 6px;
  }
  .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .right_side_content_wrappers
    .sub_content_wrapper
    .right_side_content_wrappers
    button {
    font-size: 10px;
    padding: 7px 13px;
  }
  .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .right_side_content_wrappers
    .right_side_content_wrappers_top
    .image_wrapper
    img {
    width: 20px;
  }
  .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .left_side_content_wrapper
    .image_wrapper {
    width: 82px;
  }
  .sec_2_inner_wrapper .videos_collection_wrapper .single_video_wrapper {
    padding: 2px;
    margin: 0px 12px 12px 0px;
  }
}

@media (max-width: 585px) {
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-list {
    display: flex;
    list-style-type: none;
    justify-content: space-between;
    border-radius: 0.5rem;
    background: #edeeee;
    padding: 0 1.5rem;
    margin-bottom: 0.5rem;
  }
  .videoFeatureContentComponent_wrapper
    .videoFeatureContentComponent_inner_wrapper
    .video_wrapper
    .video_inner_wrapper
    .video_details_wrapper
    .video_details_content_wrapper
    .tab-list
    li {
    padding: 0.45rem 0rem;
    font-size: 0.685rem;
  }
}
