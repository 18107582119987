.userBookmarksContentComponent_wrapper {
  padding-top: 101px;
  min-height: 96vh;

  .userBookmarksContentComponent_inner_wrapper {
    padding: 20px;
    border-radius: 16px;
    background: rgba(238, 239, 240, 0.5);

    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .heading_wrapper {
          .heading_text_content {
            margin: 0;
            font-size: 28px;
            font-weight: 600;
            letter-spacing: 0.33px;
            color: #463c34;
          }
        }
      }
    }

    .sec_2_wrapper {
      margin: 20px 0;

      .sec_2_inner_wrapper {
        .sub_content_wrapper_1 {
          margin-bottom: 48px;

          .bookmark_option_collection_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .single_select_bookmark_option {
              flex: 0 0 18%;

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                padding: 8px 20px;

                cursor: pointer;
                width: 100%;
                text-align: center;

                color: #585858;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border-radius: 8px;
                background: #eaebec;
              }

              input[type="radio"]:checked + label {
                border: none;
                color: #ffffff;
                border-radius: 8px;
                background: #263238;
                color: #fff;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }

        .sub_content_wrapper_2 {
          margin-top: -12px;

          /////dpp questions///////////////////
          .dpp_collection_view_wrapper {
            .dpp_collection_view_innner_wrapper {
              // background-color: #ffffff;
              border-radius: 10px;

              .dpp_question_collection_wrapper {
                display: flex;
                flex-direction: column;
                // height: 400px;
                overflow-y: auto;
                padding: 10px;
                height: 63vh;

                .single_question_wrapper {
                  width: 100%;

                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;

                  border-radius: 8px;
                  border: 1px solid #ebebeb;
                  background: #fff;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .left_side_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex: 0 0 90%;

                    .serial_number_wrapper {
                      .text_content_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;
                        border: 1px solid #263238;
                        background: #eaebec;
                        border-radius: 50%;

                        .text_content {
                          text-align: center;

                          color: #000;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          margin: 0 auto;
                        }
                      }
                    }

                    .details_wrapper {
                      margin: 0 10px;
                      margin-top: 10px;

                      .text_content_wrapper {
                        p {
                          color: #353535;
                          font-family: Poppins;
                          font-size: 1rem;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 26px; /* 162.5% */
                          letter-spacing: 0.08px;
                        }
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                    .image_wrapper {
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }
                }

                .not_bookmarks {
                  height: 100%;
                  min-height: 50vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .null_bkmark_inr {
                    text-align: center;
                    padding-top: 20px;

                    .img_wrp {
                      img {
                        width: 3.86963rem;
                        height: 5.0305rem;
                      }
                    }

                    p {
                      color: #484848;
                      font-family: Poppins;
                      font-size: 1.125rem;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: 0.01125rem;
                      margin-top: 1.25rem;
                    }

                    a {
                      background-color: #6d48ef;
                      padding: 7px 15px;
                      font-size: 15px;
                      color: #fff;
                      border-radius: 8px;
                      text-decoration: none;
                      display: inline-block;
                    }
                  }
                }
              }

              .dpp_question_collection_wrapper::-webkit-scrollbar {
                width: 7px !important;
              }

              .dpp_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .dpp_question_collection_wrapper::-webkit-scrollbar-thumb {
                background: #b7b7b7;
                border-radius: 10px;
              }

              /* Handle on hover */
              .dpp_question_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #b7b7b7;
              }
            }
          }

          /////exercise questions///////////////////

          .exercise_collection_view_wrapper {
            .exercise_collection_view_innner_wrapper {
              // background-color: #ffffff;
              border-radius: 10px;

              .exercise_question_collection_wrapper {
                display: flex;
                // flex-wrap: wrap;
                flex-direction: column;
                // height: 400px;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 10px;
                height: 60vh;

                .single_question_wrapper {
                  // width: 32.5%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  border-radius: 8px;
                  background-color: #faf9fe;
                  padding: 10px;
                  margin: 10px 0;
                  margin-right: 13px;
                  cursor: pointer;

                  &:nth-child(3n + 3) {
                    margin-right: 0px;
                  }
                  border-radius: 8px;
                  border: 1px solid #ebebeb;
                  background: #fff;

                  width: 100%;

                  .left_side_content_wrapper {
                    display: flex;
                    justify-content: flex-start;
                    flex: 0 0 90%;

                    .serial_number_wrapper {
                      .text_content_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;
                        border: 1px solid #263238;
                        background: #eaebec;
                        border-radius: 50%;
                        margin-top: 10px;

                        .text_content {
                          text-align: center;

                          color: #000;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          margin: 0 auto;
                        }
                      }
                    }

                    .details_wrapper {
                      margin: 0 10px;
                      margin-top: 10px;

                      .text_content_wrapper {
                        p {
                          color: #353535;
                          font-family: Poppins;
                          font-size: 1rem;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 26px; /* 162.5% */
                          letter-spacing: 0.08px;
                        }
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                    .image_wrapper {
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }
                }

                .not_bookmarks {
                  width: 100%;
                  // height: 100%;
                  min-height: 50vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .null_bkmark_inr {
                    text-align: center;
                    padding-top: 20px;

                    .img_wrp {
                      img {
                        width: 3.86963rem;
                        height: 5.0305rem;
                      }
                    }

                    p {
                      color: #484848;
                      font-family: Poppins;
                      font-size: 1.125rem;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: 0.01125rem;
                      margin-top: 1.25rem;
                    }

                    a {
                      background-color: #6d48ef;
                      padding: 7px 15px;
                      font-size: 15px;
                      color: #fff;
                      border-radius: 8px;
                      display: inline-block;
                      text-decoration: none;
                    }
                  }
                }
              }

              .exercise_question_collection_wrapper::-webkit-scrollbar {
                width: 7px !important;
              }

              .exercise_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .exercise_question_collection_wrapper::-webkit-scrollbar-thumb {
                background: #b7b7b7;
                border-radius: 10px;
              }
            }
          }

          /////exercise questions///////////////////
          .test_collection_view_wrapper {
            .test_collection_view_innner_wrapper {
              // background-color: #ffffff;
              border-radius: 10px;

              .test_question_collection_wrapper {
                display: flex;
                flex-direction: column;
                height: 63vh;
                overflow-y: auto;
                padding: 10px;

                .single_question_wrapper {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  padding: 10px;
                  margin: 10px 0;
                  cursor: pointer;

                  border-radius: 8px;
                  border: 1px solid #ebebeb;
                  background: #fff;

                  .left_side_content_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    // flex: 0 0 90%;

                    .serial_number_wrapper {
                      .text_content_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;
                        border: 1px solid #263238;
                        background: #eaebec;
                        border-radius: 50%;

                        .text_content {
                          text-align: center;

                          color: #000;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          margin: 0 auto;
                        }
                      }
                    }

                    .details_wrapper {
                      margin: 0 10px;
                      margin-top: 10px;

                      .text_content_wrapper {
                        p {
                          color: #353535;
                          font-family: Poppins;
                          font-size: 1rem;
                          font-style: normal;
                          font-weight: 400;
                          line-height: 26px; /* 162.5% */
                          letter-spacing: 0.08px;
                        }
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    // flex: 0 0 10%;
                    display: flex;
                    justify-content: center;
                    height: auto !important;
                    .image_wrapper {
                      img {
                        width: 28px;
                        height: 28px;
                      }
                    }
                  }
                }

                .not_bookmarks {
                  min-height: 50vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .null_bkmark_inr {
                    text-align: center;
                    padding-top: 20px;

                    .img_wrp {
                      img {
                        width: 3.86963rem;
                        height: 5.0305rem;
                      }
                    }

                    p {
                      color: #484848;
                      font-family: Poppins;
                      font-size: 1.125rem;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: 0.01125rem;
                      margin-top: 1.25rem;
                    }

                    button {
                      background-color: #6d48ef;
                      padding: 7px 15px;
                      font-size: 15px;
                      color: #fff;
                      border-radius: 8px;
                    }
                  }
                }
              }

              .test_question_collection_wrapper::-webkit-scrollbar {
                width: 7px !important;
              }

              .test_question_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }
            }
          }

          ///////////videos//////////////

          .video_collection_view_wrapper {
            .video_collection_view_innner_wrapper {
              border-radius: 10px;

              .videos_collection_wrapper {
                display: flex;
                flex-wrap: wrap;
                gap: 13px;

                .not_bookmarks {
                  width: 100%;
                  min-height: 50vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .user-dashboard-single_video_wrapper {
                  width: calc(50% - 8px);
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;

                  padding: 7px;

                  cursor: pointer;

                  border-radius: 8px;
                  border: 0.8px solid #e9e9e9;
                  background: #fff;

                  &:nth-child(3n + 3) {
                    margin-right: 0px;
                  }

                  .left_side_content_wrapper {
                    width: 100%;
                    flex: 0 0 100px;

                    .image_wrapper {
                      text-align: center;
                      padding: 3px 0px;

                      img {
                        width: 100px;
                      }
                    }
                  }

                  .right_side_content_wrapper {
                    flex: auto;
                    margin-left: 10px;
                    width: 100%;
                    height: auto !important;

                    button {
                      color: #fff;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 14px; /* 100% */
                      padding: 12px 20px;
                      border-radius: 4px;
                      background: #b50303;
                      width: 121px;
                    }

                    .right_side_content_wrapper_top {
                      display: flex;
                      justify-content: space-between;
                      margin-right: 13px;

                      .text_content_wrapper_1 {
                        .text_content_1 {
                          margin-bottom: 4px;

                          color: #121212;
                          font-family: Poppins;
                          font-size: 16px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: normal;
                          letter-spacing: 0.16px;
                        }
                      }

                      .image_wrapper {
                        img {
                          width: 28px;
                          height: 28px;
                        }
                      }
                    }

                    .text_content_wrapper_2 {
                      display: flex;
                      justify-content: space-between;
                      margin-right: 10px;
                      .text_content_2 {
                        margin: 0;

                        color: #484848;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 14px; /* 116.667% */
                        letter-spacing: 0.12px;
                      }

                      .rating_wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .rating-icon {
                          img {
                            width: 24px;
                            height: 24px;
                          }
                        }

                        .rating {
                          color: #000;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 14px; /* 100% */
                          margin-top: 3.5px;
                          margin-left: 0.31rem;
                        }
                      }
                    }

                    .sub_content_wrapper {
                      text-align: right;
                      padding-right: 10px;
                      width: 100%;

                      .label_content_wrapper {
                        label {
                          span {
                            vertical-align: middle;

                            img {
                              width: 13px;
                              height: 13px;
                            }
                          }

                          .rating {
                            vertical-align: bottom;
                            font-size: 14px;
                            margin-left: 3px;
                          }
                        }
                      }

                      .right_side_content_wrapper {
                        text-align: right;
                        margin: 0;
                        padding-top: 20px;
                        width: auto;
                        float: right;

                        .subject_name {
                          padding: 5px;
                          border-radius: 5px;
                          background-image: linear-gradient(
                            120deg,
                            #ff9600 37%,
                            #ff5d00 72%
                          );
                          font-size: 12px;
                          font-weight: 600;
                          color: #ffffff;
                        }
                      }
                    }

                    .resume_btn {
                      text-align: right;
                      padding-top: 15px;

                      span {
                        background: #668de1;
                        display: inline-block;
                        font-size: 10px;
                        color: #fff;
                        padding: 3px 8px;
                        border-radius: 4px;
                      }
                    }
                  }
                }

                .single_video_wrapper:hover {
                  border-radius: 8px;
                  border: 0.8px solid #121212;
                  background: #fff;
                }

                .not_bookmarks {
                  min-height: 50vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .null_bkmark_inr {
                    text-align: center;
                    padding-top: 20px;

                    .img_wrp {
                      img {
                        width: 3.86963rem;
                        height: 5.0305rem;
                      }
                    }

                    p {
                      color: #484848;
                      font-family: Poppins;
                      font-size: 1.125rem;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      letter-spacing: 0.01125rem;
                      margin-top: 1.25rem;
                    }

                    button {
                      background-color: #6d48ef;
                      padding: 7px 15px;
                      font-size: 15px;
                      color: #fff;
                      border-radius: 8px;
                    }
                  }
                }
              }

              .videos_collection_wrapper::-webkit-scrollbar {
                width: 7px !important;
              }

              .videos_collection_wrapper::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px grey;
                border-radius: 10px;
              }

              /* Handle */
              .videos_collection_wrapper::-webkit-scrollbar-thumb {
                background: #b7b7b7;
                border-radius: 10px;
              }

              /* Handle on hover */
              .videos_collection_wrapper::-webkit-scrollbar-thumb:hover {
                background: #b7b7b7;
              }

              .navigation_btn_wrapper {
                margin: 10px 0;

                .navigation_btn_inner_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .btn_wrapper {
                    button {
                      border: none;
                      outline: none;
                      width: auto;
                      height: 45px;
                      border-radius: 5px;
                      border: 2px solid #6d48ef;
                      background-color: #6d48ef;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #ffffff;
                      padding: 0 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 769px) {
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .test_collection_view_wrapper
    .test_collection_view_innner_wrapper
    .test_question_collection_wrapper
    .single_question_wrapper
    .left_side_content_wrapper
    .details_wrapper {
    margin: 0 5px;
    margin-top: 2px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .test_collection_view_wrapper
    .test_collection_view_innner_wrapper
    .test_question_collection_wrapper
    .single_question_wrapper
    .left_side_content_wrapper
    .serial_number_wrapper
    .text_content_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    border: 1px solid #263238;
    background: #eaebec;
    border-radius: 50%;
  }
}

@media (max-width: 850px) {
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .left_side_content_wrapper
    .image_wrapper
    img {
    width: 70px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    .right_side_content_wrapper_top
    .text_content_wrapper_1
    .text_content_1 {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    button {
    font-size: 12px;
    line-height: 10px;
    padding: 10px 0px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    .text_content_wrapper_2
    .rating_wrapper
    .rating {
    font-size: 12px;
    line-height: 14px;
    margin-top: 2.5px;
    margin-left: 0.11rem;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    .text_content_wrapper_2
    .rating_wrapper
    .rating-icon
    img {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 769px) {
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .left_side_content_wrapper
    .image_wrapper {
    padding: 1px 0px;
    width: 70px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper {
    margin-left: -26px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    button {
    line-height: 10px;
    padding: 8px 0px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    .right_side_content_wrapper_top
    .image_wrapper
    img {
    width: 20px;
    height: 20px;
  }
  .image_wrapper {
    margin-right: 0px;
  }
  .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    .text_content_wrapper_2
    .rating_wrapper {
    margin-right: -12px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    .text_content_wrapper_2
    .rating_wrapper
    .rating-icon
    img {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 613px) {
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper {
    width: 100%;
  }
  .image_wrapper {
    margin-right: 0px;
  }
  .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2
    .video_collection_view_wrapper
    .video_collection_view_innner_wrapper
    .videos_collection_wrapper
    .user-dashboard-single_video_wrapper
    .right_side_content_wrapper
    .text_content_wrapper_2
    .rating_wrapper {
    margin-right: 0px;
  }
}
@media (max-width: 575.98px) {
  .bookmark_option_collection_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 68px;
  }
  .userBookmarksContentComponent_wrapper {
    padding-top: 50px;

    .userBookmarksContentComponent_inner_wrapper {
      padding: 0px;
    }
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_1
    .bookmark_option_collection_wrapper
    .single_select_bookmark_option
    input[type="radio"]:checked
    + label {
    font-size: 12px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_1
    .bookmark_option_collection_wrapper
    .single_select_bookmark_option
    label {
    padding: 6px 0px;
    font-size: 12px;
  }
  .userBookmarksContentComponent_wrapper
    .userBookmarksContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .sub_content_wrapper_2 {
    margin-top: -42px;
  }
  .sec_2_inner_wrapper .videos_collection_wrapper .single_video_wrapper {
    width: 96%;
  }
}

/* Handle */
.test_question_collection_wrapper::-webkit-scrollbar-thumb {
  background: #b7b7b7;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b7b7b7;
}
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: #b7b7b7;
}
::-webkit-scrollbar {
  width: 7px !important;
}
