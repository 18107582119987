////////No need to subtract scrollbar width in ipad,and mobile screens
.header_component_wrapper {
  width: 100%;
  position: fixed;
  z-index: 999;
  background-color: #ffffff;
  border-bottom: 1px solid #ebebeb;
  .headerTopMenuWrapper {
    background-color: #ebebeb;
    .upper_layer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .header_inner_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    margin: 0px auto;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1200px) {
  .header_left_wrapper .logo {
  }
  .header_component_wrapper {
    .header_inner_wrapper {
      padding: 10px 10px;

      .header_right_wrapper {
        flex: 0 0 26%;
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .header_component_wrapper {
    .header_inner_wrapper {
      padding: 15px 5px;

      .header_right_wrapper {
        flex: 0 0 28%;
      }
    }
  }
}
@media (max-width: 701px) {
  .header_component_wrapper .header_inner_wrapper .header_right_wrapper {
    flex: 0 0 75%;
  }
}
