.topicContentComponent_wrapper {
  background-color: #ffffff;
  padding-top: 70px;
  .topicContentComponent_inner_wrapper {
    min-height: 80vh;
    margin: 20px 30px 0px;
    border-radius: 10px;
    background: rgba(238, 239, 240, 0.5);
    padding: 20px 50px;
    .sec_1_wrapper {
      // margin: 0px 0 15px;
      .sec_1_inner_wrapper {
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          text-align: center;
          width: 100%;
          .text_content {
            // margin-top: -35px;
            margin-bottom: 0px;
            color: #000;
            font-family: "Poppins";
            font-size: 22px;
            font-weight: 500;
            text-align: center;
          }
        }
      }
    }
    .sec_2_wrapper {
      .faculty_tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 20px;
        overflow-x: auto;
        width: 100%;
        margin-bottom: 30px;
        .single_select_facultyName {
          display: -webkit-inline-box;
          // margin-left: 20px;
          // margin-right: 10px;
          margin-right: 20px;
          display: flex;

          &:last-child {
            margin-right: 0px;
          }

          img {
            width: 32px;
            margin-right: 8px;
            border-radius: 50%;
          }

          input[type="radio"] {
            opacity: 0;
            position: fixed;
            width: 0;
          }
          label {
            border: solid 0.8px #f0eef8;
            background: #ffffff;
            border-radius: 8px;
            color: #02262b;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 6px 20px 6px 10px;
            font-family: "Poppins";
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.12px;
          }
        }

        input[type="radio"]:checked + label {
          background-color: #263238;
          border: 0.8px solid #02262b;
          font-weight: 600;
          color: #ffffff;
        }
      }

      .sec_2_inner_wrapper {
        .content_wrapper {
          padding: 20px 10px;
          .left_side_content_wrapper {
            .feature_collection_wrapper {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              background: #f5f4f8;
              width: max-content;
              margin: 0 auto 35px;
              border-radius: 8px;
              overflow: hidden;
              .single_select_feature {
                background-color: #ffffff;
                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }
                label {
                  background-color: #ffffff;
                  color: #585858;
                  font-family: "Poppins";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 10px 27.5px;
                  letter-spacing: 0.2px;
                  cursor: pointer;
                  text-align: center;
                  // min-width: 125px;
                  // margin-right: 15px;
                }
                input[type="radio"]:checked + label {
                  // background-color: #ff933a;
                  border-bottom: 2px solid #b50303;
                  font-weight: 500;
                  color: #b50303;
                }
              }
            }
          }
          .right_side_content_wrappers {
            flex: 0 0 70%;
            padding: 0 0 0 10px;
            ////////////////////videos/////////////////
            .videosCollectionView_wrapper {
              .videosCollectionView_inner_wrapper {
                .sec_1_wrapper {
                  text-align: right;
                  margin: 0;
                  .select_wrapper {
                    select {
                      width: auto;
                      height: 45px;
                      border-radius: 25px;
                      border: 2px solid #6d48ef;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #6d48ef;
                      padding: 0 10px;
                    }
                  }
                }
                .sec_2_wrapper {
                  margin: 10px 0;
                  .sec_2_inner_wrapper {
                    // overflow-y: auto;
                    // height: 350px;
                    .videos_collection_wrapper {
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: flex-start;
                      .single_video_wrapper {
                        width: calc(50% - 10px);
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-radius: 8px;
                        background-color: #ffffff;
                        padding: 15px;
                        margin: 0px 20px 20px 0px;
                        cursor: pointer;
                        &:nth-child(2n + 2) {
                          margin-right: 0px;
                        }
                        .left_side_content_wrapper {
                          width: auto;
                          flex: auto;
                          .image_wrapper {
                            text-align: center;
                            width: 105px;
                            img {
                              border-radius: 4px;
                              width: 105px;
                            }
                          }
                        }
                        .right_side_content_wrappers {
                          flex: auto;
                          // margin: 0 10px;
                          width: 100%;
                          .right_side_content_wrappers_top {
                            display: flex;
                            justify-content: space-between;
                            margin-right: 3px;
                            .text_content_wrapper_1 {
                              .text_content_1 {
                                color: #121212;
                                font-family: "Poppins";
                                font-weight: 600;
                                line-height: normal;
                                letter-spacing: 0.16px;
                                margin: 0;
                                font-size: 16px;
                                margin-bottom: 5px;
                              }
                            }

                            .image_wrapper {
                              width: 28px;

                              img {
                                width: 28px;
                              }
                            }
                          }
                          .text_content_wrapper_2 {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            .text_content_2 {
                              margin: 0;
                              color: #484848;
                              font-family: "Poppins";
                              font-size: 12px;
                              font-weight: 400;
                              line-height: 14px; /* 116.667% */
                              letter-spacing: 0.12px;
                            }
                            label {
                              padding: 5px 5px 0 0;
                            }
                          }
                          .sub_content_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            .left_side_content_wrapper {
                              // flex: 0 0 50%;
                              .label_content_wrapper {
                                label {
                                  padding: 5px 10px;
                                  border-radius: 15px;
                                  background-color: #fef4ec;
                                  span {
                                    img {
                                      width: 15px;
                                      height: 15px;
                                    }
                                  }
                                }
                              }
                            }
                            .right_side_content_wrappers {
                              // flex: 0 0 50%;
                              text-align: right;
                              margin: 0;
                              padding: 5px 0px 0px;
                              width: auto;
                              .subject_name {
                                padding: 5px;
                                border-radius: 5px;
                                background-image: linear-gradient(
                                  120deg,
                                  #ff9600 37%,
                                  #ff5d00 72%
                                );
                                font-size: 12px;
                                font-weight: 600;
                                color: #ffffff;
                              }
                              button {
                                background: #b50303;
                                border-radius: 8px;
                                color: #f5f4f8;
                                font-size: 12px;
                                padding: 12px 20px;
                              }
                            }
                          }
                        }
                      }
                    }
                    .videos_collection_wrapper::-webkit-scrollbar {
                      width: 3px;
                    }
                    .videos_collection_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px #f5f4f8;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .videos_collection_wrapper::-webkit-scrollbar-thumb {
                      background: grey;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .videos_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: #f5f4f8;
                    }
                  }
                }
                .sec_3_wrapper {
                  margin: 10px 0;
                  .sec_3_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 33px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 15px;
                        font-weight: 500;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }
            //////////////live class/////////////////
            .liveClassCollectionView_wrapper {
              .liveClassCollectionView_inner_wrapper {
                .section_wrapper {
                  .section_inner_wrapper {
                    .options_wrapper {
                      .custom_nav_pills {
                        border-radius: 25px;
                        background-color: #ffffff;
                        width: max-content;
                        margin: 20px auto;
                        overflow: hidden;
                        .custom_nav_item {
                          min-width: 125px;
                          .custom_nav_link {
                            width: 100%;
                            border-radius: 25px;
                            border: none;
                            outline: none;
                            font-size: 14px;
                            font-weight: 400;
                            color: #000;
                          }
                          .custom_nav_link.active {
                            color: #ffffff;
                            background-color: #263238;
                            font-weight: 500;
                            border-radius: 0px;
                          }
                        }
                      }
                    }
                    .tab_wrapper {
                      .custom_tab_content {
                        //////upcoming class tab////
                        .custom_tab_pane {
                          .upcoming_class_tab_wrapper {
                            .outer_content_wrapper {
                              .liveClasses_collection_wrapper {
                                display: flex;
                                flex-direction: column;
                                .single_liveClass_wrapper {
                                  width: 100%;
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-start;
                                  border-radius: 8px;
                                  background-color: #faf9fe;
                                  padding: 10px;
                                  margin: 10px 0;

                                  .left_side_content_wrapper {
                                    width: auto;
                                    flex: auto;
                                    .image_wrapper {
                                      text-align: center;
                                      img {
                                      }
                                    }
                                  }
                                  .right_side_content_wrappers {
                                    flex: auto;
                                    margin: 0 10px;
                                    width: 100%;
                                    .text_content_wrapper_1 {
                                      .text_content_1 {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #0f0404;
                                      }
                                    }
                                    .text_content_wrapper_2 {
                                      .text_content_2 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #6f6868;
                                      }
                                    }
                                    .sub_content_wrapper {
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                      width: 100%;
                                      .left_side_content_wrapper {
                                        // flex: 0 0 50%;
                                        .label_content_wrapper {
                                          label {
                                            padding: 5px 10px;
                                            border-radius: 15px;
                                            // background-color: #fef4ec;
                                            span {
                                              img {
                                                width: 15px;
                                                height: 15px;
                                              }
                                            }
                                          }
                                        }
                                      }
                                      .right_side_content_wrappers {
                                        text-align: right;
                                        margin: 0;
                                        padding: 0;
                                        width: auto;
                                        .text_content_wrapper {
                                          .text_content {
                                            margin: 0;
                                            font-size: 14px;
                                            font-weight: 600;
                                            text-decoration: underline;
                                            color: #6d48ef;
                                            cursor: pointer;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .sec_3_wrapper {
                                margin: 10px 0;
                                .sec_3_inner_wrapper {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  .btn_wrapper {
                                    button {
                                      border: none;
                                      outline: none;
                                      width: auto;
                                      height: 45px;
                                      border-radius: 5px;
                                      border: 2px solid #6d48ef;
                                      background-color: #6d48ef;
                                      font-size: 16px;
                                      font-weight: 600;
                                      text-align: center;
                                      color: #ffffff;
                                      padding: 0 10px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        //////previous class tab////
                        .custom_tab_pane {
                          .previous_class_tab_wrapper {
                            .outer_content_wrapper {
                              .liveClasses_collection_wrapper {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: flex-start;
                                .single_liveClass_wrapper {
                                  width: calc(50% - 10px);
                                  display: flex;
                                  align-items: center;
                                  justify-content: flex-start;
                                  border-radius: 8px;
                                  background-color: #ffffff;
                                  padding: 15px;
                                  margin: 0px 10px 20px 0px;
                                  cursor: pointer;
                                  .left_side_content_wrapper {
                                    width: auto;
                                    flex: auto;
                                    .image_wrapper {
                                      text-align: center;
                                      width: 105px;
                                      img {
                                        border-radius: 4px;
                                        width: 105px;
                                      }
                                    }
                                  }
                                  .right_side_content_wrappers {
                                    flex: auto;
                                    margin: 0 10px;
                                    width: 100%;
                                    span {
                                      margin-right: 5px;
                                      p {
                                        display: inline;
                                        display: inline-block;
                                        color: #484848;
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 14px;
                                        margin: 0 10px 0 0;
                                      }
                                      img {
                                        padding-right: 5px;
                                        width: 20px;
                                      }
                                    }
                                    .text_content_wrapper_1 {
                                      .text_content_1 {
                                        margin: 0;
                                        font-size: 16px;
                                        font-weight: 600;
                                        color: #0f0404;
                                      }
                                    }
                                    .text_content_wrapper_2 {
                                      .text_content_2 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: #6f6868;
                                      }
                                    }
                                    .sub_content_wrapper {
                                      display: flex;
                                      align-items: center;
                                      justify-content: space-between;
                                      width: 100%;
                                      .left_side_content_wrapper {
                                        // flex: 0 0 50%;
                                        .label_content_wrapper {
                                          label {
                                            padding: 5px 10px;
                                            border-radius: 15px;
                                            // background-color: #fef4ec;
                                            span {
                                              img {
                                                width: 15px;
                                                height: 15px;
                                              }
                                            }
                                          }
                                        }
                                      }
                                      .right_side_content_wrappers {
                                        // flex: 0 0 50%;
                                        text-align: right;
                                        margin: 0;
                                        padding: 0;
                                        width: auto;
                                        button {
                                          background: #b50303;
                                          border-radius: 8px;
                                          color: #f5f4f8;
                                          font-size: 12px;
                                          padding: 12px 20px;
                                        }
                                        .text_content_wrapper {
                                          .text_content {
                                            margin: 0;
                                            font-size: 14px;
                                            font-weight: 600;
                                            text-decoration: underline;
                                            color: #6d48ef;
                                            cursor: pointer;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              .sec_3_wrapper {
                                margin: 10px 0;
                                .sec_3_inner_wrapper {
                                  display: flex;
                                  justify-content: space-between;
                                  align-items: center;
                                  .btn_wrapper {
                                    button {
                                      border: none;
                                      outline: none;
                                      width: auto;
                                      height: 45px;
                                      border-radius: 5px;
                                      border: 2px solid #6d48ef;
                                      background-color: #6d48ef;
                                      font-size: 16px;
                                      font-weight: 600;
                                      text-align: center;
                                      color: #ffffff;
                                      padding: 0 10px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            /////////exercise////////////////
            .exerciseCollectionView_wrapper {
              .exerciseCollectionView_inner_wrapper {
                .sec_1_wrapper {
                  .options_wrapper {
                    .custom_nav_pills {
                      border-radius: 25px;
                      background-color: #ffffff;
                      width: max-content;
                      margin: 20px auto;
                      overflow: hidden;
                      .custom_nav_item {
                        min-width: 158px;
                        .custom_nav_link {
                          width: 100%;
                          border-radius: 25px;
                          border: none;
                          outline: none;
                          font-size: 14px;
                          font-weight: 400;
                          color: #000;
                        }
                        .custom_nav_link.active {
                          color: #ffffff;
                          background-color: #263238;
                          font-weight: 500;
                          border-radius: 0px;
                        }
                      }
                    }
                  }
                  .sec_1_inner_wrapper {
                    overflow-y: auto;
                    // height: 350px;
                    .exercise_collection_wrapper {
                      width: 100%;
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: flex-start;
                      .single_exercise_wrapper {
                        height: 85px;
                        width: 49%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border: 1px solid #f5f4f8;
                        border-radius: 8px;
                        background-color: #ffffff;
                        padding: 15px 10px;
                        margin: 0px 20px 20px 0px;
                        cursor: pointer;
                        &:nth-child(2n + 2) {
                          margin-right: 0px;
                        }
                        .serial_number_wrapper {
                          border-radius: 15px;
                          background-image: linear-gradient(
                            149deg,
                            #fff5e6 32%,
                            #ffefe6 76%
                          );
                          padding: 15px;
                          .text_content_wrapper {
                            .text_content {
                              margin: 0;
                              font-size: 20px;
                              font-weight: 600;
                              text-align: center;
                              color: #b35557;
                            }
                          }
                        }
                        .exercise_description_wrapper {
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          width: 100%;
                          padding: 0 10px;
                          .exercise_details_wrapper {
                            .text_content_wrapper_1 {
                              .text_content_1 {
                                margin: 0;
                                font-size: 16px;
                                font-weight: 500;
                                color: #000;
                                margin-bottom: 7px;
                              }
                            }
                            .text_content_wrapper_2 {
                              .text_content_2 {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 400;
                                color: #404040;
                              }
                            }
                          }
                          .caret_wraper {
                            .text_content_wrapper {
                              margin: 0;
                              display: flex;
                              align-items: center;
                              span {
                                color: #b50303;
                                font-size: 14px;
                                font-weight: 500;
                                margin-right: 10px;
                                top: -1px;
                                position: relative;
                              }
                              img {
                                width: 24px;
                              }
                            }
                          }
                        }
                      }
                    }
                    .sec_1_inner_wrapper::-webkit-scrollbar {
                      width: 3px;
                    }
                    .sec_1_inner_wrapper::-webkit-scrollbar-track {
                      box-shadow: inset 0 0 5px #f5f4f8;
                      border-radius: 10px;
                    }

                    /* Handle */
                    .sec_1_inner_wrapper::-webkit-scrollbar-thumb {
                      background: grey;
                      border-radius: 10px;
                    }

                    /* Handle on hover */
                    .exercise_collection_wrapper::-webkit-scrollbar-thumb:hover {
                      background: grey;
                    }
                  }
                }
                .sec_2_wrapper {
                  .sec_2_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 33px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 15px;
                        font-weight: 500;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }
            ////////////notes///////////////
            .notesCollectionView_wrapper {
              .notesCollectionView_inner_wrapper {
                .notes_collection_wrapper {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  // height: 350px;
                  overflow-y: auto;
                  .single_notes_wrapper {
                    width: 49%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    border: 1px solid #f5f4f8;
                    border-radius: 8px;
                    background-color: #ffffff;
                    padding: 20px 10px;
                    margin: 0px 20px 20px 0px;
                    cursor: pointer;
                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }
                    .serial_number_wrapper {
                      border-radius: 15px;
                      background-image: linear-gradient(
                        149deg,
                        #fff5e6 32%,
                        #ffefe6 76%
                      );
                      padding: 15px;
                      .image_wrapper {
                        img {
                        }
                      }
                    }
                    .notes_description_wrapper {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      padding: 0 10px;
                      .notes_details_wrapper {
                        .text_content_wrapper_1 {
                          .text_content_1 {
                            margin: 10px 0px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #000;
                          }
                        }
                        .text_content_wrapper_2 {
                          .text_content_2 {
                            margin: 0;
                            font-size: 14px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }
                      }
                      .arrow {
                        img {
                          width: 24px;
                        }
                      }
                      .caret_wraper {
                        .image_wrapper {
                          img {
                          }
                        }
                      }
                    }
                  }
                }
                .notes_collection_wrapper::-webkit-scrollbar {
                  width: 3px;
                }
                .notes_collection_wrapper::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 5px #f4f5f8;
                  border-radius: 3px;
                }

                /* Handle */
                .notes_collection_wrapper::-webkit-scrollbar-thumb {
                  background: grey;
                  border-radius: 10px;
                }

                /* Handle on hover */
                .notes_collection_wrapper::-webkit-scrollbar-thumb:hover {
                  background: grey;
                }
                .sec_3_wrapper {
                  margin: 10px 0;
                  .sec_3_inner_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn_wrapper {
                      button {
                        border: none;
                        outline: none;
                        width: auto;
                        height: 35px;
                        border-radius: 5px;
                        border: 2px solid #6d48ef;
                        background-color: #6d48ef;
                        font-size: 15px;
                        font-weight: 500;
                        text-align: center;
                        color: #ffffff;
                        padding: 0 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.no_data_found_wrapper {
  margin-top: 90px;
  text-align: center;
  margin: 2rem auto 1rem auto !important;

  p {
    color: #000;
    font-size: 16px !important;
  }
  img {
    width: 150px;
    height: 150px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .topicContentComponent_wrapper {
    padding-top: 60px;
    .topicContentComponent_inner_wrapper {
      padding: 20px 10px;
      margin-top: 0;
      .sec_1_wrapper {
        margin: 10px 0;
        .sec_1_inner_wrapper {
          .btn_wrapper {
            button {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
        }
      }
      .sec_2_wrapper {
        margin: 10px 0;
        .sec_2_inner_wrapper {
          .content_wrapper {
            flex-wrap: wrap;
            padding: 10px 0;
            .left_side_content_wrapper {
              flex: 0 0 100%;
              margin: 5px 0;
              .feature_collection_wrapper {
                width: 100%;
                flex-direction: row;
                justify-content: stretch;
                margin-bottom: 20px;
                justify-content: center;
                // .single_select_feature {
                //   // margin: 5px;
                //   flex: 0 0 50%;
                //   input[type="radio"] {
                //   }
                //   label {
                //     display: block;
                //     align-items: unset;
                //     width: 100%;
                //     text-align: center;
                //   }
                //   input[type="radio"]:checked + label {
                //   }
                // }
              }
            }
            .right_side_content_wrappers {
              flex: 0 0 100%;
              margin: 5px 0;
              ////////////////////videos/////////////////
              .videosCollectionView_wrapper {
                .videosCollectionView_inner_wrapper {
                  .sec_1_wrapper {
                    .select_wrapper {
                      select {
                      }
                    }
                  }
                  .sec_2_wrapper {
                    .sec_2_inner_wrapper {
                      .videos_collection_wrapper {
                        .single_video_wrapper {
                          &:nth-child(2n + 2) {
                            margin-right: 15px;
                          }
                          .left_side_content_wrapper {
                            .image_wrapper {
                              img {
                              }
                            }
                          }
                          .right_side_content_wrappers {
                            .text_content_wrapper_1 {
                              .text_content_1 {
                              }
                            }
                            .text_content_wrapper_2 {
                              .text_content_2 {
                              }
                            }
                            .sub_content_wrapper {
                              .left_side_content_wrapper {
                                // flex: 0 0 50%;
                                .label_content_wrapper {
                                  label {
                                    span {
                                      img {
                                      }
                                    }
                                  }
                                }
                              }
                              .right_side_content_wrappers {
                                .subject_name {
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              //////////////live class/////////////////
              .liveClassCollectionView_wrapper {
                .liveClassCollectionView_inner_wrapper {
                  .section_wrapper {
                    .section_inner_wrapper {
                      .options_wrapper {
                        .custom_nav_pills {
                          .custom_nav_item {
                            .custom_nav_link {
                            }
                            .custom_nav_link.active {
                            }
                          }
                        }
                      }
                      .tab_wrapper {
                        .custom_tab_content {
                          //////upcoming class tab////
                          .custom_tab_pane {
                            .upcoming_class_tab_wrapper {
                              .outer_content_wrapper {
                                .liveClasses_collection_wrapper {
                                  .single_liveClass_wrapper {
                                    .left_side_content_wrapper {
                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                    .right_side_content_wrappers {
                                      .text_content_wrapper_1 {
                                        .text_content_1 {
                                        }
                                      }
                                      .text_content_wrapper_2 {
                                        .text_content_2 {
                                        }
                                      }
                                      .sub_content_wrapper {
                                        .left_side_content_wrapper {
                                          .label_content_wrapper {
                                            label {
                                              span {
                                                img {
                                                }
                                              }
                                            }
                                          }
                                        }
                                        .right_side_content_wrappers {
                                          .text_content_wrapper {
                                            .text_content {
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          //////previous class tab////
                          .custom_tab_pane {
                            .previous_class_tab_wrapper {
                              .outer_content_wrapper {
                                .liveClasses_collection_wrapper {
                                  .single_liveClass_wrapper {
                                    .left_side_content_wrapper {
                                      .image_wrapper {
                                        img {
                                        }
                                      }
                                    }
                                    .right_side_content_wrappers {
                                      .text_content_wrapper_1 {
                                        .text_content_1 {
                                        }
                                      }
                                      .text_content_wrapper_2 {
                                        .text_content_2 {
                                        }
                                      }
                                      .sub_content_wrapper {
                                        .left_side_content_wrapper {
                                          .label_content_wrapper {
                                            label {
                                              span {
                                                img {
                                                }
                                              }
                                            }
                                          }
                                        }
                                        .right_side_content_wrappers {
                                          .text_content_wrapper {
                                            .text_content {
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              /////////exercise////////////////
              .exerciseCollectionView_wrapper {
                .exerciseCollectionView_inner_wrapper {
                  .exercise_collection_wrapper {
                    .single_exercise_wrapper {
                      width: 100% !important;
                      margin: 0px 10px 15px 0px !important;
                      .serial_number_wrapper {
                        .text_content_wrapper {
                          .text_content {
                          }
                        }
                      }
                      .exercise_description_wrapper {
                        .exercise_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                            }
                          }
                        }
                        .caret_wraper {
                          .text_content_wrapper {
                            .text_content {
                              i {
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ////////////notes///////////////
              .notesCollectionView_wrapper {
                .notesCollectionView_inner_wrapper {
                  .notes_collection_wrapper {
                    .single_notes_wrapper {
                      width: 100%;
                      margin: 0 10px 15px 0;
                      &:nth-child(2n + 2) {
                        margin-right: 10px;
                      }
                      .serial_number_wrapper {
                        width: 49%;
                        .image_wrapper {
                          img {
                          }
                        }
                      }
                      .notes_description_wrapper {
                        .notes_details_wrapper {
                          .text_content_wrapper_1 {
                            .text_content_1 {
                            }
                          }
                          .text_content_wrapper_2 {
                            .text_content_2 {
                            }
                          }
                        }
                        .caret_wraper {
                          .image_wrapper {
                            img {
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dppCollectionView_wrapper {
  .dppCollectionView_inner_wrapper {
    .sec_1_wrapper {
      overflow-y: auto;
      .dpp_collection_wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .single_dpp_wrapper {
          width: 49%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border: 1px solid #f5f4f8;
          border-radius: 8px;
          background-color: #ffffff;
          padding: 15px 10px;
          margin: 0px 20px 20px 0px;
          cursor: pointer;
          &:nth-child(2n + 2) {
            margin-right: 0px;
          }
          .dpp_description_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 10px;
            .dpp_details_wrapper {
              .text_content_wrapper_1 {
                .text_content_1 {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 500;
                  color: #000;
                  margin-bottom: 7px;
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                  margin: 0;
                  font-size: 14px;
                  font-weight: 400;
                  color: #404040;
                }
              }
            }
            .caret_wraper {
              .text_content_wrapper {
                margin: 0;
                display: flex;
                align-items: center;
                span {
                  color: #b50303;
                  font-size: 14px;
                  font-weight: 500;
                  margin-right: 10px;
                  top: -1px;
                  position: relative;
                }
                img {
                  width: 24px;
                }
              }
            }
          }
        }
        .sec_1_inner_wrapper::-webkit-scrollbar {
          width: 3px;
        }
        .sec_1_inner_wrapper::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #f5f4f8;
          border-radius: 10px;
        }
        /* Handle */
        .sec_1_inner_wrapper::-webkit-scrollbar-thumb {
          background: grey;
          border-radius: 10px;
        }
        /* Handle on hover */
        .sec_1_inner_wrapper::-webkit-scrollbar-thumb:hover {
          background: grey;
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  .topicContentComponent_wrapper .topicContentComponent_inner_wrapper {
    margin: 20px 0px 0px;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1368px !important;
  }
}
@media (max-width: 1155px) {
  .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .sec_1_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper {
    width: 48%;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .notesCollectionView_wrapper
    .notesCollectionView_inner_wrapper
    .notes_collection_wrapper
    .single_notes_wrapper {
    width: 48%;
  }
}
@media (max-width: 1030px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .exerciseCollectionView_wrapper
    .exerciseCollectionView_inner_wrapper
    .sec_1_wrapper
    .sec_1_inner_wrapper
    .exercise_collection_wrapper
    .single_exercise_wrapper {
    width: 48%;
  }
}
@media (max-width: 992px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 994px !important;
  }
  .topicContentComponent_wrapper .topicContentComponent_inner_wrapper {
    margin: 16px 0px 0px;
    padding: 16px 0px;
  }
}

@media (max-width: 821px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .faculty_tabs
    .single_select_facultyName
    label {
    padding: 6px 6px 6px 6px;
    font-size: 10px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .videosCollectionView_wrapper
    .videosCollectionView_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .left_side_content_wrapper
    .image_wrapper
    img {
    width: 70px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .videosCollectionView_wrapper
    .videosCollectionView_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .left_side_content_wrapper
    .image_wrapper {
    width: 70px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .videosCollectionView_wrapper
    .videosCollectionView_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .right_side_content_wrappers
    .right_side_content_wrappers_top
    .text_content_wrapper_1
    .text_content_1 {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .videosCollectionView_wrapper
    .videosCollectionView_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .right_side_content_wrappers
    .text_content_wrapper_2
    .text_content_2 {
    font-size: 11px;
    line-height: 5px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .videosCollectionView_wrapper
    .videosCollectionView_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper {
    padding: 10px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .videosCollectionView_wrapper
    .videosCollectionView_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .right_side_content_wrappers
    .sub_content_wrapper
    .right_side_content_wrappers
    button {
    font-size: 11px;
    padding: 8px 14px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .videosCollectionView_wrapper
    .videosCollectionView_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper
    .right_side_content_wrappers
    .text_content_wrapper_2
    label {
    padding: 2px 5px 0 0;
  }
}
@media (max-width: 798px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .notesCollectionView_wrapper
    .notesCollectionView_inner_wrapper
    .notes_collection_wrapper
    .single_notes_wrapper
    .notes_description_wrapper
    .notes_details_wrapper
    .text_content_wrapper_1
    .text_content_1 {
    margin: 0px 0px;
    font-size: 12px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .notesCollectionView_wrapper
    .notesCollectionView_inner_wrapper
    .notes_collection_wrapper
    .single_notes_wrapper
    .notes_description_wrapper
    .arrow
    img {
    width: 18px;
  }
}
@media (max-width: 765px) {
  .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .sec_1_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper
    .dpp_details_wrapper
    .text_content_wrapper_1
    .text_content_1 {
    font-size: 14px;
  }
  .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .sec_1_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper
    .dpp_details_wrapper
    .text_content_wrapper_2
    .text_content_2 {
    font-size: 12px;
  }
  .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .sec_1_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper
    .caret_wraper
    .text_content_wrapper
    span {
    font-size: 12px;
  }
  .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .sec_1_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper
    .dpp_description_wrapper
    .caret_wraper
    .text_content_wrapper
    img {
    width: 17px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .exerciseCollectionView_wrapper
    .exerciseCollectionView_inner_wrapper
    .sec_1_wrapper
    .sec_1_inner_wrapper
    .exercise_collection_wrapper
    .single_exercise_wrapper
    .exercise_description_wrapper
    .exercise_details_wrapper
    .text_content_wrapper_1
    .text_content_1 {
    font-size: 14px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .exerciseCollectionView_wrapper
    .exerciseCollectionView_inner_wrapper
    .sec_1_wrapper
    .sec_1_inner_wrapper
    .exercise_collection_wrapper
    .single_exercise_wrapper
    .exercise_description_wrapper
    .exercise_details_wrapper
    .text_content_wrapper_2
    .text_content_2 {
    font-size: 12px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .exerciseCollectionView_wrapper
    .exerciseCollectionView_inner_wrapper
    .sec_1_wrapper
    .sec_1_inner_wrapper
    .exercise_collection_wrapper
    .single_exercise_wrapper
    .exercise_description_wrapper
    .caret_wraper
    .text_content_wrapper
    span {
    font-size: 12px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .exerciseCollectionView_wrapper
    .exerciseCollectionView_inner_wrapper
    .sec_1_wrapper
    .sec_1_inner_wrapper
    .exercise_collection_wrapper
    .single_exercise_wrapper
    .exercise_description_wrapper
    .caret_wraper
    .text_content_wrapper
    img {
    width: 18px;
  }
}

@media (max-width: 690px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .faculty_tabs
    .single_select_facultyName
    img {
    width: 25px;
    margin-right: 2px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .left_side_content_wrapper
    .feature_collection_wrapper
    .single_select_feature
    label {
    font-size: 11px;
    padding: 8px 18.5px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .faculty_tabs
    .single_select_facultyName {
    display: -webkit-inline-box;
    margin-right: 4px;
    display: flex;
  }
}
@media (max-width: 665px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .exerciseCollectionView_wrapper
    .exerciseCollectionView_inner_wrapper
    .sec_1_wrapper
    .sec_1_inner_wrapper
    .exercise_collection_wrapper
    .single_exercise_wrapper {
    width: 96%;
  }
}
@media (max-width: 612px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .videosCollectionView_wrapper
    .videosCollectionView_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .videos_collection_wrapper
    .single_video_wrapper {
    width: 96%;
  }
  .dppCollectionView_wrapper
    .dppCollectionView_inner_wrapper
    .sec_1_wrapper
    .dpp_collection_wrapper
    .single_dpp_wrapper {
    width: 96%;
  }
}
@media (max-width: 578px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .left_side_content_wrapper
    .feature_collection_wrapper
    .single_select_feature
    label {
    font-size: 10px;
    padding: 5px 8.5px;
  }
}
@media (max-width: 550px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .faculty_tabs
    .single_select_facultyName
    img {
    width: 19px;
    margin-right: 1px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .faculty_tabs {
    padding: 0px 0px;
    margin-bottom: 15px;
  }
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .exerciseCollectionView_wrapper
    .exerciseCollectionView_inner_wrapper
    .sec_1_wrapper
    .options_wrapper
    .custom_nav_pills
    .custom_nav_item
    .custom_nav_link {
    font-size: 12px;
  }
}
@media (max-width: 532px) {
  .topicContentComponent_wrapper
    .topicContentComponent_inner_wrapper
    .sec_2_wrapper
    .sec_2_inner_wrapper
    .content_wrapper
    .right_side_content_wrappers
    .notesCollectionView_wrapper
    .notesCollectionView_inner_wrapper
    .notes_collection_wrapper
    .single_notes_wrapper {
    width: 96%;
  }
}
