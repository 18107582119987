.more-details-inner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .desktop-layout {
    display: contents;
  }

  #growth {
    color: #797979;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  #years-wrapper {
    .years {
      color: #de422e;
      font-family: "Poppins", sans-serif;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.045rem;
    }

    .year {
      color: #de422e;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .years-text {
      color: #686868;
      font-family: "Poppins", sans-serif;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  #centers-wrapper {
    .center-count {
      color: #de422e;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.045rem;
    }

    .center-text {
      color: #686868;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  #students-count-wrapper {
    .student-count {
      color: #de422e;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.045rem;
    }

    .student-count-text {
      color: #686868;
      font-family: "Poppins", sans-serif;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.mobile-layout {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  .more-details-inner-wrapper #growth {
    font-size: 1.25rem;
  }
  .more-details-inner-wrapper #years-wrapper .years {
    font-size: 1.75rem;
    letter-spacing: 0.035rem;
  }
  .more-details-inner-wrapper #years-wrapper .years-text {
    font-size: 0.875rem;
  }
  .more-details-inner-wrapper #centers-wrapper .center-count {
    font-size: 1.75rem;
    letter-spacing: 0.035rem;
  }
  .more-details-inner-wrapper #centers-wrapper .center-text {
    font-size: 0.875rem;
  }
  .more-details-inner-wrapper #students-count-wrapper .student-count {
    font-size: 1.75rem;
    letter-spacing: 0.035rem;
  }
  .more-details-inner-wrapper #students-count-wrapper .student-count-text {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 768px) {
  .homepage-container .more-details-wrapper {
    padding: 10px 29px 10px 29px !important;
  }
  .more-details-inner-wrapper #growth {
    font-size: 19px;
  }
  .more-details-inner-wrapper #years-wrapper .years {
    font-size: 28px;
  }
  .more-details-inner-wrapper #years-wrapper .years-text {
    font-size: 16px;
  }
  .more-details-inner-wrapper #centers-wrapper .center-count {
    font-size: 28px;
  }
  .more-details-inner-wrapper #centers-wrapper .center-text {
    font-size: 16px;
  }
  .more-details-inner-wrapper #students-count-wrapper .student-count {
    font-size: 28px;
  }
  .more-details-inner-wrapper #students-count-wrapper .student-count-text {
    font-size: 16px;
  }
}
@media screen and (max-width: 560px) {
  .desktop-layout {
    display: none !important;
  }
  #growth {
    display: none;
  }
  .mobile-layout {
    display: block !important;
  }
  .homepage-container .more-details-wrapper {
    padding: 10px 0px 10px 0px !important;
  }

  .growth {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
  }
  .mobile-layout-growth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px 17px 20px;
  }
  .stat-number span {
    color: #de422e;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.03rem;
  }
  .homepage-container .more-details-wrapper {
    width: 100%;
    height: 10.5rem;
    flex-shrink: 0;
    background: #f4f4f4;
  }
}
@media screen and (max-width: 376px) {
  .stat-number span {
    font-size: 1rem;
  }
  .stat-sublabel {
    font-size: 0.9rem;
  }
  .stat-label {
    font-size: 0.9rem;
  }
  .mobile-layout-growth {
    padding: 12px 10px 12px 10px;
  }
}
