.QuestionWiseAnalysisContentComponentWrapper {
  width: 90%;
  margin: auto;
  .subjects_tab_wrapper {
    width: 100%;

    .outer_content_wrapper {
      .content_wrapper {
        // padding: 20px 0;

        .left_side_content_wrapper {
          text-align: -webkit-center;
          margin-bottom: 25px;
          text-align: center;

          .subject_type_collection_wrapper {
            display: inline-block;
            background-color: #fff;
            border-radius: 25px;
            border-radius: 1.5rem;
            border: 0.6px solid #e0e0e0;
            overflow: hidden;

            .single_select_subject_type {
              width: auto;
              min-width: 160px;
              display: inline-block;
              background: #fff !important;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                padding: 0.5rem 1rem;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="radio"]:checked + label {
                background-color: #263238;
                color: #ffffff;
              }
            }
          }
        }

        .right_side_content_wrapper {
          flex: 0 0 65%;

          .questionWiseCards {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 70%;
            margin: auto;
            margin-bottom: 20px;
            .questionWiseCard {
              width: 30%;

              border-radius: 0.375rem;
              border: 0.953px solid #e8e8e8;
              background: linear-gradient(0deg, #fff 0%, #fff 100%) lightgray
                0px -24.926px / 123.73% 155.055% no-repeat;
              box-shadow: 2.383px 2.383px 9.532px 0px rgba(0, 0, 0, 0.08);
              display: flex;
              align-items: center;
              flex-direction: flex-start;

              padding: 1rem 0 1rem 1rem;

              .cardLeft {
                img {
                  width: 3rem;
                  height: 3rem;
                  margin-right: 0.5rem;
                }
              }
              .cardRight {
                .text {
                  color: #828282;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
                .num {
                  font-family: Poppins;
                  font-size: 1.5rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.015rem;
                }
                .marks {
                  color: #02929a;
                }
                .percentage {
                  color: #02519a;
                }
                .rank {
                  color: #9a7902;
                }
              }
            }
          }

          .table_section {
            border-radius: 6px;
            margin-bottom: 50px;
            border: 1px solid #c2c2c2;

            table {
              margin: auto;

              thead {
                background: #fff7d7;
                th {
                  color: #121212;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.01rem;
                  padding: 1rem 1.8rem 1rem 1.8rem;
                  border-bottom: none !important;
                }
              }

              tbody {
                tr {
                  td {
                    color: #000;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 1rem 1.8rem 1rem 1.8rem;
                    border-bottom: 1px dashed #fffdf5;
                    background: #fffdf5;
                  }

                  .start {
                    color: #373737;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
              }
            }
          }

          .questionDetails {
            border-radius: 0.75rem;
            border: 0.8px solid #ddd;
            background: #fff;
          }
        }
      }
    }
  }
}
