.SupportCenterInfoWrpr {
  max-width: 41%;
  margin: auto;
  // margin-top: 55px;
  .img-wrapper {
    text-align: center;
    img {
      width: 169px;
    }
    .text {
      color: #000;
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.18px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  .form-section {
    // margin-top: 35px;
    // small {
    //   font-family: Poppins;
    //   font-size: 14px;
    //   font-weight: 400;
    //   line-height: 18px;
    //   letter-spacing: 0em;
    //   color: #4e4e4e;
    // }
    p {
      width: 100%;
      height: 45px;
      border-radius: 6px;
      border: none;
      background: #ffffff;
      padding: 12px 10px;
      color: #121212;
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 20px;
    }
    .rollnum {
      // margin-bottom: 20px;
    }
    button {
      border-radius: 4px;
      border: 0.6px solid #B91311;
      background: #B50503;
      color: #FFF;
      padding: 12px 16px;
      font-family: "Poppins";
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      width: 100%;
      margin-top: 30px;
        }
  }
}
