.parent_sub_sub_section_wrapper_5 {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.8px solid #f2f2f2;
  padding: 15px 40px;
  background-color: #fff;
  width: 66%;
  margin-top: -41px;
  button {
    border-radius: 4px;
    border: 0.6px solid #b91311;
    background: #b91311;
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 136px;
    padding: 10px 20px;
  }
}
