.otsSubjectWiseMarksWrapper {
    background-color: #ffffff;
    min-height: 100vh;
  
    .subjectWiseMarksInrWrapper {
      padding-top: 101px;
      padding-bottom: 20px;
  
      .sbjtWiseMarksInr {
        border-radius: 1rem;
        background: rgba(238, 239, 240, 0.50);
        padding: 1.75rem 1.5rem;
        width: 78.75%;
        margin: auto;
        min-height: 45rem;
  
        .backBtn {
          cursor: pointer;
  
          img {}
        }

        h3 {
          color: #000;
          font-family: "Poppins";
          font-size: 1.375rem;
          font-weight: 500;
          line-height: normal;
          margin: 0 auto 1.25rem;
        }
  
        .difficultyLevel {
  
          .difficultyLevelTab {
            .difficultyLevelTabBox {
              display: flex;
              max-width: max-content;
              margin: 0 auto 2.5rem;
              border-radius: 1.5rem;
              border: 0.6px solid #E0E0E0;
              background: #FFF;
              overflow: hidden;
  
              .single_select_subject {
                &:last-child {
                  margin-right: 0px;
                }
  
                input[type="radio"] {
                  opacity: 0;
                  position: fixed;
                  width: 0;
                }
  
                label {
                  color: #585858;
                  font-family: "Poppins";
                  font-size: 0.875rem;
                  font-weight: 400;
                  line-height: normal;
                  padding: 0.62rem 1.25rem;
                  cursor: pointer;
                  min-width: 110px;
                  text-align: center;
                }
  
                input[type="radio"]:checked+label {
                  background: #263238;
                  font-weight: 500;
                  border: none;
                  color: #ffffff;
                }
              }
            }
  
            .difficultyLevelcontent {
              display: flex;
              flex-wrap: wrap;
  
              .difficultyData {
                width: calc(50% - 0.625rem);
                margin-right: 1.25rem;
                border-radius: 0.5rem;
                border: 0.8px solid #E9E9E9;
                background: #FFF;
                height: max-content;

                &:last-child {
                  margin-right: 0;
                }
  
                ul {
                  padding: 2rem 1.5rem 0 0.5rem;
                  margin: 0px;
  
                  li {
                    list-style: none;
                    margin-bottom: 1.8rem;
  
                    span {
                      width: 25%;
                      display: inline-block;
                      color: #585858;
                      font-family: "Poppins";
                      font-size: 0.875rem;
                      font-weight: 500;
                      line-height: normal;
                      text-align: center;
                    }
                  }
  
                  .titleData {
                    span {
                      color: #585858;
                      font-family: "Poppins";
                      font-size: 0.875rem;
                      font-weight: 400;
                      line-height: normal;
                    }
                  }
                }
              }
  
              .scorePercentageBox {
                width: calc(50% - 15px);
                display: flex;
                align-items: center;
                border: 1px solid #eeeeee;
                border-radius: 8px;
                background: #f5f4f8;
                margin-bottom: 30px;
                margin-left: 30px;
  
                &:nth-child(2n+1) {
                  margin-left: 0px;
                }
  
                .scoreChart {
                  width: 150px;
                  margin: 40px 20px;
  
                  .CircularProgressbar .CircularProgressbar-path {
                    stroke: #FCB21D;
                  }
                }
  
                .mediumResult {
                  .CircularProgressbar .CircularProgressbar-path {
                    stroke: #6D48EF;
                  }
                }
  
                .hardResult {
                  .CircularProgressbar .CircularProgressbar-path {
                    stroke: #4DD7D8;
                  }
                }
  
                .scoreDetail {
                  p {
                    font-size: 14px;
                    margin-bottom: 10px;
                    font-weight: 600;
                    padding-right: 15px;
                  }
  
                  .percentageDiv {
                    font-size: 12px;
                    margin-bottom: 5px;
  
                    span {
                      background-color: #E2E2E2;
                      width: 8px;
                      height: 8px;
                      border-radius: 50%;
                      display: inline-block;
                      margin-right: 8px;
                    }
                  }
  
                  .gotPrcntg {
                    span {
                      background-color: #FCB21D;
                    }
                  }
  
                  .medium {
                    span {
                      background-color: #6D48EF;
                    }
                  }
  
                  .hard {
                    span {
                      background-color: #4DD7D8;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 575.98px) {
    .otsSubjectWiseMarksWrapper {
        .subjectWiseMarksInrWrapper {
          padding-top: 46px;
          .sbjtWiseMarksInr {
            margin: 10px 0 0px;
          }
          .sbjtWiseMarksInr{
            .difficultyLevel {
              .difficultyLevelTab {
                .difficultyLevelTabBox{
                  max-width: none;
                  flex-wrap: wrap;
                  .single_select_subject{
                    width: 50%;
                    label{
                      min-width: -webkit-fill-available;
                    }
                  }
                }
                .difficultyLevelcontent{
                  flex-wrap: nowrap;
                  flex-direction: column;
                  .difficultyData{
                    width: 100%;
                    padding: 10px;
                    margin-bottom: 20px;
                    .type_list {
                      .list{
                        max-width: none;
                        flex-wrap: wrap;
                        // border: 1px solid #6d48ef;
                        .single_select_subject{
                          width: 50%;
                          &:last-child{
                            width: 100%;
                          }
                          label{
                            min-width: -webkit-fill-available;
                          }
                        }
                      }
                    }
                    ul{
                      padding: 0px;
    margin: 0px;
    overflow-y: scroll;
    li{
      display: flex;
      gap: 1rem;
    }
                    }
                  }
                }
              }
            }
          } 
        }
      }
  }
  