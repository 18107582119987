.pyq_detail_wrapper {
  .jee-mains-adv {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .jee-mains-adv-wrapper {
      display: flex;
      justify-content: center;
      gap: 1rem;
      border-radius: 0.5rem;
      border: 0.6px solid var(--border);
      background: var(--white);
      overflow: hidden;
      .jee-btn {
        padding: 7px 20px;
        border-bottom: 2px solid transparent;
        font-weight: 500;
        color: var(--text-gray-400);
      }
      .active {
        border-bottom: 2px solid var(--red) !important;
        color: var(--red);
      }
    }
    input {
      margin-right: 10px;
      cursor: pointer;
    }

    input[type="radio"]:checked + label {
      color: #6d48ef;
    }

    label {
      margin-right: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .pyqTabBox {
    margin-bottom: 15px;
    // margin-left: 300px;
    display: flex;
    justify-content: center;
    gap: 2rem;
    position: relative;

    ul {
      border: none;
      border: 0.6px solid var(--border);
      background: var(--white);
      // margin: 0 auto;
      width: max-content;
      // background-color: #f5f4f8;
      border-radius: 22px;
      overflow: hidden;

      li {
        border: none;

        button {
          border: none;
          color: var(--text-gray-400);
          font-size: 15px;
          min-width: 120px;
          border-radius: 0px;
          min-width: 190px;
        }

        .nav-link.active {
          color: #fff;
          background-color: var(--bg-dark);
          border: none;
        }
      }
    }

    .pyq_filter_dropdown {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100px;
      position: absolute;
      top: 0;
      right: 0;

      select {
        float: right;
        margin-right: 20px;
        border: 0px;
        outline: 0px;
        padding: 5px 20px 5px 20px;
        border: 1px solid var(--red);
        border-radius: 0.5rem;
        background: #fff;
        option {
          margin-right: 30px;
        }
      }
    }
  }

  .pyqTabContent {
    height: 65vh;
    padding: 15px 15px;
    overflow: auto;

    .upcomingExamWrapper {
      display: flex;
      flex-wrap: wrap;

      .upcomingExamBox {
        border-radius: 0.5rem;
        border: 0.8px solid var(--border);
        background: var(--white);
        padding: 15px;
        margin: 0px 16px 16px 0px;
        width: calc(50% - 8px);

        &:nth-child(2n + 2) {
          margin-right: 0px;
        }

        .pyqNameType {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

          .data-btn1 {
            display: inline-block;
            font-size: 0.7rem;
            padding: 0.1rem 0.5rem;
            border-radius: 4px;
            border: 0.6px solid var(--border-lime);
            color: var(--border-lime);
          }
          .data-btn2 {
            display: inline-block;
            font-size: 0.7rem;
            padding: 0.1rem 0.5rem;
            border-radius: 4px;
            border: 0.6px solid #bbbbbb;
            // color: #bbbbbb;
          }

          .pyqName {
            width: 68%;
            display: flex;
            align-items: center;

            img {
              border-radius: 50%;
              width: 25px;
              height: 25px;
              margin-right: 8px;
            }

            span {
              font-size: 14px;
              font-weight: 500;
              display: inline-block;
              line-height: 18px;
              color: var(--text-dark);
            }
          }

          .examDate {
            color: #929292;
            font-size: 12px;
            width: 32%;
            text-align: right;
          }
        }

        .pyqNameDateDuration {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 10px;

          .pyqNameDate {
            p {
              color: var(--text-gray-400);
              font-size: 12px;
              margin-bottom: 0;
            }
          }
        }

        .attemBtn {
          display: flex;

          a {
            text-align: center;
            text-decoration: none;
            margin-right: 15px;
          }

          button {
            margin-right: 15px;

            &:last-child {
              margin-right: 0px;
            }
          }

          .attempt {
            background: var(--red);
            border-radius: 19px;
            color: #fff;
            font-size: 16px;
            width: 100%;
            padding: 5px 10px;
            width: calc(65% - 15px);
            margin-right: 15px;
          }

          .practice {
            font-size: 15px;
            font-weight: 500;
            color: #000;
            border: 1px solid var(--border);
            border-radius: 19px;
            background-color: #f6f6f7;
            padding: 5px 10px;
            width: 35%;
          }
        }
      }
    }

    .topicWiseWrapper {
      display: flex;
      flex-wrap: wrap;

      .topicBox,
      .daysBox {
        // background: #f5f4f8;
        border-radius: 8px;
        border: 0.8px solid var(--border);
        background: var(--white);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(50% - 8px);
        margin: 0px 16px 16px 0px;
        cursor: pointer;

        &:nth-child(2n + 2) {
          margin-right: 0px;
        }

        .topicNameImg {
          .img_wrp {
            display: inline-block;
            width: 32px;
            margin-right: 10px;

            img {
              width: 100%;
              font-size: 12px;
            }
          }

          h5 {
            display: inline-block;
            margin: 0px;
          }
        }

        .arrow {
          .arrow_image {
            width: 10px;
          }
          .lock_image {
            width: 14px;
          }
        }
      }

      .topicBox {
        padding: 15px;
      }

      .daysBox {
        padding: 24px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .pyqCpsExam_wrapper .pyqCpsExam_InrWrapper .container .pyqCPsExam_contentWpr {
    margin: 0 0px;
  }
  .pyq_detail_wrapper .pyqTabBox .pyq_filter_dropdown select {
    padding: 2px 10px 2px 10px;
  }
  .pyq_detail_wrapper .jee-mains-adv .jee-mains-adv-wrapper .jee-btn {
    padding: 7px 20px;
    font-size: 12px;
  }
  .pyq_detail_wrapper .jee-mains-adv .jee-mains-adv-wrapper .active {
    font-size: 12px;
  }
  .pyq_detail_wrapper {
    .pyqTabContent {
      .upcomingExamWrapper {
        .upcomingExamBox {
          padding: 10px;
          margin: 0px 12px 12px 0px;
          width: calc(50% - 6px);

          .pyqNameType {
            flex-direction: column;
            margin-bottom: 10px;

            .pyqName {
              width: 100%;

              span {
                font-size: 12px;
                line-height: 17px;
              }
            }

            .examDate {
              width: 100%;
              font-size: 11px;
            }
          }

          .attemBtn {
            .practice {
              font-size: 12px;
              padding: 5px 6px;
            }

            .attempt {
              font-size: 12px;
            }
          }

          .pyqNameDateDuration {
            margin-bottom: 5px;

            .pyqNameDate {
              p {
                font-size: 12px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .pyqTabBox {
      .pyq_filter_dropdown {
        position: static;
      }
      ul {
        li {
          button {
            min-width: 85px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .pyq_detail_wrapper .pyqTabBox .pyq_filter_dropdown select {
    padding: 2px 10px 2px 10px;
  }
  .pyq_detail_wrapper .jee-mains-adv .jee-mains-adv-wrapper .jee-btn {
    padding: 7px 20px;
    font-size: 12px;
  }
  .pyq_detail_wrapper {
    .pyqTabContent {
      min-height: auto;
      padding: 10px 0px;

      .upcomingExamWrapper {
        .upcomingExamBox {
          margin: 0px 0px 12px 0px;
          width: 100%;

          .pyqNameType {
            .pyqName {
              span {
              }
            }

            .examDate {
            }
          }

          .attemBtn {
            .practice {
            }

            .attempt {
            }
          }

          .pyqNameDateDuration {
            .pyqNameDate {
              p {
              }
            }
          }
        }
      }

      .topicWiseWrapper {
        .topicBox {
          padding: 10px;
          width: 100%;
          margin: 0px 0px 10px 0px;
        }
      }
    }
  }
}
@media (max-width: 620px) {
  .pyq_detail_wrapper
    .pyqTabContent
    .upcomingExamWrapper
    .upcomingExamBox
    .pyqNameType
    .pyqName
    span {
    font-size: 10px;
  }
  .pyq_detail_wrapper
    .pyqTabContent
    .upcomingExamWrapper
    .upcomingExamBox
    .attemBtn
    .attempt {
    font-size: 10px;
  }
  .pyq_detail_wrapper
    .pyqTabContent
    .upcomingExamWrapper
    .upcomingExamBox
    .attemBtn
    .practice {
    font-size: 10px;
  }
  .pyq_detail_wrapper
    .pyqTabContent
    .upcomingExamWrapper
    .upcomingExamBox
    .attemBtn
    button {
    margin-right: 5px;
  }
}

@media (max-width: 510px) {
  .pyq_detail_wrapper .pyqTabBox ul li button {
    min-width: 33px;
    font-size: 10px;
  }
}
