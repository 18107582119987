.modal {
  .my-modal-2 {
    max-width: 51.5%!important;
    width: 51.5%!important;

    .modal-header {
      border: none;
      position: relative;

      h3 {
        color: #121212;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.16px;
        margin-top: 25px;
      }

      .btn-close {
        opacity: 1;
        position: absolute;
        top: 15px;
        right: 15px;
      }
    }

    .modal-body {
      padding-top: 0px!important;
      padding-bottom: 0px!important;

      .ots-syllabus-modal-wrapper {
        border-radius: 4px;
        border: 1px solid #C6C6C6;
        text-align: center;
        margin: 0px 5px 20px;
  
        .ots-syllabus-modal_header {
          color: #121212;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          border: none;
          padding: 20px 0;
        }

        ul {
          margin: 0 0 20px 0;
          padding: 0px;
  
          li {
            list-style: none;
            margin-bottom: 10px;
            text-align: start !important;
            margin-left: 50px;
            color: #121212;
            font-family: "Poppins";
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            width: 100%;
          }
          span {
            font-weight: 500;
          }
        }
      }

      table {
        width: calc(100% - 10px);
        margin: 0 5px;
        text-align: center;
        border-collapse: collapse;
        background: #ffffff;
        border-radius: 4px;
        border: 0.6px solid #C6C6C6;
  
        th {
          color: #000;
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border: 0.6px solid #C6C6C6;
          padding: 8px;
        }
        td {
          width: 135px;
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 10px;
          color: #3A3A3A;
          border: 0.6px solid #C6C6C6;
        }
      }
    }

    .modal-footer-btn {
      text-align: center;

      button {
        width: 200px;
        margin: 40px auto;
        padding: 12px 14px;
        border-radius: 4px;
        background: #B50503;
        color: #FFF;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .modal {
    .my-modal {
      .ots-syllabus-modal-wrapper {
        margin: 0px 0 20px 0;
        ul{
          li{
            margin-left: 0;
            padding-left: 5px;
          }
        }
      }}}
}
