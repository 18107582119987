.test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .questionListPanel {
            padding: 20px;
            position: sticky;
            top: 0px;
            width: 100%;
            width: 70%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            padding: 0px;
            box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06);
            z-index: 10;
            background-color: #ffffff;
            .sub_section_wrapper {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              margin: 0 40px;
            }
          }
        }
      }
    }
  }
}
