.pay_now {
  background: #059249;
  letter-spacing: 0.36px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  padding: 8px 10px;
  width: 100%;
  a {
    background: #059249;
    letter-spacing: 0.36px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    padding: 8px 10px;
    width: 100%;
    display: inline-block;
    text-decoration: none;
  }
}

.store_paynow {
  width: 100%;
  color: #fff;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 0.5rem;
  background: #b50503;
  padding: 0.75rem 0;
}
