.bitsat_questions_solution_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;
          .section_content_wrapper_backdrop {
            display: none;
          }

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: stretch;

          .left_side_content_wrapper {
            width: 25%;
            background-color: #ffffff;
            position: "sticky";
            top: 0;
            height: 100vh;
            overflow: hidden;

            .sub_section_1_wrapper {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .closebtn {
                display: none;
              }
              .submitBtn {
                background: #6d48ef;
                border-radius: 4px;
                padding: 0.3rem 0.6rem;
                text-decoration: none;
                font-weight: 500;
                font-size: 1rem;
                width: 100%;
                color: #ffffff;
              }
              .text_content_wrapper {
                margin-bottom: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 1rem;
                border-bottom: 1px solid #d1d1d1;

                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #0f0404;
                }
                button {
                  border-radius: 4px;
                  padding: 0.3rem 0.6rem;
                  text-decoration: none;
                  font-weight: 500;
                  font-size: 0.8rem;
                  border-radius: 0.25rem;
                  border: 1px solid #646464;
                  background: #fff;
                  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
                }
                .countdown_timer_container {
                  span {
                    label {
                      color: #6d48ef;
                      font-size: 15px;
                    }
                  }
                }
              }

              .questions_serial_number_collection {
                font-family: "Poppins";
                overflow-y: scroll;
                overflow-y: auto;
                max-height: 79vh;
                padding: 1rem;
                margin-bottom: 0.5rem;
                background: #f6f6f7;
                text-align: center;

                &::-webkit-scrollbar {
                  width: 5px;
                }

                &::-webkit-scrollbar-track {
                  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                  border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
                }
                .single_question_number_wrapper1 {
                  display: block !important;
                  margin: 0.875rem 5px !important;
                  .unanswered_lbl1 {
                    align-items: baseline;
                    overflow: hidden;
                    display: flex !important;
                    gap: 0.5rem;
                    align-items: center !important;
                    justify-content: space-between !important;
                    .questionBox1 {
                      display: flex !important;
                      p {
                        overflow: visible;
                        display: block !important;
                      }
                      .QBox {
                        display: inline-block;
                        text-align: start;
                      }
                    }
                  }
                }
                .single_question_number_wrapper {
                  margin: 5px;
                  display: inline-block;

                  .arrow {
                    width: 8px;
                  }

                  .unanswered_lbl,
                  .unAttempted {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    padding: 8px;
                    border-radius: 0.25rem;
                    border: 1px solid #d9d9d9;
                    background: #fff;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 40px;
                    &.incorrect_answered_lbl {
                      border: 1px solid #ffc4c4;
                      background-color: #fed5d5;
                    }

                    &.current_lbl {
                      border: 1px solid #ff933a;
                      cursor: pointer;
                      background: inherit;
                    }

                    &.correct_answered_lbl {
                      border: 1px solid #8ae09f;
                      background-color: #c8f1d2;
                    }
                    .questionBox1 {
                      display: flex !important;
                      p {
                        overflow: visible;
                        display: block !important;
                      }
                      .QBox {
                        display: inline-block;
                        text-align: start;
                      }
                    }

                    .questionBox {
                      display: flex;
                      align-items: baseline !important;
                      overflow-x: hidden;
                      margin-right: 0;
                      height: 100%;

                      p {
                        margin-left: 2px;
                        margin-bottom: 0px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;

                        img {
                          display: none;
                        }

                        table {
                          width: 100% !important;
                        }
                      }
                      .QBox {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;

                        .wrs_chemistry {
                          display: block;
                          margin: 20px auto;
                          font-family: Arial, sans-serif;
                          font-size: 14px;
                          overflow-x: auto;

                          mtable {
                            width: 100%;
                            border-collapse: collapse;
                            border: 1px solid #ccc;

                            mtr {
                              &:nth-child(even) {
                                background-color: #f5f5f5;
                              }

                              mtd {
                                padding: 5px;
                                border: 1px solid #ccc;
                                vertical-align: middle;
                                text-align: center;

                                mtext {
                                  display: inline-block;
                                }

                                mtable {
                                  margin-top: 5px;
                                  width: 100%;

                                  mtr {
                                    mtd {
                                      padding: 5px;
                                      border: 1px solid #ccc;
                                      vertical-align: middle;
                                      text-align: center;
                                      display: inline-block;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }

                        .wrs_chemistry::-webkit-scrollbar {
                          width: 4px;
                        }

                        .wrs_chemistry::-webkit-scrollbar-track {
                          background-color: #f1f1f1;
                        }

                        .wrs_chemistry::-webkit-scrollbar-thumb {
                          background-color: #888;
                          border-radius: 2px;
                        }

                        .wrs_chemistry math,
                        .wrs_chemistry mo,
                        .wrs_chemistry mi,
                        .wrs_chemistry mn,
                        .wrs_chemistry msub,
                        .wrs_chemistry mtext,
                        .wrs_chemistry mfenced,
                        .wrs_chemistry msup,
                        .wrs_chemistry mrow {
                          display: inline;
                          height: 20px;
                        }

                        .tableContainer {
                          max-height: 200px;
                          max-height: 200px; /* Adjust the max-height as per your requirements */
                          overflow-y: auto;
                          overflow-x: auto;

                          ::-webkit-scrollbar {
                            width: 4px !important;
                            height: 2px !important;
                          }

                          ::-webkit-scrollbar-track {
                            background-color: #f1f1f1;
                          }

                          ::-webkit-scrollbar-thumb {
                            background-color: #888;
                            border-radius: 4px;
                          }

                          ::-webkit-scrollbar-thumb:hover {
                            background-color: #555;
                          }
                        }

                        .tableContainer {
                          table {
                            width: 50%;
                            table-layout: fixed;

                            td {
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            }
                          }
                        }

                        .tableContainer::-webkit-scrollbar {
                          width: 4px !important;
                          height: 4px !important;
                        }

                        img {
                          width: 100%;
                          max-height: 100px;
                          object-fit: cover;
                        }
                        &.twoLines {
                          p {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            margin-left: 5px;
                          }
                        }

                        p {
                          margin-left: 2px;
                          margin-bottom: 0px;
                        }
                        math {
                          display: inline-block;
                        }

                        img {
                          display: none;
                        }

                        table {
                          width: 100% !important;
                        }

                        p > p {
                          display: inline;
                          margin: 0;
                        }
                      }
                    }
                  }

                  .answered_lbl {
                    border: 1px solid #e2e2e2;
                    background-color: #fff;
                  }
                  .unanswered_lbl1 {
                    align-items: baseline;
                    overflow: hidden;
                    display: flex !important;
                    gap: 0.5rem;
                    align-items: center !important;
                    justify-content: space-between !important;
                    .questionBox1 {
                      display: flex !important;
                      p {
                        overflow: visible;
                        display: block !important;
                      }
                      .QBox {
                        text-align: start;
                      }
                    }
                  }

                  .review_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    padding: 10px;
                    border-radius: 8px;
                    background: #fed5d5;
                    border: 1px solid #ffc4c4;
                    cursor: pointer;
                  }

                  .skip_lbl {
                    border: 1px solid #ffc4c4;
                    background-color: #fed5d5;
                  }

                  .current_lbl {
                    border: 1px solid #ff933a;
                    cursor: pointer;
                    background: inherit;
                  }
                }
              }
            }

            .sub_section_2_wrapper {
              margin-bottom: 0.5rem;
              padding: 0.5rem 1rem;

              .question_marking_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding: 5px;

                .single_marking {
                  display: flex;
                  align-items: center;
                  flex: 1 1 50%;
                  margin-bottom: 5px;

                  &:last-child {
                    margin-right: 0px;
                  }

                  .box_pic {
                    width: 14px;
                    height: 14px;
                    border-radius: 3px;
                    margin-right: 8px;
                  }

                  .box_pick_background_1 {
                    border: 1px solid #8ae09f;
                    background: #c8f1d2;
                  }

                  .box_pick_background_2 {
                    background-color: #f99746;
                  }

                  .box_pick_background_3 {
                    border: 1px solid #d9d9d9;
                    background: #fff;
                  }

                  .box_pick_background_4 {
                    border: 1px solid #ffa68c;
                    background: #ffc5b4;
                  }

                  .question_marking_text {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    color: #000000;
                  }
                }
              }
            }
          }

          .right_side_content_wrapper {
            width: 75%;
            border-radius: 0.5rem;
            padding: 1rem 3rem;
            background-color: #ffffff;
            box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06);
            z-index: 10;
            .right_btn_wrapper {
              display: none;
            }

            .sub_section_wrapper {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .videoWrapper {
                .view_videoSolution_btn {
                  background-color: #263238 !important;
                  border-radius: 0.25rem;
                }
              }
              .video-container {
                border-radius: 0.25rem;
              }

              .sub_sub_section_wrapper_1 {
                .sub_sub_section_wrapper_1_card {
                  background-color: #263238;
                  padding: 0.5rem 1rem;
                  border-radius: 8px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                  color: var(--white);
                  margin-bottom: 0.5rem;
                  .countdown_timer_container {
                    color: #f7b201;
                    font-weight: 600;
                  }
                  p {
                    margin-bottom: 0;
                    letter-spacing: 0.01rem;
                  }
                  .markReview {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    .m2_language {
                      color: var(--white);
                      font-weight: 700;
                      width: 25px;
                    }
                    .BookmarkBtn {
                      cursor: pointer;
                    }
                  }
                }
                h2 {
                  text-align: center;
                  font-size: 1.5rem;
                  font-weight: bold;
                  margin-bottom: 0.6rem;
                }

                .right_side_content_wrapper {
                  flex: 0 0 100%;
                  display: flex;
                  justify-content: flex-end;
                  .right_btn_wrapper {
                    display: none;
                  }

                  .review_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }

                  .bookmark_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }
                }
              }

              .testProgressIndicator {
                background: #fff;
                padding: 15px;
                border-radius: 8px;
                margin-bottom: 40px;

                h4 {
                  font-size: 14px;
                  color: #000;
                  margin-bottom: 16px;
                }

                .ProgressBar_Line {
                  position: relative;
                }

                .ProgressBar_Line span {
                  position: absolute;
                  top: -16px;
                  font-size: 14px;
                }

                .progress {
                  height: 8px;
                  margin-bottom: 5px;

                  .progress-bar {
                    background: #ff933a;
                    border-radius: 9px;
                  }
                }

                .questionPercentage {
                  display: flex;
                  justify-content: space-between;

                  span {
                    color: #707070;
                    font-size: 14px;
                  }
                }
              }
              .sectionWiseTestWrapper {
                .subjectWiseTest_heading {
                  color: #121212;
                  font-size: 0.875rem;
                  font-weight: 500;
                  margin: 0 !important;
                  margin-bottom: 1.5rem !important;
                  span {
                    font-weight: 400;
                    color: #5e5e5e;
                  }
                }
                .subjectWiseTest_year {
                  border-radius: 8px;
                  border: 0.6px solid #cecece;
                  padding: 0.5rem 1rem;
                  line-height: 1;
                  background: #f6f6f7;
                }
                .subjectWiseTest {
                  border-radius: 0.5rem;
                  border: 0.6px solid #cecece;
                  background: #f6f6f7;
                  max-width: -moz-fit-content;
                  max-width: fit-content;
                  display: flex;
                  padding: 4px;
                  justify-content: space-between;
                  align-items: center;

                  .single_select_subject {
                    input[type="radio"] {
                      opacity: 0;
                      position: fixed;
                      width: 0;

                      &:checked + label {
                        background-color: #263238;
                        border: none;
                        color: #ffffff;
                        border-radius: 0.25rem;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 23px;
                      }
                    }

                    label {
                      padding: 3px;
                      font-size: 15px;
                      letter-spacing: 0.2px;
                      color: #6b6876;
                      cursor: pointer;
                      min-width: 150px;
                      text-align: center;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                    }
                  }
                }

                .sectionWiseTest {
                  display: flex;
                  width: max-content;
                  margin-bottom: 0.4rem;
                  margin-top: 0.4rem;
                }
              }
              .sectionWiseTestWrapper {
                .subjectWiseTest_heading {
                  color: #121212;
                  font-size: 0.875rem;
                  font-weight: 500;
                  margin: 0 !important;
                  margin-bottom: 1.5rem !important;
                  span {
                    font-weight: 400;
                    color: #5e5e5e;
                  }
                }
                .subjectWiseTest_year {
                  border-radius: 8px;
                  border: 0.6px solid #cecece;
                  padding: 0.5rem 1rem;
                  line-height: 1;
                  background: #f6f6f7;
                }
                .subjectWiseTest {
                  border-radius: 0.5rem;
                  border: 0.6px solid #cecece;
                  background: #f6f6f7;
                  max-width: -moz-fit-content;
                  max-width: fit-content;
                  display: flex;
                  padding: 4px;
                  justify-content: space-between;
                  align-items: center;

                  .single_select_subject {
                    input[type="radio"] {
                      opacity: 0;
                      position: fixed;
                      width: 0;

                      &:checked + label {
                        background-color: #263238;
                        border: none;
                        color: #ffffff;
                        border-radius: 0.25rem;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 23px;
                      }
                    }

                    label {
                      padding: 3px;
                      font-size: 15px;
                      letter-spacing: 0.2px;
                      color: #6b6876;
                      cursor: pointer;
                      min-width: 150px;
                      text-align: center;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                    }
                  }
                }

                .sectionWiseTest {
                  display: flex;
                  width: max-content;
                  margin-bottom: 0.4rem;
                  margin-top: 0.4rem;
                }
              }
              .sub_sub_section_wrapper_2 {
                h2 {
                  text-align: center;
                  font-size: 25px;
                  font-weight: bold;
                  margin-bottom: 20px;
                }

                .text_content_wrapper {
                  .typeLanguageWrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 5px;

                    span {
                      color: #707070;
                      font-size: 12px;
                    }
                  }

                  .questionBox {
                    display: flex;
                    align-items: baseline;
                    margin-bottom: 20px;

                    span {
                      margin-right: 5px !important;
                    }

                    .text_content {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 500;
                      color: #000;

                      p {
                        margin-bottom: 0px;
                      }
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_3 {
                .single_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;
                  margin-bottom: 30px;

                  .single_select {
                    width: calc(50% - 8px);
                    margin: 10px 16px 10px 0px;

                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    .classCorrect {
                      border: 1px solid #5ddd9b;
                      background: #e1f1ec !important;
                    }

                    .classWrong {
                      border: 1px solid #f93030;
                      background: #f6e0e4 !important;
                    }

                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }

                    .single_option_wrapper {
                      min-height: 50px;
                      background-color: #fff;
                      border-radius: 0.25rem;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;
                      border: 0.6px solid #939393;

                      .option_initial {
                        margin-right: 10px;

                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #acacac;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #000;

                          p {
                            margin-bottom: 0px;
                          }
                        }
                      }
                    }

                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border-radius: 0.25rem;
                      border: 1px solid #fbab35;
                      background: #fff9ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                //multi-select
                .multi_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;

                  .single_select {
                    width: calc(50% - 8px);
                    margin: 10px 16px 10px 0px;

                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }

                    .single_option_wrapper {
                      min-height: 50px;
                      border: solid 1px #f0eef8;
                      background-color: #fafafd;
                      border-radius: 15px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;

                      .option_initial {
                        margin-right: 10px;

                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }
                    }

                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                //integer
                .input_wrapper {
                  .input_inner_wrapper {
                    margin-bottom: 30px;

                    input[type="number"] {
                      outline: none !important;
                      width: 100%;
                      border: 0.6px solid #939393;
                      background-color: #fff;
                      height: 50px;
                      border-radius: 0.25rem;
                      padding: 0 5px;
                      font-size: 16px;
                      font-weight: 500;
                    }

                    input[type="number"] {
                      border: none;
                      outline: none;
                      width: 100%;
                      border: 0.6px solid #939393;
                      background-color: #fff;
                      height: 50px;
                      border-radius: 0.25rem;
                      padding: 0 5px;
                      font-size: 16px;
                      font-weight: 500;
                    }
                    input:focus {
                      border: 0.6px solid #fbab35;
                    }

                    input[type="number"]:focus {
                      border: 0.6px solid #fbab35;
                    }

                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_4 {
                .checkbox_wrapper {
                  input[type="checkbox"] {
                    margin-right: 10px;
                  }

                  label {
                    font-size: 13px;
                    vertical-align: text-top;
                    color: #09041a;
                  }
                }
              }

              .sub_sub_section_wrapper_5 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0.5rem auto;
                width: 100%;
                padding-top: 0.5rem;
                border-top: 1px solid #ddd;

                .left_side_wrapper {
                  width: 30%;
                  margin-right: 16px;

                  .btn_wrapper {
                    text-align: left;

                    button {
                      border: none;
                      outline: none;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: var(--red);
                      padding: 10px;
                      width: auto;
                      border-radius: 0.25rem;
                      border: 1px solid var(--red);
                    }
                  }
                }

                .right_side_wrapper {
                  width: auto;

                  .btn_wrapper {
                    text-align: right;

                    button,
                    a {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      padding: 10px;
                      width: auto;
                      &:first-child {
                        border-radius: 0.25rem;
                        border: 1px solid #e9e9e9;
                        margin-right: 0.5rem;
                        color: #333333;
                        background-color: #fff !important;
                      }
                      &:last-child {
                        border-radius: 0.25rem;
                        color: #b91311;
                        border: 0.6px solid #b91311;
                        background: #b91311;
                      }
                    }
                  }
                }
              }

              .QuestionSolution {
                padding-top: 20px;
                div {
                  p {
                    margin-bottom: 2px;

                    math {
                      width: 100%;
                      word-break: break-word;
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_6 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.onSubmitModal {
  text-align: center;

  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    .submit_Popup {
      img {
        width: 50px;
        margin-bottom: 20px;
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
        }

        .cancel {
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .bitsat_questions_solution_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            .left_side_content_wrapper {
              .sub_section_1_wrapper {
                .questions_serial_number_collection {
                  .single_question_number_wrapper {
                    .unanswered_lbl,
                    .unAttempted {
                      font-size: 12px;
                      padding: 10px;
                    }
                  }
                }
              }
            }

            .right_side_content_wrapper {
              .sub_section_wrapper {
                .sub_sub_section_wrapper_2 {
                  h2 {
                    font-size: 22px;
                  }

                  .text_content_wrapper {
                    .questionBox {
                      .text_content {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .bitsat_questions_solution_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            .section_content_wrapper_backdrop {
              height: 100vh;
              width: 100vw;
              z-index: 1995;
              background-color: #000;
              opacity: 0.5;
              position: absolute;
              display: block;
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }
            .left_side_content_wrapperToggle {
              display: block !important;
              animation: fadeIn 200ms ease-in;
            }

            .left_side_content_wrapper {
              width: 70% !important;
              position: absolute !important;
              display: none;
              z-index: 1999;

              .sub_section_1_wrapper {
                position: relative;
                .closebtn {
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  right: 0;
                }
                .questions_serial_number_collection {
                  .single_question_number_wrapper {
                    flex: 0 0 280px;
                  }
                }
              }
            }

            .right_side_content_wrapper {
              width: 100%;
              padding: 0;
              .right_btn_wrapper {
                display: block;
                position: sticky;
                top: 0;
                z-index: 1000;
                background: inherit;
                padding: 10px;
                .togglebtn {
                  display: inline-block;
                  border: none;
                  outline: none;
                  border-radius: 8px;
                  background-color: #ffffff;
                  font-size: 16px;
                  font-weight: 600;
                  text-align: center;
                  color: #fff;
                  padding: 12px 20px;
                  background: #6d48ef;
                  position: sticky;
                  top: 0;
                  z-index: 1000;
                }
              }
              .sub_section_wrapper {
                padding: 0 10px 10px;
                .sub_sub_section_wrapper_2 {
                  h2 {
                  }

                  .text_content_wrapper {
                    .questionBox {
                      .text_content {
                        line-height: 18px;
                      }
                    }
                  }
                }

                .sub_sub_section_wrapper_3 {
                  .single_select_option_collection_wrapper {
                    margin-bottom: 15px;

                    .single_select {
                      width: 100%;
                      margin: 0px 0px 10px 0px;
                    }
                  }
                }

                .testProgressIndicator {
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.right_side_content_wrapper .sub_section_wrapper::-webkit-scrollbar {
  width: 0px;
}
