.signUp_step_one_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  .input_wrapperr {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .input-heading-text {
      color: #3d3d3d;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.5rem;
      text-align: start;
    }

    input {
      border-radius: 0.375rem;
      border: 0.8px solid #cecece;
      height: 2.875rem;
      padding: 0.6875rem 11.8125rem 0.6875rem 0.875rem;
      align-items: center;
      margin-bottom: 2.5rem;
    }

    .nextBtn {
      padding: 0.75rem 0;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
      background: #b50503;
      width: 100%;
      text-align: center;
      color: #fff;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
  .signup_right_form {
    .signup_form {
      display: flex;
      align-items: center;
      justify-content: center;
      .content_inner_wrapper {
        width: 100%;
        height: 100%;

        .targets_wrapper {
          width: 100%;
          height: 100%;
          padding-top: 46px;
          .text_content_wrapper {
            display: flex;
            align-items: center;
            img {
              width: 36px;
              height: 36px;
            }
            .text_content-target {
              color: #3d3d3d;
              font-family: Poppins;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              padding-top: 10px;
            }
          }

          .radio_collection_wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            height: 50vh;

            .single_select {
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                border-radius: 0.25rem;
                border: 0.8px solid #9f9f9f;
                // padding: 0.75rem 0;
                padding: 0.6875rem 11.8125rem 0.6875rem 0.875rem;
                width: 100%;
                color: #6b6b6b;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &:hover {
                  border-radius: 0.25rem;
                  border: 0.8px solid #6f6f6f;

                  background: #f6f6f6;
                  color: #121212;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
}

@media (max-width: 991.98px) {
}

@media (max-width: 575.98px) {
  .signUp_step_one_wrapper {
    .signUp_step_one_inner_wrapper {
      .content_wrapper {
        width: 100%;
        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;
        }
      }
    }
  }
}
