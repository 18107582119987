.topicWiseAnalysisContentComponentWrapper {
  width: 90%;
  margin: auto;
  .subjects_tab_wrapper {
    .outer_content_wrapper {
      .content_wrapper {
        .left_side_content_wrapper {
          text-align: -webkit-center;
          margin-bottom: 25px;
          text-align: center;

          .subject_type_collection_wrapper {
            display: inline-block;
            background-color: #f5f4f8;
            border-radius: 25px;
            overflow: hidden;
            border: 0.6px solid #e0e0e0;

            .single_select_subject_type {
              width: auto;
              min-width: 160px;
              display: inline-block;
              background: #f5f4f8 !important;
              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                background-color: #fff;
                padding: 0.5rem 1rem;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: #6b6876;
                cursor: pointer;
                width: 100%;
                text-align: center;
              }

              input[type="radio"]:checked + label {
                background-color: #263238;
                border: none;
                color: #ffffff;
              }
            }
          }
        }

        .right_side_content_wrapper {
          flex: 0 0 65%;

          .section-heading {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            color: #121212;
            margin-bottom: 10px;
          }
          #customers {
            border-collapse: collapse;

            width: 100%;

            margin: auto;
            margin-bottom: 30px;
            border-radius: 0.375rem;
            border: 0.6px solid #ddd;
            background: #fffdf5;

            thead {
              th {
                padding: 1rem 1.8rem 1rem 1.8rem;
                color: #121212;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.01rem;
              }

              tr {
                background: #fff7d7;
              }
            }

            tbody {
              tr {
                border-bottom: 1px dashed #fffdf5;
                background: #fffdf5;
              }
              td {
                padding: 10px 0px 10px 35px;
                color: #373737;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              .num {
                padding: 5px 0px 5px 65px;
                color: #000;
                font-family: Poppins;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}
