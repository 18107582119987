.termsNConditionContentComponent_wrapper {
  margin-top: 25px;

  .termsNConditionContentComponent_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .heading_wrapper {
          .heading_text_content {
            margin-bottom: 10px;
            color: #000;
            font-family: "Poppins";
            font-size: 18px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.18px;
          }
        }
      }
    }

    .sec_2_wrapper {
      padding: 15px;
      background-color: #ffffff;
      border-radius: 8px;
      p {
        color: #3F3F3F;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
        line-height: 23px; /* 164.286% */
        letter-spacing: 0.07px;
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .termsNConditionContentComponent_wrapper {
    padding-top: 70px;
  }
}