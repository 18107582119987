.offlineTransactionWrapper {
  margin: 75px 0;

  .offline_transactions_history_collection_wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 0;

    .no-transaction {
      text-align: center;
      margin-top: 100px;

      img {
        width: 120px;
      }

      p {
        color: #000;
        font-family: "Poppins";
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }

    .single_transaction_wrapper {
      background: #ffffff;
      border: 0.6px solid #cecece;
      border-radius: 8px;
      padding: 15px;
      margin: 10px 0;

      .section_wrapper_1 {
        background-color: #ffefe6;
        background: #ffefe6;
        border-radius: 4px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left_side_content_wrapper {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 16px;
              font-weight: 500;
              color: #0f0404;
            }
          }
        }

        .right_side_content_wrappers {
          .text_content_wrapper {
            .text_content {
              margin: 0;
              font-size: 16px;
              font-weight: 500;
              color: #0f0404;
            }
            .dropDown {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 27px;
              color: #6d48ef;
            }
          }
        }
      }
      .products {
        margin: 25px auto;
        width: 94%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .product {
          // width: 100%;
          padding: 20px;
          background: #fafafb;
          border: 0.4px solid #cdcdcd;
          border-radius: 4px;
          .productName {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            color: #000000;
          }

          .qnty {
            width: 90%;
            margin: 0px auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0px 0px 0px;
          }

          .actualPrice {
            width: 90%;
            margin: 0px auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0px 8px 0px;
          }
          .discount {
            padding: 0px 0px 8px 0px;
            width: 90%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .num {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            color: #121212;
          }

          .underLine {
            border: 0.4px solid #b8b5b5;
          }

          .price {
            width: 90%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .singleProduct {
          width: 100%;
        }
        .items {
          width: 49%;
        }
      }

      ul {
        .heading {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 36px;
          color: #000000;
        }
        width: 94%;
        margin: 0px auto;
        margin-top: 25px;
        margin-bottom: 20px !important;
        // border-bottom: 0.6px solid #d3d3d3;
        padding: 0px;
        li {
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 93%;
          margin: 0px auto;
          font-family: "Poppins";
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          .text {
            font-weight: 400;

            color: #545454;
          }

          .num {
            font-weight: 500;
            color: #121212;
          }
        }
        li:last-child {
          margin-bottom: 15px;
        }
      }

      .section_wrapper_2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;
        padding: 10px;
        // border-top: 0.6px solid #d3d3d3;

        .text_content_wrapper {
          .text_content_1 {
            margin: 0;
            font-size: 14px;
            font-weight: 500;
            color: #6f6868;
            margin-bottom: 5px !important;
          }

          .text_content_2 {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: #0f0404;
            .img {
              margin-right: 8px;
            }
          }
          .netPrice {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #c00714;
          }
        }
      }
    }
  }
}
