.added_to_cart_container {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  .left {
    width: 65%;
    .card {
      max-width: 100%;
      padding: 20px;
      background: none !important;
      border: 0.5px solid #9d9d9d !important;
      border-radius: 4px !important;
      .card_left {
        img {
          width: 70%;
          height: 18vh;
          object-fit: cover;
        }
      }
      .col-right {
        margin-left: -20px;
        .card-body {
          padding: 0px;
          .card-title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: #2d2d2d;
          }
          .card-text {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #686868;
          }
          .card_text_actions {
            border-bottom: 0.5px solid #bdbdbd;
            .actions {
              display: flex;
              justify-content: space-between;
              width: 55%;
              align-items: center;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 8px;
              cursor: pointer;
              .action {
                color: #6d48ef;
              }
              .qnty {
                display: flex;
                justify-content: space-between;
                width: 30%;
                .btns {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  // width: 60%;
                  padding: 1px 4px;
                  background: #f7f7f7;
                  border: 0.5px solid #d0d0d0;
                  border-radius: 4px;
                  img {
                    width: 17px;
                  }
                  .qntyNo {
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 14px;
                    color: #6d48ef;
                  }
                }
              }
            }
          }
          .price {
            text-align: end;
            font-family: "Poppins";
            font-style: normal;
            font-size: 18px;
            line-height: 27px;
            margin-top: 10px;
            margin-bottom: -10px;
            .price_text {
              font-weight: 400;
              color: #626262;
              margin-right: 10px;
            }
            .cost {
              font-weight: 500;
              color: #000000;
            }
          }
        }
      }
    }
  }
  .right {
    width: 32%;

    .checkOutBtn {
      text-align: center !important;
      background: #b50503 !important;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08) !important;
      border-radius: 4px !important;
      border-radius: 4px !important;
    }
    // margin-right: 20px;

    //   .your_order {
    //     border: 0.5px solid #9d9d9d;
    //     border-radius: 4px;
    //     .your_order_Content {
    //       .header {
    //         display: flex;
    //         justify-content: space-between;
    //         align-items: center;
    //         border-bottom: 0.5px solid #d9d9d9;
    //         padding: 10px 15px;
    //         .text {
    //           font-family: "Poppins";
    //           font-style: normal;
    //           font-weight: 400;
    //           font-size: 20px;
    //           line-height: 30px;
    //           color: #4b4b4b;
    //         }
    //       }

    //       .itemsBox {
    //         border-bottom: 0.5px solid #d9d9d9;
    //         .item {
    //           padding: 10px 20px;
    //           display: flex;
    //           justify-content: space-between;
    //           align-items: center;

    //           .item_left {
    //             display: flex;
    //             justify-content: space-between;
    //             width: 45%;
    //             padding: 0px;
    //             margin: 0px;
    //             img {
    //               width: 25%;
    //               height: 6vh;
    //               object-fit: cover;
    //             }
    //             .item_content {
    //               .subName {
    //                 font-family: "Poppins";
    //                 font-style: normal;
    //                 font-weight: 400;
    //                 font-size: 16px;
    //                 line-height: 18px;
    //                 color: #2d2d2d;
    //               }

    //               small {
    //                 font-family: "Poppins";
    //                 font-style: normal;
    //                 font-weight: 400;
    //                 font-size: 12px;
    //                 line-height: 18px;
    //                 color: #787878;
    //               }
    //             }
    //           }

    //           .item_right {
    //             width: 23%;
    //             text-align: center;
    //             margin: 0px;
    //             font-weight: 500;
    //             font-size: 16px;
    //             line-height: 24px;
    //             color: #121212;
    //           }
    //         }
    //       }

    //       .delivery_discount {
    //         border-bottom: 0.5px solid #d9d9d9;
    //         padding: 5px 0px;
    //         .deliveryBox {
    //           padding: 3px 20px;
    //           display: flex;
    //           justify-content: space-between;
    //           align-items: center;
    //           .deliveryName {
    //             font-family: "Open Sans";
    //             font-style: normal;
    //             font-weight: 600;
    //             font-size: 14px;
    //             line-height: 18px;
    //             color: #6d6d6d;
    //           }
    //           .deliveryCost {
    //             width: 23%;
    //             text-align: center;
    //             font-family: "Poppins";
    //             font-style: normal;
    //             font-weight: 500;
    //             font-size: 14px;
    //             line-height: 16px;
    //             color: #666666;
    //           }
    //         }
    //         .discountBox {
    //           padding: 3px 20px;
    //           display: flex;
    //           justify-content: space-between;
    //           align-items: center;
    //           .dicountName {
    //             font-family: "Open Sans";
    //             font-style: normal;
    //             font-weight: 600;
    //             font-size: 14px;
    //             line-height: 19px;
    //             color: #6d6d6d;
    //           }
    //           .discountPrice {
    //             width: 20%;
    //             text-align: start;
    //             font-family: "Poppins";
    //             font-style: normal;
    //             font-weight: 500;
    //             font-size: 14px;
    //             line-height: 16px;
    //             color: #666666;
    //           }
    //         }
    //       }

    //       .totalPriceContent {
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;
    //         background: #ece7ff;
    //         padding: 15px 20px;
    //         .totalPriceText {
    //           font-family: "Poppins";
    //           font-style: normal;
    //           font-weight: 400;
    //           font-size: 18px;
    //           line-height: 27px;
    //           color: #2d2d2d;

    //           color: #121212;
    //         }
    //         .totalPrice {
    //           font-family: "Poppins";
    //           font-style: normal;
    //           font-weight: 500;
    //           font-size: 18px;
    //           line-height: 27px;
    //           color: #121212;
    //         }
    //       }
    //     }
    //   }

    // .checkOutBtn {
    //   margin-top: 20px;
    //   text-align: center;
    //   background: #ff933a;
    //   box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
    //   border-radius: 4px;
    //   border-radius: 4px;
    //   cursor: pointer;
    //   button {
    //     font-family: "Poppins";
    //     font-style: normal;
    //     font-weight: 500;
    //     font-size: 18px;
    //     line-height: 27px;
    //     color: #ffffff;
    //     padding: 10px 0px;
    //     width: 100%;
    //   }
    // }
  }
}

@media (max-width: 578px) {
  .added_to_cart_container {
    .left {
      .card {
        padding: 10px;
        text-align: center;
        .card_left {
          margin-bottom: 0.5rem;
          img {
            width: 100%;
            height: auto;
          }
        }
        .col-right {
          margin-left: 0;
          .card-body {
            .card-title {
              line-height: 1.3;
            }
            .card_text_actions {
              .actions {
                width: 100%;
                .qnty {
                  width: 35%;
                  .btns {
                    width: 65%;
                  }
                }
              }
            }
            .price {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
