.notesFeatureContentComponent_wrapper {
  padding: 20px;
  background-color: #f4f4f4;
  overflow-y: hidden !important;
}

.pdf-container {
  max-width: 100%;
  padding: 0 20px;
}

.notesFeatureContentComponent_inner_wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 100px;
  height: 90vh;
  overflow-y: auto;
}
.sec_1_inner_wrapper {
  display: flex;
  justify-content: flex-start;
  .btn_wrapper {
    button {
      outline: none;
      border: none;
      background: transparent;
    }
  }
  .text_content_wrapper {
    margin: 0 10px;
    .text_content {
      margin: 0;
      font-size: 20px;
      font-weight: 600;
      color: #0f0404;
    }
  }
}

.pdf-header {
  display: flex;
  align-items: center;
  .btn_wrapper {
    width: fit-content;
    margin-right: 20px;
  }

  .headingContentWrapper {
    margin-top: -10 !important;

    .titleText {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}

.notes_wrapper {
  margin-top: 20px;
}

.notes_inner_wrapper {
  width: 100%;
}

.zoom_controls {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.zoom_controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.zoom_controls button:hover {
  background-color: #0056b3;
}

.pdf-container {
  display: flex;
  justify-content: center;
}
