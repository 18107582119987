.headerRight_wrapper {
  width: fit-content;
  .headerRight_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .search_bar_wrapper {
      .search_bar_inner_wrapper {
        .content_wrapper {
          width: 40px;
          padding: 0px;
          height: 40px;
          background-color: #ebebeb;
          border-radius: 50%;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          form {
            display: flex;
            align-items: center;
            .search_image {
            }
            .search_input {
              width: 100%;
              height: 40px;
              outline: none;
              border: none;
              display: none;
              width: 0%;
            }
            ::placeholder {
              color: #9898a0;
              font-size: 15px;
            }
          }
        }
        .open_search {
          background-color: #ebebeb;
          padding-left: 10px;
          border-radius: 20px;
          width: 100%;

          form {
            .search_input {
              background: none;
              border: none;
              font-size: 15px;
              display: block;
              transition: width 1s;
              width: 100%;

              &:focus {
                outline: none !important;
                border: none;
              }
            }

            .search_image {
            }
          }
        }
      }
    }

    .referEarn_wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;

      // Styling for the animated element
      .animation-container {
        width: 33px;
        margin-top: -10px;
        margin-right: 7px;
        animation-name: slideIn;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
      }

      .text {
        color: #000;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    // Define the animation keyframes
    @keyframes slideIn {
      0% {
        transform: translateX(-100%);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }

    .notifications_wrapper {
      position: relative;

      .notifications_inner_wrapper {
        .notication_dropDown {
          position: relative;
          button {
            padding: 0px;

            &::after {
              display: none;
            }

            &:focus {
              outline: none !important;
              box-shadow: none !important;
            }
            .image_wrapper {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-color: #ebebeb;
              margin-left: 0.875rem;
            }
          }
          .custom_dropdown_notification {
            width: 450px;
            transform: translate(-253px, 15px) !important;
            border-radius: 10px;
            position: absolute;
            box-shadow: #00000040 0px 54px 55px, #0000001f 0px -12px 30px,
              #0000001f 0px 4px 6px, #0000002b 0px 12px 13px,
              #00000017 0px -3px 5px !important;
            border: 0px;
            padding-bottom: 20px;

            &::after {
              content: "";
              position: absolute;
              right: 150px;
              top: -14px;
              border-left: 9px solid transparent;
              border-right: 9px solid transparent;
              border-bottom: 15px solid #fff;
            }

            .notification_dataBox {
              .heading {
                padding: 15px 18px;

                h3 {
                  color: #212121;
                  font-size: 18px;
                }
              }

              .notification_list_wrpr {
                max-height: 450px;
                min-height: 250px;
                overflow-y: auto;

                .notification_list {
                  padding: 9px 18px 5px;
                  &:hover {
                    .ntf_desc {
                      p {
                        display: block;
                      }
                    }
                  }

                  &:nth-child(even) {
                    background: #f6f6f6;
                  }

                  .ntf_title {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    h6 {
                      color: #212121;
                      font-size: 14px;
                      margin: 0px;
                    }

                    span {
                      color: #212121;
                      font-size: 11px;
                    }
                  }

                  .ntf_desc {
                    display: inline-block;
                    box-sizing: border-box;
                    white-space: pre-line;
                    word-wrap: break-word;

                    p {
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 2;
                      overflow: hidden;
                      letter-spacing: 0.18px;
                      color: #212121;
                      font-size: 13px;
                      margin: 0px;
                    }
                  }
                }

                .notDataFound {
                  padding: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  img {
                    width: 130px;
                  }
                  p {
                    font-size: 14px;
                    text-align: center;
                    width: 90%;
                  }
                }
              }
            }
          }
        }
      }
    }
    .userName_and_image_wrapper {
      .userName_and_image_inner_wrapper {
        .content_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name_number_container {
            .text_content_wrapper_1 {
              margin: 1px 0;
              .text_content_1 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #a02b2d;
              }
            }
            .text_content_wrapper_2 {
              margin: 1px 0;
              .text_content_2 {
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                color: #6f6868;
              }
            }
            .text_content_wrapper_3 {
              margin: 1px 0;
              .text_content_3 {
                margin: 0;
                font-size: 14px;
                font-weight: 600;
                color: #000000;
                img {
                  width: 15px;
                  margin-left: 2.5px;
                }
              }
            }
          }
          .image_container {
            .custom_dropdown_toggle {
              padding: 5px;
              margin-left: 0.875rem;
              display: flex;
              border: none;
              .userdetail_wrapper {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 10px;
                .userdetail_1 {
                  font-weight: 700;
                  font-size: 14px;
                }
                .userdetail_2 {
                  font-weight: 400;
                  font-size: 11px;
                }
              }

              &::after {
                display: none;
              }
              .image_wrapper {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: #ffffff;
                text-align: center;
                display: inline-block;
                .image_inner_wrapper {
                  display: inline-block;
                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                  }
                }
              }
            }
            .custom_dropdown_menu {
              border: none;
              box-shadow: 0px 0px 60px #0000001a;
              border-radius: 10px;
              position: absolute;
              right: 0;
              transform-origin: right top;
              .custom_dropdown_menu.left {
                right: auto;
                left: 0;
                transform-origin: left top;
              }
              .profile_option_wrapper {
                width: 300px;
                .profile_option_inner_wrapper {
                  .content_wrapper_1 {
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #7070701f;
                    justify-content: space-between;
                    padding: 0px 12px;

                    .userProfile {
                      display: flex;
                      align-items: center;
                      .image_container {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background-color: #ffffff;
                        text-align: center;
                        line-height: 50px;
                        img {
                          width: 40px;
                          height: 40px;
                          border-radius: 50%;
                        }
                      }
                      .text_content_wrapper_1 {
                        margin: 5px;
                        .text_content_1 {
                          margin: 0;
                          color: #000;
                          font-size: 15px;
                          font-weight: 600;
                        }
                        .text_content_2 {
                          margin: 0;
                          color: #000;
                          font-size: 14px;
                          opacity: 0.7;
                        }
                      }
                    }
                    .text_content_3 {
                      border: 1px solid #eeeeee;
                      width: 30px;
                      height: 30px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 50%;
                      margin: 0px;
                      img {
                        width: 18px;
                      }
                    }
                    .text_content_wrapper_2 {
                      margin: 5px;
                      border: 1px solid #d8e5eb;
                      border-radius: 15px;
                      padding: 10px;
                      cursor: pointer;
                      .text_content_1 {
                        margin: 0;
                        color: #152257;
                        font-size: 14px;
                        font-weight: 600;
                      }
                      &:hover {
                        box-shadow: 0px 0px 8px #1046752b;
                      }
                    }
                  }
                  .content_wrapper_2 {
                    .text_content_wrapper_1 {
                      .profile_link {
                        text-decoration: none;
                        padding: 5px 12px 7px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #e2e2e2;
                        margin: 8px 0px;

                        &:last-child {
                          border: 0px;
                        }
                        .text_content_1 {
                          margin: 0;
                          color: #000;
                          font-weight: 600;
                        }
                        img {
                          width: 8px;
                          margin-right: 5px;
                        }
                      }
                      .profile_link1 {
                        text-decoration: none;
                        padding: 5px 12px 7px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-bottom: 1px solid #e2e2e2;
                        margin: 8px 0px;

                        &:last-child {
                          border: 0px;
                        }
                        .text_content_1 {
                          margin: 0;
                          color: #000;
                          font-weight: 600;
                        }
                        img {
                          width: 8px;
                          margin-right: 5px;
                        }
                      }

                      .text_content_wrapper_2 {
                        cursor: pointer;

                        p {
                          img {
                            width: 20px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .headerRight_wrapper {
    .headerRight_inner_wrapper {
      .search_bar_wrapper {
        .search_bar_inner_wrapper {
          .content_wrapper {
            form {
              .search_image {
              }
              .search_input {
                width: 80%;
              }
              ::placeholder {
              }
            }
          }
        }
      }
      .notifications_wrapper {
        .notifications_inner_wrapper {
          .image_wrapper {
            img {
            }
          }
        }
      }
      .userName_and_image_wrapper {
        .userName_and_image_inner_wrapper {
          .content_wrapper {
            .name_number_container {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
            .image_container {
              .custom_dropdown_toggle {
                .image_wrapper {
                  .image_inner_wrapper {
                    img {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .headerRight_wrapper {
    .headerRight_inner_wrapper {
      .search_bar_wrapper {
        .search_bar_inner_wrapper {
          .content_wrapper {
            form {
              .search_image {
              }
              .search_input {
                width: 70%;
              }
              ::placeholder {
              }
            }
          }
        }
      }
      .notifications_wrapper {
        .notifications_inner_wrapper {
          .image_wrapper {
            img {
            }
          }
        }
      }
      .userName_and_image_wrapper {
        .userName_and_image_inner_wrapper {
          .content_wrapper {
            .name_number_container {
              .text_content_wrapper_1 {
                .text_content_1 {
                }
              }
              .text_content_wrapper_2 {
                .text_content_2 {
                }
              }
            }
            .image_container {
              .custom_dropdown_toggle {
                .image_wrapper {
                  .image_inner_wrapper {
                    img {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 911px) {
  .headerRight_wrapper .headerRight_inner_wrapper .referEarn_wrapper .text {
    font-size: 12px;
  }
  .headerRight_wrapper
    .headerRight_inner_wrapper
    .notifications_wrapper
    .notifications_inner_wrapper
    .notication_dropDown
    button
    .image_wrapper {
    width: 26px;
    height: 26px;
    margin-left: 10px;
  }
  .headerRight_wrapper
    .headerRight_inner_wrapper
    .userName_and_image_wrapper
    .userName_and_image_inner_wrapper
    .content_wrapper
    .image_container
    .custom_dropdown_toggle
    .image_wrapper
    .image_inner_wrapper
    img {
    width: 29px;
    height: 29px;
  }
  .headerRight_wrapper
    .headerRight_inner_wrapper
    .userName_and_image_wrapper
    .userName_and_image_inner_wrapper
    .content_wrapper
    .image_container
    .custom_dropdown_toggle
    .image_wrapper {
    width: 26px;
    height: 26px;
    margin-top: 5px;
  }
  .headerRight_wrapper
    .headerRight_inner_wrapper
    .userName_and_image_wrapper
    .userName_and_image_inner_wrapper
    .content_wrapper
    .image_container
    .custom_dropdown_toggle {
    padding: 5px;
    margin-left: 1px;
  }
  .headerRight_wrapper
    .headerRight_inner_wrapper
    .userName_and_image_wrapper
    .userName_and_image_inner_wrapper
    .content_wrapper
    .image_container
    .custom_dropdown_toggle
    .userdetail_wrapper
    .userdetail_1 {
    font-size: 12px;
  }
  .headerRight_wrapper
    .headerRight_inner_wrapper
    .userName_and_image_wrapper
    .userName_and_image_inner_wrapper
    .content_wrapper
    .image_container
    .custom_dropdown_toggle
    .userdetail_wrapper
    .userdetail_2 {
    font-size: 10px;
  }
}
