.userCreateDoubtContentComponent_wrapper {
  .userCreateDoubtContentComponent_inner_wrapper {
    font-family: Poppins;

    .sec_1_wrapper {
      margin-bottom: 1.25rem;
      .sec_1_inner_wrapper {
        text-align: center;
        .btn_wrapper {
          button {
            outline: none;
            border: none;
            background: transparent;
          }
        }
        .text_content_wrapper {
          margin: 0 10px;
          .text_content {
            margin: 0;
            color: #121212;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.16px;
          }
        }
      }
    }
    .sec_2_wrapper {
      padding: 0px 10px;
      .sec_2_inner_wrapper {
        .content_wrapper {
          display: flex;
          justify-content: flex-start;
          .content_inner_wrapper {
            flex: 0 0 100%;
            .select_subject {
              width: 100%;

              display: flex;
              align-items: center;

              color: #121212;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;

              padding: 12px 0 12px 15px;

              border-radius: 4px;
              border: 1px solid #c6c6c6;
              background: #fff;

              span {
              }
            }
            .sub_content_wrapper_1 {
              margin: 10px 0;
              .select_wrapper {
                select {
               
                  width: 100%;
                 

                  color: #121212;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  padding: 12px 0 12px 15px;

                  border-radius: 4px;
                  border: 1px solid #c6c6c6;
                  background: #fff;

                

                  &:focus {
                    outline: none;
                  }

                  option {
                    margin-right: 10px;
                  }
                }
              }
            }
            .sub_content_wrapper_2 {
              margin: 10px 0;
              .textarea_wrapper {
                textarea {
                  border-radius: 4px;
                  border: 1px solid #c6c6c6;
                  background: #f6f6f7;
                  width: 100%;
                  padding: 5px 5px;
                  min-height: 186px;
                  &:focus {
                    outline: none;
                    background: #fff;
                  }

                  color: #858585;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
            .sub_content_wrapper_3 {
              margin: 10px 0;
              .show_uploading_img_wrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 15px;
                .image_wrapper {
                  margin: 10px;
                  position: relative;
                  img {
                    width: 70px;
                    height: 70px;
                    border-radius: 4px;
                  }
                  span {
                    width: 15px;
                    height: 15px;
                    background-color: #f33d3d;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    position: absolute;
                    right: -6px;
                    top: -6px;
                    cursor: pointer;
                  }
                }
              }
              .input_wrapper {
                position: relative;
                // height: 40px;
                // text-align: center;
                .custom_file_upload {
                  cursor: pointer;
                  input[type="file"] {
                    display: none;
                  }
                  .upload_content_action_wrapper {
                    border-radius: 4px;
                    background: #263238;
                    color: #fff;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    padding: 10px 14px;
                  }
                }
              }
            }
            .sub_content_wrapper_4 {
              margin: 20px 0;
              .btn_wrapper {
                button {
                  border: none;
                  outline: none;
                  width: 100%;

                  padding: 12px 0px;

                  border-radius: 4px;
                  background: #b50503;
                  color: #fff;
                  font-size: 0.875rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}
