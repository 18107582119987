.homepage-container {
  ul {
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0;
    margin: 0;
  }
  background: #fff;
  scroll-behavior: smooth !important;
  overflow: hidden !important;

  .more-details-wrapper {
    margin-top: 3rem;
    padding: 0 5rem;
  }

  .courses-wrapper {
    margin-top: 10rem;
  }

  .benifits-wrapper {
    margin-top: 10rem;
  }

  .app-details-wrapper {
    margin-top: 10rem;
    padding: 0 5rem;
  }

  .packages-wrapper {
    margin-top: 10rem;
  }

  .why-motion-wrapper {
    margin-top: 10rem;
  }

  .motion-news-wrapper {
    margin-top: 10rem;
  }

  .contact-us-wrapper {
    margin-top: 10rem;
    padding: 0 5rem;
  }

  .footer-wrapper {
    margin-top: 7.5rem;
  }
}
.contact-banner-wrapper {
  display: none;
}

@media (max-width: 1024px) {
  .homepage-container .courses-wrapper {
    margin-top: 5rem;
  }
  .homepage-container .app-details-wrapper {
    margin-top: 5rem;
    padding: 0 2rem;
  }
  .homepage-container .benifits-wrapper {
    margin-top: 5rem;
  }
  .homepage-container .packages-wrapper {
    margin-top: 5rem;
  }
  .homepage-container .why-motion-wrapper {
    margin-top: 5rem;
  }
  .homepage-container .contact-us-wrapper {
    margin-top: 3rem;
    padding: 0 3rem;
  }
}

@media screen and (max-width: 560px) {
  .homepage-container .more-details-wrapper {
    margin-top: 0rem;
  }
  .homepage-container .app-details-wrapper {
    margin-top: 5rem;
    background: linear-gradient(
      0deg,
      rgba(181, 5, 3, 0.86) 0%,
      rgba(181, 5, 3, 0.86) 100%
    );
    padding: 2rem 1.5rem;
  }

  .homepage-container .packages-wrapper {
    margin-top: 3rem;
  }
  .homepage-container .why-motion-wrapper {
    margin-top: 2rem;
  }
  .news-inner-wrapper {
    padding: 0 1.5rem;
  }
  .homepage-container .motion-news-wrapper {
    margin-top: 2rem;
  }
  .homepage-container .contact-us-wrapper {
    margin-top: 2rem;
    padding: 0 0rem;
    background: #b50505;
  }
  .homepage-container .footer-wrapper {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 426px) {
  .homepage-container .app-details-wrapper {
    margin-top: 3rem;
    padding: 1rem 1rem;
  }
  .news-inner-wrapper {
    padding: 0 1rem;
  }
}

@media screen and (max-width: 550px) {
  .contact-banner-wrapper {
    display: block !important;
  }
  .homepage-container .footer-wrapper {
    margin-top: 3.5rem;
  }
}
