.cart_wrapper {
  padding-top: 75px;

  .cart_inner_wrapper {
    display: flex;
    justify-content: center;
    gap: 50px;
    padding-top: 1.8rem;
    position: relative;
  }

  .cart_empty_content_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;

    img {
      width: 10.8125rem;
      height: 10.8125rem;
      margin-top: 7rem;
    }

    .hd_text1 {
      color: #121212;
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 3rem;
      margin-bottom: 0.5rem;
    }

    .hd_text2 {
      color: #636363;
      text-align: center;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1875rem; /* 158.333% */
      margin-bottom: 3.5rem;
      max-width: 80%;
    }

    .gotostore_btn {
      border-radius: 0.25rem;
      background: #b80f0d;
      padding: 0.87rem 4rem;
      cursor: pointer;
      button {
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
