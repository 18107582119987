.testSummary {
  .section-heading {
    color: #373737;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.5rem;
  }
  .table_section {
    border-radius: 0.375rem;
    border: 0.6px solid #ddd;
    background: #fffdf5;
    filter: blur(0px);
    overflow-x: auto;
    table {
      .table_heading {
        text-align: center;

        tr {
          background: #fff7d7;

          .firstTd {
            text-align: start !important;
            border-top-left-radius: 6px;
            width: 33.5%;
            color: #121212;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.01rem;
          }
          td {
            // padding: 10px 25px 10px 25px;
            padding: 1rem 1.8rem 1rem 1.8rem;

            color: #121212;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.01rem;
          }
        }
        :last-child {
          border-top-right-radius: 6px;
        }
      }

      tbody {
        tr {
          // border-bottom: 1px dashed #dec2ab;
          // background: #fffcfa;
          // &:nth-child(2n + 1) {
          //   background: #faf6f3;
          // }
          border-bottom: 1px dashed #fffdf5;
          background: #fffdf5;
          .num {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 27px;

            color: #000000;
          }
          .firstTd {
            text-align: start !important;
            color: #373737;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          td {
            text-align: center;
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            padding: 0.81rem 1rem 0.81rem 1.8rem;
            // padding: 10px 25px 5px 25px;
          }
        }
        :last-child {
          border-bottom: none;
          background: none;
        }
      }
    }
  }
}
