.login_step_two_wrapper {
  .login_step_two_inner_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .content_wrapper {
      .content_inner_wrapper {
        width: 400px;
        border-radius: 0.5rem;
        background: #fff;
        // box-shadow: -4px -4px 16px 4px rgba(18, 18, 18, 0.25),
        //   4px 4px 16px 4px rgba(18, 18, 18, 0.25);

        // border-radius: 10px;
        // border: solid 1px #f2f2f3;
        // background-color: #ffffff;

        padding: 20px 25px 25px;
        .back_btn_wrapper {
          margin-bottom: 0px;
          text-align: left;
          button {
            border: none;
            outline: none;
            background: transparent;
          }
        }
        .image_container {
          text-align: center;
          width: 80px;
          margin: 0 auto 20px;
          img {
            width: 100%;
          }
        }
        h3 {
          // font-size: 24px;
          // color: #000;
          // font-weight: 600;
          // margin: 10px 0px 10px;
          color: #121212;
          font-family: Poppins;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.01125rem;
          text-align: center;
          margin-bottom: 0.25rem;
        }
        p {
          color: #505050;
          text-align: center;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 2.25rem;
        }
        .otp_input_wrapper {
          // margin: 25px 0;
          margin-bottom: 2.25rem;

          .otp_input_inner_wrapper {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .single_input {
              input[type="text"] {
                // width: 45px;
                // height: 45px;
                // border-radius: 6px;
                // border: none;
                // background: #ebebeb;
                text-align: center;
                font-size: 18px;
                font-weight: 600;

                width: 3.25rem;
                height: 3.25rem;

                border-radius: 0.375rem;
                border: 0.8px solid #cecece;
                background: #f6f6f6;
              }
            }
          }
        }
        .btn_wrapper {
          margin: 20px 0 10px;
          text-align: center;
          button {
            // border: none;
            // outline: none;
            // width: 100%;
            // height: 42px;
            // border-radius: 6px;
            // background: #6d48ef;
            // box-shadow: 0px 7px 30px #6d48ef1c;
            // font-size: 16px;
            // font-weight: 600;
            // color: #fff;
            color: #fff;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border-radius: 0.25rem;
            background: #b50503;
            box-shadow: -2px -2px 10px 1px rgba(181, 5, 3, 0.16),
              2px 2px 10px 1px rgba(181, 5, 3, 0.16);
            padding: 0.75rem 0;
            width: 100%;
          }
          // button:focus {
          //   background-color: #6d48ef;
          //   box-shadow: none;
          //   color: #ffffff;
          // }
        }
        .resend_btn_wrapper {
          // margin: 0px 0;
          margin-bottom: 2rem;
          text-align: center;
          button {
            // border: none;
            // outline: none;
            // width: auto;
            // border-radius: 25px;
            // background-color: transparent;
            // font-size: 16px;
            // font-weight: 600;
            // color: #6d48ef;
            // text-decoration: underline;
            color: #b50503;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .login_step_two_wrapper {
    .login_step_two_inner_wrapper {
      height: calc(100vh - 58px);

      .content_wrapper {
        width: 100%;
        .content_inner_wrapper {
          width: 100%;
          padding: 30px 15px;
          .back_btn_wrapper {
            margin-bottom: 15px;
            button {
            }
          }
          .image_container {
            img {
            }
          }
          .text_content_wrapper {
            .text_content {
            }
          }
          .otp_input_wrapper {
            margin: 15px 0;
            .otp_input_inner_wrapper {
              .single_input {
                input[type="text"] {
                }
              }
            }
          }
          .btn_wrapper {
            margin: 10px 0;
            button {
            }
          }
          .resend_btn_wrapper {
            margin: 10px 0;
            button {
            }
          }
        }
      }
    }
  }
}
