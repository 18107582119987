.store-details-inner-wrapper {
  display: flex;
  border-radius: 1rem;
  background: linear-gradient(0deg, #e8ecff 0%, #ffffff 100%) 0px -348.667px / 99.766%
    552.814% no-repeat;

  .content-and-btn-wrapper {
    padding: 2.37rem 3.94rem 4.62rem 3.62rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content {
      .heading {
        color: #121212;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1.28px;
      }

      .sub-heading {
        color: #5a5a5a;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.2px;
      }
    }
    .target-icon {
      img {
        width: 115px;
        height: 115px;
      }
    }
  }
}
.landing-page-package_offering_component_inner_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 58px 50px 27px 50px;
  .package_outer {
    border-radius: 1rem;
    border: 0.6px solid #d3d3d3;
    background: #fff;
    width: calc(33.33% - 0.85rem);
    margin: 0 1.25rem 1.25rem 0;
    padding: 0.75rem 0.75rem 1.19rem 0.75rem;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover {
      border: 0.8px solid #707070;
      box-shadow: -2px -2px 6px 0px rgba(0, 0, 0, 0.12),
        2px 2px 6px 0px rgba(0, 0, 0, 0.12);
    }

    .package_name_banner {
      position: relative;
      margin-bottom: 1rem;

      img {
        width: 100%;
      }

      p {
        font-size: 0.83894rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.00838rem;
        border-radius: 0.13981rem;
        padding: 0.27963rem 0.55931rem;
        display: inline-block;
        position: absolute;
        top: 1.3rem;
        left: 8.5rem;
      }

      h1 {
        color: #fff;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.01375rem;
        position: absolute;
        top: 3.3rem;
        left: 8.5rem;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Limiting to two lines */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .package_description {
      color: #505050;
      font-family: "Poppins";
      font-size: 0.875rem;
      font-weight: 400;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Limiting to two lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 1rem;
      height: 2.75rem;
    }

    .package_flex {
      margin-bottom: 1.56rem;
      min-height: 3.21rem;

      .duration_div {
        img {
          width: 1.25rem;
          margin-right: 0.5rem;
        }

        p {
          color: #121212;
          font-family: "Poppins";
          font-size: 0.875rem;
          font-weight: 400;
          line-height: normal;
          margin: 0;
        }
      }

      .discount_div {
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        background: #edffea;
        height: fit-content;
        margin-left: auto;
        margin-right: 0.75rem;

        img {
          width: 1rem;
          height: 1rem;
          margin-right: 0.25rem;
        }
        p {
          color: #148e00;
          font-family: "Poppins";
          font-size: 0.875rem;
          font-weight: 400;
          line-height: normal;
          margin: 0;

          span {
            font-weight: 600;
          }
        }
      }

      .price_div {
        p {
          margin: 0;
        }

        .initial_amount {
          color: #4b4b4b;
          font-family: "Poppins";
          font-size: 0.875rem;
          font-weight: 400;
          line-height: normal;
        }

        .final_amount {
          color: #121212;
          font-family: "Poppins";
          font-size: 1.25rem;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.0125rem;
        }
      }
    }

    .package_button_div {
      .details {
        color: #121212;
        text-align: center;
        font-family: "Poppins";
        font-size: 1rem;
        font-weight: 400;
        line-height: normal;
        padding: 0.75rem 0.5rem;
        border-radius: 0.25rem;
        border: 0.6px solid #121212;
        width: calc(50% - 0.5rem);
        margin-right: 1rem;
      }

      .buy {
        color: #fff;
        text-align: center;
        font-family: "Poppins";
        font-size: 1rem;
        font-weight: 600;
        line-height: normal;
        padding: 0.75rem 0.5rem;
        border-radius: 0.25rem;
        background: #b80f0d;
        width: calc(50% - 0.5rem);
      }
    }
  }
}

@media (max-width: 975px) {
  .landing-page-package_offering_component_inner_wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px 20px 20px;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_button_div
    .details {
    font-size: 0.725rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_button_div
    .buy {
    font-size: 0.725rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .duration_div
    img {
    width: 1rem;
    margin-right: 0.1rem;
    height: 1rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .duration_div
    p {
    font-size: 0.675rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .discount_div
    img {
    width: 0.7rem;
    height: 0.7rem;
    margin-right: 0.15rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .discount_div
    p {
    font-size: 0.675rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .price_div
    .final_amount {
    font-size: 0.925rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .price_div
    .initial_amount {
    font-size: 0.675rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_description {
    font-size: 0.675rem;
  }
  .landing-page-package_offering_component_inner_wrapper .package_outer {
    margin: 0 0.725rem 0.725rem 0;
    padding: 0.75rem 0.75rem 0.919rem 0.75rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    h1 {
    font-size: 0.775rem;
    top: 2.3rem;
    left: 6.5rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    font-size: 0.6rem;
    border-radius: 0.13981rem;
    padding: 0.1963rem 0.1931rem;
    top: 0.3rem;
    left: 6.5rem;
  }
}
// @media (max-width: 1400px) {
//   .landing-page-package_offering_component_inner_wrapper
//     .package_outer
//     .package_name_banner
//     h1 {
//     font-size: 0.975rem;
//     top: 3.3rem;
//     left: 9.5rem;
//   }
//   .landing-page-package_offering_component_inner_wrapper
//     .package_outer
//     .package_name_banner
//     p {
//     left: 15.5rem;
//   }
// }

@media (max-width: 935px) {
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    left: 6.5rem;
  }
}
@media (max-width: 820px) {
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    left: 6.587rem;
  }
}
@media (max-width: 780px) {
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_flex
    .duration_div
    p {
    font-size: 0.595rem;
    margin-top: 0.175rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    font-size: 0.4rem;
    border-radius: 0.13981rem;
    padding: 0.193rem 0.191rem;
    top: 0.3rem;
    left: 6.5rem;
  }
}
@media (max-width: 769px) {
  .landing-page-package_offering_component_inner_wrapper {
    .package_outer {
      width: calc(50% - 1rem);
      margin-right: 1rem;
    }
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    left: 6.5rem;
    top: 0.7rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer:nth-child(3n) {
    margin-right: 1rem;
  }
}
@media (max-width: 705px) {
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    left: 6.5rem;
  }
}
@media (max-width: 518px) {
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    left: 6.5rem;
  }
}

@media (max-width: 501px) {
  .store-details-inner-wrapper .content-and-btn-wrapper .content .heading {
    font-size: 22px;
  }
  .store-details-inner-wrapper .content-and-btn-wrapper .content .sub-heading {
    font-size: 14px;
  }
  .store-details-inner-wrapper .content-and-btn-wrapper .target-icon img {
    width: 97px;
    height: 82px;
  }
  .landing-page-package_offering_component_inner_wrapper .package_outer {
    width: calc(99% - 1rem);
    margin-right: 1rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    h1 {
    font-size: 0.875rem;
    top: 2.3rem;
    left: 10.5rem;
  }
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    left: 10.5rem;
  }
}
@media (max-width: 410px) {
  .landing-page-package_offering_component_inner_wrapper
    .package_outer
    .package_name_banner
    p {
    left: 10.5rem;
  }
}
