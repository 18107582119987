.testsContentWrapper {
  .testsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.19rem 1.5rem;
    border-radius: 0.75rem;
    border: 0.6px solid #aeaeae;
    background: #f6f6f7;
    margin-bottom: 1.5rem;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // width: 14%;
      cursor: pointer;
      .leftSide {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .section-heading {
        color: #121212;
        font-family: Poppins;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        display: flex;
        width: 100%;

        .paperNaame {
          margin-left: 10px;

          span {
            margin-right: 5px;
            color: #828282;
          }
        }

        .paperIdd {
          span {
            margin-right: 5px;
            color: #828282;
          }
          margin-left: 30px;
        }
      }
    }

    .right {
      // width: 37%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .downloadReportBtn {
        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 15px;
          background: #ffffff;
          border: 1px solid #b50503;
          border-radius: 6px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          color: #b50503;
          .text {
            margin-right: 10px;
          }
          img {
            width: 15px;
            height: 15px;
            // margin-bottom: 7px;
          }
        }
      }
      .date {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #696969;
      }
      .obj-type {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #696969;
      }
    }

    .rightSide {
      text-align: right;
      width: 37%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .downloadReportBtn {
        button {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 15px;
          background: #ffffff;
          border: 1px solid #b50503;
          border-radius: 6px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          color: #b50503;
          .text {
            margin-right: 10px;
          }
          img {
            width: 15px;
            height: 15px;
            // margin-bottom: 7px;
          }
        }
      }
      .date {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #696969;
      }
      .obj-type {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #696969;
      }
    }
  }

  .testsContentInrWrapper {
    border-radius: 0.75rem;
    border: 0.6px solid #aeaeae;
    background: #f6f6f7;

    .tests_contentWpr {
      .testsContentTabBox {
        .testsContentTab {
          margin: 2.13rem 0 1.5rem 0;

          .testsContentUl {
            border-radius: 0.5rem;
            border: 0.6px solid #e0e0e0;
            background: #fff;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3.5rem;
            padding: 0.62rem 0.62rem 0.2rem 0.62rem;
            width: fit-content;

            li {
              border: none;
              margin-right: 0;

              button {
                border: none;
                background-color: transparent;

                color: #585858;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .nav-link.active {
                color: #b50303;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

                border-bottom: 2px solid #b50303;
              }
            }
          }
        }
        .testsTabContent {
          .tab-content {
          }
        }
      }
    }
  }
}
