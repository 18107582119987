.OtsTestListWrapper {
  background-color: #ffffff;
  min-height: calc(100vh);

  .OtsTestList {
    padding-top: 70px;
    padding-bottom: 20px;

    .ots_list_wrapper {
      padding: 20px;
      margin: 20px 33px;
      border-radius: 16px;
      background: rgba(238, 239, 240, 0.50);
      min-height: 70vh;

      .ots_list_header_wrapper {
        margin-bottom: 25px;

        .ots_header{
          margin-top: 15px;
        }

        .backBtn {
          // display: inline-block;
          cursor: pointer;
          // width: 100%;
          max-width: fit-content;
          img {
          }
        }
        .ots_test_list_heading_text {
          color: #000;
          font-family: "Poppins";
          font-size: 22px;
          font-weight: 500;
          line-height: normal;
          width: 100%;
          // display: inline-block;
          text-align: center;
          margin-top: -31.5px;
          p{
            margin-bottom: 0;
          }
        }
        .ots_test-categories {
          display: flex;
          max-width: max-content;
          margin: 20px auto 0;
          overflow: hidden;
          border-radius: 8px;
          border: 0.6px solid #E0E0E0;
          background: #FFF;

          .ots_single_select_category {
            &:last-child {
              margin-right: 0px;
            }

            input[type="radio"] {
              opacity: 0;
              position: fixed;
              width: 0;
            }

            label {
              color: #585858;
              font-family: "Poppins";
              font-size: 14px;
              font-weight: 400;
              line-height: normal;
              padding: 10px 20px;
              cursor: pointer;
            }

            input[type="radio"]:checked + label {
              color: #B50303;
              font-weight: 500;
              border-bottom: 3px solid #B50303;
            }
          }
        }
      }

      .ots_detail_wrapper {
        .otsTabBox {
          position: relative;

          // width: 80%;
          margin: 0 auto;
          justify-content: center;
          // height: 50px;

          display: flex;

          .ots_tab_list {
            // margin-left: 296px;

            ul {
              margin: 0 auto;
              width: max-content;
              border-radius: 24px;
              border: 0.6px solid #E0E0E0;
              background: #FFF;
              overflow: hidden;

              li {
                border: none;

                button {
                  color: #585858;
                  font-family: "Poppins";
                  font-size: 14px;
                  font-weight: 400;
                  line-height: normal;
                  padding: 10px 20px;
                  min-width: 160px;
                }

                .nav-link.active {
                  background-color: #263238;
                  color: #FFF;
                  font-weight: 500;
                  border: none;
                  border-radius: 0;
                }
              }
            }
          }
          // .upcoming_previous_filter {
          //   font-family: "Poppins";
          //   font-style: normal;
          //   font-weight: 400;
          //   font-size: 16px;
          //   line-height: 24px;

          //   color: #5f5f5f;

          //   background: #f5f4f8;
          //   border: 0.6px solid #cbcbcb;
          //   border-radius: 20px;
          //   padding: 5px 10px;
          //   cursor: pointer;

          //   .upcoming_previous_filter_img {
          //     margin-right: 10px;
          //   }
          // }

          .topic_content {
            // position: relative;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height */

            color: #5f5f5f;

            background: #f5f4f8;
            border: 0.6px solid #cbcbcb;
            border-radius: 20px;
            padding: 5px 15px;
            cursor: pointer;
            // margin-bottom: 15px;
            background: red;

            span {
              // margin-right: 10px;
              // background: red;
            }
          }

          .modal-container {
            position: absolute;

            width: 100%;

            left: 90%;
            top: 80%;
          }

          .modal-backdropp {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
          }

          .modall {
            position: absolute;

            transform: translateX(-50%);
            border-radius: 0.3rem;
            box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
            outline: 0;
          }

          .modal-dialogg {
          }

          .modal-content {
            .modal-body {
              padding: 0px;
              width: 100%;
              .test_type {
                padding: 10px 20px;

                .upcoming_previous_tests_label {
                  display: inline;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;

                  color: #000000;
                }

                .upcoming_previous_tests_input {
                  display: inline;
                  margin-left: 30px;

                  input[type="radio"]:checked + label {
                    // color: #6cef48;
                  }
                }

                .ots_previous_tests input {
                  margin-left: 17.5px;
                }
              }
            }
          }
        }

        // .left_side_content_wrapper {
        //   text-align: -webkit-center;
        //   margin-bottom: 25px;
        //   text-align: center;

        //   .tests_type_collection_wrapper {
        //     display: inline-block;
        //     background-color: #f5f4f8;
        //     border-radius: 25px;
        //     overflow: hidden;

        //     .single_select_test_type {
        //       width: auto;
        //       min-width: 160px;
        //       display: inline-block;

        //       input[type="radio"] {
        //         opacity: 0;
        //         position: fixed;
        //         width: 0;
        //       }

        //       label {
        //         background-color: #fafafd;
        //         padding: 0.5rem 1rem;
        //         font-size: 15px;
        //         font-weight: 500;
        //         letter-spacing: 0.2px;
        //         color: #6b6876;
        //         cursor: pointer;
        //         width: 100%;
        //         text-align: center;

        //       }

        //       input[type="radio"]:checked + label {
        //         background-color: #ff933a;
        //         border: none;
        //         color: #ffffff;
        //       }
        //     }
        //   }
        // }

        .left_side_content_wrapper {
          text-align: -webkit-center;
          // margin-bottom: 25px;
          text-align: center;
          margin-top: 20px;
          // margin-left: 10px;

          .tests_type_collection_wrapper {
            // display: inline-block;
            // background-color: #f5f4f8;
            // border-radius: 25px;
            // overflow: hidden;

            .single_select_test_type {
              // width: auto;
              // min-width: 160px;
              margin-right: 15px;
              display: inline-block;

              input[type="radio"] {
                opacity: 0;
                position: fixed;
                width: 0;
              }

              label {
                color: #585858;
                font-family: "Poppins";
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
                padding: 10px 20px;
                border-radius: 20px;
                border: 1px solid #E0E0E0;
                background: #FFF;
              }

              input[type="radio"]:checked + label {
                color: #B50503;
                font-weight: 500;
                border: 1px solid #B50503;
              }
            }
          }
        }

        .otsTabContent {
          min-height: 65vh;
          padding: 35px 0 0;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .OtsTestListWrapper {
    .OtsTestList {
      padding-top: 30px;
      .ots_list_wrapper{
        padding: 10px;
        .ots_list_header_wrapper{
          margin-bottom: 15px;
          .ots_test-categories {
              margin-top: 10px;
              max-width: none;
              overflow-x: scroll;
          
              .ots_single_select_category {
                label{
                  width: max-content;
                  margin-right: 15px;
                }
              }
          }
        }
        .ots_detail_wrapper{
          .otsTabBox{
            width: 100%;
            .ots_tab_list{
              margin-left: auto;
              margin-right: auto;
              ul{
                width: 100%;
                li{
                  button{
                    min-width: 130px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}