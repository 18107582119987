.heading-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 22px;
  line-height: 33px;
  margin: 0px 0px 20px 20px;
  color: #121212 !important;
}
.Doughnut-chart-container{
  width: 100%;
  height:60vh;
  margin: 0 auto;
  padding: 20px
}
.type_collection_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  // width: max-content;
  // background: #f5f4f8;
  // border-radius: 4px 0px 0px 4px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0.25rem;
border: 0.6px solid #E0E0E0;

background: #FFF;

width: max-content;
  // margin-top: 30px;
  margin-bottom: 20px;

  .single_select_type {
    // border: 1px solid #737373;
    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label {
      padding: 6px 20px;
      letter-spacing: 0.2px;
      color: #585858;
      cursor: pointer;
      display: flex;
      align-items: center;

font-family: 'Poppins';
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

input[type="radio"]:checked + label {
  background: #263238;
  font-weight: 500;
      color: #FFFFFF;
      outline: 0.6px solid #263238;
      // border: 1px solid #ff933a;
    }
  }
}

@media (max-width: 1199.98px) {
  .type_collection_wrapper{
    
    .single_select_type{
      margin-bottom: 0.3rem;
    }
  }
}
@media (max-width: 575.98px) {
  .Doughnut-chart-container{
    width: 100%;
    height:42vh;
    margin: 0 auto;
    padding: 20px
  }
}
