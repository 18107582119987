.adaptiveCpsWrapper {
  background-color: #ffffff;
  min-height: 100vh;

  .summary_outer {
    padding-top: 101px;

    .summary_wrapper {
        border-radius: 1rem;
        background: rgba(238, 239, 240, 0.50);
        width: 78.75%;
        padding: 1.75rem 5.95rem 4.25rem;
        margin: 0 auto 3.7rem;

        .heading_div {
            h3 {
                margin: 0 auto 2.25rem;
                color: #000;
                font-family: "Poppins";
                font-size: 1.375rem;
                font-weight: 500;
                line-height: normal;
            }
        }

        .cards_wrapper {
            display: flex;
            flex-wrap: wrap;

            .summary_card {
                padding: 1.8rem;
                border-radius: 0.5rem;
                border: 0.8px solid #E9E9E9;
                background: #FFF;
                text-align: center;
                width: calc( 33.33% - 1rem );
                margin-right: 1.25rem;
                margin: 0 1.25rem 1.25rem 0;

                &:nth-child(3n) {
                    margin-right: 0;
                }

                p {
                    &:first-child {
                        color: #505050;
                        font-family: "Poppins";
                        font-size: 1.125rem;
                        font-weight: 400;
                        line-height: normal;
                        margin-bottom: 0.5rem;
                    }

                    &:last-child {
                        color: #121212;
                        font-family: "Poppins";
                        font-size: 1.5rem;
                        font-weight: 600;
                        line-height: normal;
                        letter-spacing: 0.03rem;
                        margin: 0;
                    }
                }
            }
        }
    }

    .button_div {
        display: flex;
        justify-content: center;
        width: 100%;

        button {
          width: 14rem;
          padding: 1rem 3rem;
          border-radius: 0.5rem;
          color: #B50303;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 500;
          line-height: normal;
          border: 1px solid #B50303;
          margin-right: 1.5rem;
        }
    }
  }

  // .adaptiveCpsInrWrapper {
  //   padding-top: 101px;
  //   padding-bottom: 100px;
  //   .adaptiveCpsInr {
  //     margin: 20px 50px 0px;
  //     background-color: #fff;
  //     border-radius: 10px;
  //     padding: 20px 20px 70px 20px;

  //     .backBtn {
  //       cursor: pointer;

  //       img {
  //       }
  //     }

  //     h3 {
  //       text-align: center;
  //       font-size: 25px;
  //       font-weight: 500;
  //       margin-bottom: 45px;
  //       margin-top: -25px;
  //     }

  //     .adaptiveCps {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       flex-direction: column;

  //       .adaptiveCpsContent {
  //         margin-bottom: 20px;
  //         .aCpsFirstText {
  //           text-align: center;
  //           font-family: "Poppins";
  //           font-style: normal;
  //           font-weight: 500;
  //           font-size: 19px;
  //           line-height: 20px;

  //           color: #000000;
  //         }

  //         .aCpsSecondText {
  //           text-align: center;
  //           font-family: "Poppins";
  //           font-style: normal;
  //           font-weight: 400;
  //           font-size: 14px;
  //           line-height: 1px;
  //           text-align: center;
  //           margin-top: 0px;

  //           color: #717171;
  //         }
  //       }

  //       .adaptiveCpsImg {
  //         img {
  //           width: 100px;
  //           height: 110px;
  //         }
  //       }

  //       .adaptiveCpsMarksList {
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         margin-top: 4rem;
  //         width: 100%;
  //         .adaptiveCpsMarkItem {
  //           background: #fafafa;
  //           border: 0.6px solid #e1e1e1;
  //           border-radius: 6px;
  //           margin-right: 1.5em;
  //           padding: 0.625em;
  //           width: 137px;
  //           height: 80px;
  //           .adaptiveCpsMarks {
  //             text-align: center;
  //             font-family: "Poppins";
  //             font-style: normal;
  //             font-weight: 500;
  //             font-size: 20px;
  //             color: #000000;
  //             padding-bottom: 5px;
  //           }

  //           .adaptiveCpsMarkLabel {
  //             text-align: center;
  //             font-family: "Poppins";
  //             font-style: normal;
  //             font-weight: 400;
  //             font-size: 14px;
  //             color: #585858;
  //           }
  //         }
  //       }

  //       .adaptiveCpsBtns {
  //         display: flex;
  //         justify-content: space-between;
  //         margin-top: 3em;
  //         width: 60%;

  //         .adaptvieCpsBackBtn {
  //           width: 45%;
  //           border: 1px solid #6d48ef;
  //           text-align: center;
  //           border-radius: 5px;
  //           button {
  //             font-family: "Poppins";
  //             font-style: normal;
  //             font-weight: 500;
  //             font-size: 16px;
  //             line-height: 24px;
  //             color: #6d48ef;
  //             height: 46px;
  //           }
  //         }

  //         .adaptiveCpsGetCpsBtn {
  //           width: 45%;
  //           background: #6d48ef;
  //           text-align: center;
  //           border-radius: 5px;
  //           button {
  //             font-family: "Poppins";
  //             font-style: normal;
  //             font-weight: 500;
  //             font-size: 16px;
  //             line-height: 24px;
  //             // padding: 10px 100px;
  //             color: #ffffff;
  //             background: #6d48ef;

  //             height: 46px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
