.allTestsPerformanceReport {
  width: 100%;
  margin-top: 2.5rem;
  // border-radius: 1rem;

  table {
    background: #f6f6f7;
    border: 0.6px solid #121212;
    // border-radius: 1rem;
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;

    width: 100%;

    .table_topHeadings {
      // border-top-left-radius: 50px !important;
      overflow: hidden;

      th {
        padding: 10px;
        text-align: center;
        color: #fff;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .tablebtmHeadings {
      // border-bottom: 0.5px solid #000;
      // background: #fffcde;
      // background: #fff599;
      text-align: center;
      // .border_right {
      //   border-right: 1.7px solid #565656;
      // }
      td {
        // border-right: 0.5px solid #4e4e4e;
        // border-bottom: 0.5px solid #000000;
        padding: 7px 0px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #000000;
      }
      td:last-child {
        border-right: none;
      }
    }

    .tableCells {
      width: 100%;
      border-bottom: 0.5px solid #000000;

      td {
        padding: 5px 5px;
        border-right: 0.2px solid #4e4e4e;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #000000;
      }
      td:last-child {
        border-right: none;
        // border-bottom: none !important;
      }
      td:first-child {
        border-bottom: none !important;
      }
    }

    .tableCells:last-child {
      border-bottom: none;
      border-bottom-left-radius: 11px !important;
      border-bottom-right-radius: 11px !important;

      td:first-child {
        border-bottom-left-radius: 11px !important;
      }

      td:last-child {
        border-bottom-right-radius: 11px !important;
      }
    }

    .testDetails {
      background: #f6f6f7;
    }
    // .testDetailsL {
    //   border-right: 1.7px solid #565656 !important;
    // }
    // .physics {
    //   background: #e3f8fe;
    // }
    // .physicsL {
    //   border-right: 1.7px solid #565656 !important;
    // }

    // .chemistry {
    //   background: #e4fffa;
    // }
    // .chemistryL {
    //   border-right: 1.7px solid #565656 !important;
    // }
    // .maths {
    //   background: #fff5f9;
    // }
    // .mathsL {
    //   border-right: 1.7px solid #565656 !important;
    // }

    .overAll {
      background: #f6f6f7;
    }
  }
}

.scrollmenu {
  overflow: auto;
  // white-space: nowrap;
  // border-radius: 11px !important;
  border: 1.7px solid #4e4e4e;
  position: relative;
  // width: 88%;
  margin: 50px auto;
  // margin: 0 auto;
  // padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  .allTestsPerformanceReportConent {
    // width: 88%;
    width: 1270px;

    table {
      margin-left: -12px;
      // width: 1270px;

      width: 100%;

      .table_topHeadings {
        th {
          padding: 10px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          text-align: center;
        }
      }

      .tablebtmHeadings {
        // background: #fff599;
        text-align: center;
        .border_right {
          border-right: 1.7px solid #565656;
        }
        td {
          border-right: 0.5px solid #4e4e4e;
          // border-bottom: 0.5px solid #000000;
          padding: 7px 0px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #000000;
          // min-width: 30.75px;
        }
        td:last-child {
          border-right: none;
        }
      }

      .tableCells {
        border-bottom: 0.5px solid #000000;
        // background: red;

        td {
          padding: 7px 7px;
          border-right: 0.2px solid #4e4e4e;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 15px;
          color: #000000;
        }
        td:last-child {
          border-right: none;
        }
        td:first-child {
          border-bottom: none !important;
          border-left: none !important;
        }
      }

      .tableCells:last-child {
        border-bottom: none;
        // background: red;

        td:first-child {
          // border-bottom-left-radius: 11px !important;
        }

        td:last-child {
          // border-bottom-right-radius: 11px !important;
        }
      }

      .testDetails {
        background: #f7f7f7;
      }
      .testDetailsL {
        border-right: 1.7px solid #565656 !important;
      }
      .physics {
        background: #e3f8fe;
      }
      .physicsL {
        border-right: 1.7px solid #565656 !important;
      }

      .chemistry {
        background: #e4fffa;
      }
      .chemistryL {
        border-right: 1.7px solid #565656 !important;
      }
      .zoology {
        background: #faf5ff;
      }
      .zoologyL {
        border-right: 1.7px solid #565656 !important;
      }

      .botony {
        background: #fff5f9;
      }
      .botonyL {
        border-right: 1.7px solid #565656 !important;
      }

      .overAll {
        background: #fff7f1;
      }
    }
  }
}

.scrollmenu::-webkit-scrollbar {
  width: 5px !important;
  height: 17px;
}
.scrollmenu::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px #f5f4f8;
  box-shadow: inset rgba(98, 98, 98, 0.29) 10 5px #f5f4f8;
}

/* Handle */
.scrollmenu::-webkit-scrollbar-thumb {
  background: rgba(109, 109, 109, 0.53);
  border-radius: 20px;
}

/* Handle on hover */
.scrollmenu::-webkit-scrollbar-thumb:hover {
  background: rgba(98, 98, 98, 0.29);
}
