// .exam_cracker_test_content_component_wrapper {
//   padding-top: 0px;
//   min-height: 100vh;

//   .test_content_component_inner_wrapper {
//   }
// }

.test_component_wrapper .test_component_inner_wrapper {
  background-color: #fff !important;
}
