.ques_paper_test_questions_solution_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;
          .section_content_wrapper_backdrop {
            display: none;
          }

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;

          .left_side_content_wrapper {
            width: 30%;
            background-color: #ffffff;
            order: 2;

            .sub_section_1_wrapper {
              flex: 0 0 100%;
              .closebtn {
                display: none;
              }

              .text_content_wrapper {
                background-color: #ffffff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                border-bottom: 1px solid #d1d1d1;
                height: 8vh;

                .text_content {
                  margin: 0;
                  font-size: 16px;
                  font-weight: 600;
                  color: #0f0404;
                }

                .countdown_timer_container {
                  span {
                    label {
                      color: #6d48ef;
                      font-size: 15px;
                    }
                  }
                }
              }

              .questions_serial_number_collection {
                font-family: "Poppins";
                overflow-y: scroll;
                overflow-x: hidden;
                max-height: 75vh;
                padding: 0.5rem;
                margin-bottom: 0;
                background: #f6f6f7;
                .single_question_number_wrapper1 {
                  display: block !important;
                  margin: 0.875rem 5px !important;
                  .unanswered_lbl1 {
                    height: 80px;
                    overflow: hidden;
                    display: flex !important;
                    gap: 0.5rem;
                    align-items: center !important;
                    justify-content: space-between !important;
                    .questionBox1 {
                      display: flex !important;
                      p {
                        overflow: visible;
                        display: block !important;
                      }
                      .QBox {
                        display: inline-block;
                      }
                    }
                  }
                }
                .single_question_number_wrapper {
                  margin: 5px;
                  display: inline-block;

                  .arrow {
                    width: 8px;
                  }

                  .unanswered_lbl {
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid #d9d9d9;
                    background: #fff;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    min-width: 40px;

                    .questionBox {
                      height: 100%;
                      display: flex;
                      align-items: center;
                      overflow-x: hidden;

                      .QBox {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 100%;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        /* number of lines to show */
                        -webkit-box-orient: vertical;

                        p {
                          margin-left: 2px;
                          margin-bottom: 0px;

                          math {
                            display: inline-block;
                          }

                          img {
                            display: none;
                          }

                          table {
                            width: 100% !important;
                          }
                        }
                      }
                    }
                  }

                  .answered_lbl {
                    border: 0.6px solid #8ae09f;
                    background: #c8f1d2 !important;
                  }

                  .review_lbl {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    padding: 10px;
                    border-radius: 8px;
                    background: #ffc4c4;
                    border: 1px solid #ffc4c4;
                    cursor: pointer;
                  }

                  .skip_lbl {
                    border: 1px solid #ffa68c;
                    background: #ffc5b4;
                  }

                  .current_lbl {
                    border: 1px solid #ffa68c;
                    cursor: pointer;
                    background: #fff;
                  }

                  .isGussedAnswer {
                    border: 1px solid #b184ff;

                    background: #dbc9fb !important;
                  }
                }
              }
            }

            .sub_section_2_wrapper {
              background-color: #ffffff;
              padding: 13px 27px 13px 16px;
              height: 17vh;

              .question_marking_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding: 5px;
                .single_marking {
                  display: flex;
                  align-items: center;
                  flex: 1 1 50%;
                  margin-bottom: 10px;

                  &:last-child {
                    margin-right: 0px;
                  }

                  .box_pic {
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    margin-right: 5px;
                  }

                  .box_pick_background_1 {
                    border: 1px solid #8ae09f;
                    background: #c8f1d2;
                  }

                  .box_pick_background_2 {
                    background-color: #f99746;
                  }

                  .box_pick_background_3 {
                    border: 1px solid #d9d9d9;
                    background: #fff;
                  }

                  .box_pick_background_4 {
                    border: 1px solid #ffa68c;
                    background: #ffc5b4;
                  }

                  .box_pick_background_5 {
                    border: 1px solid #b184ff;
                    background: #dbc9fb;
                  }

                  .question_marking_text {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    color: #000000;
                  }
                }
              }
            }
          }

          .right_side_content_wrapper {
            width: 70%;
            overflow-x: hidden;
            position: relative;
            background-color: #ffffff;
            height: 100vh;
            padding: 0px;
            box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06);
            z-index: 10;
            display: flex;
            flex-direction: column;

            .right_btn_wrapper {
              display: none;
            }

            .sub_section_wrapper {
              display: flex;
              flex-direction: column;
              overflow: hidden;
              margin: 0;
              padding: 10px 35px;
              height: 100%;

              .topicWrapper {
                color: #121212;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 15px;

                span {
                  color: #5e5e5e;
                  font-weight: 400;
                }
              }

              .subTopicWrapper {
                color: #121212;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 15px;

                span {
                  color: #5e5e5e;
                  font-weight: 400;
                }
              }

              .typeLanguageWrapper {
                display: block !important;
                color: #121212;
                font-family: "Poppins";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 30px;

                span {
                  color: #5e5e5e;
                  font-weight: 400;
                }
              }

              .sub_sub_section_wrapper_1 {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .right_side_content_wrapper {
                  flex: 0 0 100%;
                  display: flex;
                  justify-content: flex-end;

                  .review_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }

                  .bookmark_image_wrapper {
                    margin: 0 10px;

                    img {
                      cursor: pointer;
                    }
                  }
                }
              }

              .testProgressIndicator {
                background: #fff;
                padding: 5px 10px;
                border-radius: 8px;
                margin-bottom: 20px;

                h4 {
                  font-size: 14px;
                  color: #000;
                  margin-bottom: 16px;
                }

                .ProgressBar_Line {
                  position: relative;
                }

                .ProgressBar_Line span {
                  position: absolute;
                  top: -16px;
                  font-size: 14px;
                }

                .progress {
                  height: 8px;
                  margin-bottom: 5px;

                  .progress-bar {
                    background: #ff933a;
                    border-radius: 9px;
                  }
                }

                .questionPercentage {
                  display: flex;
                  justify-content: space-between;

                  span {
                    color: #707070;
                    font-size: 14px;
                  }
                }
              }

              .timer_container {
                margin: 25px 0 15px;
                padding: 15px 25px;
                border-radius: 8px;
                background: #263238;

                .question_counter {
                  color: #fff;
                  font-family: "Poppins";
                  font-size: 16px;
                  font-weight: 400;
                  line-height: normal;
                  letter-spacing: 0.16px;
                }

                .timer_div {
                  color: #f7b201;
                  font-family: "Poppins";
                  font-size: 18px;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: 0.36px;
                }

                .right_button {
                  .bookmark_image_wrapper {
                    margin-right: 35px;
                  }

                  button {
                    color: #fff;
                    font-family: "Mukta";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    width: 30px;
                    height: 20px;
                    margin-top: -3px;
                  }
                }
              }

              .subject_select_container {
                p {
                  color: #121212;
                  font-family: "Poppins";
                  font-size: 14px;
                  font-weight: 500;
                  line-height: normal;
                  display: inline-block;
                  margin: 0;
                }

                .option_select {
                  display: flex;
                  align-items: center;
                  margin-bottom: 15px;

                  .label_container {
                    border-radius: 8px;
                    border: 0.6px solid #cecece;
                    background: #f6f6f7;
                    padding: 4px;
                    margin-left: 15px;
                    display: flex;
                    width: fit-content;

                    input[type="radio"] {
                      opacity: 0;
                      position: fixed;
                      width: 0;
                    }

                    label {
                      padding: 7px 14px;
                      // margin-right: 20px;
                      color: #646666;
                      font-family: "Poppins";
                      font-size: 14px;
                      font-weight: 400;
                      line-height: normal;
                    }

                    input[type="radio"]:checked + label {
                      border-radius: 4px;
                      background: #263238;
                      color: #fff;
                      font-family: "Poppins";
                      font-size: 14px;
                      font-weight: 500;
                      line-height: normal;
                    }
                  }
                }
              }

              .question_topic_container {
                p {
                  font-family: "Poppins";
                  font-size: 14px;
                  line-height: normal;
                  color: #121212;
                  font-weight: 600;
                  margin-bottom: 0;

                  span {
                    font-weight: 400;
                    color: #5e5e5e;
                  }
                }
              }

              .question_details {
                margin: 50px 0 20px;

                .question_number {
                  color: #121212;
                  font-family: "Poppins";
                  font-size: 14px;
                  font-weight: 600;
                  line-height: normal;
                  // margin-bottom: -10px;
                }

                p {
                  color: #353535;
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 26px; /* 162.5% */
                  letter-spacing: 0.08px;
                  margin: 0;
                }
              }

              .sectionWiseTestWrapper {
                .subjectWiseTest {
                  background: #f5f4f8;
                  border: 0.8px solid #c9c9c9;
                  border-radius: 24px;
                  max-width: -moz-fit-content;
                  max-width: fit-content;
                  display: flex;
                  padding: 2px;
                  border-radius: 36px;
                  justify-content: space-between;
                  align-items: center;
                  margin: 0px auto;

                  .single_select_subject {
                    input[type="radio"] {
                      opacity: 0;
                      position: fixed;
                      width: 0;

                      &:checked + label {
                        background-color: #ff933a;
                        border: none;
                        color: #ffffff;
                        border-radius: 19px;
                        font-family: "Poppins";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 23px;
                      }
                    }

                    label {
                      padding: 3px;
                      font-size: 15px;
                      letter-spacing: 0.2px;
                      color: #6b6876;
                      cursor: pointer;
                      min-width: 150px;
                      text-align: center;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 500;
                    }
                  }
                }

                .sectionWiseTest {
                  display: flex;
                  width: max-content;
                  margin: 0 auto;
                  margin-bottom: 0.8rem;
                  margin-top: 0.4rem;
                }
              }
              .questionWrapper {
                border-radius: 4px;
                padding: 10px;
                width: 100%;
                height: 65vh;
                overflow-y: auto;
                overflow-x: hidden;

                .sub_sub_section_wrapper_3 {
                  .single_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    .single_select {
                      width: calc(50% - 10px);
                      margin: 10px 20px 10px 0px;

                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }

                      .classCorrect {
                        border: 1px solid #5ddd9b;
                        background: #e1f1ec !important;
                      }

                      .classWrong {
                        border: 1px solid #f93030;
                        background: #f6e0e4 !important;
                      }

                      input[type="checkbox"] {
                        opacity: 0;
                        position: fixed;
                        width: 0;
                      }

                      .single_option_wrapper {
                        min-height: 50px;
                        background: #ffffff;
                        border-radius: 4px;
                        border: 0.6px solid #939393;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 20px;
                        cursor: pointer;
                        overflow: hidden;

                        .MJX-TEX {
                          text-wrap: wrap;
                        }

                        .option_initial {
                          margin-right: 10px;

                          .text_content_2 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #acacac;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #000;

                            p {
                              margin-bottom: 0px;

                              img {
                                max-width: 300px;
                              }
                            }
                          }

                          .rightarrow {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #000;

                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      input[type="checkbox"]:checked + .single_option_wrapper {
                        border: 1px solid #fbab35;
                        background: #fff9ef;

                        .option_initial {
                          .text_content_2 {
                            font-weight: 600;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            font-weight: 600;

                            .image-syle {
                              height: 100px;
                              width: 300px;
                            }
                          }
                        }
                      }
                    }
                  }

                  .hide {
                    display: none !important;
                  }

                  .show {
                    display: flex !important;
                  }

                  .show2 {
                    display: block !important;
                  }

                  //multi-select
                  .multi_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;

                    .single_select {
                      width: calc(50% - 8px);
                      margin: 10px 16px 10px 0px;

                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }

                      input[type="checkbox"] {
                        opacity: 1;
                        position: fixed;
                        width: 0;
                      }

                      .single_option_wrapper {
                        min-height: 50px;
                        border: solid 1px #f0eef8;
                        background-color: #fafafd;
                        border-radius: 15px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 10px;
                        cursor: pointer;
                        overflow: hidden;

                        .option_initial {
                          margin-right: 10px;

                          .text_content_2 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;

                            .image-syle {
                              height: 100px;
                              width: 300px;
                            }
                          }

                          .rightarrow {
                            margin: 0;
                            font-size: 16px;
                            font-weight: 500;
                            color: #6b6876;

                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      input[type="checkbox"]:checked + .single_option_wrapper {
                        border: 2px solid #6d48ef;

                        .option_initial {
                          .text_content_2 {
                            font-weight: 600;
                          }
                        }

                        .option_final {
                          .text_content_3 {
                            font-weight: 600;
                          }
                        }
                      }
                    }
                  }
                  input[type="checkbox"]:checked + .single_option_wrapper {
                    border: 2px solid #6d48ef;

                    .option_initial {
                      .text_content_2 {
                        font-weight: 600;
                      }
                    }

                    .option_final {
                      .text_content_3 {
                        font-weight: 600;
                      }
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_4 {
                margin-bottom: 15px;

                .checkbox_wrapper {
                  input[type="checkbox"] {
                    margin-right: 10px;
                  }

                  label {
                    font-size: 13px;
                    vertical-align: text-top;
                    color: #09041a;
                  }
                }
              }

              .QuestionSolution {
                padding-top: 20px;

                div {
                  p {
                    margin-bottom: 2px;
                  }
                }
              }

              .sub_sub_section_wrapper_6 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }
            }
            .sub_sub_section_wrapper_5 {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-top: 0.8px solid #f2f2f2;
              padding: 15px 40px;

              .left_side_wrapper {
                margin: 0;
                padding: 0;

                .btn_wrapper {
                  text-align: left;

                  button {
                    border-radius: 4px;
                    border: 1px solid #b50503;
                    padding: 10px 20px;
                    color: #b50503;
                    font-family: "Poppins";
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;
                    width: fit-content;
                  }
                }
              }

              .right_side_wrapper {
                padding: 0;

                .btn_wrapper {
                  text-align: right;

                  button,
                  a {
                    border-radius: 4px;
                    border: 0.6px solid #b91311;
                    background: #b91311;
                    color: #fff;
                    font-family: "Poppins";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    width: fit-content;
                    padding: 10px 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.onSubmitModal {
  text-align: center;

  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    .submit_Popup {
      img {
        width: 50px;
        margin-bottom: 20px;
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #6d48ef;
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #6d48ef;
        }

        .cancel {
          color: #6d48ef;
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: 1199.98px) {
  .ques_paper_test_questions_solution_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            .left_side_content_wrapper {
              .sub_section_1_wrapper {
                .questions_serial_number_collection {
                  .single_question_number_wrapper {
                    .unanswered_lbl {
                      font-size: 12px;
                      padding: 10px;
                    }
                  }
                }
              }
            }

            .right_side_content_wrapper {
              .sub_section_wrapper {
                .sub_sub_section_wrapper_2 {
                  h2 {
                    font-size: 22px;
                  }

                  .text_content_wrapper {
                    .questionBox {
                      .text_content {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .ques_paper_test_questions_solution_wrapper {
    .test_questions_view_inner_wrapper {
      .sec_2_wrapper {
        .sec_2_inner_wrapper {
          .section_content_wrapper {
            // flex-direction: column;
            .section_content_wrapper_backdrop {
              height: 100vh;
              width: 100vw;
              z-index: 1995;
              background-color: #000;
              opacity: 0.5;
              position: absolute;
              display: block;
            }
            @keyframes fadeIn {
              from {
                opacity: 0;
              }

              to {
                opacity: 1;
              }
            }
            .left_side_content_wrapperToggle {
              display: block !important;
              animation: fadeIn 200ms ease-in;
            }
            .left_side_content_wrapper {
              width: 70%;
              position: absolute;
              display: none;
              z-index: 1999;

              .sub_section_1_wrapper {
                position: relative;
                .closebtn {
                  display: inline-block;
                  position: absolute;
                  top: 0;
                  right: 0;
                }
                .questions_serial_number_collection {
                  .single_question_number_wrapper {
                    flex: 0 0 280px;
                  }
                }

                .text_content_wrapper {
                  margin-bottom: 10px;
                }
              }

              .sub_section_2_wrapper {
                margin-bottom: 10px;
              }
            }

            .right_side_content_wrapper {
              width: 100%;
              padding: 0;
              .right_btn_wrapper {
                display: block;
                position: sticky;
                top: 0;
                z-index: 1000;
                background: inherit;
                padding: 10px;
                .togglebtn {
                  display: inline-block;
                  border: none;
                  outline: none;
                  border-radius: 8px;
                  background-color: #ffffff;
                  font-size: 16px;
                  font-weight: 600;
                  text-align: center;
                  color: #fff;
                  padding: 12px 20px;
                  background: #6d48ef;
                  position: sticky;
                  top: 0;
                  z-index: 1000;
                }
              }

              .sub_section_wrapper {
                padding: 0 10px 10px;
                .sub_sub_section_wrapper_1 {
                  .ots_right_side_content_wrapper {
                    .ots-_correct_incorrect_unattempt_percent {
                      padding: 10px;
                    }
                  }
                }
                .sub_sub_section_wrapper_2 {
                  .text_content_wrapper {
                    .questionBox {
                      .text_content {
                        line-height: 18px;
                      }
                    }
                  }
                }

                .sub_sub_section_wrapper_3 {
                  .single_select_option_collection_wrapper {
                    margin-bottom: 15px;

                    .single_select {
                      width: 100%;
                      margin: 0px 0px 10px 0px;
                    }
                  }
                }

                .testProgressIndicator {
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.right_side_content_wrapper .sub_section_wrapper::-webkit-scrollbar {
  width: 0px;
}

.sub_section_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .videoWrapper {
    .view_videoSolution_btn {
      background-color: #263238 !important;
      border-radius: 0.25rem;
    }
  }
  .video-container {
    border-radius: 0.25rem;
  }

  .sub_sub_section_wrapper_1 {
    .sub_sub_section_wrapper_1_card {
      background-color: #263238;
      padding: 0.5rem 1rem;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: var(--white);
      margin-bottom: 0.5rem;
      .countdown_timer_container {
        color: #f7b201;
        font-weight: 600;
      }
      p {
        margin-bottom: 0;
        letter-spacing: 0.01rem;
      }
      .markReview {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .m2_language {
          color: var(--white);
          font-weight: 700;
          width: 25px;
        }
        .BookmarkBtn {
          cursor: pointer;
        }
      }
    }
    h2 {
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.6rem;
    }

    .right_side_content_wrapper {
      flex: 0 0 100%;
      display: flex;
      justify-content: flex-end;

      .review_image_wrapper {
        margin: 0 10px;

        img {
          cursor: pointer;
        }
      }

      .bookmark_image_wrapper {
        margin: 0 10px;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .testProgressIndicator {
    background: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    margin-bottom: 20px;

    h4 {
      font-size: 14px;
      color: #000;
      margin-bottom: 16px;
    }

    .ProgressBar_Line {
      position: relative;
    }

    .ProgressBar_Line span {
      position: absolute;
      top: -16px;
      font-size: 14px;
    }

    .progress {
      height: 8px;
      margin-bottom: 5px;

      .progress-bar {
        background: #ff933a;
        border-radius: 9px;
      }
    }

    .questionPercentage {
      display: flex;
      justify-content: space-between;

      span {
        color: #707070;
        font-size: 14px;
      }
    }
  }

  .sectionWiseTestWrapper {
    .subjectWiseTest_heading {
      color: #121212;
      font-size: 0.875rem;
      font-weight: 500;
      margin: 0 !important;
      span {
        font-weight: 400;
        color: #5e5e5e;
      }
    }
    .subjectWiseTest_year {
      border-radius: 8px;
      border: 0.6px solid #cecece;
      padding: 0.5rem 1rem;
      line-height: 1;
      background: #f6f6f7;
    }
    .subjectWiseTest {
      border-radius: 0.5rem;
      border: 0.6px solid #cecece;
      background: #f6f6f7;
      max-width: -moz-fit-content;
      max-width: fit-content;
      display: flex;
      padding: 4px;
      justify-content: space-between;
      align-items: center;

      .single_select_subject {
        input[type="radio"] {
          opacity: 0;
          position: fixed;
          width: 0;

          &:checked + label {
            background-color: #263238;
            border: none;
            color: #ffffff;
            border-radius: 0.25rem;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
          }
        }

        label {
          padding: 3px;
          font-size: 15px;
          letter-spacing: 0.2px;
          color: #6b6876;
          cursor: pointer;
          min-width: 150px;
          text-align: center;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
        }
      }
    }

    .sectionWiseTest {
      display: flex;
      width: max-content;
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
    }
  }
  .questionWrapper {
    margin: 1rem 0;
    width: 100%;
    min-height: 40vh;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.5rem;
    .sub_sub_section_wrapper_2 {
      h2 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .text_content_wrapper {
        .typeLanguageWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;

          .stopwatch_timer {
            font-family: "Poppins" !important;
            color: #6d48ef;
            font-size: 15px;
            justify-content: center;
          }

          span {
            color: #707070;
            font-size: 12px;
          }

          .markReview {
            display: flex;

            .bookmark_image_wrapper {
              margin: 0px 10px;

              span {
                margin-right: 5px;
              }

              img {
                cursor: pointer;
              }
            }

            button {
            }
          }
        }

        .questionBox {
          display: flex;
          align-items: baseline;
          margin-bottom: 20px;

          .wrs_chemistry math,
          .wrs_chemistry mo,
          .wrs_chemistry mi,
          .wrs_chemistry mn,
          .wrs_chemistry msub,
          .wrs_chemistry mtext,
          .wrs_chemistry mfenced,
          .wrs_chemistry msup,
          .wrs_chemistry mrow {
            display: inline;
          }

          .mtable-style {
            display: block;
            margin: 20px auto;
            font-family: Arial, sans-serif;
            font-size: 14px;

            mtable {
              width: 80%;
              border-collapse: collapse;
              border: 1px solid #ccc;

              mtr {
                &:nth-child(even) {
                  background-color: #f5f5f5;
                }

                mtd {
                  padding: 5px;
                  border: 1px solid #ccc;
                  vertical-align: middle;
                  text-align: center;

                  mtext {
                    display: inline-block;
                  }

                  mtable {
                    margin-top: 5px;
                    width: 100%;

                    mtr {
                      mtd {
                        padding: 5px;
                        border: 1px solid #ccc;
                        vertical-align: middle;
                        text-align: center;
                        display: inline-block;
                      }
                    }
                  }
                }
              }
            }
          }

          .image-style {
            width: 100%;
            height: auto;
            object-fit: contain;
          }

          .table-style {
            width: 100%;
            border-collapse: collapse;

            td,
            th {
              border: 1px solid black;
              padding: 8px;
            }
          }

          p {
            line-height: 1.5;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

            .math-variant-normal {
              font-style: italic;

              sup {
                vertical-align: super;
                font-size: smaller;
              }

              sub {
                vertical-align: sub;
                font-size: smaller;
              }

              mo {
                margin: 0 2px;
              }

              mrow {
                display: inline;
              }

              mfenced {
                display: inline;
              }

              mfrac {
                display: inline;
              }

              mi {
                font-style: italic;
              }

              mn {
                font-style: normal;
              }

              mo[stretchy="true"] {
                width: 1.2em;
                text-align: center;
              }

              mo[stretchy="true"]:before {
                content: "";
                display: inline-block;
                height: 0.85em;
                vertical-align: middle;
              }

              mo[stretchy="true"][fence="true"] {
                height: auto;
                vertical-align: middle;
              }

              mo[stretchy="true"][fence="true"]:before {
                content: "";
                display: inline-block;
                height: 0.4em;
                vertical-align: middle;
              }

              mo[largeop="true"] {
                vertical-align: middle;
              }

              mo[largeop="true"]:before {
                content: "";
                display: inline-block;
                height: 0.5em;
                vertical-align: middle;
              }
            }
          }

          span {
            margin-right: 5px !important;
          }

          .text_content {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: #000;

            p {
              margin-bottom: 0px;
            }
          }

          .hide {
            display: none;
          }

          .show {
            display: block;
          }
        }
      }
    }

    .sub_sub_section_wrapper_3 {
      .single_select_option_collection_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .single_select {
          width: calc(50% - 8px);
          margin: 10px 16px 10px 0px;

          &:nth-child(2n + 2) {
            margin-right: 0px;
          }

          .classCorrect {
            border: 1px solid #5ddd9b !important;
            background: #e1f1ec !important;
          }

          .classWrong {
            border: 1px solid #f93030 !important;
            background: #f6e0e4 !important;
          }

          input[type="checkbox"] {
            opacity: 1;
            position: fixed;
            width: 0;
          }

          .single_option_wrapper {
            min-height: 50px;
            background-color: #fff;

            border-radius: 0.25rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            cursor: pointer;
            border: 0.6px solid #939393;

            .option_initial {
              margin-right: 10px;

              .text_content_2 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #121212;
              }
            }

            .option_final {
              .text_content_3 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #000;
              }

              p {
                margin-bottom: 0px;
              }
            }
          }

          input[type="checkbox"]:checked + .single_option_wrapper {
            border-radius: 0.25rem;
            border: 1px solid #fbab35;
            background: #fff9ef;

            .option_initial {
              .text_content_2 {
                font-weight: 600;
              }
            }

            .option_final {
              .text_content_3 {
                font-weight: 600;
              }
            }
          }
        }
      }

      .hide {
        display: none !important;
      }

      .show {
        display: flex !important;
      }

      .show2 {
        display: block !important;
      }

      //multi-select start
      .multi_select_option_collection_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .single_select {
          width: calc(50% - 8px);
          margin: 10px 16px 10px 0px;

          &:nth-child(2n + 2) {
            margin-right: 0px;
          }

          input[type="checkbox"] {
            opacity: 1;
            position: fixed;
            width: 0;
          }

          .single_option_wrapper {
            min-height: 50px;
            border: solid 1px #f0eef8;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            cursor: pointer;

            .option_initial {
              margin-right: 10px;

              .text_content_2 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #6b6876;
              }
            }

            .option_final {
              .text_content_3 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #6b6876;
              }
            }
          }

          input[type="checkbox"]:checked + .single_option_wrapper {
            border: 2px solid #6d48ef;

            .option_initial {
              .text_content_2 {
                font-weight: 600;
              }
            }

            .option_final {
              .text_content_3 {
                font-weight: 600;
              }
            }
          }
        }
      }
      // multi-select end

      //integer start
      .input_wrapper {
        .input_inner_wrapper {
          margin-bottom: 30px;

          input {
            outline: none !important;
            width: 100%;
            border: 0.6px solid #939393;
            background-color: #fff;
            height: 50px;
            border-radius: 0.25rem;
            padding: 0 5px;
            font-size: 16px;
            font-weight: 500;
          }
          input:focus {
            border: 0.6px solid #fbab35;
          }
          input[type="number"]:focus {
            border: 0.6px solid #fbab35;
          }

          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
  }

  .sub_sub_section_wrapper_4 {
    margin-bottom: 15px;

    .checkbox_wrapper {
      input[type="checkbox"] {
        margin-right: 10px;
      }

      label {
        font-size: 13px;
        vertical-align: text-top;
        color: #09041a;
      }
    }
  }

  .sub_sub_section_wrapper_5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem auto;
    width: 100%;
    padding-top: 0.5rem;
    border-top: 1px solid #ddd;

    .left_side_wrapper {
      // width: calc(50% - 8px);
      width: 30%;
      margin-right: 16px;

      .btn_wrapper {
        text-align: left;

        button {
          border: none;
          outline: none;
          background-color: #ffffff;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          color: var(--red);
          padding: 10px;
          width: auto;
          border-radius: 0.25rem;
          border: 1px solid var(--red);
        }
      }
    }

    .right_side_wrapper {
      width: auto;

      .solution {
        margin-right: 16px;
        background: #ff933a !important;
      }

      .btn_wrapper {
        text-align: right;
        .markBtn {
          background-color: #e9e9e9 !important;
        }
        button {
          border: none;
          outline: none;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 600;
          text-align: center;
          padding: 10px;
          width: auto;
          &:first-child {
            border-radius: 0.25rem;
            border: 1px solid #e9e9e9;
            margin-right: 0.5rem;
            color: #333333;
            background-color: #fff !important;
          }
          &:last-child {
            border-radius: 0.25rem;
            color: #fff;
            border: 0.6px solid #b91311;
            background: #b91311;
          }
        }
      }
    }
  }
  .QuestionSolution {
    padding-top: 20px;

    div {
      p {
        margin-bottom: 2px;
      }
    }
  }

  // video Frame start
  .videoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

    .view_videoSolution_btn {
      background: #ff933a;
      width: 30%;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 30px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #f5f4f8;
    }

    #iframe {
      height: 420px;
      width: 100%;
    }
  }

  .solution-text p {
    margin-bottom: 10px;
  }

  .solution-text .equation {
    font-style: italic;
    font-family: "Courier New", monospace;
    font-size: 16px;
    padding: 10px;
    display: inline-block;
    vertical-align: middle;
    border-bottom: 1px solid black;
    padding: 2px;
  }

  /* Target MathJax elements */
  .solution-text .mjx-mi {
    font-style: italic;
  }

  .solution-text .mjx-mo {
    color: black;
  }

  .solution-text .mjx-mn {
    font-weight: bold;
    color: black;
  }
  /// solution scss end

  table {
    border-collapse: collapse;
    width: 500px;
  }

  table,
  th,
  td {
    border: 1px solid #ccc;
  }

  th,
  td {
    text-align: center;
    padding: 10px;
  }

  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .math-expression {
    font-style: italic;
  }

  .option {
    margin-bottom: 10px;
  }

  .correct-answer {
    color: green;
  }

  .incorrect-answer {
    color: red;
  }

  .sub_sub_section_wrapper_6 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text_content_wrapper {
      .text_content {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
