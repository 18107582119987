.cart_count_container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  background: #edf0f0;
  padding: 0.625rem 0.875rem;
  position: relative;
  cursor: pointer;
  .cart_img {
    position: relative;

    img {
      width: 1.88713rem;
      height: 1.5rem;
    }

    .cart_count {
      background: #263238;
      position: absolute;
      top: -0.2rem;
      right: -0.2rem;
      border-radius: 50%;
      padding: 0.25rem 0.375rem;
      color: #fff;
      font-family: "Source Sans Pro";
      font-size: 0.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
  }

  .my_cart_text {
    color: #121212;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.00438rem;
    margin-left: 0.5rem;
  }
}
