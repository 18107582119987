.packages-inner-wrapper {
  padding: 0 5rem;

  .packages-heading-wrapper {
    .packages-heading {
      text-align: center;
      color: hsl(0, 0%, 0%);
      font-family: "Gothic A1", "Poppins", sans-serif;
      font-size: 1.75rem;
      font-weight: 800;
      line-height: normal;
      letter-spacing: 0.035rem;
      margin-bottom: 0.75rem;

      span {
        color: #b50503;
      }
    }

    .packages-subheading {
      margin: 0 auto;
      color: #494949;
      text-align: center;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.625rem;
      width: 36.5rem;
      margin-bottom: 0.4rem;
    }
  }

  .packages-content-wrapper {
    margin-top: 3.25rem;

    .packages-content-inner-wrapper {
      .slick-slider {
        .slick-list {
          overflow: hidden;
        }

        .slick-track {
          display: flex !important;
          justify-content: space-between;
          align-items: stretch;
        }

        .slick-slide {
          flex: 0 0 auto;
          height: 100%;
          padding: 0 0.625rem;
          max-width: 100%;
          &:nth-child(4n) {
            margin-right: 0;
          }
        }
      }

      .package {
        list-style: none;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        border-radius: 1rem;
        background: #fff;
        box-shadow: -2px -2px 6px 2px rgba(0, 0, 0, 0.08),
          2px 2px 6px 2px rgba(0, 0, 0, 0.08);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        max-width: 93%;
        height: 100%;

        &:hover {
          transform: translateY(-5px);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        }

        .package-img {
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            border-radius: 0.75rem;
          }
        }

        .pkg-details-wrapper {
          margin-top: 0.5rem;
          width: 100%;

          .pkg-title {
            color: #121212;
            text-align: left;
            font-family: "Poppins", sans-serif;
            font-size: 1.125rem;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.01125rem;
            margin-bottom: 0.5rem;
          }

          .pkg-description {
            color: #454545;
            font-family: "Poppins", sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-height: 3.25rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .view-details-btn {
          margin-top: 0.75rem;
          color: #fff;
          font-family: "Poppins", sans-serif;
          cursor: pointer;
          font-size: 1rem;
          font-weight: 500;
          line-height: normal;
          border-radius: 0.5rem;
          background: #b50503;
          width: 100%;
          padding: 0.75rem 0;
          text-align: center;
          .class-pill {
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .packages-inner-wrapper {
    padding: 0 3rem;
  }
}

@media (max-width: 1024px) {
  .packages-inner-wrapper .packages-heading-wrapper .packages-heading {
    font-size: 1.375rem;
    letter-spacing: 0.0275rem;
  }
  .packages-inner-wrapper .packages-heading-wrapper .packages-subheading {
    font-size: 0.875rem;
    line-height: 1.375rem; /* 157.143% */
    letter-spacing: 0.00875rem;
    width: 30.875rem;
  }
  .packages-inner-wrapper
    .packages-content-wrapper
    .packages-content-inner-wrapper
    .package
    .pkg-details-wrapper
    .pkg-title {
    font-size: 1rem;
    letter-spacing: 0.01rem;
  }
  .packages-inner-wrapper
    .packages-content-wrapper
    .packages-content-inner-wrapper
    .package
    .pkg-details-wrapper
    .pkg-description {
    font-size: 0.75rem;
    letter-spacing: 0.0075rem;
    width: 15.5rem;
  }
}

@media (max-width: 992px) {
  .packages-inner-wrapper {
    padding: 0 1.5rem;

    .packages-heading-wrapper {
      .packages-subheading {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .packages-inner-wrapper {
    padding: 0 2.5rem;
  }
  .packages-inner-wrapper .packages-heading-wrapper .packages-heading {
    font-size: 1.375rem;
  }
  .packages-inner-wrapper .packages-heading-wrapper .packages-subheading {
    width: 30.875rem;
    font-size: 0.875rem;
  }
  .packages-inner-wrapper .packages-content-wrapper {
    margin-top: 1.25rem;
  }
  .packages-inner-wrapper
    .packages-content-wrapper
    .packages-content-inner-wrapper
    .package
    .pkg-details-wrapper
    .pkg-title {
    font-size: 1rem;
  }
  .packages-inner-wrapper
    .packages-content-wrapper
    .packages-content-inner-wrapper
    .package
    .pkg-details-wrapper
    .pkg-description {
    font-size: 0.75rem;
  }
  .packages-inner-wrapper
    .packages-content-wrapper
    .packages-content-inner-wrapper
    .package
    .view-details-btn {
    font-size: 0.88525rem;
  }
}

@media (max-width: 560px) {
  .packages-inner-wrapper .packages-heading-wrapper .packages-heading {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.0225rem;
  }
  .packages-inner-wrapper .packages-heading-wrapper .packages-subheading {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.1875rem;
    width: 20.375rem;
  }
  .packages-inner-wrapper
    .packages-content-wrapper
    .packages-content-inner-wrapper
    .package {
    max-width: 100%;
    height: 100%;
  }
  .packages-inner-wrapper {
    padding: 0 1.5rem;
  }
}
@media (max-width: 426px) {
  .packages-inner-wrapper {
    padding: 0 1rem;
  }
}
