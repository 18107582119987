.user_dashboard_wrapper {
  background: #ffffff;
  .user_dashboard_inner_wrapper {
    .user_dashboard_left_wrapper {
      // flex: 0 0 220px;
    }
    .user_dashboard_right_wrapper {
    }
  }
}
.coinModal {
  border-radius: 0.3rem;
  outline: 10px solid #6d48ef;
}
.coinModal .coinText {
  font-weight: 600;
  font-size: 13px;
  line-height: 40px;
  color: #000000;
}
.coinModal .coinText1 {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #000000;
}
.coinModal .coinNum {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  color: #2d2d2d;
}
.coinClsClass {
  top: -22px !important;
  right: -19px !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 24px !important;
  background-size: 0.8em !important;
  opacity: 1 !important;
}
.coinModal .coinWelcome {
  position: relative;
  top: -5rem;
  margin-bottom: -3rem;
}
.coinModal .coinBtn {
  background: #6d48ef;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  color: #ffffff;
}

.feedback_modal {
  .modal_content_feedback {
    background-color: #ffffff; /* white background for modal content */
    margin: 10vh auto 0;
    // padding: 25px;
    border: 1px solid #888;
    width: 80%;
    border-radius: 16px;
    width: 34.25rem;
    position: relative;
    // max-height: 60%;
  
    .close {
      position: absolute;
      right: 15px;
      top: 20px;
    }
  
    .heading {
      padding: 1.2rem 0 1rem 1.5rem;
  
      img {
        width: 1.5rem;
        margin-right: 0.5rem;
      }
      p {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 1.125rem;
        font-weight: 600;
        letter-spacing: 0.01125rem;
        margin: 0;
  
        span {
          color: #333;
          text-align: center;
          font-family: "Poppins";
          font-size: 0.875rem;
          font-weight: 400;
          letter-spacing: 0.00875rem;
        }
      }
    }
    
    .feedback_card_container {
      max-height: 25rem;
      overflow-y: scroll;
      scrollbar-width: thin;

      .feedback_card {
        padding: 0 1.5rem 0;
        border-bottom: 0.6px solid #DBDBDB;
    
        .card_heading {
          padding: 1rem 0 0.5rem;
  
          p {
            color: #000;
            font-family: "Poppins";
            font-size: 1rem;
            font-weight: 600;
            line-height: 117.6%; /* 1.176rem */
            margin: 0;
    
            &:last-child {
              color: #4B4B4B;
              font-size: 0.75rem;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.0075rem;
            }
          }
        }
    
        .date_time {
          border-radius: 0.25rem;
          background: rgba(254, 245, 210, 0.60);
          padding: 0.125rem 0.25rem;
          width: fit-content;
          margin-bottom: 1.13rem;
    
          p {
            color: #404040;
            font-family: "Poppins";
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1.1875rem; /* 158.333% */
            letter-spacing: 0.0075rem;
            margin: 0;
          }
        }
    
        .rating_star_div {
          width: fit-content;
          margin-bottom: 1rem;
    
          .stars {
            label {
              margin-right: 0.5rem;
    
              &:last-child {
                margin-right: 0;
              }
    
              input[type=radio] {
                display: none;
              }
    
              span {
                img {
                  width: 1.75rem;
                }
              }
            }
          }
        }
    
        .comments {
          width: 100%;
          padding: 0.75rem;
          border-radius: 0.25rem;
          border: 0.8px solid #E5E5E5;
          background: #FCFCFC;
          margin-bottom: 1.5rem;
        }
      }
    }
    
  
    .btn_container {
      padding: 1rem 1.5rem;
      box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.04);
      
      button {
        color: #FFF;
        font-family: "Poppins";
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.01rem;
        border-radius: 0.25rem;
        background: #B50303;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
        padding: 0.875rem;
        width: 100%;
      }
    }
  }
}

.coinDetailsPopup p {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}
.coinDetailsPopup .coinHeading {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0;
}
.coinDetailsPopup ul {
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin-bottom: 1.5rem;
}
.coinDetailsPopup ul li {
  margin-bottom: 0.35rem;
}
.coinDetailsPopup p:last-child {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.5rem;
  color: #000000;
}

.modal_outer_package {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000;
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
  .user_dashboard_wrapper {
    background: #ffffff;

    .user_dashboard_inner_wrapper {
      .user_dashboard_left_wrapper {
        flex: 0 0 190px;
      }
      .user_dashboard_right_wrapper {
        flex: 0 0 calc(100% - 190px);
      }
    }
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .user_dashboard_wrapper {
     background: #ffffff;
    .user_dashboard_inner_wrapper {
      .user_dashboard_left_wrapper {
        flex: 0 0 160px;
      }
      .user_dashboard_right_wrapper {
        flex: 0 0 calc(100% - 160px);
      }
    }
  }
}
