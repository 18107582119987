.QuestionDetailPanel {
  border-radius: 8px;
  padding: 10px;
  .timer_container {
    margin: 25px 0 15px;
    padding: 15px 25px;
    border-radius: 8px;
    background: #263238;
  }
  .section-heading,
  .question_counter {
    color: #fff;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
  }
  .downloadReportBtn {
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      background: #ffffff;
      border: 1px solid #fff;
      border-radius: 6px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      .text {
        margin-right: 10px;
      }
      img {
        width: 15px;
        height: 15px;
        margin-bottom: 7px;
      }
    }
  }
  .testsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 0.6px solid #aeaeae;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 9px 14px;
    margin: 20px 30px 30px 30px;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 16.5%;

      .backBtn {
        cursor: pointer;
      }
      .section-heading {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        color: #121212;
      }
    }
    .right {
      width: 45%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .date {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #696969;
      }
      .obj-type {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #696969;
      }
    }
  }

  .section_wrapper {
    margin: 0 auto;

    .testsProgressIndicator {
      margin: 0px auto 20px auto !important;
      background: #fff;
      // padding: 5px 10px;
      border-radius: 8px;
      width: 80%;
      padding: 15px 30px;

      h4 {
        font-size: 14px;
        color: #000;
        margin-bottom: 16px;
      }

      .ProgressBar_Line {
        position: relative;
      }

      .ProgressBar_Line span {
        position: absolute;
        // top: -16px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
      }

      .progress {
        height: 8px;
        margin-bottom: 5px;

        .progress-bar {
          background: #ff933a;
          border-radius: 9px;
        }
      }

      .questionPercentage {
        display: flex;
        justify-content: space-between;

        span {
          color: #707070;
          font-size: 14px;
        }
      }
    }

    .sub_sub_section_wrapper_2 {
      h2 {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .text_content_wrapper {
        .typeLanguageWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;

          span {
            font-family: "Poppins";
            font-size: 14px;
            line-height: normal;
            color: #121212;
            font-weight: 600;
            margin-bottom: 0;
          }

          .markReview {
            display: flex;

            .bookmark_image_wrapper {
              margin: 0px 10px;

              span {
                margin-right: 5px;
              }

              img {
                cursor: pointer;
              }
            }

            button {
            }
          }
        }

        .questionBox {
          display: flex;
          align-items: baseline;
          margin-bottom: 20px;

          span {
            margin-right: 5px !important;
          }

          .text_content {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            color: #000;

            p {
              margin-bottom: 0px;
            }
          }

          .hide {
            display: none;
          }

          .show {
            display: block;
          }
        }
      }
    }

    .sub_sub_section_wrapper_3 {
      //single-select
      .single_select_option_collection_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .single_select {
          width: calc(50% - 8px);
          margin: 10px 16px 10px 0px;

          &:nth-child(2n + 2) {
            margin-right: 0px;
          }

          .classCorrect {
            border: 1px solid #5ddd9b !important;
            // background: #e1f1ec !important;
            background: green;
          }

          .classWrong {
            border: 1px solid #f93030 !important;
            background: #f6e0e4 !important;
          }

          input[type="checkbox"] {
            opacity: 1;
            position: fixed;
            width: 0;
          }

          .single_option_wrapper {
            min-height: 50px;
            background-color: #fff;
            border-radius: 4px;
            border: 0.6px solid #939393;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 20px;
            cursor: pointer;

            .option_initial {
              margin-right: 10px;

              .text_content_2 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #acacac;
              }
            }

            .option_final {
              .text_content_3 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #000;

                p {
                  margin-bottom: 0px;
                }
              }
            }
          }

          input[type="checkbox"]:checked + .single_option_wrapper {
            border: 2px solid #6d48ef;

            .option_initial {
              .text_content_2 {
                font-weight: 600;
              }
            }

            .option_final {
              .text_content_3 {
                font-weight: 600;
              }
            }
          }
        }
      }

      .hide {
        display: none !important;
      }

      .show {
        display: flex !important;
      }

      .show2 {
        display: block !important;
      }

      //multi-select
      .multi_select_option_collection_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .single_select {
          width: calc(50% - 8px);
          margin: 10px 16px 10px 0px;

          &:nth-child(2n + 2) {
            margin-right: 0px;
          }

          input[type="checkbox"] {
            opacity: 1;
            position: fixed;
            width: 0;
          }

          .single_option_wrapper {
            min-height: 50px;
            border: solid 1px #f0eef8;
            background-color: #fafafd;
            border-radius: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            cursor: pointer;

            .option_initial {
              margin-right: 10px;

              .text_content_2 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #6b6876;
              }
            }

            .option_final {
              .text_content_3 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;
                color: #6b6876;
              }
            }
          }

          input[type="checkbox"]:checked + .single_option_wrapper {
            border: 2px solid #6d48ef;

            .option_initial {
              .text_content_2 {
                font-weight: 600;
              }
            }

            .option_final {
              .text_content_3 {
                font-weight: 600;
              }
            }
          }
        }
      }

      //integer
      .input_wrapper {
        .input_inner_wrapper {
          margin-bottom: 30px;

          input {
            border: none;
            outline: none;
            width: 100%;
            border: solid 1px #f0eef8;
            background-color: #fff;
            height: 50px;
            border-radius: 8px;
            padding: 0 5px;
            font-size: 16px;
            font-weight: 500;
          }

          input[type="number"]:focus {
            border: 2px solid #6d48ef;
          }

          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }

    .sub_sub_section_wrapper_4 {
      margin-bottom: 15px;

      .checkbox_wrapper {
        input[type="checkbox"] {
          margin-right: 10px;
        }

        label {
          font-size: 13px;
          vertical-align: text-top;
          color: #09041a;
        }
      }
    }

    .sub_sub_section_wrapper_5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 0.8px solid #f2f2f2;
      padding: 15px 40px;

      .left_side_wrapper {
        width: calc(50% - 8px);
        margin-right: 16px;

        .btn_wrapper {
          text-align: left;

          button {
            border-radius: 4px;
            border: 0.6px solid #b91311;
            background: #b91311;
            color: #fff;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 136px;
            padding: 10px 20px;
          }
        }
      }

      .right_side_wrapper {
        width: calc(50% - 8px);

        .btn_wrapper {
          text-align: right;
          button {
            border-radius: 4px;
            border: 0.6px solid #b91311;
            background: #b91311;
            color: #fff;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 136px;
            padding: 10px 20px;
          }

          .btn {
            border: none;
            outline: none;
            border-radius: 8px;
            background-color: #ffffff;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #fff;
            padding: 10px;
            width: 100%;
            background: #6d48ef;
          }
        }

        .backBtn {
          color: #fff;
        }
      }
    }

    .solutionBoxWrapper {
      padding-top: 20px;

      p {
        word-break: break-all;
      }
    }

    .videoWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .view_videoSolution_btn {
        background: #ff933a;
        width: 30%;
        text-align: center;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 30px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #f5f4f8;
      }
      #iframe {
        height: 420px;
        width: 100%;
      }
    }

    .QuestionSolution {
      // padding-top: 20px;

      p {
        margin-bottom: 5px;
      }

      h5 {
      }

      div {
        p {
          margin-bottom: 2px;
        }
      }
    }

    // .sub_sub_section_wrapper_6 {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;

    //   .text_content_wrapper {
    //     .text_content {
    //       margin: 0;
    //       font-size: 18px;
    //       font-weight: 600;
    //     }
    //   }
    // }
  }
}
