.allQuestionList {
  width: 32%;

  position: sticky;
  top: 0px;
  box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.06);

  .sub_section_1_wrapper {
    flex: 0 0 100%;
    padding: 1.25rem 2.5rem 0 1.5rem;

    background: #fff;

    .closebtn {
      display: none;
    }

    .sub_section_heading {
      .text_content_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.875rem;
        border-bottom: 0.0375rem solid #d1d1d1;

        .text_content {
          margin: 0;
          color: #121212;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        button {
          border-radius: 0.25rem;
          border: 1px solid #646464;
          background: #fff;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
          color: #333;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0.625rem 1rem;
        }

        .countdown_timer_container {
          min-width: 55px;

          span {
            label {
              color: #6d48ef;
              font-size: 15px;
            }
          }
        }
      }
      .sub_section_2_wrapper {
        flex: 0 0 100%;

        .section_2_1 {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .allQuestionsText {
            margin: 0;
            padding: 0;
            color: #121212;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .gridviewIcons {
            display: flex;
            gap: 1rem;
            margin: 1rem 0;
          }
        }

        .question_marking_wrapper {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          align-items: center;
          padding-bottom: 1.5rem;

          .single_marking {
            margin-right: 3.25rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .box_pic {
              width: 1.25rem;
              height: 1.25rem;
              border-radius: 0.25rem;
              margin-right: 0.5rem;
            }

            .box_pick_background_1 {
              border: 1px solid #8ae09f;
              background: #c8f1d2;
            }

            .box_pick_background_2 {
              background-color: #f99746;
            }

            .box_pick_background_3 {
              border: 1px solid #d9d9d9;
              background: #fff;
            }

            .box_pick_background_4 {
              background: #fed5d5;
              border: 1px solid #ffc4c4;
            }

            .box_pick_background_5 {
              background: #6d48ef;
              border: 1px solid #6d48ef;
            }

            .question_marking_text {
              color: #121212;
              font-family: Poppins;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
  .questions_serial_number_collection {
    overflow-y: scroll;
    height: 72vh;
    padding: 1.25rem 2.5rem 0 1.5rem;
    background: #f6f6f7;
    width: 100%;

    .questions_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 1.25rem;
    }

    .excr_single_question_number_wrapper1 {
      display: block !important;

      .unanswered_lbl1 {
        border-radius: 0.5rem;
        border: 0.6px solid rgba(38, 50, 56, 0.31);
        background: #fff;
        margin-bottom: 0.8rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem 0.75rem;

        .questionBox1 {
          overflow-x: hidden;
          display: flex;

          color: #383838;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0.6rem 0.75rem;

          .questionNumber {
            margin-right: 0.5rem;
          }
          p {
            overflow: visible;
            display: block !important;
            color: #383838;
            font-family: Poppins;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: 0;
            margin: 0;
          }
          .QBox {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        img {
          width: 0.36869rem;
          height: 0.67269rem;
        }
      }
      .answered_lbl {
        border-radius: 0.5rem;
        border: 0.6px solid #8ae09f;
        background: #c8f1d2;
      }

      .current_lbl {
        border: 1px solid #ff933a;
        border: 1px solid #ffa68c;
        cursor: pointer;
        background: #fff;
      }
    }
    .excr_single_question_number_wrapper {
      .unanswered_lbl {
        width: 3rem;
        height: 2.875rem;
        cursor: pointer;
        border-radius: 0.25rem;
        border: 0.6px solid #d9d9d9;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        .questionBox {
          color: #000;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .answered_lbl {
        border: 0.6px solid #8ae09f;
        background: #c8f1d2 !important;
      }

      .current_lbl {
        border: 1px solid #ff933a;
        border: 1px solid #ffa68c;
        cursor: pointer;
        background: #fff;
      }
    }
  }
}

@media (max-width: 578px) {
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  .allQuestionListToggle {
    display: block !important;
    animation: fadeIn 200ms ease-in;
  }
  .allQuestionList {
    padding: 10px;
    position: absolute;
    display: none;
    width: 70%;
    z-index: 9999;
    top: 0;
    left: 0;
    .sub_section_1_wrapper {
      position: relative;
      .closebtn {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
      }
      .text_content_wrapper {
        button {
          display: none;
        }
      }
    }
  }
}
