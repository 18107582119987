.chatbox {
  margin: 0 auto;
  width: 53%;
  border-radius: 8px;
  background: #FFF;

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    min-height: 520px;

    .message {
      .content {
        padding: 0px 30px;
        small {
          color: #878787;
          font-family: "Poppins";
          font-size: 8px;
          font-weight: 400;
          line-height: normal;
        }
      }

      img {
        margin-top: -80px;
        width: 24px;
      }
    }

    .buttons {
      width: 300px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 1rem;
      // text-align: center;
      // padding: 50px 0px;
      align-items: center;
      justify-content: center;

      button {
        border-radius: 8px;
        border: 1px solid #B50503;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
        padding: 8px 24px;
        color: #B50503;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.07px;

        &:hover {
          background: #B50503;
          color: #ffffff;
        }

        &:last-child {
        margin-top: 15px;
      }

        &:active {
        background: #FFF2F2;
        }
      }
    }

    .para1 {
      border-radius: 16px 16px 16px 0px;
      background: #EDEFEF;
      box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
    }

    .para2 {
      margin-top: -5px;
      border-radius: 0px 16px 16px 16px;
      background: #EDEFEF;
      box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
    }

    p {
      padding: 8px 12px;
      width: 90%;
      color: #121212;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
      letter-spacing: 0.07px;
    }
  }

  .assistant {
    border-radius: 5px;
    margin-top: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 520px;

    .message {
      padding: 25px 25px 0;
      .content {
        padding: 0px 30px;
        small {
          font-family: Poppins;
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          color: #878787;
        }

        .para1 {
          border-radius: 16px 16px 16px 0px;
          background: #EDEFEF;
          box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
        }
    
        .para2 {
          margin-top: -5px;
          border-radius: 0px 16px 16px 16px;
          background: #EDEFEF;
          box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
        }
    
        p {
          padding: 8px 12px;
          width: 90%;
          color: #121212;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 400;
          line-height: 22px; /* 157.143% */
          letter-spacing: 0.07px;
        }
      }

      img {
        margin-top: -80px;
        width: 24px;
      }
    }

    .selectedOption {
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 0px 25px;
      .option {
        margin-top: 10px;
        color: #FFF;
        width: fit-content;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
        padding: 8px 16px;
        border-radius: 16px 16px 0px 16px;
        background: #B50503;
        box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
      }
    }

    .selectedOption2 {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: 40px;
      padding: 0px 25px;
      min-width: 10%;
      .option2 {
        margin-top: 10px;
        // overflow-wrap: break-word;
        // max-width: 300px;
        color: #FFF;
        width: fit-content;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
        padding: 8px 16px;
        border-radius: 16px 16px 0px 16px;
        background: #B50503;
        box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
      }
    }

    .kidlyState {
      text-align: center;
      margin-bottom: 25px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      color: #6b6b6b;
    }

    .confirmationMessage {
      color: #6B6B6B;
      text-align: center;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: 23px;
      margin-bottom: 20px !important;
      width: 70%;
      margin: 0px auto;
      margin-top: auto;
    }

    .custom-message-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      padding: 15px 25px;
      background-color: #ffffff;
      border: 0.6px solid #F5F5F5;
      textarea {
        flex-grow: 1;
        border: none;
        outline: none;
        border-radius: 5px;
        background: none;
        min-height: 20px;
        overflow: hidden;
        resize: none;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        color: #121212;
        &::placeholder {
          font-family: Poppins;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          color: #adadad;
        }
      }

      img {
        display: inline;
        margin-left: 10px;
        cursor: pointer;
        width: 45px;
        height: 45px;
      }
    }

    .buttons {
      margin-top: 20px !important;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      margin: 0px auto;
    }

    button {
      border-radius: 8px;
      border: 1px solid #B50503;
      background: #ffffff;
      box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
      padding: 8px 24px;
      color: #B50503;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.07px;
      width: max-content;
      margin: auto;

      &:hover {
        background: #B50503;
        color: #ffffff;
      }

      &:active {
      background: #FFF2F2;
      }
    }

    .other-button {
      display: flex;
      justify-content: center;

      .other-btn {
        color: #5F5F5F;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.07px;
        padding: 8px 24px;
        border-radius: 16px;
        background: #FFF;
        border-radius: 8px;
        border: 1px solid #5F5F5F;
        box-shadow: 0px 2px 8px 0px rgba(163, 163, 163, 0.08);
        cursor: pointer;
        display: inline-block;
        margin-top: 10px;
      }
    }

    .backbtn {
      text-align: center;
      margin-top: 25px;
      padding-bottom: 30px;

      button {
        color: #B50503;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.07px;
        text-decoration-line: none;
        border: none;
        padding: 0.876rem;
        &:hover {
          color: #fff;
        }
      }
    }

    input[type="text"] {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-top: 10px;
    }

    button[type="button"] {
      display: block;
      margin-top: 10px;
    }
  }
}
