//single-select
.single_select_option_collection_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  .single_select {
    width: calc(50% - 10px);
    margin: 10px 20px 10px 0px;
    &:nth-child(2n + 2) {
      margin-right: 0px;
    }

    .classCorrect {
      border: 1px solid #5ddd9b !important;
      background: #e1f1ec !important;
    }

    .classWrong {
      border: 1px solid #f93030 !important;
      background: #f6e0e4 !important;
    }

    input[type="checkbox"] {
      opacity: 1;
      position: fixed;
      width: 0;
    }

    .single_option_wrapper {
      min-height: 50px;
      background-color: #fff;
      // border-radius: 8px;
      border-radius: 4px;
      border: 0.6px solid #939393;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px;
      cursor: pointer;

      .option_initial {
        margin-right: 10px;

        .text_content_2 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #acacac;
        }
      }

      .option_final {
        .text_content_3 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #000;

          p {
            margin-bottom: 0px;
          }
        }
      }
    }

    input[type="checkbox"]:checked + .single_option_wrapper {
      border: 1px solid #fbab35;
      background: #fff9ef;
      .option_initial {
        .text_content_2 {
          font-weight: 600;
        }
      }

      .option_final {
        .text_content_3 {
          font-weight: 600;
        }
      }
    }
  }
}

// .hide {
//   display: none !important;
// }

// .show {
//   display: flex !important;
// }

// .show2 {
//   display: block !important;
// }

//multi-select
.multi_select_option_collection_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .single_select {
    width: calc(50% - 8px);
    margin: 10px 16px 10px 0px;

    &:nth-child(2n + 2) {
      margin-right: 0px;
    }

    input[type="checkbox"] {
      opacity: 1;
      position: fixed;
      width: 0;
    }

    .single_option_wrapper {
      min-height: 50px;
      border: solid 1px #f0eef8;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      cursor: pointer;

      .option_initial {
        margin-right: 10px;

        .text_content_2 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #6b6876;
        }
      }

      .option_final {
        .text_content_3 {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          color: #6b6876;
        }
      }
    }

    input[type="checkbox"]:checked + .single_option_wrapper {
      border: 2px solid #6d48ef;

      .option_initial {
        .text_content_2 {
          font-weight: 600;
        }
      }

      .option_final {
        .text_content_3 {
          font-weight: 600;
        }
      }
    }
  }
}

//integer
.input_wrapper {
  .input_inner_wrapper {
    margin-bottom: 30px;

    input {
      border: none;
      outline: none;
      width: 100%;
      border: solid 1px #f0eef8;
      background-color: #fff;
      height: 50px;
      border-radius: 8px;
      padding: 0 5px;
      font-size: 16px;
      font-weight: 500;
    }

    input[type="number"]:focus {
      border: 2px solid #6d48ef;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
