.user_address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 0.0375rem solid #d8d8d8;
  margin-bottom: 2rem;

  .address_left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 0.81rem;
    }

    .address_wrapper {
      .student_name_pincode {
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        color: #121212;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 137.5%; /* 1.20313rem */

        .student_name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 130px;
        }
      }

      .address_detail {
        color: var(--sub-Text, #5c5c5c);
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 170px;
      }
    }
  }

  .address_right {
    color: #448fff;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
}
