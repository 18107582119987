$maths-color: #e230a0;
$physics-color: #31d680;
$chemistry-color: #4dd7d8;

.AdaptiveCps_subject_collection_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .AdaptiveCps_single_select_subject {
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    background: #f5f4f8;

    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label {
      width: 164px;
      background-color: #ffffff;
      border-radius: 8px;
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.2px;
      color: #000000;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 16px;
      transition: background-color 0.3s ease;
    }

    .subject_icon {
      width: 32px;
      margin-right: 12px;
      cursor: pointer;

      img {
        width: 32px;
      }
    }
  }
}
