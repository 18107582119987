.recorded_video_component_wrapper {
    margin-bottom: 100px;
    
    .recorded_video_component_inner_wrapper {
        .iframe-container {
            padding-top: 101px;
            margin: 0;

            .heading-wrapper {
                .text_content_wrapper {
                    .text_content {
                        font-size: 20px;
                        font-weight: 600;
                        color: #0f0404;
                        margin-left: 10px;
                    }
                }
            }

            .video_frame_wrapper {
                text-align: center;

                iframe {
                    width: 100%;
                    height: 600px;
                }
            }
        }
    }
}
