.attendance__content_wrapper {
  padding-top: 75px;

  .grey_box {
    // width: 70.875rem;
    border-radius: 1rem;
    background: #f6f6f7;
    padding: 1.73rem 4.1rem 4.2rem;
    margin: 2.5rem auto 5rem;

    .bckBtn-title {
      display: flex;
      // justify-content: space-between;
      // align-items: center;
      margin-bottom: 2.25rem;

      img {
        flex: 0 0 10%;
      }
      h1 {
        color: #000;
        font-family: Poppins;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-align: center;
        flex: 0 0 90%;
      }
    }
    .range_div {
      border-radius: 0.5rem;
      background: #fff;
      padding: 1.5rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      .left {
        p {
          color: #121212;
          font-family: "Poppins";
          font-size: 1.25rem;
          font-weight: 400;
          line-height: normal;
          margin: 0;

          &:last-child {
            color: #7b7b7b;
            font-size: 0.875rem;
          }
        }
      }

      .right {
        display: flex;
        margin-left: auto;
        align-items: center;

        .date {
          border-radius: 0.5rem;
          border: 0.6px solid #9f9f9f;
          background: #f6f6f7;
          padding: 0.5rem 1rem;
          margin-right: 1.5rem;

          input {
            background: inherit;
            border: none;
            outline: none !important;
            width: 8rem;
            cursor: pointer;
          }

          p {
            color: #8f8f8f;
            font-family: "Poppins";
            font-size: 0.75rem;
            font-weight: 400;
            line-height: normal;
            margin: 0;

            &:last-child {
              color: #000;
              font-weight: 500;
            }
          }
        }

        button {
          color: #fff;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          border-radius: 0.5rem;
          background: #b50503;
          padding: 0.75rem 1.25rem;
        }
      }
    }

    h2 {
      color: #121212;
      font-family: "Poppins";
      font-size: 1.125rem;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.01125rem;
      margin-bottom: 0.6rem;
    }

    .record_cards_wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      .record_card {
        border-radius: 0.5rem;
        background: #fff;
        box-shadow: 2.383px 2.383px 9.532px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        width: calc(25% - 0.75rem);

        p {
          color: #121212;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.01rem;
          margin: 0;

          &:last-child {
            font-family: "Poppins";
            font-size: 1.5rem;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.03rem;
          }
        }

        .green {
          color: #15a434;
        }

        .yellow {
          color: #bd8d12;
        }

        .red {
          color: #ec2e2e;
        }

        &:first-child {
          background: #263238;

          p {
            color: #fff;
          }
        }
      }
    }

    .details_div {
      border-radius: 0.5rem;
      border: 0.6px solid rgba(181, 144, 0, 0.19);
      background: #fffdf5;
      filter: blur(0px);

      .heading {
        padding: 0.87rem 0 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(181, 144, 0, 0.19);

        span {
          width: 25%;
          text-align: center;
          color: #121212;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 500;
          line-height: normal;
        }
      }

      .details {
        padding: 0.75rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          width: 25%;
          text-align: center;
          color: #555;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 400;
          line-height: normal;

          &:first-child {
            font-weight: 500;
          }
        }
      }
    }
  }
}
