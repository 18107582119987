.questionOptions {
  .examcrcker_single_select_option_collection_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    .excrker_single_select {
      width: calc(50% - 8px);
      border-radius: 0.25rem;
      border: 0.6px solid #939393;
      background: #fff;

      &:nth-child(2n + 2) {
        margin-right: 0px;
      }

      .classCorrect {
        border-radius: 0.25rem;
        border: 0.6px solid #19b244;
        background: rgba(25, 178, 68, 0.1);

        .option_initial {
          margin-right: 10px;
          display: inline-block;

          .text_content_2 {
            margin: 0;
            color: #00701f !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .option_final {
          display: inline-block;

          .text_content_3 {
            margin: 0;
            color: #00701f !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            p {
              margin-bottom: 0px;
            }
          }
        }
      }

      .classWrong {
        border-radius: 0.25rem;
        border: 1px solid #c62a2a;
        background: rgba(198, 42, 42, 0.1);

        .option_initial {
          margin-right: 10px;
          display: inline-block;

          .text_content_2 {
            margin: 0;
            color: #c62a2a !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .option_final {
          display: inline-block;

          .text_content_3 {
            margin: 0;
            color: #c62a2a !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            p {
              margin-bottom: 0px;
            }
          }
        }
      }

      input[type="checkbox"] {
        opacity: 1;
        position: fixed;
        width: 0;
      }

      .single_option_wrapper {
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding: 1.2rem 0 1.2rem 1.2rem;
        display: flex;

        .option_initial {
          margin-right: 10px;
          display: inline-block;

          .text_content_2 {
            margin: 0;
            color: #3e3e3e;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .option_final {
          display: inline-block;

          .text_content_3 {
            margin: 0;
            color: #3e3e3e;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            p {
              margin-bottom: 0px;
            }
          }
        }
      }

      input[type="checkbox"]:checked + .single_option_wrapper {
        border-radius: 0.25rem;
        border: 1px solid #fbab35;
        background: #fff9ef;

        .option_initial {
          .text_content_2 {
            // font-weight: 600;
          }
        }

        .option_final {
          .text_content_3 {
            // font-weight: 600;
          }
        }
      }
    }
  }

  .hide {
    display: none !important;
  }

  .show {
    display: flex !important;
  }

  .show2 {
    display: block !important;
  }

  //multi-select
  .examcracker_multi_select_option_collection_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    .excrker_single_select {
      width: calc(50% - 8px);
      border-radius: 0.25rem;
      border: 0.6px solid #939393;
      background: #fff;

      &:nth-child(2n + 2) {
        margin-right: 0px;
      }

      .classCorrect {
        border-radius: 0.25rem;
        border: 0.6px solid #19b244;
        background: rgba(25, 178, 68, 0.1);

        .option_initial {
          margin-right: 10px;
          display: inline-block;

          .text_content_2 {
            margin: 0;
            color: #00701f !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .option_final {
          display: inline-block;

          .text_content_3 {
            margin: 0;
            color: #00701f !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            p {
              margin-bottom: 0px;
            }
          }
        }
      }

      .classWrong {
        border-radius: 0.25rem;
        border: 1px solid #c62a2a;
        background: rgba(198, 42, 42, 0.1);

        .option_initial {
          margin-right: 10px;
          display: inline-block;

          .text_content_2 {
            margin: 0;
            color: #c62a2a !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .option_final {
          display: inline-block;

          .text_content_3 {
            margin: 0;
            color: #c62a2a !important;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            p {
              margin-bottom: 0px;
            }
          }
        }
      }

      input[type="checkbox"] {
        opacity: 1;
        position: fixed;
        width: 0;
      }

      .single_option_wrapper {
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        padding: 1.2rem 0 1.2rem 1.2rem;
        display: flex;

        .option_initial {
          // margin-right: 10px;
          margin-right: 10px;
          display: inline-block;

          .text_content_2 {
            margin: 0;
            color: #3e3e3e;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .option_final {
          display: inline-block;

          .text_content_3 {
            margin: 0;
            color: #3e3e3e;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            p {
              margin-bottom: 0px;
            }
          }
        }
      }

      input[type="checkbox"]:checked + .single_option_wrapper {
        border-radius: 0.25rem;
        border: 1px solid #fbab35;
        background: #fff9ef;

        .option_initial {
          .text_content_2 {
          }
        }

        .option_final {
          .text_content_3 {
          }
        }
      }
    }
  }

  //integer
  .input_wrapper {
    .input_inner_wrapper {
      input {
        border: none;
        outline: none;
        width: 100%;
        border-radius: 0.25rem;
        border: 0.6px solid #939393;
        background: #fff;
        padding: 1.2rem;
        color: #838383;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      input[type="number"]:focus {
        // border: 2px solid #6d48ef;
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
