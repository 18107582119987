.multiple-login-modal {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Poppins;
  
    .modal-content {
      width: 24.75rem !important;
      margin: 0 auto;
  
      .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
  
        .multiple-login-image {
          width: 80px;
          height: 80px;
          margin-top: 1.8rem;
        }
  
        .modal-text {
          margin-top: 1.438rem;
          color: #000;
          text-align: center;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem; /* 150% */
          letter-spacing: 0.01rem;
        }
  
       
  
        .multiple-login-more-info {
          margin-top: 1rem;
          color: #535353;
text-align: center;
font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
line-height: 1.3125rem; /* 150% */
          width: 80%;
        }

        .multipleLoginLogoutBtn{

            margin-top: 1rem;
            color: #FFF;
font-family: Poppins;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: normal;
border-radius: 0.25rem;
border: 0.6px solid #B91311;
background: #B50503;
width: 80%;
padding: 0.75rem 0;
text-align: center;
cursor: pointer;
        }


      }
    }
  }
  
