#acpsm {
  max-width: 495px;

  .modal-content {
    .modal-body {
      padding: 35px 25px !important;
      position: relative;

      p {
        text-align: center;

        &:first-child {
          color: #121212;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.16px;
        }

        &:nth-child(2) {
          strong {
            color: #000;
            font-size: 16px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.16px;
          }
        }

        &:last-child {
          color: #505050;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0.14px;

          strong {
            color: #121212;
            font-weight: 500;
          }
        }
      }

      button {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        padding: 12px 14px;
        gap: 8px;
        border-radius: 4px;
        background: #b50503;
        width: 200px;
        text-align: center;
        margin: auto;
      }

      div {
        &:last-child {
          position: absolute;
          top: 20px;
          right: 5px;
          width: 32px;
        }
      }
    }
  }
}

.ACpsTopicWiseWrapper {
  display: flex;
  flex-wrap: wrap;
  .section-heading {
    margin: 0 auto 10px auto;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .ACpsTopicBox {
    background: #ffffff;
    // box-shadow: 2px 2px 4px 0px #5959591f;
    // box-shadow: 2px 2px 4px 0px #5959591f;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    align-items: center;
    margin: 0 20px 20px 0;
    justify-content: space-between;
    width: calc(50% - 40px);
    cursor: pointer;
    box-sizing: content-box;

    &:nth-child(2n + 2) {
      margin-right: 0px;
    }

    .ACpsTopicNameImg {
      .ACpsImg_wrp {
        display: inline-block;
        margin-right: 15px;
        width: 32px;

        img {
          width: 100%;
        }
      }

      .name {
        display: inline-block;
        margin: 0px;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
      }
    }

    .arrow {
      img {
        width: 9px;
      }
    }
  }

  /* Remove margin-bottom from all .ACpsTopicBox elements */
  .ACpsTopicBox {
    // margin-top: 16px;
  }
}

@media (max-width: 991.98px) {
  .topicWiseWrapper {
    .topicBox {
      padding: 10px;
    }
  }
}

@media (max-width: 575.98px) {
  .topicWiseWrapper {
    .topicBox {
      margin: 0px 0px 10px 0px;
      width: 100%;

      .topicNameImg {
        h5 {
          font-size: 19px;
        }
      }
    }
  }
}
.modal-button {
  color: #fff;
}
a {
  text-decoration: none !important;
}
