.type_list {
  .list {
    display: flex;
    background: #ffffff;
    max-width: max-content;
    margin: 1.9rem auto 0;
    overflow: hidden;
    border-radius: 0.25rem;
    border: 0.6px solid #E0E0E0;
    background: #FFF;
  }

  .single_select_subject {
    &:last-child {
      margin-right: 0px;
    }

    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }

    label {
      padding: 0.625rem 1.25rem;
      color: #585858;
      font-family: "Poppins";
      font-size: 0.875rem;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      min-width: 110px;
      text-align: center;
    }

    input[type="radio"]:checked + label {
      color: #FFF;
      font-weight: 500;
      background: #263238;
    }
  }
}

.progress_box_wrapper {
  width: 100%;
  background-color: #ffffff;
  padding: 15px 15px 15px 15px;
  .progress_box {
    width: 100%;
    display: flex;
    align-items: center;
    .prgs_tst_detail {
      position: relative;
      width: 70%;
      margin-left: 15px;

      .tst_dtl_count {
        padding: 3px 0px;

        .tst_count {
          color: #000;
          font-weight: 600;
          min-width: 30px;
          display: inline-block;
          margin-right: 5px;
        }

        .tst_name {
          color: #707070;
          font-size: 14px;
        }
      }

      .arrow_dv {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }

    .progress-circle {
      width: 100%;
      padding: 12px;

      .progress-circle-top {
        display: flex;
        justify-content: space-around;

        .progress-circle-one {
          position: relative;
          span {
            position: absolute;
            left: 50px;
            top: 80px;
            // margin-top: 8px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            color: #000000;
          }
        }

        .progress-circle-two {
          position: relative;
          span {
            position: absolute;
            top: 80px;
            right: 30px;
            // margin-top: 8px;

            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;

            color: #000000;
          }
        }
      }

      .progress-circle-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        
        .progress-circle-third{
        position: relative;


        span {
          position: absolute;
          left: 48px;
          top: 80px;
          // margin-top: 8px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;

          color: #000000;
        }
      }
      }
    }
  }
}

@media (max-width: 575.98px) {

  .type_list {
    .list {
      margin-bottom: 0;
    }
  }

  .progress_box_wrapper {
    padding: 0;
    margin-top: 20px;
      .progress_box {
        .prgs_tst_detail {
  
          .tst_dtl_count {
  
            .tst_count {}
  
            .tst_name {}
          }
  
          .arrow_dv {}
        }
  
        .progress-circle {
          padding: 0;
  
          .progress-circle-top {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
}
