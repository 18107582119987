.mockTestWrapper {
  display: flex;
  flex-wrap: wrap;

  .mainAdvTab {
    margin-bottom: 25px;
    margin-top: -20px;
    // display: flex;
    justify-content: space-between;

    ul {
      margin: auto;
      width: max-content;
      border-radius: 8px;
      border: 0.6px solid #E0E0E0;
      background: #FFF;
      overflow: hidden;

      li {
        // border: none;

        button {
          // border: none;
          color: #000;
          font-size: 14px;
          font-weight: 400;
          // min-width: 120px;
          border-radius: 0px;
          min-width: 138px;
        }

        .nav-link.active {
          color: #B50303;
          font-weight: 500;
          // background-color: #ff933a;
          border-bottom: 4px solid #B50303!important;
        }
      }
    }
  }

  .mockTestBox {
    border-radius: 8px;
    border: 0.8px solid #E9E9E9;
    background: #ffffff;
    padding: 20px;
    margin: 0px 20px 20px 0px;
    width: calc(50% - 10px);

    &:nth-child(2n + 2) {
      margin-right: 0px;
    }

    .mockTestNameType {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 15px;

      .mockTestName {
        width: 65%;
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
          width: 25px;
          height: 25px;
          margin-right: 8px;
        }

        span {
          font-size: 16px;
          font-weight: 500;
          display: inline-block;
          line-height: normal;
          color: #121212;
        }
      }

      .examDate {
        color: #484848;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.12px;
        // width: 33%;
        text-align: right;
        margin-bottom: 5px;
      }
    }
    .mockTestPara {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
      color: #000000;
      margin-bottom: 20px;
    }

    .attemBtn {
      display: flex;

      a {
        text-align: center;
        text-decoration: none;
        margin-right: 15px;
      }

      button {
        margin-right: 15px;

        &:last-child {
          margin-right: 0px;
        }
      }

      .attempt {
        background: #B50303;
        border-radius: 59px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        padding: 14px 32px;
        width: calc(65% - 15px);
        margin-right: 15px;
      }

      .practice {
        font-size: 14px;
        font-weight: 500;
        color: #263238;
        border: 1px solid #E0E0E0!important;
        border-radius: 59px;
        background-color: #F6F6F7;
        padding: 14px 32px;
        width: 35%;
      }
    }
  }
}


@media (max-width: 575.98px) {
  .mockTestWrapper {
    flex-direction: column;
    .mockTestBox{
      width: 100%;
      margin: 0;
      margin-bottom: 16px;
      .attemBtn{
        justify-content: center;
        flex-wrap: wrap;
        gap: 0.3rem;
      }
    }
    .mainAdvTabBox{
      width: 100%;
    }
    .mainAdvTab {
      ul {
            margin-left: auto;
            margin-right: auto;
            justify-content: space-between;
            li{
              button{
                min-width: 10px;
              }
            }
      }
    }
  }
}