.questionbank_test_questions_view_wrapper {
  .test_questions_view_inner_wrapper {
    .sec_1_wrapper {
      .sec_1_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: center !important;
          margin: 20px 0px;

          .btn_wrapper {
            button {
              border: none;
              outline: none;
              width: 150px;
              height: 40px;
              border-radius: 25px;
              background-color: #6d48ef;
              font-size: 18px;
              font-weight: 600;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }

    .sec_2_wrapper {
      .sec_2_inner_wrapper {
        .section_content_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .left_side_content_wrapper {
            width: 70%;
            height: 100vh;
            border-radius: 0.5rem;
            background: #fff;
            box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06);
            position: relative;

            .sub_section_wrapper {
              padding: 1.5rem 2.5rem 0 2.5rem;
              min-height: 70vh;
              scrollbar-width: thin;
              .questionBnktestProgressIndicator {
                display: inline-flex;
                padding: 0 1.25rem;
                height: 3.5rem;
                justify-content: space-between;
                align-items: center;
                border-radius: 0.5rem;
                background: #263238;
                width: 100%;
                margin-bottom: 1.5rem;

                .qstnCount {
                  color: #fff;
                  font-family: Poppins;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  letter-spacing: 0.01rem;
                }

                .languageSwitcherIcon {
                  button {
                    color: #fff !important;
                    font-family: Mukta;
                    font-size: 1.3rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                  }
                }
              }

              .qstnBnkquestionAndSolutionWrapper {
                .quesitonInfoSection {
                  font-family: Poppins;

                  .text_content_wrapper {
                    .typeLanguageWrapper {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      margin-bottom: 1.13rem;

                      span {
                        color: #5e5e5e;
                        font-size: 0.875rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                        .qstnTypeKey {
                          color: #121212;
                          font-size: 0.875rem;
                          font-style: normal;
                          font-weight: 600;
                          line-height: normal;
                        }
                      }

                      .markReview {
                        display: flex;

                        .bookmark_image_wrapper {
                          margin: 0px 10px;

                          span {
                            margin-right: 5px;
                          }

                          img {
                            cursor: pointer;
                          }
                        }
                      }
                    }

                    .qstnCount {
                      color: #121212;
                      font-family: Poppins;
                      font-size: 0.875rem;
                      font-style: normal;
                      font-weight: 600;
                      line-height: normal;
                    }

                    .questionBox {
                      color: #353535;
                      font-size: 1rem;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 1.625rem; /* 162.5% */
                      letter-spacing: 0.005rem;

                      .hide {
                        display: none;
                      }

                      .show {
                        display: block;
                      }
                    }
                  }
                }

                .questionOptions {
                  .examcrcker_single_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 1rem;
                    .excrker_single_select {
                      width: calc(50% - 8px);
                      border-radius: 0.25rem;
                      border: 0.6px solid #939393;
                      background: #fff;

                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }

                      .classCorrect {
                        border-radius: 0.25rem;
                        border: 0.6px solid #19b244;
                        background: rgba(25, 178, 68, 0.1);

                        .option_initial {
                          margin-right: 10px;
                          display: inline-block;

                          .text_content_2 {
                            margin: 0;
                            color: #00701f !important;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                          }
                        }

                        .option_final {
                          display: inline-block;

                          .text_content_3 {
                            margin: 0;
                            color: #00701f !important;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      .classWrong {
                        border-radius: 0.25rem;
                        border: 1px solid #c62a2a;
                        background: rgba(198, 42, 42, 0.1);

                        .option_initial {
                          margin-right: 10px;
                          display: inline-block;

                          .text_content_2 {
                            margin: 0;
                            color: #c62a2a !important;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                          }
                        }

                        .option_final {
                          display: inline-block;

                          .text_content_3 {
                            margin: 0;
                            color: #c62a2a !important;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      input[type="checkbox"] {
                        opacity: 1;
                        position: fixed;
                        width: 0;
                      }

                      .single_option_wrapper {
                        justify-content: flex-start;
                        align-items: center;
                        cursor: pointer;
                        padding: 1.2rem 0 1.2rem 1.2rem;
                        display: flex;

                        .option_initial {
                          margin-right: 10px;
                          display: inline-block;

                          .text_content_2 {
                            margin: 0;
                            color: #3e3e3e;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                          }
                        }

                        .option_final {
                          display: inline-block;

                          .text_content_3 {
                            margin: 0;
                            color: #3e3e3e;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      input[type="checkbox"]:checked + .single_option_wrapper {
                        border-radius: 0.25rem;
                        border: 1px solid #fbab35;
                        background: #fff9ef;
                      }
                    }
                  }

                  .hide {
                    display: none !important;
                  }

                  .show {
                    display: flex !important;
                  }

                  .show2 {
                    display: block !important;
                  }

                  //multi-select
                  .examcracker_multi_select_option_collection_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 1rem;

                    .excrker_single_select {
                      width: calc(50% - 8px);
                      border-radius: 0.25rem;
                      border: 0.6px solid #939393;
                      background: #fff;

                      &:nth-child(2n + 2) {
                        margin-right: 0px;
                      }

                      .classCorrect {
                        border-radius: 0.25rem;
                        border: 0.6px solid #19b244;
                        background: rgba(25, 178, 68, 0.1);

                        .option_initial {
                          margin-right: 10px;
                          display: inline-block;

                          .text_content_2 {
                            margin: 0;
                            color: #00701f !important;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                          }
                        }

                        .option_final {
                          display: inline-block;

                          .text_content_3 {
                            margin: 0;
                            color: #00701f !important;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      .classWrong {
                        border-radius: 0.25rem;
                        border: 1px solid #c62a2a;
                        background: rgba(198, 42, 42, 0.1);

                        .option_initial {
                          margin-right: 10px;
                          display: inline-block;

                          .text_content_2 {
                            margin: 0;
                            color: #c62a2a !important;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                          }
                        }

                        .option_final {
                          display: inline-block;

                          .text_content_3 {
                            margin: 0;
                            color: #c62a2a !important;
                            font-family: Poppins;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      input[type="checkbox"] {
                        opacity: 1;
                        position: fixed;
                        width: 0;
                      }

                      .single_option_wrapper {
                        justify-content: flex-start;
                        align-items: center;
                        cursor: pointer;
                        padding: 1.2rem 0 1.2rem 1.2rem;
                        display: flex;

                        .option_initial {
                          // margin-right: 10px;
                          margin-right: 10px;
                          display: inline-block;

                          .text_content_2 {
                            margin: 0;
                            color: #3e3e3e;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                          }
                        }

                        .option_final {
                          display: inline-block;

                          .text_content_3 {
                            margin: 0;
                            color: #3e3e3e;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            p {
                              margin-bottom: 0px;
                            }
                          }
                        }
                      }

                      input[type="checkbox"]:checked + .single_option_wrapper {
                        border-radius: 0.25rem;
                        border: 1px solid #fbab35;
                        background: #fff9ef;
                      }
                    }
                  }

                  //integer
                  .input_wrapper {
                    .input_inner_wrapper {
                      input {
                        border: none;
                        outline: none;
                        width: 100%;
                        border-radius: 0.25rem;
                        border: 0.6px solid #939393;
                        background: #fff;
                        padding: 1.2rem;
                        color: #838383;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                      }

                      input[type="number"]::-webkit-outer-spin-button,
                      input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                    }
                  }
                }
              }

              .qstbBnkquestionSwitcherWrrpr {
                .reportAndError {
                  cursor: pointer;
                  img {
                    margin-right: 0.25rem;
                  }
                  .reportAndErrText {
                    color: #b50503;
                    font-family: Poppins;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 0.875rem; /* 116.667% */
                  }
                }

                .qstnBnkquestionSwitcherWrrpr {
                  // background: red;
                  font-family: Poppins;
                  width: 92%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0.88rem 0;
                  border-top: 0.8px solid #f2f2f2;
                  // background: #fff;
                  margin-top: 2.19rem;

                  .exc_left_side_wrapper {
                    .btn_wrapper {
                      button {
                        color: #b50503;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 0.62rem 1rem;
                        border-radius: 0.25rem;
                        border: 1px solid #b50503;
                      }
                    }
                  }

                  .exc_right_side_wrapper {
                    .btn_wrapper {
                      .solDisable {
                        color: #fff;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        border-radius: 0.25rem;
                        border: 0.6px solid #b91311;
                        opacity: 0.6;
                        background: #b91311;
                        padding: 0.625rem 3.12rem;
                      }
                      .solEnable {
                        color: #fff;
                        font-family: Poppins;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        border-radius: 0.25rem;
                        border: 0.6px solid #b91311;
                        background: #b91311;
                        padding: 0.625rem 3.12rem;
                      }

                      .excr_next_btn,
                      .submitBtn {
                        color: #fff;
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        border-radius: 0.25rem;
                        border: 0.6px solid #b91311;
                        background: #b91311;
                        padding: 0.625rem 3.12rem;
                        margin-left: 1.25rem;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }

              .sectionWiseTestWrapper {
                display: none;

                .subjectWiseTest {
                  background: #fff;
                  display: flex;
                  padding: 7px 10px;
                  border-radius: 36px;
                  justify-content: space-between;
                  margin-bottom: 15px;

                  .single_select_subject {
                    input[type="radio"] {
                      opacity: 0;
                      position: fixed;
                      width: 0;

                      &:checked + label {
                        background-color: #ff933a;
                        border: none;
                        color: #ffffff;
                        border-radius: 19px;
                      }
                    }

                    label {
                      padding: 8px 20px;
                      font-size: 15px;
                      font-weight: 500;
                      letter-spacing: 0.2px;
                      color: #6b6876;
                      cursor: pointer;
                      min-width: 150px;
                      text-align: center;
                    }
                  }
                }

                .sectionWiseTest {
                  display: flex;
                  width: max-content;
                  margin: 0 auto;
                  margin-bottom: 15px;
                }
              }

              .sub_sub_section_wrapper_2 {
                h2 {
                  text-align: center;
                  font-size: 25px;
                  font-weight: bold;
                  margin-bottom: 20px;
                }

                .text_content_wrapper {
                  .typeLanguageWrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 5px;

                    span {
                      color: #707070;
                      font-size: 12px;
                    }

                    .markReview {
                      display: flex;

                      .bookmark_image_wrapper {
                        margin: 0px 10px;

                        span {
                          margin-right: 5px;
                        }

                        img {
                          cursor: pointer;
                        }
                      }
                    }
                  }

                  .questionBox {
                    display: flex;
                    align-items: baseline;
                    margin-bottom: 20px;

                    span {
                      margin-right: 5px !important;
                    }

                    .text_content {
                      margin: 0;
                      font-size: 16px;
                      font-weight: 500;
                      color: #000;

                      p {
                        margin-bottom: 0px;
                      }
                    }

                    .hide {
                      display: none;
                    }

                    .show {
                      display: block;
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_3 {
                //single-select
                .single_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;
                  margin-bottom: 30px;

                  .single_select {
                    width: calc(50% - 8px);
                    margin: 10px 16px 10px 0px;

                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    .classCorrect {
                      border: 1px solid #5ddd9b !important;
                      background: #e1f1ec !important;
                    }

                    .classWrong {
                      border: 1px solid #f93030 !important;
                      background: #f6e0e4 !important;
                    }

                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }

                    .single_option_wrapper {
                      min-height: 50px;
                      background-color: #fff;
                      border-radius: 8px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;

                      .option_initial {
                        margin-right: 10px;

                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #acacac;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #000;

                          p {
                            margin-bottom: 0px;
                          }
                        }
                      }
                    }

                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                .hide {
                  display: none !important;
                }

                .show {
                  display: flex !important;
                }

                .show2 {
                  display: block !important;
                }

                //multi-select
                .multi_select_option_collection_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;

                  .single_select {
                    width: calc(50% - 8px);
                    margin: 10px 16px 10px 0px;

                    &:nth-child(2n + 2) {
                      margin-right: 0px;
                    }

                    input[type="checkbox"] {
                      opacity: 1;
                      position: fixed;
                      width: 0;
                    }

                    .single_option_wrapper {
                      min-height: 50px;
                      border: solid 1px #f0eef8;
                      background-color: #fafafd;
                      border-radius: 15px;
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                      padding: 10px;
                      cursor: pointer;

                      .option_initial {
                        margin-right: 10px;

                        .text_content_2 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          margin: 0;
                          font-size: 16px;
                          font-weight: 500;
                          color: #6b6876;
                        }
                      }
                    }

                    input[type="checkbox"]:checked + .single_option_wrapper {
                      border: 2px solid #6d48ef;

                      .option_initial {
                        .text_content_2 {
                          font-weight: 600;
                        }
                      }

                      .option_final {
                        .text_content_3 {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }

                //integer
                .input_wrapper {
                  .input_inner_wrapper {
                    margin-bottom: 30px;

                    input {
                      border: none;
                      outline: none;
                      width: 100%;
                      border: solid 1px #f0eef8;
                      background-color: #fff;
                      height: 50px;
                      border-radius: 8px;
                      padding: 0 5px;
                      font-size: 16px;
                      font-weight: 500;
                    }

                    input[type="number"]:focus {
                      border: 2px solid #6d48ef;
                    }

                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                      -webkit-appearance: none;
                      margin: 0;
                    }
                  }
                }
              }

              .sub_sub_section_wrapper_4 {
                margin-bottom: 15px;

                .checkbox_wrapper {
                  input[type="checkbox"] {
                    margin-right: 10px;
                  }

                  label {
                    font-size: 13px;
                    vertical-align: text-top;
                    color: #09041a;
                  }
                }
              }

              .sub_sub_section_wrapper_5 {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left_side_wrapper {
                  width: calc(50% - 8px);
                  margin-right: 16px;

                  .btn_wrapper {
                    text-align: left;

                    button {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #6d48ef;
                      padding: 10px;
                      width: 100%;
                      border: 1px solid #e2e2e2;
                      // text-decoration: underline;
                    }
                  }
                }

                .right_side_wrapper {
                  width: calc(50% - 8px);

                  .btn_wrapper {
                    text-align: right;

                    button {
                      border: none;
                      outline: none;
                      border-radius: 8px;
                      background-color: #ffffff;
                      font-size: 16px;
                      font-weight: 600;
                      text-align: center;
                      color: #fff;
                      padding: 10px;
                      width: 100%;
                      background: #6d48ef;
                      // text-decoration: underline;
                    }
                  }
                }
              }

              .solutionContainer {
                .solutionBoxWrapper {
                  padding-top: 20px;

                  p {
                    word-break: break-all;
                  }
                }
              }

              .videoWrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 50px;

                .view_videoSolution_btn {
                  background: #ff933a;
                  width: 30%;
                  text-align: center;
                  padding: 10px;
                  border-radius: 10px;
                  margin-bottom: 30px;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: #f5f4f8;
                }

                #iframe {
                  height: 420px;
                  width: 100%;
                }
              }

              .sub_sub_section_wrapper_6 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text_content_wrapper {
                  .text_content {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                  }
                }
              }
            }
          }

          .right_side_content_wrapper {
            width: 30%;
            overflow-y: scroll;
            position: sticky;
            top: 0px;
            box-shadow: 0px 0px 0px 4px rgba(0, 0, 0, 0.06);
            min-height: 100vh;
            .sub_section_1_wrapper {
              background-color: #fff;
              padding: 1.25rem 2.5rem 0 1.5rem;
              .sub_section_heading {
                .text_content_wrapper {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding-bottom: 0.875rem;
                  border-bottom: 0.0375rem solid #d1d1d1;

                  .text_content {
                    margin: 0;
                    color: #121212;
                    font-family: Poppins;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }

                  button {
                    border-radius: 0.25rem;
                    border: 1px solid #646464;
                    background: #fff;
                    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
                    color: #333;
                    font-family: Poppins;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 0.625rem 1rem;
                  }

                  .countdown_timer_container {
                    min-width: 55px;

                    span {
                      label {
                        color: #6d48ef;
                        font-size: 15px;
                      }
                    }
                  }
                }
                .sub_section_2_wrapper {
                  flex: 0 0 100%;

                  .section_2_1 {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .allQuestionsText {
                      margin: 0;
                      padding: 0;
                      color: #121212;
                      font-family: Poppins;
                      font-size: 1rem;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                    }

                    .gridviewIcons {
                      display: flex;
                      gap: 1rem;
                      margin: 1rem 0;
                    }
                  }

                  .question_marking_wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 1.5rem;

                    .single_marking {
                      margin-right: 3.25rem;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .box_pic {
                        width: 1.25rem;
                        height: 1.25rem;
                        border-radius: 0.25rem;
                        margin-right: 0.5rem;
                      }

                      .box_pick_background_1 {
                        border: 1px solid #8ae09f;
                        background: #c8f1d2;
                      }

                      .box_pick_background_2 {
                        background-color: #f99746;
                      }

                      .box_pick_background_3 {
                        border: 1px solid #d9d9d9;
                        background: #fff;
                      }

                      .box_pick_background_4 {
                        background: #fed5d5;
                        border: 1px solid #ffc4c4;
                      }

                      .box_pick_background_5 {
                        background: #6d48ef;
                        border: 1px solid #6d48ef;
                      }

                      .question_marking_text {
                        color: #121212;
                        font-family: Poppins;
                        font-size: 0.75rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                      }
                    }
                  }
                }
              }
            }

            .questions_serial_number_collection {
              overflow-y: scroll;
              padding: 1.5rem 1.5rem 0 1.5rem;
              background: #f6f6f7;
              width: 100%;
              height: 76vh;

              .questions_wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                flex-wrap: wrap;
                gap: 1.25rem;
              }

              .qstn_bnk_single_question_number_wrapper1 {
                display: block !important;

                .unanswered_lbl1 {
                  border-radius: 0.5rem;
                  border: 0.6px solid rgba(38, 50, 56, 0.31);
                  background: #fff;
                  margin-bottom: 0.8rem;
                  cursor: pointer;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0.8rem 0.75rem;

                  .questionBox1 {
                    overflow-x: hidden;
                    display: flex;

                    color: #383838;
                    font-family: Poppins;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 550;
                    line-height: normal;
                    padding: 0.6rem 0.75rem;

                    .questionNumber {
                      margin-right: 0.5rem;
                    }
                    p {
                      overflow: visible;
                      display: block !important;
                      color: #383838;
                      font-family: Poppins;
                      font-size: 0.875rem;
                      font-style: normal;
                      font-weight: 550;
                      line-height: normal;
                      padding: 0;
                      margin: 0;
                    }
                    .QBox {
                      display: inline-block;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    }
                  }

                  img {
                    width: 0.36869rem;
                    height: 0.67269rem;
                  }
                }
                .answered_lbl {
                  border-radius: 0.5rem;
                  border: 0.6px solid #8ae09f;
                  background: #c8f1d2;
                }

                .current_lbl {
                  border: 1px solid #ff933a;
                  border: 1px solid #ffa68c;
                  cursor: pointer;
                  background: #fff;
                }
              }
              .qstn_bnk_single_question_number_wrapper {
                .unanswered_lbl {
                  width: 3rem;
                  height: 2.875rem;
                  cursor: pointer;
                  border-radius: 0.25rem;
                  border: 0.6px solid #d9d9d9;
                  background: #fff;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .questionBox {
                    color: #000;
                    font-family: Poppins;
                    font-size: 0.875rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                }

                .answered_lbl {
                  border: 0.6px solid #8ae09f;
                  background: #c8f1d2 !important;
                }

                .current_lbl {
                  border: 1px solid #ff933a;
                  border: 1px solid #ffa68c;
                  cursor: pointer;
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

.onSubmitModal {
  .modal-header {
    padding: 10px;
    border: 0px;
  }

  .modal-body {
    margin-top: 0px !important;
    .submit_Popup {
      .exit_img {
        width: 100%;

        img {
          width: 35%;
          height: 130px;
          margin-bottom: 20px;
          margin-top: -25px;
        }
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      p {
        margin: 0 auto;
        max-width: 310px;
        margin-bottom: 20px;
      }

      .submitCancelBtn {
        button {
          border: 1px solid #6d48ef;
          border-radius: 6px;
          height: 40px;
          width: 150px;
          color: #fff;
          background: #6d48ef;
        }

        .cancel {
          color: #6d48ef;
          background: none;
          margin-right: 10px;
        }
      }
    }
  }
}

///////////////////////////   REPORT ERROR MODAL ///////////////////////////

.errorModal {
  margin-top: 70px;
  .modal-content {
    border-radius: 16px;
    padding: 30px !important ;
  }
  text-align: center;

  .modal-header {
    margin-top: 0;
    font-weight: 600;
    font-family: "Poppins";
    font-style: normal;
    border-bottom: 0 none;
    padding: 0;
    text-align: center;
    // background: red;

    .btn-close {
      width: 0;
      height: 0;
      margin-right: 20px;
    }

    h3 {
      color: #000000;
      font-weight: 600;
      font-size: 20px;
      margin-right: 0;
      margin-left: 0;
      text-align: start;
      padding: 0 !important;
    }
  }

  .modal-body {
    padding: 0 !important ;

    .error_Popup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      margin: auto;
    }

    .error_Popup .text_content {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #505050;
      text-align: start;
    }

    .errorList {
      text-align: start;
      padding: 0;
      margin: 0;
    }

    .errorList .errorListItem {
      list-style: none;
      text-align: start;
      margin-bottom: 10px;
      font-size: 16px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
      color: #000000;
    }

    .errorList .errorListItem input {
      font-size: 25px;
    }

    .errorList .errorListItem input[type="radio"] {
      height: 22px;
      width: 22px;
      vertical-align: middle;
      margin-right: 8px;
    }

    textarea::-webkit-input-placeholder {
      padding: 0;
      font-size: 14px;
      color: #b8b8b8;
    }

    button {
      padding: 10px 40px;
      font-weight: 500;
      background: var(--red);
      border-radius: 4px;
      color: #fff;
      border-color: transparent;
    }

    textarea {
      width: 100%;
      height: 85px;
      padding: 10px;
      border-radius: 4px;
      resize: none;
      outline: none;
      border: none;
      background-color: #f6f6f6;
    }
  }
}

.classCorrect {
  border: 1px solid #5ddd9b;
  background: #e1f1ec !important;
}

.classWrong {
  border: 1px solid #f93030;
  background: #f6e0e4 !important;
}

.opacity {
  opacity: 0.5;
}
