.mock_test_ppr_test_intructions_view_wrapper {
  padding-top: 50px;
  background-color: #fff;

  .test_intructions_view_inner_wrapper {
    background-color: #fff;
    // border-radius: 10px;
    border-radius: 1rem;
    background: rgba(238, 239, 240, 0.50);
    padding: 15px;

    //istruction Screen
    .instructionScreenBox {
      .backBtn {
        cursor: pointer;
        display: inline-block;
      }

      .instruction_title {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .instruction_detailBox {
        max-width: 750px;
        margin: 0 auto;

        .examTime {
          border: 1px solid #ffdec2;
          background: #ff933a0d;
          padding: 15px;
          text-align: center;
          border-radius: 10px;
          margin-bottom: 20px;
          border-radius: 0.5rem;
          border: 0.6px solid #979797;
          background: #FFF;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2rem;

          .examTitle {
            color: #121212;
            margin-bottom: 0;
            font-weight: 600;
            font-family: "Source Sans 3", sans-serif ;
          }

          .examDurations {
            span{
              font-weight: 600;
            }
          }
        }

        .instructions {
          margin-bottom: 15px;
          border-radius: 0.5rem;
          border: 0.6px solid #979797;
          background: #FFF;

          h3 {
            font-size: 20px;
            // border-bottom: 1px solid #ffdec2;
            padding: 10px 15px 0;
          }
          ul{
            margin-left: 10px;
            margin-bottom: 0;
            li{
              color: #424242;

font-family: Poppins;
font-size: 0.875rem;
font-style: normal;
font-weight: 400;
            }
          }

          .instPoint {
            padding: 10px 15px;

            img {
              width: 100%;
            }

            p {
              font-size: 14px;
              color: #000;
            }

            h6 {
              margin-top: 0.5rem;
              text-align: center;
            }
          }
        }

        .img {
          text-align: center;
          // padding: 15px 0px;
        }

        .startTestBtn {
          button {
            background: transparent;
            width: 100%;
            color: #fff;
            padding: 7px 10px;
            border-radius: 8px;
          }
        }
      }
    }
  }
}