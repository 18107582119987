.parent-tabs-container {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;

  .single-tab {
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 49%;
    cursor: pointer;
    border-radius: 0.25rem;
    background: #fff;

    .tab-name {
      color: #000;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    img {
      width: 0.5015rem;
      height: 1.00294rem;
    }
  }
}
