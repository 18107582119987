.course_WiseWrapper {
  display: flex;
  margin-top: 20px;
  .courseBox {
    width: 50%;
    cursor: pointer;
    .course_content {
      margin-bottom: 30px;
      margin-right: 20px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 0.8px solid #E9E9E9;
      background: #FFF;
      img{
      }
      .content {
        margin-left: 25px;
        h5 {
          margin-right: 20px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          display: flex;
          align-items: center;
          color: #121212;
          margin-bottom: -0.5px;
          letter-spacing: 0.16px;
        line-height: 25px;

        }
        .contentDesc {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #484848;
          margin-bottom: -1px;
          line-height: 19px;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .course_WiseWrapper {
    flex-direction: column;
    .courseBox{
      width: 100%;
      .course_content{
        margin-right: 0;
      }
    }
  }
}
