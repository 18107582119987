@mixin flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin font-family {
  font-family: "Poppins";
  font-style: normal;
}

.predictCollegeSection {
  width: 90%;
  margin: auto;

  .cards {
    @include flex-container;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
    .testCard {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: flex-start;
      padding: 0.8rem 0;
      border-radius: 0.5rem;
      border: 0.953px solid #e8e8e8;
      width: 25%;

      .testCardImg {
        img {
          width: 2rem;
          height: 2rem;
          margin-right: 0.8rem;
        }
      }

      .testCardRight {
        .testCardRightTop {
          margin-bottom: 0.13rem;
          color: #828282;
          text-align: center;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .testCardRightBottom {
          font-family: Poppins;
          font-size: 1.3rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.015rem;
        }

        .expected {
          color: #00afb9;
        }

        .allindia {
          color: #ec6f64;
        }
        .yourmarks {
          color: #02519a;
        }

        .toppermarks {
          color: #9a7902;
        }
      }
    }
  }
  .clgList {
    @include flex-container;
    margin-top: 25px;
    margin-bottom: 20px;
    padding: 1rem 1.25rem;
    border-radius: 0.5rem;
    background: #fff;

    .left {
      height: 46px;
      @include font-family;
      font-weight: 400;
      text-align: left;
      letter-spacing: 0em;
      .section-Heading {
        color: #121212;
        font-family: Poppins;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .desc {
        color: #5a5a5a;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .right {
      @include flex-container;
      gap: 2rem;

      .stateDropDownWrpper {
        @include flex-container;
        gap: 1rem;

        select {
          border-radius: 0.25rem;
          background: #fff;
          border: 1px solid #818181;
          width: 218px;
          padding: 7px 10px;
          option {
            margin-left: 30px;
          }
        }
      }

      .categoriesDropdownWrpper {
        @include flex-container;
        gap: 1rem;

        select {
          border-radius: 0.25rem;
          background: #fff;
          border: 1px solid #818181;
          width: 218px;
          padding: 7px 10px;
        }
      }
    }
  }

  .table_section {
    border-radius: 0.375rem;
    border: 0.6px solid #ddd;
    background: #fffdf5;
    filter: blur(0px);

    table {
      .table_headingg {
        text-align: center;

        tr {
          background: #fff7d7;

          .firstTd {
            border-top-left-radius: 6px;
          }

          th {
            padding: 1rem 3rem;
            color: #121212;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.01rem;

            &:last-child {
              border-top-right-radius: 6px;
            }
          }
        }
      }

      .predictClgTbody {
        tr {
          border-bottom: 1px dashed #fffdf5;
          background: #fffdf5;

          .num {
            color: #373737;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .td-text {
            color: #373737;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .stream {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .elerank {
            color: #000;
            font-family: Poppins;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .firstTd {
            text-align: start !important;
          }

          td {
            text-align: center;

            padding: 0.81rem 25px;
          }
        }
      }
    }
  }

  .no-data-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;

    .no-data {
      img {
        width: 7.5rem;
        height: 7.5rem;
      }

      .no-data-msg {
        text-align: center;
        color: #000;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
