.videoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 0 auto 50px;

  .view_videoSolution_btn {
    color: #FFF;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    border-radius: 4px;
    background: #263238;
    padding: 10px 20px;
    width: auto;
  }

  #iframe {
    height: 420px;
    width: 100%;
  }

  // .shakaPlayer {
  //   height: 420px;
  //   width: 100%;
  //   background-color: #000000;
  // }
}
