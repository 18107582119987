.solutionBoxWrapper {
  margin-top: 1.25rem;

  .solHeading {
    color: #121212;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-top: 1rem;
    word-break: break-all;
    color: #353535;
    font-family: "Source Sans Pro";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.005rem;
  }
}
