
.modal {
    .my-modal-1 {
      width: 51.5% !important;
      max-width: 51.5% !important;
      margin: 130px auto !important;
  
      .modal-header {
        border: none;
        position: relative;

        h3 {
          color: #121212;
          font-family: "Poppins";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.16px;
          width: 100%;
          text-align: center;
          padding-top: 25px;
        }

        button {
          position: absolute;
          right: 20px;
          top: 20px;
          opacity: 1;
        }
      }
      
      .modal-body {
        padding: 0 35px!important;
        color: #121212;
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        .instruction_language {
          float: right;
    
          .lanugage_dropdown {
            border-radius: 8px;
            border: 1px solid #B50303;
            padding: 10px 20px;
            background: #FFF;

            select {
              border: none;
              color: #B50303;
              font-family: "Poppins";
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              cursor: pointer;
              outline: 0px;
            }
          }
    
          span {
            margin-right: 20px;
            display: inline-block;
          }
        }
    
        .instruction_wrapper {
          margin-top: 63px;
          max-height: calc(60vh - 200px);
          overflow-y: scroll;
          overflow-x: hidden;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #C6C6C6;
          padding: 20px;
    
          .instruction_inner_wrapper {
            .instruction_inner_header {
              
    
              h3 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                color: #121212;
                font-size: 14px;
                font-weight: 600;
                line-height: normal;
              }
              h4 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                color: #121212;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }
  
      .modal-footer-btn {
        text-align: center;
        margin: 40px auto;
  
        button {
          width: 200px;
          color: #FFF;
          padding: 12px 14px;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          border-radius: 4px;
          background: #B50503;

        }
      }
    }
  }

    @media (max-width: 575.98px) {
      .modal .my-modal {
        width: 100% !important;
        max-width: 100% !important;
      }
    }