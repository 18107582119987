.video-player-container {
  .video-details-watch-count {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.6rem;
    .video-details {
      display: flex;
      align-items: center;

      .subject-name {
        color: #383838;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 0.25rem;
      }

      .morethan-symbol {
        margin-right: 0.25rem;

        img {
          width: 1rem;
          height: 1rem;
        }
      }

      .topic-name {
        color: #383838;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
          color: #000000;
        }
      }
    }

    .watch-count {
      border-radius: 0.25rem;
      border: 0.6px solid #f2f2f2;
      background: #f6f6f7;

      .img-and-count {
        padding: 0.5rem 0.75rem;
        color: #121212;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.00375rem;

        img {
          margin-right: 0.5rem;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .more-details {
    margin-top: 1rem;

    .live {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;

      .live-circle {
        border-radius: 50%;
        width: 0.75rem;
        height: 0.75rem;
        background: #ff2424;
        margin-right: 0.25rem;
      }

      .live-text {
        color: #121212;

        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .topic-and-leave-class {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .topic {
        color: #121212;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .leave-class-btn {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 0.25rem;
        background: #b50303;
        padding: 0.38rem 0.75rem;
        cursor: pointer;
      }
    }

    .tutor-details {
      display: flex;
      align-items: center;

      .tutor-profile-pic {
        margin-right: 1rem;

        display: flex;
        align-items: center;

        img {
          width: 2.00894rem;
          height: 2.5rem;
          margin-right: 0.5rem;
        }

        .tutor-name {
          color: #585858;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .tutor-rating {
        display: flex;
        align-items: center;

        img {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.25rem;
        }

        .rating {
          color: #000;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          margin-top: 2.5px;
        }
      }
    }
  }
  .video_details_content_wrapper {
    .tab-list {
      display: flex;
      list-style-type: none;
      justify-content: space-between;
      border-radius: 0.5rem;
      background: #edeeee;
      padding: 0 1.5rem;
      margin-bottom: 2.5rem;

      li {
        padding: 0.75rem 1rem;
        color: #646666;
        font-family: "Poppins";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid #263238;
          color: #121212;
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }

    .tab-content {
      display: none;

      .notes_wrapper {
        .notes_empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 2rem;

          img {
            width: 3rem;
            margin-bottom: 1rem;
          }

          p {
            color: #7e7e7e;
            text-align: center;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
          }
        }

        .add_button {
          margin-bottom: 1rem;

          .active {
            opacity: 1;
          }

          button {
            display: flex;
            align-items: center;
            border-radius: 0.25rem;
            opacity: 0.6;
            background: #b50303;
            padding: 0.1rem 0.75rem;
            color: #fff;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;

            span {
              font-size: 2rem;
              margin-right: 0.5rem;
              font-weight: 250;
            }
          }
        }

        input[type="text"] {
          border: none;
          border-bottom: 0.8px solid #c5c5c5;
          width: 100%;
          padding: 0.75rem;
          outline: none !important;
          margin-bottom: 1rem;

          &:focus {
            outline: none;
          }

          ::placeholder {
            color: #9a9a9a;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
          }
        }

        .note_buttons {
          p {
            color: #121212;
            font-family: "Poppins";
            font-size: 0.75rem;
            font-weight: 500;
            line-height: normal;
          }

          button {
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            border: 1px solid #000;
            color: #212121;
            margin-left: 1.5rem;

            &:last-child {
              color: #fff;
              background: #b50303;
              border: none;
            }
          }
        }

        .notes_inner {
          .notes_list {
            margin-top: 2rem;

            .note {
              padding: 0.75rem;
              margin-bottom: 0.75rem;
              position: relative;
              z-index: 10;

              p {
                color: #3f3f3f;
                font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.4375rem;
                margin: 0;
                width: 87%;
              }

              div {
                p {
                  color: #4d4d4d;
                  font-family: "Poppins";
                  font-size: 0.75rem;
                  font-weight: 400;
                  line-height: normal;
                  margin: 0 0.5rem 0 0;
                }

                button {
                  background: #fff;
                  width: 3rem;
                  height: 2rem;
                  border-radius: 2rem;

                  img {
                    width: 1rem;
                  }
                }

                button.active {
                  background: #edeeee;
                }
              }

              ul {
                position: absolute;
                right: 0;
                top: -150%;
                list-style-type: none;
                margin: 0;
                padding: 0;
                z-index: 11;
                background-color: #fff;
                border-radius: 0.5rem;
                box-shadow: -2px -2px 6px 0px rgba(0, 0, 0, 0.12),
                  4px 4px 12px 0px rgba(0, 0, 0, 0.12);

                li {
                  cursor: pointer;
                  color: #000;
                  padding: 0.75rem 1.25rem;

                  p {
                    font-family: "Poppins";
                    font-size: 0.75rem;
                    font-weight: 400;
                    line-height: normal;
                    display: inline;
                    margin: 0;
                  }

                  img {
                    width: 1rem;
                    margin-right: 0.5rem;
                  }

                  &:hover {
                    background: #eee;
                  }
                }
              }
            }
          }
        }
      }

      .study_material {
        flex-wrap: wrap;

        .sm_empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 2rem auto 0;

          img {
            width: 3rem;
            margin-bottom: 1rem;
          }

          p {
            color: #7e7e7e;
            text-align: center;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
          }
        }

        .topic_card {
          border-radius: 0.5rem;
          background: #f6f6f7;
          width: calc(50% - 1.25rem);
          margin: 0 1.25rem 1.25rem 0;
          padding: 1.2rem 1rem;
          align-items: center;

          div {
            display: flex;
            align-items: center;

            img {
              width: 2.75rem;
            }

            p {
              margin: 0 0 0 1rem;
              color: #000;
              font-family: "Poppins";
              font-size: 1rem;
              font-weight: 400;
              line-height: normal;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          a {
            margin-left: auto;
            background-color: #b50503;
            width: 2rem;
            height: 2rem;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 1rem;
            }
          }
        }
      }

      .rating {
        h3 {
          color: #121212;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.005rem;
          margin-bottom: 1rem;
        }

        .rating_star_div {
          width: fit-content;

          .stars {
            label {
              margin-right: 1rem;

              &:last-child {
                margin-right: 0;
              }

              input[type="radio"] {
                display: none;
              }

              span {
                img {
                  width: 3rem;
                }
              }
            }
          }

          .rating_guide {
            margin-top: 0.75rem;

            span {
              color: #5d5d5d;
              font-family: "Poppins";
              font-size: 0.6875rem;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .comments_div {
          margin-top: 1.5rem;

          .comment_input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;

            input {
              border: none;
              outline: none;
              border-bottom: 0.8px solid #c5c5c5;
              padding: 0.75rem;
              width: 93%;

              ::placeholder {
                color: #9a9a9a;
                font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: normal;
              }
            }

            button {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 2.5rem;
              background-color: #b50503;

              img {
                width: 1.5rem;
              }
            }
          }

          .comment_details {
            margin-bottom: 1.65rem;

            .user_name {
              color: #000;
              font-family: "Poppins";
              font-size: 1rem;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.005rem;
              margin-bottom: 0.5rem;
            }

            .stars_container {
              img {
                width: 1rem;
                margin-right: 0.2rem;
              }
            }

            .comment {
              color: #3f3f3f;
              font-family: "Poppins";
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.4375rem;
              margin: 0.5rem 0;
            }

            .like_report {
              span {
                margin-right: 1rem;

                img {
                  width: 1.25rem;
                }

                p {
                  display: inline-block;
                  color: #4d4d4d;
                  font-family: "Poppins";
                  font-size: 0.875rem;
                  font-weight: 400;
                  line-height: normal;
                  margin: 0 0 0 0.25rem;
                }
              }
            }
          }
        }
      }

      .timeline_div {
        .timeline {
          margin-left: 0.5rem;

          button {
            color: #316ddf;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
          }

          p {
            color: #585858;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0.5rem;
            padding-left: 0.2rem;
            display: inline-block;
          }
        }
      }

      &.active {
        display: block;
      }
    }

    .text_content_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 40px;
      }

      .text_content {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: #000000;
      }
    }
    .content_wrapper_1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .left_side_content_wrapper {
        .text_content_wrapper {
          .text_content {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: #585858;
          }
        }
      }
      .right_side_content_wrappers {
        display: flex;
        align-items: center;
        .label_content_wrapper {
          margin: 0 5px;
          label {
            // padding: 5px 15px;
            // border-radius: 15px;
            // background-color: #fef4ec;
            span {
              font-weight: 500;
              color: #000;

              img {
                width: 15px;
                height: 15px;
                margin-right: 5px;
                vertical-align: unset;
              }
            }
          }
        }
        // .bookmark_content_wrapper {
        //   margin: 0 5px;
        //   .image_wrapper {
        //     img {
        //       cursor: pointer;
        //     }
        //   }
        // }
      }
    }
    .content_wrapper_2 {
      margin: 10px 0;
      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
          color: #000000;
        }
      }
      .topic_collection {
        .sub_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          margin-bottom: 10px;
          .left_side_content_wrapper {
            .text_content_wrapper {
              .text_content {
                margin: 0;
                .key_tag {
                  margin-right: 5px;
                  font-size: 16px;
                  font-weight: 500;
                  color: #000000;
                }
                .value_tag {
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }
          }
          .right_side_content_wrappers {
            padding: 0;
            .text_content_wrapper {
              .text_content {
                margin: 0;
                label {
                  padding: 8px 16px;
                  border-radius: 4px;
                  background-color: #f6f6f7;
                  font-size: 14px;
                  font-weight: 500;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.is-live {
  width: 920px;
}

.is-recorded-video {
  // width: 1150px;
  width: 1270px;
  height: 650px;
}
.video_details_wrapper {
  margin: 20px 0;
  .video_details_content_wrapper {
    .tab-list {
      display: flex;
      list-style-type: none;
      justify-content: space-between;
      border-radius: 0.5rem;
      background: #edeeee;
      padding: 0 1.5rem;
      margin-bottom: 2.5rem;

      li {
        padding: 0.75rem 1rem;
        color: #646666;
        font-family: "Poppins";
        font-size: 0.875rem;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;

        &.active {
          border-bottom: 2px solid #263238;
          color: #121212;
          font-size: 0.875rem;
          font-weight: 500;
        }
      }
    }

    .tab-content {
      display: none;

      .notes_wrapper {
        .notes_empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 2rem;

          img {
            width: 3rem;
            margin-bottom: 1rem;
          }

          p {
            color: #7e7e7e;
            text-align: center;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
          }
        }

        .add_button {
          margin-bottom: 1rem;

          .active {
            opacity: 1;
          }

          button {
            display: flex;
            align-items: center;
            border-radius: 0.25rem;
            opacity: 0.6;
            background: #b50303;
            padding: 0.1rem 0.75rem;
            color: #fff;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;

            span {
              font-size: 2rem;
              margin-right: 0.5rem;
              font-weight: 250;
            }
          }
        }

        input[type="text"] {
          border: none;
          border-bottom: 0.8px solid #c5c5c5;
          width: 100%;
          padding: 0.75rem;
          outline: none !important;
          margin-bottom: 1rem;

          &:focus {
            outline: none;
          }

          ::placeholder {
            color: #9a9a9a;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
          }
        }

        .note_buttons {
          p {
            color: #121212;
            font-family: "Poppins";
            font-size: 0.75rem;
            font-weight: 500;
            line-height: normal;
          }

          button {
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            border: 1px solid #000;
            color: #212121;
            margin-left: 1.5rem;

            &:last-child {
              color: #fff;
              background: #b50303;
              border: none;
            }
          }
        }

        .notes_inner {
          .notes_list {
            margin-top: 2rem;

            .note {
              padding: 0.75rem;
              margin-bottom: 0.75rem;
              position: relative;
              z-index: 10;

              p {
                color: #3f3f3f;
                font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: 1.4375rem;
                margin: 0;
                width: 87%;
              }

              div {
                p {
                  color: #4d4d4d;
                  font-family: "Poppins";
                  font-size: 0.75rem;
                  font-weight: 400;
                  line-height: normal;
                  margin: 0 0.5rem 0 0;
                }

                button {
                  background: #fff;
                  width: 3rem;
                  height: 2rem;
                  border-radius: 2rem;

                  img {
                    width: 1rem;
                  }
                }

                button.active {
                  background: #edeeee;
                }
              }

              ul {
                position: absolute;
                right: 0;
                top: -150%;
                list-style-type: none;
                margin: 0;
                padding: 0;
                z-index: 11;
                background-color: #fff;
                border-radius: 0.5rem;
                box-shadow: -2px -2px 6px 0px rgba(0, 0, 0, 0.12),
                  4px 4px 12px 0px rgba(0, 0, 0, 0.12);

                li {
                  cursor: pointer;
                  color: #000;
                  padding: 0.75rem 1.25rem;

                  p {
                    font-family: "Poppins";
                    font-size: 0.75rem;
                    font-weight: 400;
                    line-height: normal;
                    display: inline;
                    margin: 0;
                  }

                  img {
                    width: 1rem;
                    margin-right: 0.5rem;
                  }

                  &:hover {
                    background: #eee;
                  }
                }
              }
            }
          }
        }
      }

      .study_material {
        flex-wrap: wrap;

        .sm_empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 2rem auto 0;

          img {
            width: 3rem;
            margin-bottom: 1rem;
          }

          p {
            color: #7e7e7e;
            text-align: center;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
          }
        }

        .topic_card {
          border-radius: 0.5rem;
          background: #f6f6f7;
          width: calc(50% - 1.25rem);
          margin: 0 1.25rem 1.25rem 0;
          padding: 1.2rem 1rem;
          align-items: center;

          div {
            display: flex;
            align-items: center;

            img {
              width: 2.75rem;
            }

            p {
              margin: 0 0 0 1rem;
              color: #000;
              font-family: "Poppins";
              font-size: 1rem;
              font-weight: 400;
              line-height: normal;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          a {
            margin-left: auto;
            background-color: #b50503;
            width: 2rem;
            height: 2rem;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 1rem;
            }
          }
        }
      }

      .rating {
        h3 {
          color: #121212;
          font-family: "Poppins";
          font-size: 1rem;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.005rem;
          margin-bottom: 1rem;
        }

        .rating_star_div {
          width: fit-content;

          .stars {
            label {
              margin-right: 1rem;

              &:last-child {
                margin-right: 0;
              }

              input[type="radio"] {
                display: none;
              }

              span {
                img {
                  width: 3rem;
                }
              }
            }
          }

          .rating_guide {
            margin-top: 0.75rem;

            span {
              color: #5d5d5d;
              font-family: "Poppins";
              font-size: 0.6875rem;
              font-weight: 400;
              line-height: normal;
            }
          }
        }

        .comments_div {
          margin-top: 1.5rem;

          .comment_input {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;

            input {
              border: none;
              outline: none;
              border-bottom: 0.8px solid #c5c5c5;
              padding: 0.75rem;
              width: 93%;

              ::placeholder {
                color: #9a9a9a;
                font-family: "Poppins";
                font-size: 0.875rem;
                font-weight: 400;
                line-height: normal;
              }
            }

            button {
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 2.5rem;
              background-color: #b50503;

              img {
                width: 1.5rem;
              }
            }
          }

          .comment_details {
            margin-bottom: 1.65rem;

            .user_name {
              color: #000;
              font-family: "Poppins";
              font-size: 1rem;
              font-weight: 400;
              line-height: normal;
              letter-spacing: 0.005rem;
              margin-bottom: 0.5rem;
            }

            .stars_container {
              img {
                width: 1rem;
                margin-right: 0.2rem;
              }
            }

            .comment {
              color: #3f3f3f;
              font-family: "Poppins";
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 1.4375rem;
              margin: 0.5rem 0;
            }

            .like_report {
              span {
                margin-right: 1rem;

                img {
                  width: 1.25rem;
                }

                p {
                  display: inline-block;
                  color: #4d4d4d;
                  font-family: "Poppins";
                  font-size: 0.875rem;
                  font-weight: 400;
                  line-height: normal;
                  margin: 0 0 0 0.25rem;
                }
              }
            }
          }
        }
      }

      .timeline_div {
        .timeline {
          margin-left: 0.5rem;

          button {
            color: #316ddf;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
          }

          p {
            color: #585858;
            font-family: "Poppins";
            font-size: 0.875rem;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 0.5rem;
            padding-left: 0.2rem;
            display: inline-block;
          }
        }
      }

      &.active {
        display: block;
      }
    }

    .text_content_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 40px;
      }

      .text_content {
        margin: 0;
        font-size: 20px;
        font-weight: 600;
        color: #000000;
      }
    }
    .content_wrapper_1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .left_side_content_wrapper {
        .text_content_wrapper {
          .text_content {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            color: #585858;
          }
        }
      }
      .right_side_content_wrappers {
        display: flex;
        align-items: center;
        .label_content_wrapper {
          margin: 0 5px;
          label {
            span {
              font-weight: 500;
              color: #000;

              img {
                width: 15px;
                height: 15px;
                margin-right: 5px;
                vertical-align: unset;
              }
            }
          }
        }
      }
    }
    .content_wrapper_2 {
      margin: 10px 0;
      .text_content_wrapper {
        .text_content {
          margin: 0;
          font-size: 18px;
          font-weight: 600;
          color: #000000;
        }
      }
      .topic_collection {
        .sub_content_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          margin-bottom: 10px;
          .left_side_content_wrapper {
            .text_content_wrapper {
              .text_content {
                margin: 0;
                .key_tag {
                  margin-right: 5px;
                  font-size: 16px;
                  font-weight: 500;
                  color: #000000;
                }
                .value_tag {
                  font-size: 16px;
                  font-weight: 400;
                }
              }
            }
          }
          .right_side_content_wrappers {
            padding: 0;
            .text_content_wrapper {
              .text_content {
                margin: 0;
                label {
                  padding: 8px 16px;
                  border-radius: 4px;
                  background-color: #f6f6f7;
                  font-size: 14px;
                  font-weight: 500;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
