.otsBehaviourWrapper {
    background-color: #ffffff;
    min-height: 100vh;

    .behaviourInrWrapper {
        padding-top: 101px;
        padding-bottom: 20px;

        .sbjtWiseMarksInr {
            border-radius: 16px;
            background: rgba(238, 239, 240, 0.50);
            padding: 1.75rem 1.5rem 2.75rem;
            width: 78.75%;
            margin: auto;

            .backBtn {
                cursor: pointer;

                img {}
            }

            h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 1.375rem;
                font-weight: 500;
                line-height: normal;
                margin: 0 auto 1.25rem;
            }

            .difficultyLevel {
                .difficultyLevelTab {
                    .difficultyLevelTabBox {
                        display: flex;
                        max-width: max-content;
                        margin: 0 auto 2.5rem;
                        border-radius: 1.5rem;
                        border: 0.6px solid #E0E0E0;
                        background: #FFF;
                        overflow: hidden;

                        .single_select_subject {
                            &:last-child {
                                margin-right: 0px;
                            }

                            input[type="radio"] {
                                opacity: 0;
                                position: fixed;
                                width: 0;
                            }

                            label {
                                color: #585858;
                                font-family: "Poppins";
                                font-size: 0.875rem;
                                font-weight: 400;
                                line-height: normal;
                                padding: 0.62rem 1.25rem;
                                cursor: pointer;
                                min-width: 110px;
                                text-align: center;
                            }

                            input[type="radio"]:checked+label {
                                background: #263238;
                                font-weight: 500;
                                border: none;
                                color: #ffffff;
                            }
                        }
                    }

                    .difficultyLevelcontent {
                        display: flex;
                        flex-wrap: wrap;

                        .difficultyData {
                            background: #F5F4F8;
                            padding: 15px 20px;
                            width: calc(50% - 15px);
                            border-radius: 8px;
                            margin-bottom: 30px;

                            ul {
                                padding: 0px;
                                margin: 0px;

                                li {
                                    list-style: none;
                                    margin-bottom: 20px;

                                    span {
                                        width: 25%;
                                        display: inline-block;
                                        font-weight: 600;
                                        font-size: 15px;
                                    }
                                }

                                .titleData {
                                    span {
                                        font-weight: 500;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                        .scatterChart {
                            width: 100%;
                            margin-bottom: 1rem;
                            position: relative;
                            background-color: #ffffff;
                            padding: 1rem 1rem 0 1rem;

                            .chart_guide {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                span {
                                    color: #707070;
                                    font-family: "Poppins";
                                    font-size: 0.75rem;
                                    font-weight: 400;
                                    line-height: normal;
                                    margin-right: 1.5rem;
                                    padding: 1rem 0 1rem 1.5rem;
                                    position: relative;

                                    &:last-child {
                                        margin-right: 0;
                                    }

                                    &::before {
                                        position: absolute;
                                        content: "";
                                        width: 1rem;
                                        height: 0.625rem;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        border-radius: 0.125rem;
                                    }
                                }

                                .correct {
                                    &::before {
                                        background: #00A02D;
                                    }
                                }

                                .wrong {
                                    &::before {
                                        background: #DF2727;
                                    }
                                }

                                .unattempt {
                                    &::before {
                                        background: #6A6A6A;
                                    }
                                }
                            }

                            .canvasjs-chart-credit {
                                display: none;
                            }
                        }

                        .scorePercentageBox {
                            width: calc(50% - 0.625rem);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 0.5rem;
                            border: 0.8px solid #E9E9E9;
                            background: #FFF;
                            margin-right: 1.25rem;

                            &:last-child {
                                justify-content: flex-start;
                                align-items: flex-start;
                                margin-right: 0;

                                .scoreDetail {
                                    padding: 2rem 0 0 2rem;
                                }
                            }

                            .canvasDoughnutChart_wrapper {
                                position: relative;

                                // ::after {
                                //     content: "";
                                //     width: 60px;
                                //     height: 10px;
                                //     background-color: #F5F4F8;
                                //     position: absolute;
                                //     bottom: 2px;
                                //     left: 1px;
                                // }

                                .canvasjs-chart-credit {
                                    display: none;
                                }
                            }

                            .scoreChart {
                                width: 150px;
                                margin: 40px 20px;

                                .CircularProgressbar .CircularProgressbar-path {
                                    stroke: #FCB21D;
                                }
                            }

                            .mediumResult {
                                .CircularProgressbar .CircularProgressbar-path {
                                    stroke: #00A9B3;
                                }
                            }

                            .hardResult {
                                .CircularProgressbar .CircularProgressbar-path {
                                    stroke: #4DD7D8;
                                }
                            }

                            .scoreDetail {

                                .left_para {
                                    color: #000;
                                    font-family: "Poppins";
                                    font-size: 0.875rem;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                                
                                .right_para {
                                    font-family: "Poppins";
                                    font-size: 1rem;
                                    font-weight: 500;
                                    line-height: normal;
                                    letter-spacing: 0.005rem;
                                }

                                .percentageDiv {
                                    margin-bottom: 0.75rem;

                                    span {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 0.875rem;
                                        font-weight: 400;
                                        line-height: normal;
                                        letter-spacing: 0.00438rem;
                                        padding-left: 1.3rem;                                      
                                    }
                                }

                                .correct {
                                    span {
                                        position: relative;
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            left: 0;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            width: 0.875rem;
                                            height: 0.875rem;
                                            border-radius: 50%;
                                            background-color: #00A02D;
                                        }
                                    }
                                }

                                .wrong {
                                    span {
                                        position: relative;
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            left: 0;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            width: 0.875rem;
                                            height: 0.875rem;
                                            border-radius: 50%;
                                            background-color: #DF2727;
                                        }
                                    }
                                }

                                .unattempt {
                                    span {
                                        position: relative;
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            left: 0;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            width: 0.875rem;
                                            height: 0.875rem;
                                            border-radius: 50%;
                                            background-color: #6A6A6A;
                                        }
                                    }
                                }
                            }
                        }

                        .margin0 {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1199.98px) {
    .behaviourWrapper {
        .behaviourInrWrapper {
            .sbjtWiseMarksInr {
                margin: 20px 0px 0px !important;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .otsBehaviourWrapper {

        .behaviourInrWrapper {
            padding-top:40px ;

            .sbjtWiseMarksInr {
                margin-left: 0;
                margin-right: 0;

                .backBtn {}

                .difficultyLevel {
                    h3 {}

                    .difficultyLevelTab {
                        .difficultyLevelTabBox {
                            flex-wrap:wrap ;
                            max-width: none;
                            .single_select_subject{
                                width: 33.33%;
                                label{
                                    min-width: -webkit-fill-available;
                                    padding: 8px 5px;
                                }
                            }
                        }
                        .difficultyLevelcontent {
                            .scorePercentageBox{
                                width: 100%;
                                margin-left: 0;
                                flex-wrap: wrap;
                                .scoreChart{
                                    width: 100%;
                                    margin: 10px;
                                }
                                .scoreDetail{
                                    margin: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}