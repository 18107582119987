.view_doubts_modal {
  font-family: Poppins;

  .image_wrapper {
    width: 100%;
    background: rgba(38, 50, 56, 0.16);
    border-radius: 0.2rem;

    .image_inner_wrapper {
      padding: 1rem;
      position: relative;

      img {
        width: 100%;
        height: 85px;
        object-fit: cover;
        border-radius: 0.2rem;
      }

      .view_image {
        position: absolute;
        color: #fff;
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 0.375rem 0.625rem;
        background: #000;
        border-radius: 0.25rem;
        background: rgba(45, 45, 45, 0.8);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }

  .modal-header {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem !important;

    .btn-close {
      margin-bottom: auto !important;
    }

    .posted_doubt_details {
      //   border: 1px solid red;
      width: 100%;
      .posted_date {
        color: #888;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.0008rem;
      }

      .posted_doubt {
        color: #121212;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.009rem;

        margin: 0.25rem 0 1rem 0;
      }
    }
  }
  .modal-body {
    padding: 1.25rem 1.25rem 1.25rem 1.25rem !important;

    .doubt_answer_details {
      .doubt_answered_by {
        color: #757575;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.12px;
      }

      .doubt_answer {
        color: #121212;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        letter-spacing: 0.14px;
        margin: 0.25rem 0 1rem 0;
      }
    }
  }
}

.doubt_image_full_screen {
  width: 100%;
}
