.storeBack {
  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.15rem;
  }

  color: #000;
  font-family: Poppins;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.8rem;
  cursor: pointer;
}

.store-banner-inner-wrapper {
  display: flex;
  flex-direction: column;
  width: 749px;
  border-radius: 8px 8px 0 0;
  overflow: hidden;

  .landingpage-store-banner-content-and-btn-wrapper {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      position: absolute;
      text-align: center;
      color: #fff;
      top: 40%;
      left: 35%;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.4px;
    }

    .new-label {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 2px 8px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .tabs-container {
    display: flex;
    background-color: white;
    align-items: center;
    padding: 0 15px;

    .tabs {
      display: flex;
      flex: 1;
    }

    .tab {
      padding: 10px 15px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      color: #797979;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 400;

      &.active {
        color: #b50503;
        border-bottom-color: #b50503;
      }

      .icon {
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    .contact-icons {
      display: flex;
      gap: 10px;

      .icon-button {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.subject-tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  gap: 1rem;

  .single-sub {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // width: calc(48% - 10px);
    width: 48.5%;
    // gap: 10rem;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
      -4px -4px 8px 0px rgba(0, 0, 0, 0.06);

    .sub-content {
      display: flex;
      justify-content: center;
      align-items: center;
      .sub-image {
        width: 2.25rem;
        height: 2.25rem;
        margin-right: 0.75rem;
      }

      .subname {
        color: #121212;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.2rem;
      }

      .subtopics {
        color: #696969;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .arrw-image {
      width: 0.9rem;
      height: 0.9rem;
    }
  }
}

.description-inner-wrapper {
  display: flex;
  width: 749px;
  padding: 16px 17px 18px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
  margin-top: 30px;

  .description-content-and-btn-wrapper {
    h3 {
      color: #121212;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.08px;
    }

    .description-content-wrapper {
      .description-content {
        .description-heading {
          color: #505050;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px; /* 164.286% */
          letter-spacing: 0.07px;
          width: 699px;
          button {
            color: #b50503;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0.07px;
          }
        }
      }
    }
  }
}

.video-inner-wrapper {
  display: flex;
  width: 749px;
  padding: 16px 17px 18px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  .video-content-and-btn-wrapper {
    h3 {
      color: #121212;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.08px;
    }
  }
}

.course-benefits-inner-wrapper {
  display: flex;
  width: 749px;
  padding: 16px 17px 18px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  h3 {
    color: #121212;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    margin-bottom: 8px;
  }
  .course-benefits-list {
    .course-benefit-item {
      img {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 10px;
      }
      span {
        color: #505050;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.performance-inner-wrapper {
  display: inline-flex;
  padding: 11px 30px 11px 30px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background: #fff3e9;
  width: 749px;
  flex-shrink: 0;
  margin-top: 30px;
  border-radius: 8px;
  .performance-benefits-list {
    .performance-benefit-item {
      .performance-benefit-name {
        img {
          width: 42px;
          height: 42px;
        }
        color: #464646;
        font-family: Poppins, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.16px;
        margin-left: 30px;
      }
    }
  }
}

.other-details-inner-wrapper {
  display: flex;
  width: 749px;
  padding: 16px 17px 18px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  h3 {
    color: #121212;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
  }
  ul {
    color: #505050;
  }
  li {
    color: #505050;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.faq-inner-wrapper {
  display: flex;
  width: 749px;
  padding: 16px 17px 18px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
  margin-top: 30px;
  margin-bottom: 70px;

  h3 {
    color: #121212;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    margin-bottom: 16px;
  }

  .faq-accordion {
    width: 100%;

    .accordion-item {
      border: none;
      border-radius: 8px;
      margin-bottom: 8px;
      background: #f6f8f8;

      .accordion-header {
        .accordion-button {
          background: #f6f8f8;
          color: #000;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-weight: 400;
          padding: 12px 16px;

          &:not(.collapsed) {
            color: #000;
            background-color: transparent;
            box-shadow: none;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      .accordion-body {
        padding: 0 16px 16px;
        color: #666;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

.coupon-referral-wrapperr {
  width: 365px;
  height: 416px;
  // height: fit-content;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 25px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem !important;

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      span {
        color: #121212;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .view-coupons {
      color: #121212;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.12px;
      text-decoration: none;
      margin-left: 65px;
    }
  }

  .coupon-input {
    display: flex;
    margin-bottom: 20px;

    input {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px 0 0 8px;
      border: 1px solid #b1b1b1;
      color: #797979;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
      padding-left: 0.6rem;
    }

    button {
      display: flex;
      padding: 11px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      background: #263238;
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      border-radius: 0 8px 8px 0;
    }

    .disableapplybtn {
      opacity: 0.7 !important;
      background: #263238 !important;
    }
  }

  .coins-reedem-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.4rem;

    .coins-reedem-inner-wrapper {
      display: flex;
      align-items: center;

      img {
        width: 1.625rem;
        height: 1.625rem;
        margin-right: 0.5rem;
      }

      .coins-reedem-content {
        .reedem-heading {
          color: #000;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.00875rem;
          text-align: start;
        }

        .coins-count {
          color: #505050;
          text-align: center;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.0075rem;
          text-align: start;

          span {
            color: #121212;
            font-family: Poppins;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.0075rem;
          }
        }
      }
    }

    .coins-input {
      input {
        stroke-width: 1px;
        stroke: #8b8b8b;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .price-details {
    h3 {
      color: #121212;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .price-row {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 8px;
      font-size: 14px;

      &.total {
        font-weight: bold;
        margin-top: 15px;
      }

      .discount {
        color: green;
      }
    }
  }

  .terms {
    color: #256bbd;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    margin: 1.8rem 0 1.25rem;
    img {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
    }
  }

  .proceed-button {
    display: flex;
    width: 318px;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #b50503;
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin-top: 12px;
  }

  .validity {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 12px;
    span {
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.faculty-details-inner-wrapper {
  display: flex;
  width: 749px;
  padding: 16px 17px 18px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
    -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
  margin-top: 30px;

  h3 {
    color: #121212;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    margin-bottom: 8px;
  }

  .slick-slider {
    width: 100%;
  }

  .faculty-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0 10px;
    .faculty-slider {
      background-color: #f6f8f8;
      padding: 15px;
      width: 136px;
    }

    .faculty-image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 8px;
      margin: auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .faculty-name {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #333;
      margin-bottom: 4px;
    }

    .view-more {
      button {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        color: #b80f0d;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 2px solid #bc1b19;
      border-right: 2px solid #bc1b19;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &.slick-prev {
      left: -20px;
      &:before {
        transform: translate(-25%, -50%) rotate(-135deg);
      }
    }

    &.slick-next {
      right: -20px;
      &:before {
        transform: translate(-75%, -50%) rotate(45deg);
      }
    }

    &:hover {
      background-color: #fff0f0;
    }
  }
}

.mobile-store-banner-inner-wrapper,
.mobile-coupon-referral-wrapperr,
.mobile-description-inner-wrapper,
.mobile-video-inner-wrapper,
.mobile-course-benefits-inner-wrapper,
.mobile-performance-inner-wrapper,
.mobile-faculty-details-inner-wrapper,
.mobile-other-details-inner-wrapper,
.mobile-faq-inner-wrapper,
.mobile-storeBack,
.mobile-subject-tab-container,
.mobile-buy-now {
  display: none !important;
}

@media (max-width: 1200px) {
  .store-banner-inner-wrapper {
    width: 597px;
  }
  .description-inner-wrapper {
    width: 597px;
  }
  .description-inner-wrapper
    .description-content-and-btn-wrapper
    .description-content-wrapper
    .description-content
    .description-heading {
    width: 534px;
  }
  .video-inner-wrapper {
    width: 597px;
  }
  .course-benefits-inner-wrapper {
    width: 597px;
  }
  .performance-inner-wrapper {
    width: 597px;
  }
  .faculty-details-inner-wrapper {
    width: 597px;
  }
  .other-details-inner-wrapper {
    width: 597px;
  }
  .faq-inner-wrapper {
    width: 597px;
  }
  .faculty-details-inner-wrapper .faculty-slide .faculty-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 8px;
    margin: auto;
  }

  .faculty-details-inner-wrapper .faculty-slide .faculty-name {
    font-size: 10px;
  }
  .faculty-details-inner-wrapper .faculty-slide .view-more button {
    font-size: 9px;
  }
  .faculty-details-inner-wrapper .faculty-slide .faculty-slider {
    padding: 8px;
    width: 79px;
  }
}
@media (max-width: 1035px) {
  .coupon-referral-wrapperr {
    width: 311px;
    height: 342px;
  }
  .coupon-referral-wrapperr .proceed-button {
    width: 261px;
    padding: 12px 14px;
    font-size: 10px;
  }
  .coupon-referral-wrapperr .coupon-input input {
    font-size: 10px;
    width: 100%;
  }
  .coupon-referral-wrapperr .header .title img {
    width: 17px;
    height: 17px;
    margin-right: 5px;
  }
  .coupon-referral-wrapperr .header .title span {
    font-size: 10px;
  }
  .coupon-referral-wrapperr .header .view-coupons {
    font-size: 10px;
    margin-left: 71px;
  }
  .coupon-referral-wrapperr .coupon-input button {
    font-size: 10px;
  }
  .coupon-referral-wrapperr .price-details h3 {
    font-size: 11px;
  }
  .coupon-referral-wrapperr .price-details .price-row {
    font-size: 10px;
  }
  .coupon-referral-wrapperr .terms {
    font-size: 10px;
  }
  .coupon-referral-wrapperr .terms img {
    width: 10px;
    height: 10px;
  }
  .coupon-referral-wrapperr .validity {
    font-size: 10px;
  }
  .coupon-referral-wrapperr .validity span {
    font-size: 10px;
  }
  .store-banner-inner-wrapper
    .landingpage-store-banner-content-and-btn-wrapper
    .text {
    font-size: 13px;
  }
  .store-banner-inner-wrapper
    .landingpage-store-banner-content-and-btn-wrapper
    .new-label {
    font-size: 9px;
  }
  .store-banner-inner-wrapper .tabs-container .tab {
    font-size: 10px;
  }
  .store-banner-inner-wrapper .tabs-container .contact-icons .icon-button {
    width: 16px;
  }
  .description-inner-wrapper .description-content-and-btn-wrapper h3 {
    font-size: 14px;
  }
  .description-inner-wrapper
    .description-content-and-btn-wrapper
    .description-content-wrapper
    .description-content
    .description-heading {
    font-size: 10px;
  }
  .video-inner-wrapper .video-content-and-btn-wrapper h3 {
    font-size: 14px;
  }
  .course-benefits-inner-wrapper h3 {
    font-size: 14px;
  }
  .course-benefits-inner-wrapper
    .course-benefits-list
    .course-benefit-item
    img {
    width: 16px;
    height: 16px;
  }
  .course-benefits-inner-wrapper
    .course-benefits-list
    .course-benefit-item
    span {
    font-size: 10px;
  }
  .performance-inner-wrapper
    .performance-benefits-list
    .performance-benefit-item
    .performance-benefit-name {
    font-size: 14px;
  }
  .performance-benefit-item img {
    width: 24px;
  }
  .description-inner-wrapper
    .description-content-and-btn-wrapper
    .description-content-wrapper
    .description-content
    .description-heading
    button {
    font-size: 10px;
  }
  .faculty-details-inner-wrapper h3 {
    font-size: 14px;
  }
  .other-details-inner-wrapper h3 {
    font-size: 14px;
  }
  .other-details-inner-wrapper li {
    font-size: 10px;
  }
  .faq-inner-wrapper h3 {
    font-size: 14px;
  }
  .faq-inner-wrapper
    .faq-accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    font-size: 10px;
  }
  .faq-inner-wrapper .faq-accordion .accordion-item .accordion-body {
    font-size: 10px;
  }
}
@media (max-width: 990px) {
  .store-banner-inner-wrapper {
    width: 440px;
  }
  .description-inner-wrapper {
    width: 440px;
  }
  .description-inner-wrapper
    .description-content-and-btn-wrapper
    .description-content-wrapper
    .description-content
    .description-heading {
    width: 440px;
  }
  .video-inner-wrapper {
    width: 440px;
    height: 256px;
  }
  .course-benefits-inner-wrapper {
    width: 440px;
  }
  .performance-inner-wrapper {
    width: 440px;
  }
  .faculty-details-inner-wrapper {
    width: 440px;
  }
  .other-details-inner-wrapper {
    width: 440px;
  }
  .faq-inner-wrapper {
    width: 440px;
  }
  .description-inner-wrapper
    .description-content-and-btn-wrapper
    .description-content-wrapper
    .description-content
    .description-heading {
    width: 400px;
  }
  .performance-inner-wrapper
    .performance-benefits-list
    .performance-benefit-item
    .performance-benefit-name {
    font-size: 12px;
  }
}
@media (max-width: 851px) {
  .coupon-referral-wrapperr {
    width: 268px;
    height: 377px;
  }
  .coupon-referral-wrapperr .header .view-coupons {
    font-size: 10px;
    margin-left: 28px;
  }
  .coupon-referral-wrapperr .coupon-input input {
    font-size: 8px;
  }
  .coupon-referral-wrapperr .proceed-button {
    width: 213px;
  }
}
@media (max-width: 800px) {
  .store-banner-inner-wrapper,
  .coupon-referral-wrapperr,
  .description-inner-wrapper,
  .video-inner-wrapper,
  .course-benefits-inner-wrapper,
  .performance-inner-wrapper,
  .faculty-details-inner-wrapper,
  .other-details-inner-wrapper,
  .faq-inner-wrapper,
  .storeBack,
  .subject-tab-container {
    display: none;
  }
  .mobile-store-banner-inner-wrapper,
  .mobile-coupon-referral-wrapperr,
  .mobile-description-inner-wrapper,
  .mobile-video-inner-wrapper,
  .mobile-course-benefits-inner-wrapper,
  .mobile-performance-inner-wrapper,
  .mobile-faculty-details-inner-wrapper,
  .mobile-other-details-inner-wrapper,
  .mobile-faq-inner-wrapper,
  .mobile-storeBack,
  .mobile-subject-tab-container,
  .mobile-buy-now {
    display: block !important;
  }
  .mobile-storeBack {
    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;
    }

    color: #000;
    font-family: Poppins;
    font-size: 1.15rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.8rem;
    cursor: pointer;
    padding: 7px 20px 7px 28px;
  }

  .mobile-store-banner-inner-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    .mobile-landingpage-store-banner-content-and-btn-wrapper {
      position: relative;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .text {
        position: absolute;
        text-align: center;
        color: #fff;
        top: 40%;
        left: 35%;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.4px;
      }

      .new-label {
        position: absolute;
        top: 25%;
        left: 35%;
        padding: 2px 8px;
        font-size: 12px;
        font-weight: bold;
      }
    }

    .mobile-tabs-container {
      display: flex;
      background-color: white;
      align-items: center;
      padding: 0 15px;

      .tabs {
        display: flex;
        flex: 1;
      }

      .tab {
        padding: 10px 15px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        color: #797979;
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 400;
        width: 50%;

        &.active {
          color: #b50503;
          border-bottom-color: #b50503;
        }

        .icon {
          margin-right: 5px;
          vertical-align: middle;
        }
      }

      .contact-icons {
        display: flex;
        gap: 10px;

        .icon-button {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  .mobile-description-inner-wrapper {
    display: flex;
    width: 100%;
    padding: 16px 17px 18px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    margin-top: 30px;

    .mobile-description-content-and-btn-wrapper {
      h3 {
        color: #121212;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.08px;
      }

      .mobile-description-content-wrapper {
        .mobile-description-content {
          .mobile-description-heading {
            color: #505050;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px; /* 164.286% */
            letter-spacing: 0.07px;
            width: 699px;
            button {
              color: #b50503;
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 23px;
              letter-spacing: 0.07px;
            }
          }
        }
      }
    }
  }
  .mobile-video-inner-wrapper {
    display: flex;
    width: 100%;
    padding: 16px 17px 18px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    margin-top: 30px;
    .mobile-video-content-and-btn-wrapper {
      h3 {
        color: #121212;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.08px;
      }
    }
  }
  .course-benefits {
    color: #121212;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    padding: 20px 0px 4px 0px;
  }
  .mobile-course-benefits-inner-wrapper {
    padding: 0px 20px 20px 20px;
    .mobile-course-benefits-list {
      display: flex;
      width: 100%;
      padding: 16px 17px 18px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.06),
        -1px -1px 4px 0px rgba(0, 0, 0, 0.06);

      .mobile-course-benefit-item {
        img {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        span {
          color: #505050;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
  .mobile-performance-inner-wrapper {
    padding: 0px 20px 20px 20px;
    .mobile-performance-benefits-list {
      display: inline-flex;
      padding: 11px 30px 11px 30px;
      align-items: center;
      gap: 24px;
      border-radius: 8px;
      background: #fff3e9;
      width: 100%;
      flex-shrink: 0;
      border-radius: 8px;
      .mobile-performance-benefit-item {
        .mobile-performance-benefit-name {
          img {
            width: 42px;
            height: 42px;
          }
          color: #464646;
          font-family: Poppins, sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.16px;
          margin-left: 30px;
        }
      }
    }
  }
  .mobile-other-details {
    color: #121212;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    padding: 20px 0px 4px 0px;
  }
  .mobile-other-details-inner-wrapper {
    padding: 0px 20px 20px 20px;
    .mobile-other-details-inner-list {
      display: flex;
      width: 100%;
      padding: 16px 17px 18px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.06),
        -1px -1px 4px 0px rgba(0, 0, 0, 0.06);
    }

    ul {
      color: #505050;
    }
    li {
      color: #505050;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .mobile-faq {
    color: #121212;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    padding: 20px 0px 4px 0px;
  }

  .mobile-faq-inner-wrapper {
    padding: 0px 20px 20px 20px;
    .mobile-faq-inner-list {
      display: flex;
      width: 100%;
      padding: 16px 17px 18px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.06),
        -1px -1px 4px 0px rgba(0, 0, 0, 0.06);
    }

    h3 {
      color: #121212;
      font-family: Poppins, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.08px;
      margin-bottom: 16px;
    }

    .mobile-faq-accordion {
      width: 100%;

      .accordion-item {
        border: none;
        border-radius: 8px;
        margin-bottom: 8px;

        .accordion-header {
          .accordion-button {
            background: #f6f8f8;
            color: #000;
            font-family: Poppins, sans-serif;
            font-size: 14px;
            font-weight: 400;
            padding: 12px 16px;

            &:not(.collapsed) {
              color: #000;
              background-color: transparent;
              box-shadow: none;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }

        .accordion-body {
          padding: 0 16px 16px;
          color: #666;
          font-family: Poppins, sans-serif;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .mobile-subject-tab-container {
    padding: 0px 20px 20px 20px;
    .mobile-single-sub {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.5);
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.06),
        -4px -4px 8px 0px rgba(0, 0, 0, 0.06);
      margin-top: 20px;
      margin-bottom: 20px;

      .mobile-sub-content {
        display: flex;
        justify-content: center;
        align-items: center;
        .mobile-sub-image {
          width: 2.25rem;
          height: 2.25rem;
          margin-right: 0.75rem;
        }

        .mobile-subname {
          color: #121212;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 0.2rem;
        }

        .mobile-subtopics {
          color: #696969;
          font-family: Poppins;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .mobile-arrw-image {
        width: 0.9rem;
        height: 0.9rem;
      }
    }
  }
  .mobile-faculty-details {
    color: #121212;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.08px;
    padding: 20px 0px 4px 0px;
  }

  .mobile-faculty-details-inner-wrapper {
    padding: 0px 20px 20px 20px;
    .mobile-other-details-inner-list {
      display: flex;
      width: 100%;
      padding: 16px 17px 18px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      border-radius: 8px;
    }

    .slick-slider {
      width: 100%;
    }

    .mobile-faculty-slide {
      padding: 0 10px;
      background-color: #fff;
      border-radius: 7px;
      .mobile-faculty-slider {
        padding: 10px;
        width: 100%;
        display: flex;
      }

      .mobile-faculty-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 8px;
        margin: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .mobile-faculty-name {
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-weight: 500;
        color: #333;
        margin-left: 8px;
      }

      .mobile-view-more {
        button {
          font-family: Poppins, sans-serif;
          font-size: 12px;
          color: #b80f0d;
          text-decoration: none;
          margin-left: 48px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .mobile-buy-now {
    display: flex !important;
    justify-content: space-between;
    background: #fff;
    padding: 10px;
    top: 552px;
    width: 100%;
    position: fixed;
  }
  .mobile-buy-now-wrapper {
    border-radius: 4px;
    span {
      color: #121212;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.4px;
    }
  }
  .mobile-buy-now-button {
    display: inline-flex;
    height: 43px;
    padding: 12px 20px;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #b80f0d;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    button {
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

@media (max-width: 730px) {
  .mobile-description-heading {
    width: 500px !important;
  }
}
@media (max-width: 601px) {
  .course-benefits {
    margin-top: -80px;
  }
}
@media (max-width: 580px) {
  .mobile-store-banner-inner-wrapper
    .mobile-landingpage-store-banner-content-and-btn-wrapper
    .text {
    top: 53%;
    left: 35%;
    font-size: 14px;
  }
  .mobile-store-banner-inner-wrapper
    .mobile-landingpage-store-banner-content-and-btn-wrapper
    .new-label {
    position: absolute;
    top: 35%;
    left: 35%;
    padding: 2px 8px;
    font-size: 9px;
    font-weight: bold;
  }
}
@media (max-width: 530px) {
  .mobile-description-heading {
    width: 335px !important;
  }
}
@media (max-width: 840px) {
  .faq-inner-wrapper h3 {
    font-size: 12px !important;
    margin-bottom: 0px;
  }
  .faq-inner-wrapper
    .faq-accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    font-size: 10px;
    padding: 9px 9px;
  }
}
