.faculty-details {
  .modal-content {
    width: 34.25rem;
    max-height: 31.9375rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    flex: 1 1 auto; /* Allow the body to grow and shrink as needed */
    overflow-y: auto; /* Enables scrolling if the content exceeds the height */
    padding: 0px 30px 16px 30px !important;

    .faculty-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%; /* Ensure it takes full height */

      .flty-header-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1.13rem;

        img {
          width: 3rem;
          height: 3rem;
          border-radius: 3rem;
          margin-right: 0.75rem;
        }

        .flty-name {
          color: #000;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.005rem;
        }

        .flty-sub {
          color: #5b5b5b;
          font-family: Poppins;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.00438rem;
        }
      }

      .flty-video-wrapper {
        flex-shrink: 0; /* Prevents shrinking */
      }

      .flty-desc-wrapper {
        margin-top: 1.24rem;
        margin-bottom: 1.5rem;
        overflow-y: auto; /* Allows vertical scrolling */
        overflow-x: hidden; /* Prevents horizontal overflow */
        flex-grow: 1; /* Ensures it grows to fill available space */
        max-height: 31.9375rem;
        /* Adjust max-height considering other elements */
        h3 {
          color: #121212;
          text-align: center;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.005rem;
          text-align: start;
        }

        color: #5c5c5c;
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4375rem; /* 164.286% */
      }
    }
  }
}
