.box {
  border-radius: 6px;
  display: flex;
  .card_content {
    width: 100%;
    margin: auto;
    padding: 20px 0px;
    border-radius: 6px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      border-bottom: 1px solid #cccccc;

      .btn {
        background: #fff;
        border: 1px solid #b50503;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        padding: 7px 15px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #121212;
        i {
          color: #b50503;
        }
      }
      .active {
        color: #fff;
        background: #b50503;
        i {
          color: #fff;
        }
      }
      .prevBtn {
        margin: 0px 0px 0px 45px;
      }

      .nextBtn {
        margin: 0px 45px 0px 0px;
      }

      .questionbox {
        width: 70%;
        margin: auto;
        padding: 0px 0px 20px 0px;
        .tp {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          .left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 41%;

            .questionsNum {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #666666;

              span {
                font-weight: 500;
                letter-spacing: 0.01em;
                color: #252525;
              }
            }

            .isqstncrctOrwrng {
              .is_correct {
                display: flex;
                justify-content: space-around;
                align-items: center;
                background: #17a400;
                box-shadow: 0px 1.5303px 1.5303px rgba(0, 0, 0, 0.16);
                border-radius: 18.3636px;
                // width: fit-content;
                padding: 4px 15px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                color: #ffffff;

                img {
                  width: 24px;
                  height: 23px;
                }

                .is_correctText {
                  font-family: Poppins;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 23px;
                  letter-spacing: 0.05em;
                  margin-left: 8px;
                }
              }

              .is_wrong {
                display: flex;
                justify-content: space-around;
                align-items: center;
                background: #c20000;
                box-shadow: 0px 1.5303px 1.5303px rgba(0, 0, 0, 0.16);
                border-radius: 18.3636px;
                // width: fit-content;
                padding: 4px 15px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                color: #ffffff;

                img {
                  width: 24px;
                  height: 23px;
                }

                .is_wrongText {
                  font-family: Poppins;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 23px;
                  letter-spacing: 0.05em;
                  margin-left: 8px;
                }
              }
              .is_unattempted {
                display: flex;
                justify-content: space-around;
                align-items: center;
                background: #ed7800;
                box-shadow: 0px 1.5303px 1.5303px rgba(0, 0, 0, 0.16);
                border-radius: 18.3636px;
                // width: fit-content;
                padding: 4px 15px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 23px;
                color: #ffffff;

                .is_unattempted_icon {
                  width: 30px;
                  height: 30px;
                  background-color: white;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                  .inner_circle {
                    width: 10px;
                    height: 10px;
                    background-color: #ed7800;
                    border-radius: 50%;
                  }
                }

                .is_unattemptedText {
                  font-family: Poppins;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 23px;
                  letter-spacing: 0.05em;
                  margin-left: 8px;
                }
              }
            }
          }

          .right {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #b50503;
            cursor: pointer;
          }
        }

        .btm {
          color: #000;
          font-family: Poppins;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .bottom {
      margin: auto;

      .heading {
        margin-bottom: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #868686;
      }

      .basicAnalysis {
        width: 100%;
        padding: 0 2rem;
        margin-bottom: 1.75rem;

        .heading {
          color: #121212;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .basicAnalysisContent {
          // height: 200px;
          background: #ffffff;
          border: 0.8px solid #c4c4c4;
          border-radius: 4px;
          ul {
            padding: 0px;
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              list-style: none;
              padding: 7.4px 10px;
              border-bottom: 1px solid #d2d2d2;

              .text {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #2e2e2e;
              }
              .num {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #121212;
                text-align: start;
              }

              .basicc {
                color: #3d3d3d;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .basicc1 {
                color: #121212;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .basicc-row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              list-style: none;
              padding: 7.4px 10px;
              border-bottom: 1px solid #d2d2d2;
              background: #eee;
            }
            li:last-child {
              border-bottom: none;
            }
          }
        }
      }

      .timeAnalysis {
        width: 100%;
        padding: 0 2rem;

        .heading {
          color: #121212;
          font-family: Poppins;
          font-size: 1rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .timeAnalysisContent {
          // height: 200px;
          background: #ffffff;
          border: 0.8px solid #c4c4c4;
          border-radius: 4px;
          ul {
            padding: 0px;
            li {
              display: flex;
              justify-content: space-between;
              align-items: center;
              list-style: none;
              padding: 7.4px 10px;
              border-bottom: 0.8px solid #c4c4c4;
              .text {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #2e2e2e;
              }
              .num {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #121212;
              }

              .timee {
                color: #3d3d3d;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              .timee1 {
                color: #121212;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .timee-row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              list-style: none;
              padding: 7.4px 10px;
              border-bottom: 1px solid #d2d2d2;
              background: #eee;
            }
            li:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .cardBgChange {
    background: #fff;
  }
}
