.quesitonInfoSection {
  font-family: Poppins;

  .text_content_wrapper {
    .typeLanguageWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.13rem;

      span {
        color: #5e5e5e;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .qstnTypeKey {
          color: #121212;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .markReview {
        display: flex;

        .bookmark_image_wrapper {
          margin: 0px 10px;

          span {
            margin-right: 5px;
          }

          img {
            cursor: pointer;
          }
        }

        button {
        }
      }
    }

    .qstnCount {
      color: #121212;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .questionBox {
      color: #353535;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem; /* 162.5% */
      letter-spacing: 0.005rem;

      .hide {
        display: none;
      }

      .show {
        display: block;
      }
    }
  }
}
